<div>
  <div class="item-container">
    <div class="sub-header">Gurantor Info (All fields are required)</div>
  </div>
  <div class="item-container left-align">
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Guarantor Name'"
        [(value)]="model.name"
        [(ngModel)]="model.name"
        [hasError]="model.validation.name.hasError"
        [errorMessage]="model.validation.name.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorName', model.name)"
        (blur)="setValue('guarantorName', model.name)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Guarantor Email'"
        [(value)]="model.email"
        [(ngModel)]="model.email"
        [hasError]="model.validation.email.hasError"
        [errorMessage]="model.validation.email.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorEmail', model.email)"
        (blur)="setValue('guarantorEmail', model.email)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Guarantor Phone'"
        [(value)]="model.phone"
        [(ngModel)]="model.phone"
        [hasError]="model.validation.phone.hasError"
        [errorMessage]="model.validation.phone.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorPhone', model.phone)"
        (blur)="setValue('guarantorPhone', model.phone)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
  </div>
  <div class="item-container left-align">
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Guarantor Address'"
        [(value)]="model.address"
        [(ngModel)]="model.address"
        [hasError]="model.validation.address.hasError"
        [errorMessage]="model.validation.address.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorAddress', model.address)"
        (blur)="setValue('guarantorAddress', model.address)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Guarantor City'"
        [(value)]="model.city"
        [(ngModel)]="model.city"
        [hasError]="model.validation.city.hasError"
        [errorMessage]="model.validation.city.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorCity', model.city)"
        (blur)="setValue('guarantorCity', model.city)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper skinny">
      <app-flat-input
        [placeHolder]="'Guarantor Zip'"
        [(value)]="model.zip"
        [(ngModel)]="model.zip"
        [hasError]="model.validation.zip.hasError"
        [errorMessage]="model.validation.zip.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('guarantorZip', model.zip)"
        (blur)="setValue('guarantorZip', model.zip)"
        [disabled]="readonly"
      >
      </app-flat-input>
    </div>
  </div>
  <div class="item-container left-align">
    <div class="item-label-wrapper skinny">
      <span class="item-label">State:</span>
    </div>
    <div class="item-select-wrapper skinny left-close right-margin">
      <app-flat-select
        [selectOptions]="stateList"
        [(ngModel)]="model.state"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.state"
        (selectOptionChange)="setValue('guarantorState', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-checkbox-wrapper" *ngIf="showChk">
      <div class="item-checkbox">
        <mat-checkbox (change)="chkChange($event)"> </mat-checkbox>
      </div>
      <span class="checkbox-text"> Copy reference info? </span>
    </div>
  </div>
</div>
