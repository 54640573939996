<div class="announcement-manage-container">
  <div class="announcement-container">
    <div class="announcement-header">
      <div class="header">Manage Announcements</div>
    </div>
    <div class="announcement-row top-padding no-wrap" *ngIf="this.events">
      <div class="announcement-col col-thin">
        <app-flat-select
          [selectOptions]="events"
          (selectOptionChange)="OnSelectChange($event)"
          [(defaultLabel)]="defaultLabel"
          [(value)]="eventListValue"
          [(resetSelect)]="resetEventsList"
          ngDefaultControl
        ></app-flat-select>
      </div>
      <div class="announcement-col col-thin-extra" *ngIf="selectedEvent">
        <div class="row">
          <div
            *ngIf="!selectedEvent.validation.hasError"
            class="delete-event"
            [ngClass]="{ 'disable-delete': disableDelete }"
            (click)="deleteAnnouncement()"
          >
            <i
              class="material-icons delete-event-button"
              (click)="deleteAnnouncement()"
              >delete</i
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedEvent">
      <div class="announcement-row top-padding">
        <div class="announcement-col col-thin-extra">
          <div class="announcement-item">
            <app-flat-select
              [selectOptions]="categoryList"
              (selectOptionChange)="OnCategorySelectChange($event, false)"
              [(defaultLabel)]="defaultLabel"
              [(value)]="selectedCategory.value"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="announcement-col col-thin">
          <div class="announcement-item input-item">
            <app-flat-input
              [placeHolder]="'Name'"
              [(value)]="selectedEvent.name"
              [hasError]="selectedEvent.validation.name.hasError"
              [errorMessage]="selectedEvent.validation.name.errorMessage"
              (focusChanged)="setUpdateValue('name', selectedEvent.name)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="announcement-col">
          <div class="announcement-item">
            <input
              [matDatepicker]="eventDatePicker"
              class="event-date-input"
              [(ngModel)]="selectedEvent.eventDate"
              (dateInput)="eventDatePicker.close()"
              (dateChange)="
                setUpdateValue('eventDate', selectedEvent.eventDate);
                eventDatePicker.close()
              "
              (focus)="eventDatePicker.open()"
              (change)="setUpdateValue('eventDate', selectedEvent.eventDate)"
            />
            <mat-datepicker-toggle
              class="date-picker-event-date"
              matSuffix
              [for]="eventDatePicker"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #eventDatePicker></mat-datepicker>
            <div class="announcement-item-event-time">
              <input
                type="number"
                class="event-time-input hours"
                min="0"
                max="24"
                [(ngModel)]="selectedEventhour"
                [value]="selectedEventhour | padLeftZero"
                (change)="setUpdateValue('eventHours', selectedEventhour)"
              />
              :
              <input
                type="number"
                class="event-time-input minutes"
                min="00"
                max="60"
                [(ngModel)]="selectedEventMinutes"
                [value]="selectedEventMinutes | padLeftZero"
                (change)="setUpdateValue('eventMinutes', selectedEventMinutes)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="announcement-row top-padding">
        <div class="announcement-col col-thin">
          <div class="announcement-item-description">
            <textarea
              [(ngModel)]="selectedEvent.description"
              value="selectedEvent.description"
              class="description-text"
              placeholder="'Announcement Text'"
              (blur)="setUpdateValue('description', selectedEvent.description)"
            ></textarea>
          </div>
        </div>
        <div class="announcement-col col-thin-extra">
          <div class="announcement-item input-item">
            <app-flat-input
              [placeHolder]="'Video Url'"
              [(value)]="selectedEvent.videoUrl"
              (focusChanged)="
                setUpdateValue('videoUrl', selectedEvent.videoUrl)
              "
            >
            </app-flat-input>
          </div>
        </div>
      </div>
      <div class="announcement-row top-padding">
        <div class="announcement-col col-thin">
          <div class="announcement-item input-item">
            <app-flat-input
              [placeHolder]="'Html-Link Url'"
              [(value)]="selectedEvent.anchorUrl"
              (focusChanged)="
                setUpdateValue('anchorUrl', selectedEvent.anchorUrl)
              "
            >
            </app-flat-input>
          </div>
        </div>
        <div class="announcement-col col-thin-xextra">
          <div class="announcement-item input-item">
            <app-flat-input
              [placeHolder]="'Html-Link Name'"
              [(value)]="selectedEvent.anchorUrlText"
              (focusChanged)="
                setUpdateValue('anchorUrlText', selectedEvent.anchorUrlText)
              "
            >
            </app-flat-input>
          </div>
        </div>
        <div class="announcement-col col-thin-xextra">
          <div class="announcement-item">
            <div class="checkbox-wrapper">
              <mat-checkbox
                id="hasEventDetails"
                [(ngModel)]="selectedEvent.hasEventDetails"
                [(checked)]="selectedEvent.hasEventDetails"
                (change)="updateAnnoucement()"
              >
              </mat-checkbox>
              <span class="checkbox-text">Show Event Details </span>
            </div>
          </div>
        </div>
      </div>
      <div class="announcement-row top-padding">
        <div
          class="announcement-col col-thin"
          *ngIf="selectedEvent.eventFilesSummaryList"
        >
          <div class="files-header">Images</div>
          <div
            class="row"
            *ngFor="let file of selectedEvent.eventFilesSummaryList"
          >
            <div class="announcement-col col-thin">
              <div class="announcement-item input-item">
                <app-flat-input
                  [placeHolder]="'Image'"
                  [(value)]="file.uploadedFileName"
                  [buttonType]="'delete'"
                  [thumbnail]="file.thumbNail"
                  [hasError]="file.validation.uploadedFileName.hasError"
                  [errorMessage]="file.validation.uploadedFileName.errorMessage"
                  (focusChanged)="updateImage(file.uploadedFileName, file)"
                  (actionTriggered)="deleteImage(file)"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
        </div>
        <div class="announcement-col col-thin">
          <div class="announcement-item input-item">
            <app-flat-input
              [placeHolder]="'Address'"
              [(value)]="selectedEvent.address"
              (focusChanged)="setUpdateValue('address', selectedEvent.address)"
            >
            </app-flat-input>
          </div>
        </div>
      </div>
    </div>
    <button
      *ngIf="selectedEvent"
      mat-mini-fab
      class="add-image-button-fab"
      matTooltip="Add Image"
      matTooltipPosition="left"
      (click)="imageInput.click()"
    >
      <input
        hidden
        (change)="onImageSelected()"
        #imageInput
        type="file"
        id="image"
      />
      <i class="material-icons md-16">add</i>
    </button>
    <button
      mat-fab
      class="add-event-button-fab"
      matTooltip="Add Announcement"
      matTooltipPosition="left"
      (click)="addEvent()"
      *ngIf="!showAddEvent"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-announcement-container"
      *ngIf="showAddEvent"
      [ngClass]="{ visible: showAddEvent, invisible: !showAddEvent }"
    >
      <div class="new-announcement">
        <div class="announcement-row top-padding">
          <div class="announcement-col">
            <div class="announcement-item">
              <app-flat-select
                [selectOptions]="categoryList"
                (selectOptionChange)="OnCategorySelectChange($event, true)"
                [(defaultLabel)]="defaultLabel"
                [(value)]="newAnnouncement.categoryId"
                ngDefaultControl
              ></app-flat-select>
            </div>
          </div>
          <div class="announcement-col col-wide">
            <div class="announcement-item date-picker">
              <input
                [matDatepicker]="newEventDatePicker"
                class="event-date-input new"
                [(ngModel)]="newAnnouncement.eventDate"
                (dateInput)="newEventDatePicker.close()"
                (dateChange)="newEventDatePicker.close()"
                (focus)="newEventDatePicker.open()"
              />
              <mat-datepicker-toggle
                class="date-picker-event-date"
                matSuffix
                [for]="eventDatePicker"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #newEventDatePicker></mat-datepicker>
              <div class="announcement-item-event-time">
                <input
                  type="number"
                  class="event-time-input hours new"
                  min="0"
                  max="24"
                  [(ngModel)]="newAnnouncementHour"
                  [value]="newAnnouncementHour | padLeftZero"
                />
                :
                <input
                  type="number"
                  class="event-time-input minutes new"
                  min="0"
                  max="60"
                  [(ngModel)]="newAnnouncementMinutes"
                  [value]="newAnnouncementMinutes | padLeftZero"
                />
              </div>
            </div>
          </div>
          <div class="announcement-row top-padding">
            <div class="announcement-col">
              <div class="announcement-item input-item">
                <app-flat-input
                  [placeHolder]="'Name'"
                  [(value)]="newAnnouncement.name"
                  [hasError]="newAnnouncement.validation.name.hasError"
                  [errorMessage]="newAnnouncement.validation.name.errorMessage"
                  [(ngModel)]="newAnnouncement.name"
                  (focusChanged)="validateNew('name')"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="announcement-col padding-left">
              <div class="announcement-item-description new">
                <textarea
                  [(ngModel)]="newAnnouncement.description"
                  value="newAnnouncement.description"
                  class="description-text new"
                  placeholder="Announcement Text"
                  [(ngModel)]="newAnnouncement.description"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="announcement-row no-wrap top-padding">
            <div class="announcement-col col-thin">
              <div>
                <app-flat-input
                  [placeHolder]="'Address'"
                  [(value)]="newAnnouncement.address"
                  [(ngModel)]="newAnnouncement.address"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="announcement-col">
            <div class="announcement-item">
              <div class="form-buttons">
                <button
                  type="button"
                  class="text-button"
                  [disabled]="newAnnouncement.validation.name.hasError"
                  (click)="saveAnnouncement()"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  class="text-button"
                  (click)="closeAddAnnouncement()"
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
