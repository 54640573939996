<app-header></app-header>
<div class="rooms-container">
  <div class="rooms-section">
    <div class="rooms-header-section">
      <div class="header">
        <div class="header-title">Tenant Rooms</div>
        <div class="header-button">
          <button
            mat-raised-button
            class="waiting-list-btn"
            (click)="waitList()"
          >
            Add me to wait list
          </button>
        </div>
      </div>
    </div>
    <div class="info-section">
      <app-rent-info [(showRentBreakup)]="showRentBreakup"></app-rent-info>
    </div>
    <div class="rooms-info-section">
      <div class="rooms-card-row" *ngIf="rooms">
        <div *ngFor="let room of rooms">
          <app-room-card [room]="room"></app-room-card>
        </div>
      </div>
      <div class="empty-row" *ngIf="rooms && rooms.length === 0">
        <div class="empty-message">Rooms are not configured yet!</div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
