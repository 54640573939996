import { SignatureDialogModel } from "./../../models/signature-dialog-model";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { AddFileRequest } from "src/app/models/add-file-request";
import { NgSignaturePadOptions, SignaturePadComponent  } from "@almothafar/angular-signature-pad";
import html2canvas from "html2canvas";
import { Signature } from "src/app/models/signature";

@Component({
  standalone: false,
  selector: "app-signature-modal",
  templateUrl: "./signature-modal.component.html",
  styleUrls: ["./signature-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureModalComponent implements AfterViewInit {
  canvasWidth = 300;
  canvasHeight = 120;

  @ViewChild('signature') public signaturePad: SignaturePadComponent;
  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 300,
    canvasHeight: 180
  }



  model: SignatureDialogModel;
  faTimes = faTimes;
  signatureFile: AddFileRequest;
  sigModel: Signature;
  showClear: boolean;
  usePrintSig = false;
  printSigImage: any;

  constructor(
    public dialogRef: MatDialogRef<SignatureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignatureDialogModel
  ) {
    this.model = Object.assign({}, data);
  }



  ngAfterViewInit() {

    if(this.signaturePad) {
      this.signaturePad.set("minWidth", 2);
      this.signaturePad.clear();
    }

  }

  drawStart(event: MouseEvent | Touch) {
    this.model.hasError = false;
  }

  async saveSignature(event: MouseEvent | Touch) {
    if (this.signaturePad.isEmpty()) {
      this.model.hasError = true;
      this.model.errorMessage = "Required";
      return;
    }
    this.showClear = true;

    const dataURL = this.signaturePad.toDataURL("image/png");

    const data = atob(dataURL.substring("data:image/png;base64,".length)),
      asArray = new Uint8Array(data.length);
    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }
    const blob = new Blob([asArray], { type: "image/png" });
    const contentBuffer: any = await this.readFileAsync(blob);
    this.signatureFile = new AddFileRequest(
      "G",
      `${this.model.signee} Signature`,
      "",
      contentBuffer,
      null
    );
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = reject;
    });
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureFile = null;
    this.showClear = false;
    this.model.hasError = true;
    this.model.errorMessage = "Required";
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.sigModel = {
      signatureFile: this.signatureFile,
      useExisting: this.model.useExisting,
      usePrint: this.usePrintSig,
    };
    this.dialogRef.close(this.sigModel);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.sigModel = {
      signatureFile: this.signatureFile,
      useExisting: this.model.useExisting,
      usePrint: this.usePrintSig,
    };
    this.dialogRef.close(this.sigModel);
  }

  async onUsePrintSigChange(event: any) {
    if (event.checked) {
      html2canvas(document.querySelector("#printSig"))
        .then((canvas) => {
          const dataURL = canvas.toDataURL("image/png");
          const data = atob(dataURL.substring("data:image/png;base64,".length)),
            asArray = new Uint8Array(data.length);
          for (var i = 0, len = data.length; i < len; ++i) {
            asArray[i] = data.charCodeAt(i);
          }
          const blob: any = new Blob([asArray], { type: "image/png" });
          const contentBuffer: any = this.readFileAsync(blob);
          return contentBuffer;
        })
        .then((blob) => {
          this.signatureFile = new AddFileRequest(
            "G",
            `${this.model.signee} Signature`,
            "",
            blob,
            null
          );
          this.onConfirm();
        });
    }
  }

  onUseExistingChange(event: any) {
    if (event.checked) {
      this.onConfirm();
    }
  }
}
