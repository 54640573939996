<div class="confirm-dialog-container">
  <div class="dialog-content-container header">
    <div class="header-icon">
      <fa-icon
        [icon]="faExclamationTriangle"
        *ngIf="type === 'alert'"
      ></fa-icon>
    </div>
    <div class="dialog-header">
      {{ title }}
    </div>
  </div>
  <div class="dialog-content-container message">
    <div class="dialog-messsage">
      <p>{{ message }}</p>
    </div>
  </div>

  <div class="dialog-buttons">
    <button
      mat-button
      class="btn-cancel"
      (click)="onDismiss()"
      *ngIf="showCancel"
    >
      No
    </button>
    <button
      mat-raised-button
      color="primary"
      class="btn-ok"
      (click)="onConfirm()"
    >
      OK
    </button>
  </div>
</div>
