import { TenantValidate } from "./../../models/tenant-validate";
import { Component, OnInit } from "@angular/core";
import { UserCredential } from "../../models/user-credentials";
import { UserAuth } from "src/app/models/user-auth";
import { SecurityService } from "../../services/security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PasswordReset } from "src/app/models/password-reset";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { MatDialog } from "@angular/material/dialog";
import { RegisterUserComponent } from "../register-user/register-user.component";
import { TenantService } from "src/app/services/tenant.service";

@Component({
  standalone: false,
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  user: UserCredential = new UserCredential();
  authObject: UserAuth = null;
  returnUrl: string;
  resetToken: string;
  incorrectCredentials = false;
  showLogin = true;
  showForgotPassword = false;
  showPasswordReset = false;
  isCheckingCreds = false;
  isValid = false;
  emailForm: FormGroup;
  resetPasswordForm: FormGroup;
  passwordReset = new PasswordReset();
  passwordPattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  tenantVerificationToken: string;

  constructor(
    private securityService: SecurityService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
    this.resetToken = this.route.snapshot.queryParamMap.get("token");
    this.tenantVerificationToken =
      this.route.snapshot.queryParamMap.get("verificationToken");
    if (this.resetToken) {
      this.resetToken = this.resetToken.replace("/", "");
      this.showLogin = false;
      this.showPasswordReset = true;
    }
    if (this.tenantVerificationToken) {
      this.tenantVerificationToken = this.tenantVerificationToken.replace(
        "/",
        ""
      );
    }

    this.emailForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: [
          "",
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        confirm: [
          "",
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
      },
      {
        validator: this.mustMatch("password", "confirm"),
      }
    );
  }
  login() {
    this.isCheckingCreds = true;

    this.securityService.login(this.user).subscribe((resp) => {
      if (resp) {
        this.authObject = resp;
        if (this.authObject.isAuthenticated) {
          this.isCheckingCreds = false;
          this.isValid = true; // triggers bubble animation
          if (
            this.tenantVerificationToken &&
            this.returnUrl === "leaseRequest"
          ) {
            const request = new TenantValidate();
            request.token = this.tenantVerificationToken;
            this.tenantService.tenantValidate(request).subscribe((data) => {
              if (data) {
                this.router.navigate(["leaseRequest"]);
              }
            });
          }
        } else if (this.authObject.isAuthenticated === false) {
          this.incorrectCredentials =
            !this.securityService.auth.isAuthenticated;
          this.isCheckingCreds = false;
        }
      }
    });
  }

  animationFinished(e: Event) {
    if (this.authObject.isAuthenticated && this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else if (this.authObject.isAuthenticated) {
      this.router.navigate(["profile"]);
    }
  }
  toggleForgotPassword() {
    this.showForgotPassword = !this.showForgotPassword;
    this.showLogin = !this.showLogin;
    this.showPasswordReset = false;
    this.emailForm.reset();
  }

  togglePasswordReset() {
    this.showPasswordReset = !this.showPasswordReset;
    this.showLogin = !this.showLogin;
    this.showForgotPassword = false;
  }

  sendEmail(emailAddress: string) {
    this.passwordReset.userName = emailAddress;
    this.securityService.initiatePasswordReset(this.passwordReset).subscribe();
    this.toggleForgotPassword();
    const passwordResetSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 5000,
        panelClass: "message",
        data: {
          dismissSnackbar: () => {
            this.snackBar.dismiss();
          },
          displayText: "Recovery link sent!",
          type: "success",
        },
      }
    );
  }

  changePassword(password: string) {
    this.passwordReset.token = this.resetToken;
    this.passwordReset.password = password;
    let message = "";
    this.securityService.resetPassword(this.passwordReset).subscribe((data) => {
      if (data === "error") {
        message = "There is an error changing your password";
      } else {
        message = "Your password is changed!";
        this.togglePasswordReset();
      }
      const passwordResetSnackBar = this.snackBar.openFromComponent(
        ConfirmationSnackbarComponent,
        {
          horizontalPosition: "center",
          duration: 5000,
          panelClass: "message",
          data: {
            dismissSnackbar: () => {
              this.snackBar.dismiss();
            },
            displayText: message,
            type: "success",
          },
        }
      );
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.emailForm.controls;
  }

  get p() {
    return this.resetPasswordForm.controls;
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  resetIncorrectCredentials() {
    setTimeout(() => (this.incorrectCredentials = false), 3000);
  }

  register(): void {
    const dialogRef = this.dialog.open(RegisterUserComponent, {
      height: "650px",
      width: "450px",
    });
    // dialogRef.afterClosed().subscribe(data => {
    //     if (data) {
    //      this.refreshProperties.emit(true);
    //     }
    //  });
  }
}
