import { VehicleInfo } from "./../../models/vehicle-info";
import { SignatureDialogModel } from "../../models/signature-dialog-model";
import { AddendumInfo } from "../../models/addendum-info";
import { Router } from "@angular/router";
import { TenantAddendum } from "../../models/tenant-addendum";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { LeaseInfo } from "src/app/models/lease-info";
import { LeaseService } from "src/app/services/lease.service";
import { AddendumConfirmed } from "src/app/models/addendum-confirmed";
import { VehicleRequest } from "src/app/models/vehicle-request";
import { VehicleValidation } from "src/app/models/vehicle-validation";
import { ValidationError } from "src/app/models/validation-error";
import * as moment from "moment";

@Component({
  standalone: false,
  selector: "app-lease-addendum",
  templateUrl: "./lease-addendum.component.html",
  styleUrls: ["./lease-addendum.component.scss"],
})
export class LeaseAddendumComponent implements OnInit {
  constructor(private leaseService: LeaseService) {}
  @Input() model: TenantAddendum;
  @Output() notifyParent = new EventEmitter();
  addendumSource: string;
  showAddendumRequest = true;
  addendum: AddendumInfo;
  disableExtControls = false;
  disableSig = false;
  showMessage = false;
  addendumMessage: string;
  title = "Lease Extension Addendum";
  vehicle: VehicleRequest;
  sigModel: SignatureDialogModel;
  ngOnInit() {
    if (this.model) {
      this.title =
        this.model.type === "Parking-Add"
          ? "Add Parking Addendum"
          : this.model.type === "Parking-Term"
          ? "Terminate Parking Addendum"
          : this.title;
      this.sigModel = {
        signee: "Tenant",
        role: "member",
        hasError: false,
        errorMessage: null,
        showCancel: false,
        existingSig: this.model.tenantSig,
        signeeName: `${this.model.tenant.memberInfo.firstName} ${this.model.tenant.memberInfo.lastName}`,
        useExisting: false,
      };
      const pendingAddendum =
        this.model.tenant.leaseInfo.addendums &&
        this.model.tenant.leaseInfo.addendums.length > 0
          ? this.model.tenant.leaseInfo.addendums.find(
              (a) => a.type === this.model.type && a.statusId === 2
            )
          : null;
      if (pendingAddendum != null) {
        this.addendum = pendingAddendum;
        this.getCurrentAddendum();
        return;
      }
      if (this.model.type === "Parking-Add") {
        this.vehicle = {
          id: null,
          licensePlate: "",
          model: "",
          name: "",
          year: "",
          tenantId: this.model.tenant.id,
          parkingSpaceId: this.model.availableParkingSpace.id,
          validation: new VehicleValidation(
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            true
          ),
        };
        this.vehicle.validation.hasError =
          this.vehicle.name.length > 0 &&
          this.vehicle.licensePlate.length > 0 &&
          this.vehicle.model.length > 0 &&
          this.vehicle.year.length > 0
            ? false
            : true;
      }
    }
  }
  newVehicleAdded(newVehicle: VehicleInfo) {
    if (!newVehicle) {
      return;
    }
    this.model.tenant.vehicles = !this.model.tenant.vehicles
      ? []
      : this.model.tenant.vehicles;
    this.model.tenant.vehicles.splice(0, 0, newVehicle);
    this.vehicle.id = newVehicle.id;
  }

  getAdddendumData(leaseInfo: LeaseInfo) {
    this.model.tenant.leaseInfo = Object.assign({}, leaseInfo);
    if (
      this.model.tenant.leaseInfo.addendums &&
      this.model.tenant.leaseInfo.addendums.length > 0
    ) {
      this.addendum = this.model.tenant.leaseInfo.addendums.find(
        (a) => a.type === this.model.type
      );
      if (this.addendum && this.addendum.id > 0) {
        this.getCurrentAddendum();
      }
    }
  }

  getCurrentAddendum() {
    if (!this.addendum) {
      return;
    }
    if (!this.addendum.id) {
      return;
    }
    this.leaseService
      .getCurrentAddendum(this.addendum.leaseId, this.addendum.type)
      .subscribe((data) => {
        if (data) {
          this.addendumSource = data.file.url;
          this.showAddendumRequest = false;
        }
      });
  }
  confirmAddendum() {
    if (!this.addendum) {
      return;
    }
    this.leaseService.confirmAddendum(this.addendum.id).subscribe((data) => {
      let confirm: AddendumConfirmed = {
        addendumId: this.addendum.id,
        leaseInfo: data,
        vehicle:
          this.model.tenant.vehicles && this.model.tenant.vehicles.length > 0
            ? this.model.tenant.vehicles[0]
            : null,
      };
      this.leaseService.addendumConfirmed(confirm);
      this.disableExtControls = true;
      this.disableSig = true;
      this.showAddendumRequest = true;
      this.setAddendumMessage();
      this.showMessage = true;
      this.notifyParent.emit(confirm);
    });
  }

  cancel() {
    if (!this.addendum) {
      return;
    }
    this.leaseService.removeAddendum(this.addendum.id).subscribe((res) => {
      if (res) {
        this.addendumSource = null;
        this.addendum = null;
        this.setAddendumMessage();
        this.showMessage = false;
        this.disableExtControls = false;
        this.showAddendumRequest = true;
      }
    });
  }

  setAddendumMessage() {
    if (!this.addendum) {
      this.addendumMessage = null;
      return;
    }
    if (
      this.addendum &&
      this.addendum.type === "Lease-Ext" &&
      this.addendum.statusId === 2
    ) {
      this.addendumMessage =
        "Your lease extension request is successfully submitted and awaiting ICB signature." +
        "Once extension is signed, your lease will be updated and you will recieve a confirmation email.";
    }
  }

  getVehicle(newVehicle: VehicleRequest) {
    this.vehicle = Object.assign({}, newVehicle);
  }
}
