<div *ngIf="model as vm" class="school-parent-container">
  <form [formGroup]="schoolParentForm" (ngSubmit)="submit()">
    <div class="page-form">
      <div class="sub-header-container">
        <div class="title">Parent Information</div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Parent First Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" formControlName="firstName" [(ngModel)] = "parent.firstName"/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['firstName'].errors && schoolParentForm.controls['firstName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Parent Last Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="lastName" [(ngModel)] = "parent.lastName"/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['lastName'].errors && schoolParentForm.controls['lastName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-field">
          <div class="form-field-title">Parent Email</div>
          <div class="input-wrapper small">
            <input type="text" inputText class="input-rounded" inputText formControlName="email" [(ngModel)] = "parent.email" readonly>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['email'].errors && schoolParentForm.controls['email'].errors['required']">Required</span>
                  <span *ngIf="schoolParentForm.controls['email'].errors && schoolParentForm.controls['email'].errors['email']">Invalid email</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>

      <div class="form-group value-group">
        <div class="form-field" *ngIf = "gender">
          <div class="form-field-title">Gender</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="vm.genderList"
                [(ngModel)]="gender"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="gender"
                (selectOptionChange)="setGender($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Parent Phone</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="phone" [mask]="mask" [(ngModel)] = "parent.phone"
            />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['phone'].errors && schoolParentForm.controls['phone'].errors['required']">Required</span>
                  <span *ngIf="schoolParentForm.controls['phone'].errors && schoolParentForm.controls['phone'].errors['pattern']">Invalid Phone</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Spouse First Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="spouseFirstName" [(ngModel)] = "spouse.firstName"/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['spouseFirstName'].errors &&schoolParentForm.controls['spouseFirstName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Spouse Last Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="spouseLastName" [(ngModel)] = "spouse.lastName"/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['spouseLastName'].errors && schoolParentForm.controls['spouseLastName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-field">
          <div class="form-field-title">Spouse Email</div>
          <div class="input-wrapper small">
            <input type="text" pInputText class="input-rounded" inputText formControlName="spouseEmail" [(ngModel)] = "spouse.email"
            />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['spouseEmail'].errors && schoolParentForm.controls['spouseEmail'].errors['required']">Required</span>
                  <span *ngIf="schoolParentForm.controls['spouseEmail'].errors && schoolParentForm.controls['spouseEmail'].errors['email']">Invalid email</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field" *ngIf = "spouseGender">
          <div class="form-field-title">Spouse Gender</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="vm.genderList"
                [(ngModel)]="spouseGender"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="spouseGender"
                (selectOptionChange)="setSpouseGender($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Spouse Phone</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="spousePhone" [mask]="mask" [(ngModel)] = "spouse.phone"
           />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolParentForm.controls['spousePhone'].errors && schoolParentForm.controls['spousePhone'].errors['required']">Required</span>
                  <span *ngIf="schoolParentForm.controls['spousePhone'].errors && schoolParentForm.controls['spousePhone'].errors['pattern']">Invalid Phone</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Home Address</div>
            <div class="input-wrapper small">
              <input type="text" inputText class="input-rounded" formControlName="address" [(ngModel)] = "parent.address"/>
              <div class="validation-container">
                <div class="validation-icon">
                  <i class="material-icons error-icon">error</i>
                </div>
                <div class="validation-content">
                  <span class="validation-message validation-left">
                    <span *ngIf="schoolParentForm.controls['address'].errors && schoolParentForm.controls['address'].errors['required']">Required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">City</div>
            <div class="input-wrapper small">
              <input type="text" inputText class="input-rounded" inputText formControlName="city" [(ngModel)] = "parent.city"/>
              <div class="validation-container">
                <div class="validation-icon">
                  <i class="material-icons error-icon">error</i>
                </div>
                <div class="validation-content">
                  <span class="validation-message validation-left">
                    <span *ngIf="schoolParentForm.controls['city'].errors && schoolParentForm.controls['city'].errors['required']">Required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Zip</div>
            <div class="input-wrapper x-small">
              <input type="text" class="input-rounded" inputText formControlName="zip" [(ngModel)] = "parent.zip"/>
              <div class="validation-container">
                <div class="validation-icon">
                  <i class="material-icons error-icon">error</i>
                </div>
                <div class="validation-content">
                  <span class="validation-message validation-left">
                    <span *ngIf="schoolParentForm.controls['zip'].errors && schoolParentForm.controls['zip'].errors['required']">Required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Primary Contact</div>
            <div class="check-box-group">
              <span
                *ngFor="
                  let primary of vm.primaryContactList
                "
              >
                <mat-checkbox
                  [disableRipple]="true"
                  class="example-margin"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="primary.checked"
                >
                  {{ primary.label }}
                </mat-checkbox>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-field">
            <div class="btn-container">
              <button
              mat-flat-button
              class="btn btn-lacoste btn-save"
              type="submit"
              [disabled]="!schoolParentForm.valid"
            >
            {{submitBtnText}}
            </button>
            </div>


          </div>
        </div>

    </div>
  </form>
  </div>

