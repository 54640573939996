<app-header></app-header>
<div class="ieb-container">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center" animationDuration="0ms">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="link.active"
      (click)="toggleTab(link.emptyResultType)"
    >
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <!-- <router-outlet></router-outlet> -->
  </mat-tab-nav-panel>
  <div
    *ngIf="emptyResultType === 'info'"
    class="empty-results-container"
  >
  <app-ieb-information (redirectEvent)="toggleTab($event)"></app-ieb-information>
  </div>
  <div *ngIf="emptyResultType === 'curriculum'" class="empty-results-container">
    <app-ieb-curriculum></app-ieb-curriculum>
  </div>
  <div *ngIf="emptyResultType === 'volunteer'" class="empty-results-container">
    <app-ieb-volunteers></app-ieb-volunteers>
  </div>
  <div *ngIf="emptyResultType === 'contact'" class="empty-results-container">
    <app-ieb-contacts></app-ieb-contacts>
  </div>
  <div *ngIf="emptyResultType === 'registration'" class="empty-results-container">
    <app-ieb-registration></app-ieb-registration>
  </div>
  <div *ngIf="emptyResultType === 'iebManage'" class="empty-results-container">
    <app-ieb-manage></app-ieb-manage>
  </div>
</div>
<app-footer></app-footer>
<!-- <router-outlet></router-outlet> -->
