<app-header></app-header>
<div class="addendum-manage-container">
  <div class="addendum-manage-section">
    <div class="addendum-header-section">
      <div class="header">
        <div class="header-title">
          <div class="title">Manage Lease Addendums</div>
          <div class="title" *ngIf="tenant">
            - Tenant {{ tenant.memberInfo.firstName }}
            {{ tenant.memberInfo.lastName }}
          </div>
        </div>
      </div>
    </div>
    <div class="page-row">
      <div class="row-content">
        <div *ngIf="tenant" class="item-sec">
          <div class="left">
            <div class="child-container" *ngIf="extModel">
              <app-lease-ext-request
                [tenantInfo]="extModel"
                [showSubHeader]="true"
                [(disableControls)]="disableChildControls"
                [(disableSig)]="disableSig"
                [(sigModel)]="sigModel"
                (notifyParent)="UpdateAddendum($event)"
              ></app-lease-ext-request>
            </div>
            <div class="child-container" *ngIf="parkingModel">
              <app-parking-addendum
                [model]="parkingModel"
                [showInfo]="false"
                [(disableControls)]="disableChildControls"
                [(disableSig)]="disableSig"
                [(sigModel)]="sigModel"
                (notifyParent)="UpdateAddendum($event)"
              ></app-parking-addendum>
            </div>
          </div>
          <div class="right">
            <div class="doc-button" *ngIf="extDoc">
              <a
                [href]="extDoc.file.url | safe"
                class="download-link"
                target="_blank"
                matTooltip="Download"
                matTooltipPosition="below"
              >
                <fa-icon [icon]="faDownload" class="icon-download"> </fa-icon>
                {{ extDoc.name }}
              </a>
            </div>
            <div class="doc-button" *ngIf="parkingAddDoc">
              <a
                [href]="parkingAddDoc.file.url | safe"
                class="download-link"
                target="_blank"
                matTooltip="Download"
                matTooltipPosition="below"
              >
                <fa-icon [icon]="faDownload" class="icon-download"> </fa-icon>
                {{ parkingAddDoc.name }}
              </a>
            </div>
            <div class="doc-button" *ngIf="parkingTermDoc">
              <a
                [href]="parkingTermDoc.file.url | safe"
                class="download-link"
                target="_blank"
                matTooltip="Download"
                matTooltipPosition="below"
              >
                <fa-icon [icon]="faDownload" class="icon-download"> </fa-icon>
                {{ parkingTermDoc.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
