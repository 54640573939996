<div class="page-container" *ngIf="dataSource$ | async as vm; else loading">
  <div class="page-header">
    <div class="header">
      School Teachers
    </div>
  </div>

  <div class="page-table-container">
    <div class="top-btn-container">
      <div class="panel-btn-wrapper">
        <button mat-mini-fab class="btn btn-thin btn-secondary-blue" (click)="addTeacher()" matTooltip="Add Teacher">
          <span class="icon"><fa-icon [icon]="faPlus"></fa-icon></span>
          Add Teacher
        </button>
      </div>

    </div>
    <table mat-table [dataSource]="vm" matSort>
      <ng-container matColumnDef="name">
        <th class="page-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th
          class="page-table-header hide-column"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Email
        </th>
        <td mat-cell *matCellDef="let item" class="hide-column">
          {{ item.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="validTill">
        <th
          class="page-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Valid Till
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.validTill | customDate}}
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="editTeacher(row)"
        [ngClass]="row.rowClass"
      ></tr>
    </table>
  </div>

</div>
<ng-template #loading>
  <div class="spinner-container">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</ng-template>
