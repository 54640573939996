import { TenantCustomPayment } from './../models/tenant-custom-payment';
import { ServiceRequest } from "./../models/service-request";
import { TenantOverride } from "./../models/tenant-override";
import config from 'src/assets/config.json';
import { StripeSession } from "./../models/stripe-session";
import { TenantListResponse } from "src/app/models/tenant-list-response";
import { TenantValidate } from "./../models/tenant-validate";
import { TenantInfo } from "./../models/tenant-info";
import { TenantRequest } from "./../models/tenant-request";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GenericDataService } from "./generic-data.service";
import { ListRequest } from "../models/list-request";
import { TenantPaymentSummary } from "../models/tenant-payment-summary";
import { Rent } from "../models/rent";
import { TenantUpdate } from "../models/tenant-update";
import { StripePayment } from "../models/stripe-payment";
import { WaitToTenantRequest } from "../models/wait-to-tenant-request";
import { first, tap } from "rxjs/operators";
import { LateFeeWaiver } from "../models/late-fee-waiver";
import { StripeTenantPayment } from '../models/stripe-tenant-payment';
declare const Stripe;
@Injectable({
  providedIn: "root",
})
export class TenantService {
  public tenantSubject$ = new Subject<boolean>();
  tenantRequest: TenantRequest;
  tenant: TenantInfo;
  listResponse: TenantListResponse;
  paymentSummary: TenantPaymentSummary;
  tenantId: number;
  rent: Rent;
  redirectUrl: string;
  stripeSession: StripeSession;
  public refCheckEvent$ = new Subject<boolean>();
  lateFeeWaiverRemove$ = new Subject<boolean>();
  //addendumReqClicked$ = new BehaviorSubject<TenantAddendum | null>(null);
  verifyAddendumsClicked$ = new BehaviorSubject<TenantInfo | null>(null);
  constructor(
    private genericDataService: GenericDataService

  ) {}

  public tenantList(listRequest: ListRequest): any {
    this.genericDataService.endPoint = "Tenant/List";
    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(listRequest).subscribe((data) => {
        this.listResponse = Object.assign(data);
        subscriber.next(this.listResponse);
      });
    });
    return listObservable$;
  }

  public tenantAdd(request: TenantRequest): any {
    this.genericDataService.endPoint = "Tenant/Add";
    const addObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        subscriber.next(this.tenant);
      });
    });
    return addObservable$;
  }

  public tenantUpdate(request: TenantRequest): any {
    this.genericDataService.endPoint = "Tenant/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        subscriber.next(this.tenant);
      });
    });
    return updateObservable$;
  }

  public tenantRoomUpdate(request: TenantUpdate): any {
    this.genericDataService.endPoint = "Tenant/Room/Update";
    const updateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.tenant = Object.assign({}, data);
        subscriber.next(this.tenant);
      });
    });
    return updateObservable$;
  }

  public tenantRemoveFile(id: number): any {
    this.genericDataService.endPoint = "Tenant/Remove/File";
    const removeObservable$ = new Observable((observer) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.tenantSubject$.next(true);
      });
    });
    return removeObservable$;
  }

  public tenantGet(id: number): any {
    this.genericDataService.endPoint = "Tenant";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<TenantInfo>(null, id)
        .subscribe((data) => {
          this.tenant = Object.assign({}, data);
          subscriber.next(this.tenant);
        });
    });
    return getObservable$;
  }
  public tenantGetByMember(memberId: number): any {
    this.genericDataService.endPoint = "Tenant/Member";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<TenantInfo>(null, memberId)
        .subscribe((data) => {
          this.tenant = Object.assign({}, data);
          subscriber.next(this.tenant);
        });
    });
    return getObservable$;
  }

  public tenantPayment(request: StripeTenantPayment): any {
    this.genericDataService.endPoint = "Tenant/Payment";
    return this.genericDataService.post<any>(request).pipe(first());
  }

  public tenantMonthlyPayment(request: StripeTenantPayment): any {
    this.genericDataService.endPoint = "Tenant/Payment/Monthly";
    return this.genericDataService.post<any>(request).pipe(first());
  }

  public tenantCustomPayment(request: TenantCustomPayment): any {
    this.genericDataService.endPoint = "Tenant/Payment/Custom";
    return this.genericDataService.post<any>(request).pipe(first());
  }

  public checkoutSession(request: StripeTenantPayment): void {
    this.genericDataService.endPoint = "Tenant/CheckoutSession";
    this.genericDataService.post<any>(request).subscribe((data) => {
      this.stripeSession = Object.assign({}, data);
      this.redirectToCheckout();
    });
  }
  redirectToCheckout() {
    const stripe = Stripe(config.stripePublishableKey);
    stripe.redirectToCheckout({
      sessionId: this.stripeSession.sessionId,
    });
  }
  public tenantValidate(request: TenantValidate): any {
    this.genericDataService.endPoint = "Tenant/Validate";
    const validateObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.tenantId = Object.assign({}, data);
        subscriber.next(this.tenantId);
      });
    });
    return validateObservable$;
  }

  public rentByName(name: string): any {
    this.genericDataService.endPoint = "Tenant/Rent";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService.readById<Rent>(null, name).subscribe((data) => {
        this.rent = Object.assign({}, data);
        subscriber.next(this.rent);
      });
    });
    return getObservable$;
  }

  public determineRedirect(memberId: number, requestedUrl: string): any {
    requestedUrl = requestedUrl.length == 0 ? null : requestedUrl;
    this.genericDataService.endPoint = "Tenant/Redirect";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readByIds<string>(null, memberId, requestedUrl)
        .subscribe((data) => {
          this.redirectUrl = data && data.length > 0 ? `/${data}` : data;
          subscriber.next(this.redirectUrl);
        });
    });
    return getObservable$;
  }

  public tenantReferenceCheck(tenantId: number, refCheck: boolean): any {
    this.genericDataService.endPoint = "Tenant/Reference";
    this.genericDataService
      .readByIds<any>(null, tenantId, refCheck)
      .subscribe((data) => {
        this.refCheckEvent$.next(true);
      });
    return this.refCheckEvent$;
  }

  public tennatPaymentSummary(tenantId: number): any {
    this.genericDataService.endPoint = "Tenant/PaymentSummary";
    const getObservable$ = new Observable((subscriber) => {
      this.genericDataService
        .readById<TenantPaymentSummary>(null, tenantId)
        .subscribe((data) => {
          this.paymentSummary = Object.assign({}, data);
          subscriber.next(this.paymentSummary);
        });
    });
    return getObservable$;
  }

  public tenantFromWait(request: WaitToTenantRequest): any {
    this.genericDataService.endPoint = "Tenant/FromWait";
    return this.genericDataService.post<any>(request).pipe(first());
  }

  public tenantOverride(request: TenantOverride): any {
    this.genericDataService.endPoint = "Tenant/Override";
    return this.genericDataService.post<any>(request);
  }

  public lateFeeWaiver(request: LateFeeWaiver): any {
    this.genericDataService.endPoint = "Tenant/LateFeeWaiver";
    return this.genericDataService.post<any>(request);
  }

  public lateFeeWaiverRemove(id: number): Observable<any> {
    this.genericDataService.endPoint = "Tenant/LateFeeWaiver/Remove";
    return this.genericDataService.delete<any>(null, id).pipe(
      first(),
      tap(() => {
        this.lateFeeWaiverRemove$.next(true);
      })
    );
  }

  public serviceRequestSend(request: ServiceRequest): any {
    this.genericDataService.endPoint = "Email/ServiceRequest";
    return this.genericDataService.post<any>(request);
  }

  public tenantExtEligible(tenantId: number): any {
    this.genericDataService.endPoint = "Tenant/ExtEligible";
    return this.genericDataService.readById<any>(null, tenantId);
  }

  // public addendumReqClicked(add: TenantAddendum) {
  //   this.addendumReqClicked$.next(add);
  // }

  public verifyAddendumsClicked(tenant: TenantInfo) {
    this.verifyAddendumsClicked$.next(tenant);
  }

  public tenantResendLeaseSignEmail(tenantId: number): any {
    this.genericDataService.endPoint = "Tenant/ResendLeaseSign";
    return this.genericDataService.readById<any>(null, tenantId);
  }

  public reconcilePayments(tenantId: number): any {
    this.genericDataService.endPoint = "Tenant/Reconcile";
    return this.genericDataService.readById<any>(null, tenantId);
  }

  public tenantDelete(id: number): any {
    this.genericDataService.endPoint = "Tenant/Remove";
    return this.genericDataService.delete<any>(null, id);
  }
}
