<div class="dialog-container">
  <header class="header-row">
    <div class="logo-container">
      <img class="isgl-logo" src="../../../assets/images/isgl-logo.jpg" />
    </div>
    <div class="close-btn" (click)="onDismiss()">
      <a><fa-icon [icon]="faTimes"></fa-icon></a>
    </div>
  </header>
  <div class="title-row">
    <div class="dialog-title">
      <span class="dialog-icon">
        <fa-icon [icon]="faShoppingBag"></fa-icon>
      </span>
      {{ model.description }} ({{amountTotal}})
    </div>
  </div>
  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="payment-form">
    <div>
      <label for="card-info">Card Info</label>
      <div id="card-info" #cardInfo ></div>
      <div id="card-errors" *ngIf="error">{{ error }}</div>
    </div>
    <div class="btn-container">
      <div class="btn-wrapper">
        <button type="submit" page-btn>
          <fa-icon [icon]="faMosque" class="icon-buttons"></fa-icon> Pay
          {{ amountTotal }}
        </button>
      </div>
    </div>
  </form>

</div>


