import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { StripePayment } from "src/app/models/stripe-payment";
import config from 'src/assets/config.json';
import { faTimes, faMosque, faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { NgForm } from "@angular/forms";
import { AngularStripeService } from "@fireflysemantics/angular-stripe-service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  standalone: false,
  selector: "app-stripe-payment-modal",
  templateUrl: "./stripe-payment-modal.component.html",
  styleUrls: ["./stripe-payment-modal.component.scss"],
})
export class StripePaymentModalComponent implements OnInit, AfterViewInit {
  model: StripePayment;

  @ViewChild("cardInfo") cardInfo: ElementRef;
  stripe;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  confirmation: any;
  loading = false;
  faMosque = faMosque;
  faTimes = faTimes;
  faShoppingBag = faShoppingBag;
  amountTotal: string;
  title: string;
  description: string;
  showCancel = true;
  constructor(
    public dialogRef: MatDialogRef<StripePaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripePayment,
    private cd: ChangeDetectorRef,
    private stripeService: AngularStripeService
  ) {
    this.model = Object.assign({}, data);
    if (this.model) {
      this.amountTotal = `$${this.model.amount}`;
    }
  }

  ngOnInit() {
    //this.loadStripe();
  }

  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: "24px",
        fontFamily: "monospace",
        fontSmoothing: "antialiased",
        fontSize: "19px",
        "::placeholder": {
          color: "purple",
        },
      },
    };

    this.stripeService
      .setPublishableKey(config.stripePublishableKey)
      .then((stripe) => {
        this.stripe = stripe;
        const elements = stripe.elements();
        this.card = elements.create("card");
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener("change", this.cardHandler);
      });
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.card.removeEventListener("change", this.cardHandler);
    this.card.destroy();
  }

  // checkoutSession() {
  //   this.tenantService.checkoutSession(this.model);
  // }

  // loadStripe() {
  //   if (!window.document.getElementById("stripe-script")) {
  //     var s = window.document.createElement("script");
  //     s.id = "stripe-script";
  //     s.type = "text/javascript";
  //     s.src = "https://js.stripe.com/v3/"; //
  //     s.onload = () => {
  //       window["Stripe"].setPublishableKey(
  //         this.configService.config.stripePublishableKey
  //       );
  //     };

  //     window.document.body.appendChild(s);
  //   }
  // }

  async onSubmit(form: NgForm) {
    //form.value = this.model.amount;
    //const stripe = Stripe(this.configService.config.stripePublishableKey);
    const { token, error } = await this.stripe.createToken(this.card, {
      name: this.model.recipientEmail,
    });
    if (error) {
      console.log("Something is wrong:", error);
    } else {
      console.log("Success!", token);
      this.model.tokenId = token.id;
      this.dialogRef.close(this.model);
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(this.model);
  }

  // openStripe() {
  //   this.stripeCheckoutHandler
  //     .open({
  //       amount: this.model.amount*100,
  //       currency: "USD",
  //       name: "ISGL Tenant Portal",
  //       description: "Rent",
  //       panelLabel: "Pay {{amount}}",
  //       allowRememberMe: false,
  //       email: this.model.tenantEmail,

  //     })
  //     .then((token) => {
  //       // Do something with the token...
  //       console.log("Payment successful!", token);
  //       this.model.tokenId = token.id;
  //       this.notifyParent.emit(this.model);
  //     })
  //     .catch((err) => {
  //       // Payment failed or was canceled by user...
  //       if (err !== "stripe_closed") {
  //         throw err;
  //       }
  //     });
  // }

  // onClickCancel() {
  //   // If the window has been opened, this is how you can close it:
  //   this.stripeCheckoutHandler.close();
  //   this.notifyParent.emit(this.model);
  // }
}
