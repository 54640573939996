<div class="new-muslims-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">New Muslims</div>
    </div>
    <div class="service-info-section">
      <div class="service-info">
        <li class="list-item">
          <ul>
            Coming Soon!
          </ul>
        </li>
      </div>
    </div>
  </div>
</div>
