<div class="vision-container">
  <div class="about-isgl-section">
    <div class="header-section">
      <div class="header">Vision/Mission</div>
    </div>
    <div class="about-isgl-info-section">
      <div class="about-isgl-info">
        <ul class="about-isgl-list">
          <li class="list-item">
            Our mission is to provide a secure and comfortable facility where
            Believers may gather for the worship of the one and only
            <span class="bold-text">God ALLAH</span>
            regardless of race, nationality, ethnicity, gender or age and to
            serve and to interact with the Muslim and Non-Muslim
            communities-at-large in accordance with the teachings of the
            <span class="bold-text"> Holy Quran</span> and with the Sunna (way
            of life) of the
            <span class="bold-text right-pad"> Prophet Muhammad</span
            >&nbsp;<span class="text-italics">(peace be upon him)</span>.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
