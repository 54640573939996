<div class="reserve-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Facility Reservations</div>
    </div>
    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            ISGL Multipurpose room located in basement is available for
            community members to reserve if they wish to hold small gatherings,
            weddings, etc.
          </li>
          <li class="list-item">
            This room comes equipped with a small kitchen and provides fridge,
            sink, and a food warmer. There is no&nbsp;
            <span class="bold-text"> stove or oven </span>
            &nbsp;in the kitchen.
          </li>
          <li class="list-item">
            Tables and chairs are available for use as well.
          </li>
          <li class="list-item">
            There is&nbsp;
            <span class="bold-text"> no fee&nbsp; </span>
            for reserving the hall, but&nbsp;<span class="bold-text"
              >donations</span
            >&nbsp;are always welcome.
          </li>
          <li class="list-item">
            It is&nbsp;
            <span class="bold-text"> required </span>&nbsp; to leave the
            facility &nbsp;<span class="bold-text">clean</span>&nbsp;after use.
          </li>
          <li class="list-item">
            If you would like to reserve the Multipurpose room, please contact
            us via email&nbsp;
            <span class="bold-text">(admin&#64;masjidisgl.org)</span>
          </li>
          <li class="list-item">
            <span class="bold-text">Tenant-portal </span>&nbsp;will be available
            soon, please contact us via email for futher information&nbsp;
            <span class="bold-text">(admin&#64;masjidisgl.org)</span>
          </li>
        </ul>
        <div class="info-italics">
          <i class="material-icons info-icon">info</i>
          Due to COVID-19 and the safety of our community, all Facility
          Reservations have been put on hold until further notice, JazakAllah.
        </div>
      </div>
    </div>
  </div>
</div>
