import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Event } from './../../models/event';
import { faMapMarker, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'app-announcement-card',
  templateUrl: './announcement-card.component.html',
  styleUrls: ['./announcement-card.component.scss']
})
export class AnnouncementCardComponent implements OnInit, OnChanges {

  @Input() announcement: Event;
  faMapMarker = faMapMarker;
  faPlayCircle = faPlayCircle;
  toolTipText = '';
  constructor(private router: Router) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.announcement && this.announcement.hasEventDetails) {
      this.toolTipText = 'Click to see the pictures';
    }
  }

  detail() {
    if (!this.announcement) {
      return;
    }
    if (this.announcement.hasEventDetails) {
      this.router.navigate(['/eventDetail'], { queryParams: { eventId: this.announcement.id } });
    }
  }

}
