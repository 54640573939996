import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'app-about-isgl',
  templateUrl: './about-isgl.component.html',
  styleUrls: ['./about-isgl.component.scss']
})
export class AboutIsglComponent implements OnInit {

  navLinks: any[];
  activeLinkIndex = -1;
  emptyResultType = this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
  constructor(private router: Router) {
    this.buildNavLinks();
    this.setUrl();
  }

  ngOnInit() {
  }

  buildNavLinks() {
    this.navLinks = [
      {
        label: 'Vision',
        link: './vision',
        index: 0,
        emptyResultType: 'vision',
        active: false
      },
      // {
      //   label: 'Locations',
      //   link: './locations',
      //   index: 1,
      //   emptyResultType: 'locations'
      // },
      {
        label: 'Current Team',
        link: './team',
        index: 1,
        emptyResultType: 'team',
        active: false
      }, {
        label: 'Constitution',
        link: './constitution',
        index: 2,
        emptyResultType: 'constitution',
        active: false
      }
    ];

  }
  setUrl() {
    let path = this.router.url.split('?')[0];
    if (path === '/about') {
      path = 'vision';
    }
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => path.indexOf(tab.emptyResultType) !== -1));
    this.setActiveTab();
    this.router.navigate([this.router.url]);
  }

  setActiveTab() {
    const activeLinks = this.router.url.split('/').filter(x => x);
    if (activeLinks && activeLinks[0] === 'about') {
      let tab = activeLinks[1] ? activeLinks[1] : 'vision';
      this.toggleTab(tab);
    }
  }

  toggleTab(tabName: string) {
    this.emptyResultType = tabName;
    this.navLinks.map(x => {
      x.active = false;
    });
    let activeTab = this.navLinks.find(x => x.emptyResultType === tabName);
    activeTab.active = true;
  }


}
