<div class="flat-select-container">
  <div class="select" tabindex="0" (focus)="focus()" (blur)="unfocus()">
    <div
      class="select-trigger"
      [ngClass]="{ disabled: selectedOption?.disabled, error: hasError }"
      matTooltip="{{ toolTipText }}"
      matTooltipPosition="above"
      (click)="toggle($event)"
      #selectTrigger
    >
      <span *ngIf="defaultLabel && !selectedOption" class="default-label">{{
        defaultLabel
      }}</span>
      <span
        #selectedValue
        *ngIf="selectedOption?.value"
        class="selected-value"
        >{{ selectedOption.label }}</span
      >
      <i
        class="material-icons toggle-icon"
        [ngClass]="{ 'hide-toggle': !displayArrow }"
        >arrow_drop_down</i
      >
    </div>
    <div
      class="select-content"
      [ngClass]="{ 'show-menu': show, top: position === 'top' }"
      #selectContent
    >
      <div *ngFor="let option of selectOptions">
        <div
          *ngIf="!option.disabled"
          class="select-content-row"
          style="position: relative; z-index: 2;"
          [ngClass]="{ 'selected-row': option.value === selectedOption?.value }"
          (click)="selectOptionChanged($event, option)"
          (mousedown)="selectOptionChanged($event, option)"
          >
          <span class="select-label">{{ option.label }}</span>
        </div>
        <div *ngIf="option.disabled" class="select-content-row disabled">
          <span class="select-label">{{ option.label }}</span>
        </div>
      </div>
    </div>
    <div class="validation-error-content" *ngIf="hasError">
      <div class="error-message">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</div>
