<div class="nikah-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Marriage/Nikah</div>
    </div>

    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            Both bride and groom must visit
            <a
              class="anchor-link"
              href="https://www.tippecanoe.in.gov/224/Marriage-Licenses"
              target="_blank"
            >
              Tippecanoe county
            </a>
            clerk’s office in person and obtain &nbsp;
            <span class="bold-text">Marriage License</span>.
            <ul class="service-list">
              <li class="list-item child-item">
                <fa-icon
                  class="list-item-arrow"
                  [icon]="faArrowAltCircleRight"
                ></fa-icon>
                Indiana does not permit marriages among cousins.
              </li>
              <li class="list-item child-item">
                <fa-icon
                  class="list-item-arrow"
                  [icon]="faArrowAltCircleRight"
                ></fa-icon>
                If previously married, please ensure you have divorce
                certificate(s) in possession.
              </li>
            </ul>
          </li>
          <li class="list-item">
            Contact ISGL via email &nbsp;
            <span class="bold-text">(info&#64;masjidisgl.org)</span> &nbsp; or by
            calling &nbsp; <span class="bold-text"> (765-233-2878)</span> &nbsp;
            and schedule a date and time.
          </li>
          <li class="list-item">
            ISGL does not charge any fee for this service.
          </li>
          <li class="list-item">
            Masjid Multi-purpose hall is available free of charge, if no other
            activities are planned at the desired time.
          </li>
          <li class="list-item">
            ISGL does not promote any particular fiqh (sect). Follow the
            requirements of your fiqh of choice to fulfil marriage conditions.
          </li>
          <li class="list-item">
            Presence of two witnesses are required to sign the marriage
            certificate. Allah’s order of &nbsp;
            <span class="bold-text">mehr</span> &nbsp;(dower) – a gift from
            groom to the bride, is documented in the marriage certificate.
            Please ensure that the mehr (item/amount/service) is agreed between
            the couple before coming to the masjid for nikah.
          </li>
          <li class="list-item">
            The signed certificate is to be submitted to the county clerk’s
            office for process to complete.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
