<app-header></app-header>
<div class="restrictions-container">
  <div class="page-header">
    <div class="header-content">
      <div class="header-icon">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
      </div>
      <div class="header-title">Covid</div>
    </div>
  </div>

  <div class="restrictions-section">
    <div class="sub-header-section">
      <div class="sub-header">Covid Restrictions During Friday Prayers</div>
    </div>
    <div class="restrictions-info-section">
      <div class="restrictions-info">
        <div class="restrictions-text">
          Masjid has limited capacity during Friday prayers due to social
          distancing. ISGL board has taken the following steps to facilitate the
          worshippers:
        </div>
        <ul class="restrictions-list">
          <li class="list-item">
            We will have two Juma’a sessions; at 1:45 PM and at 2:30 PM.
          </li>
          <li class="list-item">
            Masjid will be disinfected between the two sessions.
          </li>
          <li class="list-item">
            All attendees are required to register for each Friday prayer. A
            confirmation email is sent to each person. Our capacity is limited
            to 50 in men’s area and 10 in the ladies’ hall. Please
            <a
              class="anchor-link"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdcv5F2uICtyCllu3SGGYKOnFnLqJw6t0aesxZtd_bjb0BLKw/viewform"
              target="_blank"
            >
              register here
            </a>
            each week or download
            <a
              class="anchor-link"
              href="https://apps.apple.com/us/app/the-masjid-app/id1492838521"
              target="_blank"
            >
              Masjid App
            </a>
            on your phone to register.
          </li>
          <li class="list-item">
            Childrens are not allowed for the Friday sessions.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
