<div class="youth-groups-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Youth Groups/Activities</div>
    </div>
    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            At ISGL we have a small group of local youth who come together once
            a month to practice their deen and also have a fun time as a group.
          </li>
          <li class="list-item">
            <a
              class="anchor-link"
              href="https://boilerlink.purdue.edu/organization/msa"
              target="_blank"
            >
              Purdue MSA Students
            </a>
            lead this group as they start with a halaqa at the beginning of
            their session, then pray namaz, and lastly have a good time with
            their very own brothers.
          </li>
          <li class="list-item">
            This allows our young youth to make friends within our Muslim
            community, get to find out more about college at Purdue, and most
            importantly, continue learning about Islam.
          </li>
          <li class="list-item">
            Activities range from volunteer opportunities, to cleaning the
            masjid, to gardening, to basketball games, gaming tournaments and
            much more!
          </li>
          <li class="list-item">
            Contact us via email&nbsp;
            <span class="bold-text">(info&#64;masjidisgl.org)</span>
            if you would like to learn more!
          </li>
        </ul>
        <div class="info-italics">
          <i class="material-icons info-icon">info</i>
          Due to COVID-19 and the safety of our community, all youth activities
          have been put on hold until further notice, JazakAllah
        </div>
      </div>
    </div>
  </div>
</div>
