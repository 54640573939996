<div>
  <div class="item-container">
    <div class="sub-header">Enter your service request</div>
  </div>
  <div class="item-container">
    <div class="item-select-wrapper">
      <app-flat-select
        [selectOptions]="serviceCategories"
        [(ngModel)]="model.category"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.category"
        (selectOptionChange)="setValue('category', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-select-wrapper">
      <app-flat-select
        [selectOptions]="serviceLocations"
        [(ngModel)]="model.location"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.location"
        (selectOptionChange)="setValue('location', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>

    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Name'"
        [(value)]="model.name"
        [(ngModel)]="model.name"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
  </div>
  <div class="item-container">
    <div class="item-input-wrapper">
      <textarea
        [(ngModel)]="model.description"
        value="model.description"
        class="description-text"
        placeholder="Description"
        (blur)="setValue('description', model.description)"
      ></textarea>
    </div>

    <div class="svc-btn-container">
      <button
        mat-raised-button
        class="request-btns"
        (click)="openUploadFileDialog()"
      >
        <mat-icon class="icon"> upload</mat-icon>
        Upload Image(s)
      </button>
    </div>
    <div
      class="item-label-wrapper"
      *ngIf="uploadedFiles && uploadedFiles.length > 0"
    >
      <span class="item-label">Uploaded Files:</span>
    </div>
    <div class="item-input-wrapper">
      <app-file-list
        [uploadedFiles]="uploadedFiles"
        [showDelete]="showfileListDelete"
        [showTitle]="showfileListTitle"
      ></app-file-list>
    </div>
  </div>
  <div class="item-container">
    <div class="svc-btn-container">
      <button
        mat-raised-button
        class="request-btns"
        [disabled]="disableSave"
        (click)="sendRequest()"
      >
        <fa-icon [icon]="faEnvelopeSquare" class="icon"> </fa-icon>
        Send Email to ICB Coordinator
      </button>
    </div>
  </div>
</div>
