import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserToken } from 'src/app/models/user-token';
import { SecurityService } from 'src/app/services/security.service';
@Component({
  standalone: false,
  selector: 'app-ieb',
  templateUrl: './ieb.component.html',
  styleUrls: ['./ieb.component.scss']
})
export class IebComponent implements OnInit, OnDestroy {
  navLinks: any[];
  activeLinkIndex = -1;
  emptyResultType = this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
  currentUser: UserToken;
  signedInUser$: Observable<UserToken>;
  signedIn$: Observable<boolean>;
  signedIn: boolean;
  subscription: Subscription = new Subscription();
  constructor(private router: Router, private securityService: SecurityService) {
    this.buildNavLinks();
    //this.setUrl();
    this.signedIn$ = this.securityService.isSignedIn();
    const signInSubscription = this.signedIn$.subscribe((result) => {
      if (result) {
        this.signedInUser$ = this.securityService.user$;
        this.signedIn = result;
        const signInUserSubscription =  this.signedInUser$.subscribe((data) => {
          this.currentUser = data;
        });
        this.subscription.add(signInUserSubscription);
      }
    });
    this.subscription.add(signInSubscription);

  }

  ngOnInit() {
    //this.setUrl();
    if(this.signedIn && this.currentUser) {
      if(this.currentUser.role === 'Admin' || this.currentUser.role === 'IebAdmin') {
        this.navLinks.push({
          label: 'Manage',
          link: './iebManage',
          index: 5,
          emptyResultType: 'iebManage',
          active: false
        });
      }
    }
    this.setUrl();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buildNavLinks() {
    this.navLinks = [
      {
        label: 'Information',
        link: './info',
        index: 0,
        emptyResultType: 'info',
        active: false
      }, {
        label: 'Curriculum',
        link: './curriculum',
        index: 1,
        emptyResultType: 'curriculum',
        active: false
      }, {
        label: 'Volunteer',
        link: './volunteer',
        index: 2,
        emptyResultType: 'volunteer',
        active: false
      }, {
        label: 'Contact',
        link: './contact',
        index: 3,
        emptyResultType: 'contact',
        active: false
      },
      {
        label: 'Registration',
        link: './registration',
        index: 4,
        emptyResultType: 'registration',
        active: false
      }
    ];

    //this.setUrl();

  }
  setUrl() {
    let path = this.router.url.split('?')[0];
    if (path === '/ieb') {
      path = 'info';
    } else if(path === '/ieb/iebManage') {
      path = 'iebManage'
    }
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => path.indexOf(tab.emptyResultType) !== -1));
    if(this.activeLinkIndex !== -1) {
      this.setActiveTab();
    }

    this.router.navigate([this.router.url]);
  }

  setActiveTab() {
    const activeLinks = this.router.url.split('/').filter(x => x);
    if (activeLinks && activeLinks[0] === 'ieb') {
      let tab = activeLinks[1] ? activeLinks[1] : 'info';
      this.toggleTab(tab);
    }
  }

  toggleTab(tabName: string) {
    this.emptyResultType = tabName;
    this.navLinks.map(x => {
      x.active = false;
    });
    let activeTab = this.navLinks.find(x => x.emptyResultType === tabName);
    activeTab.active = true;
  }

}
