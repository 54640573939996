<app-header></app-header>
<div class="student-container" >
  <div class="student-section" >

    <div class="page-header-section">
      <div class="icon-wrapper">
        <a [routerLink]="'/ieb/iebManage'" class="anchor-link">
          <i class="material-icons header-icon">arrow_back</i>
          Back
        </a>
      </div>

      <div class="header">
        Student Details
      </div>

    </div>
    <app-ieb-student-details [model] = "VM$ | async"></app-ieb-student-details>
    <ng-template #loading>
      <div class="table-skeleton">
      </div>
    </ng-template>
  </div>

</div>
<app-footer></app-footer>
