<app-header></app-header>
<div class="member-reg-container">
  <div class="member-reg-wrapper">
    <div class="member-reg-header">
      <div class="header-content">
        <div class="user-icon">
          <fa-icon [icon]="faUser" *ngIf="memberInfo.id"></fa-icon>
          <fa-icon [icon]="faUserPlus" *ngIf="!memberInfo.id"></fa-icon>
        </div>
        <div class="header-title">
          <ul style="list-style: none">
            <li class="header-username">{{ headerTitle }}</li>
            <li class="header-email-id"></li>
          </ul>
        </div>
      </div>
    </div>
    <form [formGroup]="memberForm" (ngSubmit)="onSubmit()">
      <div
        class="member-email-container"
        [ngClass]="{ 'hide-element': emailVerified }"
      >
        <div class="member-item">
          <div class="member-email-info">
            <div class="info-general">
              Please enter your email and click outside to continue.
            </div>

            <div class="purdue-student-info">
              Purdue students must use purdue email.
            </div>
          </div>
        </div>
        <div class="break email"></div>
        <div class="member-item center">
          <input
            required
            formControlName="email"
            id="email"
            type="email"
            class="member-input"
            placeholder="Email"
            value="{{ memberInfo.email }}"
            [ngClass]="{
              invalid: (email.dirty || email.touched) && email.errors
            }"
            [(ngModel)]="memberInfo.email"
            (blur)="checkMemberExists($event)"
            (keydown.enter)="checkMemberExists($event)"
          />
          <div
            *ngIf="
              email.errors &&
              email.errors.required &&
              (email.dirty || email.touched)
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon email">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="break"></div>
      <div
        class="member-reg-info-container"
        *ngIf="dataLoaded; else loading"
        [ngClass]="{ 'hide-element': !emailVerified }"
      >
        <div class="member-item">
          <div class="element-wrapper">
            <input
              required
              formControlName="firstName"
              id="firstName"
              type="text"
              class="member-input"
              placeholder="First name"
              value="{{ memberInfo.firstName }}"
              [ngClass]="{
                invalid:
                  ((firstName.dirty || firstName.touched) &&
                    firstName.errors &&
                    !memberInfo.id) ||
                  (firstName.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.firstName"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id &&
                firstName.errors &&
                firstName.errors.required) ||
              (!memberInfo.id &&
                firstName.errors &&
                firstName.errors.required &&
                (firstName.dirty || firstName.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
          <div class="element-wrapper">
            <input
              required
              formControlName="lastName"
              id="lastName"
              type="text"
              class="member-input"
              placeholder="Last name"
              value="{{ memberInfo.lastName }}"
              [ngClass]="{
                invalid:
                  ((lastName.dirty || lastName.touched) &&
                    lastName.errors &&
                    !memberInfo.id) ||
                  (lastName.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.lastName"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id && lastName.errors && lastName.errors.required) ||
              (!memberInfo.id &&
                lastName.errors &&
                lastName.errors.required &&
                (lastName.dirty || lastName.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
        <div class="break"></div>
        <div class="member-item">
          <div class="element-wrapper">
            <input
              required
              formControlName="address"
              id="address"
              class="member-input"
              placeholder="address"
              value="{{ memberInfo.address }}"
              [ngClass]="{
                invalid:
                  ((address.dirty || address.touched) &&
                    address.errors &&
                    !memberInfo.id) ||
                  (address.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.address"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id && address.errors && address.errors.required) ||
              (!memberInfo.id &&
                address.errors &&
                address.errors.required &&
                (address.dirty || address.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
          <div class="element-wrapper">
            <input
              required
              formControlName="city"
              id="city"
              class="member-input"
              placeholder="City"
              value="{{ memberInfo.city }}"
              [ngClass]="{
                invalid:
                  ((city.dirty || city.touched) &&
                    city.errors &&
                    !memberInfo.id) ||
                  (city.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.city"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id && city.errors && city.errors.required) ||
              (!memberInfo.id &&
                city.errors &&
                city.errors.required &&
                (city.dirty || city.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
        <div class="break"></div>
        <div class="member-item">
          <div class="element-wrapper select">
            <mat-form-field class="form-field-light-grey" floatLabel="never">
              <mat-select
                required
                class="filter-select-light-green"
                formControlName="state"
                id="state"
                placeholder="State"
                [ngClass]="{
                  invalid:
                    ((state.dirty || state.touched) &&
                      state.errors &&
                      !memberInfo.id) ||
                    (state.errors && memberInfo.id)
                }"
                [(ngModel)]="memberInfo.state"
              >
                <mat-option *ngFor="let state of states" [value]="state.value">
                  {{ state.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="
              (memberInfo.id && state.errors && state.errors.required) ||
              (!memberInfo.id &&
                state.errors &&
                state.errors.required &&
                (state.dirty || state.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon select">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
          <div class="element-wrapper">
            <input
              required
              formControlName="zip"
              id="zip"
              class="member-input thin"
              placeholder="Zip"
              value="{{ memberInfo.zip }}"
              [ngClass]="{
                invalid:
                  ((zip.dirty || zip.touched) &&
                    zip.errors &&
                    !memberInfo.id) ||
                  (zip.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.zip"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id && zip.errors && zip.errors.required) ||
              (!memberInfo.id &&
                zip.errors &&
                zip.errors.required &&
                (zip.dirty || zip.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
          <div
            *ngIf="
              (memberInfo.id && zip.errors && zip.errors.pattern) ||
              (!memberInfo.id &&
                zip.errors &&
                zip.errors.pattern &&
                (zip.dirty || zip.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Invalid.</span>
            </div>
          </div>
          <div class="element-wrapper">
            <input
              required
              formControlName="cellPhone"
              id="cellPhone"
              type="text"
              placeholder="Cell phone 1112223456"
              class="member-input thin"
              value="{{ memberInfo.cellPhone }}"
              [ngClass]="{
                invalid:
                  ((cellPhone.dirty || cellPhone.touched) &&
                    cellPhone.errors &&
                    !memberInfo.id) ||
                  (cellPhone.errors && memberInfo.id)
              }"
              [(ngModel)]="memberInfo.cellPhone"
            />
          </div>
          <div
            *ngIf="
              (memberInfo.id &&
                cellPhone.errors &&
                cellPhone.errors.required) ||
              (!memberInfo.id &&
                cellPhone.errors &&
                cellPhone.errors.required &&
                (cellPhone.dirty || cellPhone.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
          <div
            *ngIf="
              (memberInfo.id && cellPhone.errors && cellPhone.errors.pattern) ||
              (!memberInfo.id &&
                cellPhone.errors &&
                cellPhone.errors.pattern &&
                (cellPhone.dirty || cellPhone.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Enter numbers only.</span>
            </div>
          </div>
          <div class="element-wrapper select">
            <mat-form-field class="form-field-light-grey" floatLabel="never">
              <mat-select
                class="filter-select-light-green"
                formControlName="gender"
                id="gender"
                placeholder="Gender"
                [ngClass]="{
                  invalid:
                    ((gender.dirty || gender.touched) &&
                      gender.errors &&
                      !memberInfo.id) ||
                    (gender.errors && memberInfo.id)
                }"
                [(ngModel)]="memberInfo.gender"
              >
                <mat-option
                  *ngFor="let gender of genderList"
                  [value]="gender.value"
                >
                  {{ gender.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="
              (memberInfo.id && gender.errors && gender.errors.required) ||
              (!memberInfo.id &&
                gender.errors &&
                gender.errors.required &&
                (gender.dirty || gender.touched))
            "
            class="validation-error-container"
          >
            <div class="validation-error-icon select">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
        <div class="break"></div>
        <div class="member-item">
          <div class="element-wrapper checkbox-wrapper">
            <mat-checkbox
              id="isStudent"
              formControlName="isStudent"
              [(ngModel)]="memberInfo.isStudent"
              [(checked)]="memberInfo.isStudent"
              (change)="onCheckChange($event)"
            >
            </mat-checkbox>
            <span class="checkbox-text">Student </span>
          </div>
          <div class="element-wrapper checkbox-wrapper">
            <mat-checkbox
              id="isAlumni"
              formControlName="isAlumni"
              [(ngModel)]="memberInfo.isAlumni"
              [(checked)]="memberInfo.isAlumni"
              (change)="onCheckChange($event)"
            >
            </mat-checkbox>
            <span class="checkbox-text">Alumni</span>
          </div>
          <div class="element-wrapper checkbox-wrapper">
            <mat-checkbox
              formControlName="residencyStatus"
              [(ngModel)]="memberInfo.residencyStatus"
            >
            </mat-checkbox>
            <div class="checkbox-text">US Citizen/GreenCard Holder</div>
          </div>
          <div
            class="element-wrapper buttons"
            *ngIf="memberInfo && memberInfo.id"
          >
            <div class="show-kids-summary" (click)="showKidsSummary()">
              <a
                button
                mat-raised-button
                class="long-button"
                color="primary"
                (click)="showKidsSummary()"
              >
                <fa-icon [icon]="faChild" class="icon-buttons"></fa-icon>
                {{ kidsSummaryText }}
              </a>
              <i class="material-icons info-kids-reg-icon">info</i>
              <div class="kids-reg-info">
                <span>
                  Kids age 16 & above should be registered as an member.</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="break"></div> -->
        <div class="member-item" *ngIf="memberInfo && memberInfo.id">
          <div class="element-wrapper buttons" *ngIf="!memberInfo.isVoting">
            <div class="show-kids-summary">
              <a
                href="https://donorbox.org/isgl"
                button
                mat-raised-button
                class="long-button"
                color="primary"
                target="_blank"
                [disabled]="!memberForm.valid"
              >
                <fa-icon [icon]="faVote" class="icon-buttons"></fa-icon> Click
                to become voting member
              </a>
              <i class="material-icons info-payment-icon">info</i>
              <div class="payment-info">
                <span *ngIf="!memberForm.valid">
                  Please update your information to become voting member</span
                >
                <span *ngIf="memberForm.valid">
                  Payments made will be reflected in 48 hours into our
                  system.</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="break"></div> -->
        <div class="divider"></div>
        <div class="member-item">
          <div class="element-wrapper">
            <mat-checkbox
              id="acknowledged"
              formControlName="acknowledged"
              [(ngModel)]="isAcknowledged"
              [(checked)]="isAcknowledged"
            >
            </mat-checkbox>
            <span class="accept-term-text">
              I acknowledge that the information provided is accurate, complete
              and I am 16 years or older.
            </span>
          </div>
        </div>
        <!-- <div class="break"></div> -->
        <div class="member-btn-container">
          <button
            mat-raised-button
            class="member-submit-button"
            [disabled]="!memberForm.valid"
            type="submit"
          >
            {{ submitText }}
          </button>
        </div>
      </div>
    </form>
    <div
      class="member-kids-summary-container"
      [ngClass]="{
        'summary-panel-slide-in': showSummary,
        'summary-panel-slide-out': hideSummary
      }"
    >
      <div class="member-kids-summary-header">
        <div class="member-kids-summary-header-name">
          <fa-icon [icon]="faChild" class="kid-icon"></fa-icon>
          Kids Information
          <i class="material-icons info-kid-icon">info</i>

          <span class="info-content"> Enter kid's only age below 16! </span>
        </div>
        <div class="close-summary-wrapper">
          <a class="close-summary-button" (click)="hideKidsSummary()">
            <i class="material-icons expand">expand_more</i>
          </a>
          <span class="close-info" id="close-kid-panel">
            click or escape to close
          </span>
        </div>
      </div>

      <div *ngIf="memberInfo.id">
        <app-member-kids
          [memberKids]="memberKids"
          [memberId]="memberInfo.id"
          (memberKidsUpdated)="updateMemberKids($event)"
        ></app-member-kids>
      </div>
    </div>
  </div>
  <button
    mat-fab
    class="add-new-member-fab"
    [ngClass]="{ 'hide-element': !emailVerified }"
    (click)="registerNewMember()"
    matTooltip="Register New Member"
    matTooltipPosition="left"
    *ngIf="memberInfo.id"
  >
    <i class="material-icons">add</i>
  </button>
  <ng-template #loading>
    <div class="skeleton">
      <app-card-skeleton></app-card-skeleton>
    </div>
  </ng-template>
</div>
