import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UploadFile } from "src/app/models/upload-file";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";

@Component({
  standalone: false,
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent implements OnInit, OnChanges {
  @Input() uploadHeaderText: string;
  @Input() uploadHeaderSubText: string;
  @Input() btnText: string;
  @Input() imageOnly: boolean = false;
  @Input() allowMultiple: boolean = false;
  @Input() hasError = false;
  @Input() errorMessage = "";
  @Input() uploadedFiles: UploadFile[];
  @Output() notifyUpload = new EventEmitter();
  @Output() notifyDelete = new EventEmitter();
  uploadedfile: UploadFile;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {}

  ngOnChanges() {
    this.uploadedFiles = !this.uploadedFiles ? [] : this.uploadedFiles;
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    if (this.allowMultiple && this.uploadedFiles.length > 0) {
      this.showConfirmationDialog("Only one file is allowed!");
    }
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param id (File id)
   */
  deleteFile(id: number) {
    const index = this.uploadedFiles.findIndex((f) => f.id === id);
    this.notifyDelete.emit(id);
    this.uploadedFiles.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.uploadedFiles.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.uploadedFiles[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.uploadedFiles[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    var index = this.uploadedFiles.findIndex(
      (f) => f.name.toLowerCase() === files[0].name.toLowerCase()
    );
    if (index !== -1) {
      this.showConfirmationDialog("File already uploaded");
      return;
    }

    for (const item of files) {
      item.progress = 0;
      this.onFileSelected(item);
    }

    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (!bytes || bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  async onFileSelected(file: any) {
    if (this.validateFileImage(file)) {
      const contentBuffer: any = await this.readFileAsync(file);
      const id = this.uploadedFiles.length + 1;
      this.uploadedfile = new UploadFile(
        id,
        file.name,
        "",
        contentBuffer,
        0,
        file.size
      );
      this.uploadedFiles.push(this.uploadedfile);
      //this.uploadedfile = new AddFileRequest('G', file.name, '', contentBuffer, null);

      this.notifyUpload.emit(this.uploadedfile);
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  validateFileImage(file: any) {
    if (!this.imageOnly) {
      return true;
    }
    if (!file) {
      return false;
    }
    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      this.showConfirmationDialog("Invalid Image");
      return false;
    }
    // 5MB
    if (file.size > 5242880) {
      this.showConfirmationDialog("Image size should not exceed 5MB");
      return false;
    }
    return true;
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
