import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  navLinks: any[];
  activeLinkIndex = -1;
  emptyResultType = this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
  constructor(private router: Router) {
    this.buildNavLinks();
    this.setUrl();
  }

  ngOnInit() {
  }

  buildNavLinks() {
    this.navLinks = [
      {
        label: 'New Muslims',
        link: './newmuslims',
        index: 0,
        emptyResultType: 'newmuslims',
        active: false
      }, {
        label: 'Marriage',
        link: './nikah',
        index: 1,
        emptyResultType: 'nikah',
        active: false
      }, {
        label: 'Funeral',
        link: './janazah',
        index: 2,
        emptyResultType: 'janazah',
        active: false
      }, {
        label: 'Counselling',
        link: './counselling',
        index: 3,
        emptyResultType: 'counselling',
        active: false
      }, {
        label: 'Welfare',
        link: './welfare',
        index: 4,
        emptyResultType: 'welfare',
        active: false
      }, {
        label: 'Youth Groups',
        link: './youthgroups',
        index: 5,
        emptyResultType: 'youthgroups',
        active: false
      }, {
        label: 'Reserve',
        link: './reserve',
        index: 6,
        emptyResultType: 'reserve',
        active: false
      }, {
        label: 'Keyfob',
        link: './keyfob',
        index: 7,
        emptyResultType: 'keyfob',
        active: false
      }
    ];

  }
  setUrl() {
    let path = this.router.url.split('?')[0];
    if (path === '/services') {
      path = 'newmuslims';
    }
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => path.indexOf(tab.emptyResultType) !== -1));
    this.setActiveTab();
    this.router.navigate([this.router.url]);
  }

  setActiveTab() {
    const activeLinks = this.router.url.split('/').filter(x => x);
    if (activeLinks && activeLinks[0] === 'services') {
      let tab = activeLinks[1] ? activeLinks[1] : 'newmuslims';
      this.toggleTab(tab);
    }
  }

  toggleTab(tabName: string) {
    this.emptyResultType = tabName;
    this.navLinks.map(x => {
      x.active = false;
    });
    let activeTab = this.navLinks.find(x => x.emptyResultType === tabName);
    activeTab.active = true;

  }

}
