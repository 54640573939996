<div class="manage-component-container">
  <div class="component-container">
    <div class="component-wrapper">
      <div
        class="select-component-types"
        (click)="showTypes = true"
        [ngClass]="{ hide: showTypes }"
      >
        <div class="icon-wrapper">
          <i class="material-icons select-component-icon">filter_list</i>
        </div>
      </div>
      <div
        id="eventtypespanel"
        class="component-types"
        [ngClass]="{
          'show-component-types': showTypes,
          'hide-component-types': !showTypes
        }"
      >
        <div class="type-header-row" [ngClass]="{ show: showTypes }">
          <div class="back-icon-wrapper" (click)="showTypes = false">
            <i class="material-icons back-icon">arrow_back</i>
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('students'); showTypes = false"
          [ngClass]="{ 'row-active': showStudents }"
        >
          <div class="component-name" [ngClass]="{ active: showStudents }">
           Students
          </div>
        </div>

        <div
          class="component-type-row"
          (click)="typeClick('teachers'); showTypes = false"
          [ngClass]="{ 'row-active': showTeachers }"
        >
          <div class="component-name" [ngClass]="{ active: showTeachers }">
            Teachers
          </div>
        </div>
        <!-- <div
        class="component-type-row"
        (click)="typeClick('semesters'); showTypes = false"
        [ngClass]="{ 'row-active': showSemesters }"
      >
        <div class="component-name" [ngClass]="{ active: showSemesters }">
          Semesters
        </div>
      </div> -->
      <div
        class="component-type-row"
        (click)="typeClick('reducedFee'); showTypes = false"
        [ngClass]="{ 'row-active': showReducedFee }"
      >
        <div class="component-name" [ngClass]="{ active: showReducedFee }">
          Reduced Fee
        </div>
      </div>
      <div
        class="component-type-row"
        (click)="typeClick('addReg'); showTypes = false"
        [ngClass]="{ 'row-active': showAddReg }"
      >
      <div class="component-name" [ngClass]="{ active: showAddReg }">
        Cash Registration
      </div>
    </div>


      </div>
      <div class="component-content" [ngClass]="{ hide: showTypes }">
        <div *ngIf="showTeachers">
          <app-ieb-teachers></app-ieb-teachers>
        </div>
        <div *ngIf="showStudents">
          <app-ieb-students></app-ieb-students>
        </div>
        <!-- <div *ngIf="showSemesters">
          <app-ieb-semesters></app-ieb-semesters>
        </div> -->
        <div *ngIf="showReducedFee">
          <app-ieb-reduced-fee-kids></app-ieb-reduced-fee-kids>
        </div>
        <div *ngIf="showAddReg">
          <app-ieb-new-reg></app-ieb-new-reg>
        </div>
      </div>
    </div>
  </div>
</div>
