<app-header></app-header>
<div class="lease-request-container">
  <div class="lease-request-section">
    <div class="lease-request-header-section">
      <div class="header">Lease Request</div>
      <div class="amount-totals" *ngIf="showTenantInfo">
        <div class="amount-due" [ngClass]="{ 'no-due': paymentCollected }">
          Due: {{ amountTotal }}
        </div>
        <div class="amount-paid">Paid: {{ amountPaid }}</div>
      </div>
    </div>
    <div class="page-row" *ngIf="tenant && showTenantInfo">
      <div class="row-content">
        <div>
          <app-rent-info></app-rent-info>
        </div>
        <div class="item-container">
          <div class="sub-header">Lease Info</div>
        </div>
        <div class="item-container">
          <div class="item-label-wrapper">
            <span class="item-label">Room:</span>
          </div>
          <div class="item-input-wrapper medium left-close">
            <app-flat-input
              [placeHolder]="'Room'"
              [(value)]="roomName"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-label-wrapper">
            <span class="item-label">Lease Duration:</span>
          </div>
          <div class="item-select-wrapper skinny left-close" *ngIf="!paymentCollected">
            <app-flat-select
              [selectOptions]="leaseDurationList"
              [(ngModel)]="selectedLeaseDuration"
              [defaultLabel]="'Select'"
              [(value)]="selectedLeaseDuration.value"
              (selectOptionChange)="setDuration($event)"
              [disabled]="paymentCollected"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div class="item-input-wrapper skinny left-close" *ngIf="paymentCollected">
            <app-flat-input
              [placeHolder]="'Lease Duration'"
              [(value)]="selectedLeaseDuration.label"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-label-wrapper">
            <span class="item-label">Monthly Rent:</span>
          </div>
          <div class="item-input-wrapper skinny left-close">
            <app-flat-input
              [placeHolder]="'Rent'"
              [(value)]="rentAmount"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-label-wrapper">
            <span class="item-label">First Month Rent:</span>
          </div>
          <div class="item-input-wrapper skinny left-close">
            <app-flat-input
              [placeHolder]="'Rent'"
              [(value)]="firstMonthRent"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="item-container">
          <div class="item-label-wrapper">
            <span class="item-label">Security Deposit:</span>
          </div>
          <div class="item-input-wrapper skinny left-close">
            <app-flat-input
              [placeHolder]="'Security Deposit'"
              [(value)]="securityDeposit"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-label-wrapper" *ngIf="tenant.parkingSpaceRent">
            <span class="item-label">Parking Fee:</span>
          </div>
          <div
            class="item-input-wrapper skinny left-close"
            *ngIf="tenant.parkingSpaceRent"
          >
            <app-flat-input
              [placeHolder]="'Parking Fee'"
              [(value)]="parkingFee"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-label-wrapper">
            <span class="item-label">Start Date:</span>
          </div>
          <div class="input-date date-picker">
            <input
              [matDatepicker]="datePicker"
              class="input-date"
              [(ngModel)]="tenant.startDate"
              readonly
              (dateInput)="datePicker.close()"
              (dateChange)="startDateChange(); datePicker.close()"
              (focus)="datePicker.open()"
              readonly
            />
            <mat-datepicker-toggle
              class="item-date-picker"
              matSuffix
              [for]="datePicker"
              [disabled]="paymentCollected"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker ></mat-datepicker>
          </div>
          <div class="item-label-wrapper">
            <span class="item-label">End Date:</span>
          </div>
          <div class="item-input-wrapper skinny left-close">
            <app-flat-input
              [placeHolder]="'End Date'"
              [(value)]="tenant.endDate"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="item-container">
          <div class="item-note-wrapper">
            <div class="item-note">
              <i class="material-icons info-icon">info</i>
              Security deposit is refundable and will be paid back to the tenant
              within 20 days of termination of lease agreement.
            </div>
          </div>
        </div>
        <div class="item-container">
          <div class="item-note-wrapper">
            <div class="item-note">
              <i class="material-icons info-icon">info</i>
              Rent is due by 5th day of each month. Late Fee in the amount of
              $50 will be incurred, if rent is paid after due date.
            </div>
          </div>
        </div>
        <div class="item-container" *ngIf="!paymentCollected">
          <div class="btn-container">
            <a
              button
              mat-raised-button
              class="page-btn"
              color="primary"
              (click)="openStripeDialog()"
            >
              <fa-icon [icon]="faMosque" class="icon-buttons"></fa-icon> Pay
              {{ amountTotal }}
            </a>
          </div>
          <!-- <app-stripe-payment
            [model]="stripePayment"
            (notifyParent)="processToken($event)"
          ></app-stripe-payment> -->
        </div>

        <div class="item-container no-bottom-padding" *ngIf="paymentCollected">
          <div class="item-text-wrapper">
            <div class="item-text">
              <b>Renter's Insurance:</b>
              Tenant is required to obtain and maintain at all times during the
              Term, a renter’s insurance policy with a minimum of $100,000.00
              personal liability coverage. Tenant will name Landlord as an
              interested party or additional insured. Tenant will provide
              Landlord with a certificate or proof of insurance upon request. If
              you choose to decline, please initial below.
            </div>
          </div>
        </div>
        <div class="item-container" *ngIf="paymentCollected">
          <div class="item-label-wrapper wide">
            <span class="item-label">Renter's Insurance Decline Initials:</span>
          </div>
          <div class="item-input-wrapper skinny left-close">
            <app-flat-input
              [placeHolder]="'Initials'"
              [(value)]="tenantInitials"
              [(ngModel)]="tenantInitials"
            >
            </app-flat-input>
          </div>
        </div>

        <div class="item-container" *ngIf="paymentCollected">
          <!-- <app-signature
            [signee]="signee"
            (notifyCapture)="captureSignature($event)"
          ></app-signature> -->
          <div class="btn-container pad-bottom">
            <span *ngIf="hasSigError" class="error-icon-container">
              <span class="error-icon"><mat-icon>error_outline</mat-icon></span>
              <span class="error-text">Required!</span>
            </span>

            <button
              mat-raised-button
              class="upload-btn"
              (click)="openSignatureDialog()"
            >
              <fa-icon [icon]="faSignature" class="icon-buttons"></fa-icon>
              Click to Sign
            </button>
          </div>
          <div class="item-note-wrapper">
            <div class="item-note pad-top">
              <i class="material-icons info-icon">info</i>
              Signature is required.
            </div>
          </div>
        </div>
        <div class="item-container" *ngIf="paymentCollected">
          <div class="btn-container pad-top">
            <button
              mat-raised-button
              class="save-btn"
              [disabled]="hasError"
              (click)="createLease()"
            >
              Create Lease
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lease-message-container" *ngIf=""></div>
  <div class="spinner-container" *ngIf="!notLoading">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
  <div class="pdf-container" *ngIf="leaseSource && !showTenantInfo">
    <div class="item-container">
      <div class="pdf-btn-container">
        <button type="button" class="pdf-buttons save" (click)="confirmLease()">
          Confirm to save (required)
        </button>
        <button type="button" class="pdf-buttons cancel" (click)="cancel()">
          Go back & fix lease
        </button>
      </div>
    </div>
    <div class="pdf-wrapper">
      <div class="pdf-doc">
        <iframe
          width="100%"
          height="600px"
          [src]="leaseSource | safe"
          frameborder="0"
          class="file-viewer"
          allowfullscreen
        ></iframe>
      </div>

      <!-- <object
        [data]="leaseSource | safe"
        class="file-viewer"
        type="application/pdf"
        width="100%"
        height="600px"
        allowfullscreen
      ></object> -->
    </div>
  </div>
</div>

<app-footer></app-footer>
