import { UserService } from './../../services/user.service';
import { faTimes, faUserPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Inject, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewUser } from './../../models/new-user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit, AfterViewChecked {
  newUser: NewUser;
  faTimes = faTimes;
  faUserPlus = faUserPlus;
  faCheckCircle = faCheckCircle;
  registerUserForm: FormGroup;
  showForm = true;
  constructor(private userService: UserService
    , public dialogRef: MatDialogRef<RegisterUserComponent>
    , private formBuilder: FormBuilder
    , private cd: ChangeDetectorRef) {
    this.newUser = {
      firstName: '',
      lastName: '',
      email: '',
      role: ''
    };
    this.createForm();
  }

  get firstName() { return this.registerUserForm.get('firstName'); }
  get lastName() { return this.registerUserForm.get('lastName'); }
  get email() { return this.registerUserForm.get('email'); }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  createForm() {
    this.registerUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email], [this.userService.emailExistsValidator()]]
    }, { updateOn: 'change' });

  }

  registerUser() {
    this.userService.registerUser(this.newUser).subscribe(data => {
      if (data) {
        this.showForm = false;
      }
    });
  }

  isEmailExists(): boolean {
    const exists = this.registerUserForm.get('email').errors && this.registerUserForm.get('email').errors.emailExists ?
      this.registerUserForm.get('email').errors.emailExists : null;
    const test = this.email.hasError('emailExists');
    return this.email.hasError('emailExists');
  }

  closeModal(): void {
    this.dialogRef.close();
  }



}
