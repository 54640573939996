<div>
  <div class="pi-toolbar">
    <button mat-icon-button class="pi-menu-icon" [matMenuTriggerFor]="menu">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>

    <mat-menu #menu="matMenu" class="menu-screen">
      <a
        href="https://www.youtube.com/channel/UCU6y4IFdMb4IACtTP1uXVIA"
        target="_blank"
        mat-menu-item
      >
        Masjid Live
      </a>
      <a
        href="https://forms.gle/X3Qi4gFuEqF4Eb3SA"
        target="_blank"
        mat-menu-item
      >
        Khutbah Feedback
      </a>
      <button mat-menu-item (click)="redirect('index')">Home</button>
      <button mat-menu-item (click)="redirect('about')">About</button>
      <button mat-menu-item (click)="redirect('ieb')">Sunday School</button>
      <button mat-menu-item (click)="redirect('manage')" *ngIf="showManageTab">
        Manage
      </button>
      <button mat-menu-item (click)="redirect('services')">Services</button>
      <button mat-menu-item (click)="redirect('interfaith')">Interfaith</button>
      <button mat-menu-item (click)="redirect('covid')">Covid</button>
      <button mat-menu-item (click)="redirect('profile')">Profile</button>
      <button mat-menu-item (click)="redirect('rooms')">Rooms</button>
      <button
        mat-menu-item
        (click)="redirect('tenantsManage')"
        *ngIf="showTenantTab"
      >
        Tenants
      </button>
      <!-- <button mat-menu-item>Help</button>
      <button mat-menu-item>Contact</button> -->
    </mat-menu>
    <div
      class="logo-container"
      *ngIf="showLogo"
      [ngClass]="{ 'logo-skinny': showLogo }"
    >
      <a
        ><img
          class="pi-logo"
          src="../../../assets/images/isgl-logo.jpg"
          [routerLink]="'/index'"
          alt="ISGL Logo"
      /></a>
    </div>
    <div class="masjid-live-container">
      <a
        href="https://www.youtube.com/channel/UCU6y4IFdMb4IACtTP1uXVIA"
        target="_blank"
        class="btn-watch-live"
      >
        Masjid Live
      </a>
    </div>
    <div class="masjid-live-container">
      <a
        href="https://forms.gle/X3Qi4gFuEqF4Eb3SA"
        target="_blank"
        class="btn-feedback"
      >
        Khutbah Feedback
      </a>
    </div>
    <div class="pi-toolbar-links isgl-links">
      <a [routerLink]="'/index'" routerLinkActive="active"> Home </a>
      <a [routerLink]="'/about'" routerLinkActive="active"> About </a>
      <a [routerLink]="'/ieb'" routerLinkActive="active"> Sunday School </a>
      <a
        [routerLink]="'/manage'"
        routerLinkActive="active"
        *ngIf="showManageTab"
      >
        Manage
      </a>
      <a [routerLink]="'/services'" routerLinkActive="active"> Services </a>
      <a [routerLink]="'/interfaith'" routerLinkActive="active"> Interfaith </a>
      <a [routerLink]="'/covid'" routerLinkActive="active"> Covid </a>
      <a [routerLink]="'/profile'" routerLinkActive="active"> Profile </a>
      <a [routerLink]="'/rooms'" routerLinkActive="active"> Rooms </a>
      <a
        [routerLink]="'/tenantsManage'"
        routerLinkActive="active"
        *ngIf="showTenantTab"
      >
        Tenants
      </a>
    </div>

    <div class="content-right">
      <div class="icon-group">
        <a>
          <i
            class="material-icons header-icon menu-icon"
            [matMenuTriggerFor]="moreMenu"
            >more_vert
          </i>
        </a>
        <mat-menu #moreMenu="matMenu">
          <div *ngIf="signedInUser$ | async; let signedInUser; else: login">
            <div class="menu-header">
              <div class="user-name">
                Asslamualikum {{ signedInUser.firstName }}
              </div>
              <div class="user-role">{{ signedInUser.role }}</div>
            </div>
            <div class="menu-items" (click)="$event.stopPropagation()">
              <div class="menu-item" mat-menu-item (click)="logout()">
                <i class="material-icons">lock</i>
                Logout
              </div>
            </div>
          </div>
          <ng-template #login>
            <div class="menu-header">
              <div class="user-name">Asslamualikum please login.</div>
            </div>
            <div class="menu-items" (click)="$event.stopPropagation()">
              <div class="menu-item" mat-menu-item (click)="loginUser()">
                <i class="material-icons">lock_open</i>
                Login
              </div>
            </div>
          </ng-template>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
