<div class="ieb-info-container">
  <div class="ieb-section">
    <div class="header-section">
      <div class="header">Registration Information</div>
    </div>

    <div class="ieb-info-section">
      <div class="ieb-info" *ngIf="semesterDates$ | async as vm">
        <ul class="ieb-list">
          <li class="list-item">
            ISGL Sunday Islamic School offers Islamic education to students
            between the ages of 4 and 17 years old.
            Students must be 4 years old by September 1st.
          </li>
          <li class="list-item">
           Fall Semester starts on &nbsp;<span class="highlite-text">{{vm.fallSemesterSDate}}</span>&nbsp;
           and ends on &nbsp;<span class="highlite-text">{{vm.fallSemesterEDate}}</span>.
          </li>
          <li class="list-item">
            Spring Semester starts on &nbsp;<span class="highlite-text">{{vm.springSemesterSDate}}</span>&nbsp;
            and ends on &nbsp;<span class="highlite-text">{{vm.springSemesterEDate}}</span>.
          </li>
          <li class="list-item">
            School will resume In Shaa Allah every sunday at
            ISGL Masjid basement 1022 First St., West Lafayette, IN 47906.
          </li>
          <li class="list-item">
            Sunday school timings: &nbsp;<span class="highlite-text">10:45am - 2:00pm</span>.
            School timings are subject to changes during Ramadhan and Daylight savings time change.
          </li>
          <li class="list-item">
            Online registration will open on &nbsp;<span class="highlite-text">{{vm.regOpenDate}}</span>.
          </li>
          <li class="list-item">
            Due to limited class space, we will take students on a first come first serve basis only.
            We can only take 10 students per class levels. Please register at the earliest to guarantee your student(s) spot.
          </li>
          <li class="list-item">
            Current registration fee for levels 1 and above  is &nbsp;<span class="highlite-text">$125.00</span>&nbsp;
            per student per semester ($250 for the whole year).
          </li>
          <li class="list-item">
            The tuition for preschool and kindergarten (4 and 5 years old) is &nbsp;<span class="highlite-text">$100</span>
            &nbsp; per student per semester ($200 for the whole year).
          </li>
          <li class="list-item">
            In addition, there is a one-time materials fee of  &nbsp;<span class="highlite-text">$30</span>&nbsp;
            to cover books and snacks. This is due for all students.
          </li>
          <li class="list-item">
            Tuition is due at the time of registration and is payable online through the masjid website.
          </li>
          <li class="list-item">
            Financial Aid is available on request, but will be evaluated for eligibility on a case by case basis.
            Please email at &nbsp;<span class="email-text">ieb&#64;masjidisgl.org</span> &nbsp;for futher assistance.
          </li>
          <li class="list-item">
            Tuition is waived for children of school volunteers. However, the material fee is still due.
          </li>
          <li class="list-item">
            You may <a class="anchor-link" (click) = "onRegClick()"> register</a> at masjid website.
            You should have an account for Sunday School Registration.
          </li>
          <li class="list-item">
            If you do not have an account, please  <a class="anchor-link" href="login" target="_blank"> create</a>
            to register your student(s). Account creation is free.
          </li>
          <li class="list-item">
            Please contact &nbsp;<span class="email-text">ITAdmin&#64;masjidisgl.org</span> &nbsp; for any technical difficulties.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

