<div class="constitution-container">
  <div class="about-isgl-section">
    <div class="header-section">
      <div class="header">ISGL Constitution</div>
      <!-- <div class="file-download">
        <a [href]="fileUrl | safe" class="download-link">
          <fa-icon [icon]="faFileDownload" class="icon-download"> </fa-icon>
          Download
        </a>
      </div> -->
    </div>
    <div class="about-isgl-info-section">
      <div class="about-isgl-info">
        <!-- <embed
          [src]="fileUrl | safe"
          class="file-viewer"
          type="application/pdf#view=FitH"
          width="actual-width.px"
          height="actual-height.px"
        /> -->
        <!-- <object
          [data]="fileUrl | safe"
          class="file-viewer"
          type="application/pdf"
          width="100%"
          height="800px"
          allowfullscreen
        ></object> -->
        <iframe
          width="100%"
          height="800px"
          [src]="fileUrl | safe"
          frameborder="0"
          class="file-viewer"
          allowfullscreen
        ></iframe>
        <div class="download-link-container">
          <a [href]="fileUrl | safe" class="download-link" target="_blank">
            <fa-icon [icon]="faFileDownload" class="icon-download"> </fa-icon>
            Download
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<style>
  [name$="pdf"] {
    width: 100%;
    height: auto;
  }
</style>
