import { UserToken } from "./../../models/user-token";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SecurityService } from "src/app/services/security.service";
import { Observable, Subscription } from "rxjs";

@Component({
  standalone: false,
  selector: "app-manage-area",
  templateUrl: "./manage-area.component.html",
  styleUrls: ["./manage-area.component.scss"],
})
export class ManageAreaComponent implements OnInit,  OnDestroy {
  navLinks: any[];
  activeLinkIndex = -1;
  emptyResultType = this.router.url
    .split("/")
    [this.router.url.split("/").length - 1].split("?")[0];
  loggedIn = false;
  currentUser: UserToken;
  signedInUser$: Observable<UserToken>;
  signedIn$: Observable<boolean>;
  signedIn: boolean;
  subscription: Subscription = new Subscription();
  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {
    this.signedIn$ = this.securityService.isSignedIn();
    const signInSubscription = this.signedIn$.subscribe((result) => {
      if (result) {
        this.signedInUser$ = this.securityService.user$;
        this.signedIn = result;
        const signInUserSubscription =this.signedInUser$.subscribe((data) => {
          this.currentUser = data;
          this.buildNavLinks();
        });
        this.subscription.add(signInUserSubscription);
      }
    });
    this.subscription.add(signInSubscription);
  }

  ngOnInit() {
    this.setUrl();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setUrl() {
    let path = this.router.url.split("?")[0];
    if (path === "/manage") {
      path = !this.currentUser
        ? "/manage"
        : this.currentUser.role === "Admin"
        ? "members"
        : this.currentUser.role === "ContentAdmin"
        ? "events"
        : "tenants";
    }
    this.activeLinkIndex = this.navLinks.indexOf(
      this.navLinks.find((tab) => path.indexOf(tab.emptyResultType) !== -1)
    );
    if (this.signedIn) {
      this.setActiveTab();
      this.router.navigate([this.router.url]);
    } else {
      const redirectUrl = "/index";
      this.router.navigate([redirectUrl]);
    }
  }

  setActiveTab() {
    const activeLinks = this.router.url.split("/").filter((x) => x);
    if (activeLinks && activeLinks[0] === "manage") {
      let tab;
      if (this.currentUser.role === "Admin") {
        tab = activeLinks[1] ? activeLinks[1] : "members";
      } else if (this.currentUser.role === "ContentAdmin") {
        tab = activeLinks[1] ? activeLinks[1] : "events";
      } else {
        tab = activeLinks[1] ? activeLinks[1] : "tenantsManage";
      }
      this.toggleTab(tab);
    }
  }

  toggleTab(tabName: string) {
    this.emptyResultType = tabName;
    this.navLinks.map((x) => {
      x.active = false;
    });
    const tName = tabName === "finance" ? "members" : tabName;
    let activeTab = this.navLinks.find((x) => x.emptyResultType === tName);
    activeTab.active = true;
    if (tabName === "finance") {
      this.setActiveTab();
      window.open(
        "http://wabashconsulting.com/ISGL_Accounts/FA/reporting/reports_main.php?Class=5",
        "_blank"
      );
    }
  }

  buildNavLinks() {
    if (!this.currentUser) {
      return;
    }
    switch (this.currentUser.role) {
      case "Admin":
        this.navLinks = [
          {
            label: "Members",
            link: "./members",
            index: 0,
            emptyResultType: "members",
            active: false,
          },
          {
            label: "Users",
            link: "./users",
            index: 1,
            emptyResultType: "users",
            active: false,
          },
          {
            label: "Events",
            link: "./events",
            index: 2,
            emptyResultType: "events",
            active: false,
          },
          {
            label: "Teams",
            link: "./teams",
            index: 3,
            emptyResultType: "teams",
            active: false,
          },
          {
            label: "Tenants",
            link: "./tenantsManage",
            index: 4,
            emptyResultType: "tenantsManage",
            active: false,
          },
          {
            label: "Finance",
            link: "./members",
            index: 5,
            emptyResultType: "finance",
            active: false,
          },
        ];
        break;
      case "ContentAdmin":
        this.navLinks = [
          {
            label: "Events",
            link: "./events",
            index: 0,
            emptyResultType: "events",
            active: false,
          },
        ];
        break;
      case "TenantAdmin":
        this.navLinks = [
          {
            label: "Tenants",
            link: "./tenantsManage",
            index: 0,
            emptyResultType: "tenantsManage",
            active: false,
          },
        ];
        break;
    }
  }
}
