<div class="rents-container">
  <div class="rents-wrapper">
    <div class="rents-header">
      <div class="header">Manage Rents</div>
    </div>

    <div class="rents-row" *ngFor="let rent of rentList">
      <div class="rents-item-container">
        <div class="item-select-wrapper" [matTooltip]="'Rent Type'">
          <app-flat-select
            [selectOptions]="rentTypes"
            [(ngModel)]="rent.type"
            [(defaultLabel)]="defaultLabel"
            [(value)]="rent.type"
            (selectOptionChange)="setRentType($event, rent.id)"
            ngDefaultControl
          ></app-flat-select>
          <div
            class="delete-wrapper-mob"
            [matTooltip]="
              !rent.editable
                ? 'Non-editable, this rent is associated with existing lease'
                : null
            "
            matTooltipPosition="right"
          >
            <div
              class="delete-item"
              [ngClass]="{ 'disable-delete': !rent.editable }"
              *ngIf="!rent.validation.hasError"
            >
              <i
                class="material-icons delete-item-button"
                (click)="delete(rent)"
                >delete</i
              >
            </div>
          </div>
        </div>
        <div class="rents-item-container">
          <div class="item-wrapper" [matTooltip]="'Name'">
            <app-flat-input
              [placeHolder]="'Rent'"
              [(value)]="rent.name"
              [hasError]="rent.validation.name.hasError"
              [errorMessage]="rent.validation.name.errorMessage"
              (focusChanged)="setValue('name', rent.id, rent.name)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="rents-item-container">
          <div class="item-wrapper" [matTooltip]="'Amount'">
            <app-flat-input
              [placeHolder]="'Amount'"
              [(value)]="rent.amountdisplay"
              [hasError]="rent.validation.amount.hasError"
              [errorMessage]="rent.validation.amount.errorMessage"
              (focusChanged)="setValue('amount', rent.id, rent.amountdisplay)"
              [disabled]="!rent.editable"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="rents-item-container">
          <div
            class="item-select-wrapper"
            *ngIf="rent.editable && rent.type === 'Room'"
          >
            <app-flat-select
              [selectOptions]="rentRoomTypes"
              [(ngModel)]="rent.roomType"
              [defaultLabel]="'Room Type'"
              [(value)]="rent.roomType"
              (selectOptionChange)="setRentRoomType(rent.id, $event)"
              matTooltip="Room Type"
              matTooltipPosition="right"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div
            class="item-wrapper cap-width"
            *ngIf="!rent.editable && rent.type === 'Room'"
          >
            <app-flat-input
              [placeHolder]="'Rent Type'"
              [(value)]="rent.roomType"
              [disabled]="true"
              matTooltip="Room Type"
              matTooltipPosition="right" 
            >
            </app-flat-input>
          </div>
          <div class="item-wrapper cap-width" *ngIf="rent.type !== 'Room'">
            <app-flat-input
              [placeHolder]="'Rent Type'"
              [(value)]="rent.roomType"
              [disabled]="true"
              matTooltip="Type"
              matTooltipPosition="right"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="rents-item-container">
          <div
            class="item-wrapper"
            [matTooltip]="
              !rent.editable
                ? 'Non-editable, this rent is associated with existing lease'
                : null
            "
            matTooltipPosition="right"
          >
            <div class="delete-wrapper">
              <div
                class="delete-item"
                [ngClass]="{ 'disable-delete': !rent.editable }"
                *ngIf="!rent.validation.hasError"
              >
                <i
                  class="material-icons delete-item-button"
                  (click)="delete(rent)"
                  >delete</i
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      mat-fab
      class="add-rent-button-fab"
      matTooltip="Add Rent"
      matTooltipPosition="left"
      (click)="addRent()"
      *ngIf="!showAddRent"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-rent-container"
      *ngIf="showAddRent"
      [ngClass]="{ visible: showAddRent, invisible: !showAddRent }"
    >
      <div class="new-rent">
        <div class="new-rent-wrapper">
          <div class="new-rent-item-wrapper">
            <app-flat-select
              [selectOptions]="rentTypes"
              [(ngModel)]="newRent.type"
              [defaultLabel]="'Select Type'"
              [(value)]="newRent.type"
              [position]="'top'"
              (selectOptionChange)="setNewRentType($event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="new-rent-wrapper">
          <div class="new-rent-item-wrapper">
            <app-flat-input
              [placeHolder]="'Rent Name'"
              [(value)]="newRent.name"
              [hasError]="newRent.validation.name.hasError"
              [errorMessage]="newRent.validation.name.errorMessage"
              (focusChanged)="setNewRent('name', newRent.name)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="new-rent-wrapper">
          <div class="new-rent-item-wrapper">
            <app-flat-input
              [placeHolder]="'$Amount'"
              [(value)]="newRent.amountdisplay"
              [hasError]="newRent.validation.amount.hasError"
              [errorMessage]="newRent.validation.amount.errorMessage"
              (focusChanged)="setNewRent('amount', newRent.amountdisplay)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="new-rent-wrapper">
          <div
            class="new-rent-item-wrapper x-margin"
            [ngClass]="{ hide: hideRoomList }"
          >
            <app-flat-select
              [selectOptions]="roomTypes"
              [(ngModel)]="newRent.roomType"
              [defaultLabel]="'Room Type'"
              [(value)]="newRent.roomType"
              [position]="'top'"
              [hasError]="newRent.validation.roomType.hasError"
              [errorMessage]="newRent.validation.roomType.errorMessage"
              (selectOptionChange)="setNewRent('roomType', $event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="new-rent-wrapper">
          <div class="new-rent-item-wrapper">
            <div class="form-buttons">
              <button
                type="button"
                class="text-button"
                [disabled]="newRent.validation.hasError"
                (click)="saveRent()"
              >
                SAVE
              </button>
              <button
                type="button"
                class="text-button cancel"
                (click)="closeAddRent()"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
