
import { NgModule, Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./security/auth.guard";
import { RoleGuard } from "./security/role.guard";
import { LoginComponent } from "./components/login/login.component";
import { IndexComponent } from "./components/index/index.component";
import { MemberRegistrationComponent } from "./components/member-registration/member-registration.component";
import { MembersComponent } from "./components/members/members.component";
import { TestComponent } from "./components/test/test.component";
import { ErrorComponent } from "./components/error/error.component";
import { EventsManageComponent } from "./components/events-manage/events-manage.component";
import { ManageAreaComponent } from "./components/manage-area/manage-area.component";
import { UsersComponent } from "./components/users/users.component";
import { ServicesComponent } from "./components/services/services.component";
import { YouthGroupsComponent } from "./components/youth-groups/youth-groups.component";
import { WelfareComponent } from "./components/welfare/welfare.component";
import { ReserveFacilitiesComponent } from "./components/reserve-facilities/reserve-facilities.component";
import { NikahComponent } from "./components/nikah/nikah.component";
import { NewMuslimsComponent } from "./components/new-muslims/new-muslims.component";
import { JanazahComponent } from "./components/janazah/janazah.component";
import { CounsellingComponent } from "./components/counselling/counselling.component";
import { AboutIsglComponent } from "./components/about-isgl/about-isgl.component";
import { TeamsComponent } from "./components/teams/teams.component";
import { ConstitutionComponent } from "./components/constitution/constitution.component";
import { IlglComponent } from "./components/ilgl/ilgl.component";
import { InterfaithComponent } from "./components/interfaith/interfaith.component";
import { RestrictionsComponent } from "./components/restrictions/restrictions.component";
import { IebComponent } from "./components/ieb/ieb.component";
import { KeyFobComponent } from "./components/key-fob/key-fob.component";
import { MemberProfileComponent } from "./components/member-profile/member-profile.component";
import { IebRegistrationComponent } from "./components/ieb-registration/ieb-registration.component";
import { IebVolunteersComponent } from "./components/ieb-volunteers/ieb-volunteers.component";
import { IebCurriculumComponent } from "./components/ieb-curriculum/ieb-curriculum.component";
import { IebContactsComponent } from "./components/ieb-contacts/ieb-contacts.component";
import { TeamManageComponent } from "./components/team-manage/team-manage.component";
import { VisionComponent } from "./components/vision/vision.component";
import { AnnouncementDetailComponent } from "./components/announcement-detail/announcement-detail.component";
import { RoomsComponent } from "./components/rooms/rooms.component";
import { RoomBookComponent } from "./components/room-book/room-book.component";
import { LeaseRequestComponent } from "./components/lease-request/lease-request.component";
import { WaitListAddComponent } from "./components/wait-list-add/wait-list-add.component";
import { TenantsManageComponent } from "./components/tenants-manage/tenants-manage.component";
import { AddendumManageComponent } from "./components/addendum-manage/addendum-manage.component";
import { TenantSectionComponent } from "./components/tenant-section/tenant-section.component";
import { LeaseUpdateTenantComponent } from "./components/lease-update-tenant/lease-update-tenant.component";
import { IebInformationComponent } from './components/ieb-information/ieb-information.component';
import { IebManageComponent } from "./components/ieb-manage/ieb-manage.component";
import { IebStudentComponent } from "./components/ieb-student/ieb-student.component";


const routes: Routes = [
  {
    path: "",
    redirectTo: "index",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "index",
    component: IndexComponent,
    pathMatch: "full",
  },
  {
    path: "register",
    component: MemberRegistrationComponent,
  },
  {
    path: "test",
    component: TestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "eventDetail",
    component: AnnouncementDetailComponent,
  },
  {
    path: "rooms",
    component: RoomsComponent,
  },
  {
    path: "profile",
    component: MemberProfileComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "bookRoom",
    component: RoomBookComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "leaseRequest",
    component: LeaseRequestComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "tenant",
    component: TenantSectionComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "lease/update",
    component: LeaseUpdateTenantComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "waitList/add",
    component: WaitListAddComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "Registration",
    },
  },
  {
    path: "addendumsManage",
    component: AddendumManageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "TenantContent",
    },
  },
  {
    path: "manage",
    component: ManageAreaComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: ["TenantContent", "Content"],
    },
    children: [
      {
        path: "members",
        component: MembersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "Members",
        },
      },
      {
        path: "users",
        component: UsersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "Users",
        },
      },
      {
        path: "events",
        component: EventsManageComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "Content",
        },
      },
      {
        path: "tenantsManage",
        component: TenantsManageComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "TenantContent",
        },
      },
      // {
      //   path: "finance",
      //   redirectTo: "members",
      //   canActivate: [AuthGuard, RoleGuard],
      //   data: {
      //     moduleAccess: "Content",
      //   },
      // },
      {
        path: "teams",
        component: TeamManageComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "Users",
        },
      },
    ],
  },
  {
    path: "services",
    component: ServicesComponent,
    children: [
      {
        path: "newmuslims",
        component: NewMuslimsComponent,
      },
      {
        path: "nikah",
        component: NikahComponent,
      },
      {
        path: "youthgroups",
        component: YouthGroupsComponent,
      },
      {
        path: "welfare",
        component: WelfareComponent,
      },
      {
        path: "reserve",
        component: ReserveFacilitiesComponent,
      },
      {
        path: "janazah",
        component: JanazahComponent,
      },
      {
        path: "counselling",
        component: CounsellingComponent,
      },
      {
        path: "keyfob",
        component: KeyFobComponent,
      },
    ],
  },
  {
    path: "about",
    component: AboutIsglComponent,
    children: [
      {
        path: "vision",
        component: VisionComponent,
      },
      {
        path: "team",
        component: TeamsComponent,
      },
      {
        path: "constitution",
        component: ConstitutionComponent,
      },
    ],
  },
  {
    path: "interfaith",
    component: InterfaithComponent,
    children: [
      {
        path: "ilgl",
        component: IlglComponent,
      },
      {
        path: "activities",
        component: TeamsComponent,
      },
      {
        path: "resources",
        component: ConstitutionComponent,
      },
    ],
  },
  {
    path: "ieb",
    component: IebComponent,
    children: [
      {
        path: "info",
        component: IebInformationComponent,
      },
      {
        path: "curriculum",
        component: IebCurriculumComponent,
      },
      {
        path: "volunteer",
        component: IebVolunteersComponent,
      },
      {
        path: "contact",
        component: IebContactsComponent,
      },
      {
        path: "registration",
        component: IebRegistrationComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "Members",
        },
      },
      {
        path: "iebManage",
        component: IebManageComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          moduleAccess: "IebContent",
        },
      },
    ],
  },
  {
    path: "ieb/Manage/student/:id",
    component: IebStudentComponent,
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      moduleAccess: "IebContent",
    },
  },
  {
    path: "covid",
    component: RestrictionsComponent,
  },
  {
    path: "401",
    component: ErrorComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled"
}),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
