import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  standalone: false,
  selector: 'app-interfaith',
  templateUrl: './interfaith.component.html',
  styleUrls: ['./interfaith.component.scss']
})
export class InterfaithComponent implements OnInit {
  navLinks: any[];
  activeLinkIndex = -1;
  emptyResultType = this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
  constructor(private router: Router) {
    this.buildNavLinks();
    this.setUrl();
  }
  ngOnInit() {
  }

  buildNavLinks() {
    this.navLinks = [
      {
        label: 'ILGL',
        link: './ilgl',
        index: 0,
        emptyResultType: 'ilgl',
        active: false
      }, {
        label: 'Activities',
        link: './activities',
        index: 1,
        emptyResultType: 'activities',
        active: false
      }, {
        label: 'Islam Resources',
        link: './resources',
        index: 2,
        emptyResultType: 'resources',
        active: false
      }
    ];

  }
  setUrl() {
    let path = this.router.url.split('?')[0];
    if (path === '/interfaith') {
      path = 'ilgl';
    }
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => path.indexOf(tab.emptyResultType) !== -1));
    this.setActiveTab();
    this.router.navigate([this.router.url]);
  }

  setActiveTab() {
    const activeLinks = this.router.url.split('/').filter(x => x);
    if (activeLinks && activeLinks[0] === 'interfaith') {
      let tab = activeLinks[1] ? activeLinks[1] : 'ilgl';
      this.toggleTab(tab);
    }
  }

  toggleTab(tabName: string) {
    this.emptyResultType = tabName;
    this.navLinks.map(x => {
      x.active = false;
    });
    let activeTab = this.navLinks.find(x => x.emptyResultType === tabName);
    activeTab.active = true;
  }

}
