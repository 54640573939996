import { VehicleService } from "./../../services/vehicle.service";
import { TenantAddendum } from "src/app/models/tenant-addendum";
import { LeaseFile } from "src/app/models/lease-file";
import { TenantService } from "src/app/services/tenant.service";
import { Component, OnInit, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";
import { TenantInfo } from "src/app/models/tenant-info";
import { LeaseService } from "src/app/services/lease.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { File } from "src/app/models/file";
import { SecurityService } from "src/app/services/security.service";
import { SignatureDialogModel } from "src/app/models/signature-dialog-model";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";
@Component({
  standalone: false,
  selector: "app-addendum-manage",
  templateUrl: "./addendum-manage.component.html",
  styleUrls: ["./addendum-manage.component.scss"],
})
export class AddendumManageComponent implements OnInit, OnDestroy {
  verifyAddendumClicked$: Subscription;
  tenant: TenantInfo;
  extModel: TenantInfo;
  docs: LeaseFile[];
  existingCoordSig: File;
  notLoading: boolean;
  signeeName: string;
  disableSig = false;
  disableChildControls = true;
  sigModel: SignatureDialogModel;
  extDoc: LeaseFile;
  parkingAddDoc: LeaseFile;
  parkingTermDoc: LeaseFile;
  faDownload = faDownload;
  parkingModel: TenantAddendum;
  subscriptions = new Subscription();

  constructor(
    private tenantService: TenantService,
    private leaseService: LeaseService,
    private snackBar: MatSnackBar,
    private securityService: SecurityService,
    private router: Router,
    private vehicleService: VehicleService
  ) {}

  ngOnInit() {
    this.verifyAddendumClicked$ =
      this.tenantService.verifyAddendumsClicked$.subscribe((data) => {
        if (data) {
          this.tenant = Object.assign({}, data);

          this.getDocsToSign();
          this.setModels();
        } else {
          this.router.navigate(["manage/tenantsManage"]);
        }
      });
    this.signeeName = this.securityService.getUserName();
    this.getExistingCoordSig();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setModels() {
    if (!this.tenant) {
      return;
    }
    if (
      this.tenant.leaseInfo.addendums &&
      this.tenant.leaseInfo.addendums.length > 0
    ) {
      const extAddendum = this.tenant.leaseInfo.addendums.find(
        (a) => a.type === "Lease-Ext" && a.statusId === 3
      );
      if (extAddendum != null) {
        this.extModel = this.tenant;
      }

      const parkAddAddendum = this.tenant.leaseInfo.addendums.find(
        (a) =>
          a.type === "Parking-Add" ||
          (a.type === "Parking-Term" && a.statusId === 3)
      );
      if (parkAddAddendum != null) {
        this.vehicleService
          .vehicleGet(parkAddAddendum.vehicleId)
          .subscribe((res) => {
            if (!res) {
              return;
            }
            if (!this.tenant.vehicles) {
              this.tenant.vehicles = [];
              this.tenant.vehicles.push(res);
            } else {
              this.tenant.vehicles.splice(0, 0, res);
            }
            this.parkingModel = {
              tenant: this.tenant,
              eligible: {
                extEligible: true,
                parkingEligible: true,
              },
              type: parkAddAddendum.type,
            };
          });
      }
    }
  }
  getDocsToSign() {
    if (!this.tenant) {
      return;
    }
    if (!this.tenant.leaseInfo.addendums) {
      return;
    }
    this.leaseService
      .getAddendumDocsForIcbSig(this.tenant.leaseInfo.id)
      .subscribe((data) => {
        if (data) {
          this.docs = Object.assign([], data);
          const extAddendum = this.tenant.leaseInfo.addendums.find(
            (a) => a.type === "Lease-Ext" && a.statusId === 3
          );
          if (extAddendum) {
            this.extDoc = this.docs.find(
              (d) => d.fileId === extAddendum.fileId
            );
          }
          const parkingAddDoc = this.tenant.leaseInfo.addendums.find(
            (a) => a.type === "Parking-Add" && a.statusId === 3
          );
          if (parkingAddDoc) {
            this.parkingAddDoc = this.docs.find(
              (d) => d.fileId === parkingAddDoc.fileId
            );
          }
          const parkingTermDoc = this.tenant.leaseInfo.addendums.find(
            (a) => a.type === "Parking-Term" && a.statusId === 3
          );
          if (parkingTermDoc) {
            this.parkingTermDoc = this.docs.find(
              (d) => d.fileId === parkingTermDoc.fileId
            );
          }
        }
      });
  }

  getExistingCoordSig() {
    this.leaseService.coordSignatureGet().subscribe((data) => {
      if (data) {
        this.existingCoordSig = Object.assign(data);
      }
      this.sigModel = {
        signee: "Coordinator",
        role: "Admin",
        hasError: false,
        errorMessage: null,
        showCancel: false,
        existingSig: this.existingCoordSig,
        signeeName: this.signeeName,
        useExisting: false,
      };
    });
  }

  UpdateAddendum(leaseFile: LeaseFile) {
    if (leaseFile) {
      let msg;
      if (leaseFile.name === "Lease Extension") {
        this.extDoc = Object.assign({}, leaseFile);
        msg = "Lease Extension Addendum is signed, an email is sent to tenant";
      }
      if (leaseFile.name === "Parking Addition") {
        this.parkingAddDoc = Object.assign({}, leaseFile);
        msg = "Parking Addition Addendum is signed, an email is sent to tenant";
      }
      if (leaseFile.name === "Parking Termination") {
        this.parkingTermDoc = Object.assign({}, leaseFile);
        msg =
          "Parking Termination Addendum is signed, an email is sent to tenant";
      }

      this.showConfirmationDialog(msg);
    }
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
