<div class="page-container">
  <div class="page-header">
    <div class="header">
      {{headerTitle}}
    </div>
  </div>
  <div class="search-container" *ngIf="!selectedMember">
    <span class="input-icon-right element-search">
      <i class="material-icons">search</i>
      <input
        type="text"
        #memberSearch
        matInput
        pInputText
        [matAutocomplete]="auto"
        class="input-left-rounded input-white"
        placeholder="Search members by name or email "
        (keyup)="searchChanged(memberSearch.value)"
        [(ngModel)]="name"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectedMember($event.option.value)">
        <mat-option *ngFor="let item of searchSource$ | async" [value]="item" [(disabled)]="item.disabled">
          {{ item.name }}
      </mat-option>
    </mat-autocomplete>
    </span>
  </div>
  <div class="reg-item-container" *ngIf="selectedMember">
    <div class="selected-member">
      Selected Parent : {{name}} ({{email}})
    </div>
    <div class="btn-container pad-bottom">
      <button mat-flat-button
        class="btn btn-yellow btn-reg"
        (click)="cancelSearch()"
    >
      Cancel & New Search
    </button>
    <button mat-flat-button
      class="btn btn-secondary-blue-gradient btn-reg"
      [disabled] = "disableRegister"
      (click)="register()"
    >
      Start Registration
    </button>
    </div>
  </div>
  <div class="reg-item-container" *ngIf="regVM$ | async as vm else loading">
    <div *ngIf="vm.id > 0">
      <app-ieb-reg-detail [regModel] = "vm"></app-ieb-reg-detail>
    </div>
  </div>
  <div class="reg-item-container" *ngIf="kidsVM$ | async as vm else loading">
    <div *ngIf="step === 'kids' && vm.schoolParentId">
      <app-ieb-kids [model]="vm" [adminView]= "true" (addKid)="onAddNewKid($event)" (updateKid) ="onUpdateNewKid($event)" (deleteKid)="onDeleteKid($event)"></app-ieb-kids>
    </div>
  </div>
  <div class="reg-item-container" *ngIf="regFormVM$ | async as vm else loading">
    <div *ngIf="step === 'payment'">
      <app-ieb-admin-reg-payment [model]="vm" (addNewReg) = "onAddNewReg($event)" ></app-ieb-admin-reg-payment>
    </div>
  </div>
  <div class="btn-container btn-single" *ngIf="step">
    <button *ngIf="step!=='kids'"
      mat-flat-button
      class="btn btn-green-mend-gradient btn-stepper"
      (click)="backClick()"
    >
      Back
    </button>
    <button *ngIf="step!=='payment'"
      mat-flat-button
      class="btn btn-secondary-blue-gradient btn-stepper"
      [disabled] = "disableNext"
      (click)="nextClick()"
    >
      Next
    </button>

  <div *ngIf="confirm$ | async as confirm">

  </div>
  <div class="spinner-container" *ngIf="load$ | async as load">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</div>
<ng-template #loading>
  <div class="table-skeleton">
  </div>
</ng-template>
