import { Component, OnInit } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-tenants-manage",
  templateUrl: "./tenants-manage.component.html",
  styleUrls: ["./tenants-manage.component.scss"],
})
export class TenantsManageComponent implements OnInit {
  showTenants = true;
  showWaitList: boolean;
  showRents: boolean;
  showRooms: boolean;
  showParkings: boolean;
  showTypes = false;
  constructor() {}

  ngOnInit() {}

  typeClick(name: string) {
    if (name === "tenants") {
      this.showTenants = true;
      this.showWaitList = false;
      this.showRents = false;
      this.showRooms = false;
      this.showParkings = false;
    } else if (name === "waitList") {
      this.showTenants = false;
      this.showWaitList = true;
      this.showRents = false;
      this.showRooms = false;
      this.showParkings = false;
    } else if (name === "rents") {
      this.showTenants = false;
      this.showWaitList = false;
      this.showRents = true;
      this.showRooms = false;
      this.showParkings = false;
    } else if (name === "rooms") {
      this.showTenants = false;
      this.showWaitList = false;
      this.showRents = false;
      this.showRooms = true;
      this.showParkings = false;
    } else if (name === "parkings") {
      this.showTenants = false;
      this.showWaitList = false;
      this.showRents = false;
      this.showRooms = false;
      this.showParkings = true;
    }
  }
}
