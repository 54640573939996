
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  standalone: false,
  selector: 'app-ieb-student',
  templateUrl: './ieb-student.component.html',
  styleUrls: ['./ieb-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebStudentComponent implements OnInit {
  VM$ = this.schoolService.studentDetailsForm$;

  constructor(private schoolService: SchoolManageService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const schoolKidId = parseInt(this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : '');
    this.schoolService.studentSelected(schoolKidId);

  }

  backToStudents() : void {
    this.router.navigate(['ieb/iebManage']);
  }







}
