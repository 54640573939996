<div class="page-container" >
  <div class="page-header-section">
    <div class="header">
      Students
    </div>
  </div>
  <div class="page-filters"  *ngIf="VM$  | async as vm;">
    <div class="item-filter">
      <div class="select-filter">
        <app-flat-select
        [selectOptions]="vm.downloadList"
        [(ngModel)]="selectedDownload"
        [ngModelOptions]="{ standalone: true }"
        [defaultLabel]="'Select'"
        [(value)]="selectedDownload.value"
        (selectOptionChange)="onDownload($event)"
        ngDefaultControl
      ></app-flat-select>
      </div>
    </div>

    <div class="item-filter">
      <div class="select-filter" *ngIf="selectedSemester">
        <app-flat-select
        [selectOptions]="vm.semesterList"
        [(ngModel)]="selectedSemester"
        [ngModelOptions]="{ standalone: true }"
        [defaultLabel]="'Select'"
        [(value)]="selectedSemester.label"
        (selectOptionChange)="onSemesterChange($event)"
        ngDefaultControl
      ></app-flat-select>
      </div>
    </div>
    <div class="item-filter">
      <div class="select-filter" *ngIf="selectedLevel">
        <app-flat-select
        [selectOptions]="vm.levelList"
        [(ngModel)]="selectedLevel"
        [ngModelOptions]="{ standalone: true }"
        [(defaultLabel)]="selectedLevel.label"
        [(value)]="selectedLevel.label"
        (selectOptionChange)="onLevelChange($event)"
        ngDefaultControl
      ></app-flat-select>
      </div>
    </div>
    <div class="search-container">
      <span class="input-icon-right element-search">
        <i class="material-icons">search</i>
        <input
          type="text"
          #studentSearch
          pInputText
          class="input-left-rounded input-white"
          placeholder="Search students by Name & Email"
          (keyup)="onSearchChanged(studentSearch.value)"
        />
      </span>
    </div>
  </div>
  <div class="page-table-container" *ngIf="dataSource$ | async as vm; else loading">
    <table mat-table [dataSource]="vm" (matSortChange)="onSort($event)" matSort>
      <ng-container matColumnDef="level">
        <th class="page-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
          Level
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.level }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          class="page-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th
          class="page-table-header hide-column"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Email
        </th>
        <td mat-cell *matCellDef="let item" class="hide-column">
          {{ item.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="parentName">
        <th
          class="page-table-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Parent
        </th>
        <td mat-cell *matCellDef="let item">
          {{ item.parentName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="parentEmail">
        <th
          class="page-table-header hide-column"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Parent Email
        </th>
        <td mat-cell *matCellDef="let item" class="hide-column">
          {{ item.parentEmail}}
        </td>
      </ng-container>
      <ng-container matColumnDef="parentPhone">
        <th
          class="page-table-header hide-column"
          mat-header-cell
          *matHeaderCellDef
          >
          Parent Phone
        </th>
        <td mat-cell *matCellDef="let item" class="hide-column">
          {{ item.parentPhone}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="studentSelection(row.schoolKidId)"
        [ngClass]="row.rowClass"
      ></tr>
    </table>
    <div class="table-paginator">
      <mat-paginator
      #paginator
      [length]="totalCount"
      [pageSizeOptions]="pageSizes"
      (page) = "onPageChanged($event)"
      hidePageSize
      showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="spinner-container">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</ng-template>
