<div class="tenant-container">
  <div class="tenant-section">
    <div class="tenant-header-section">
      <div class="header">
        <div class="header-title" *ngIf="tenant">
          Tenant -
          {{ tenant.memberInfo.firstName }}
          {{ tenant.memberInfo.lastName }}
        </div>
      </div>
    </div>
    <div class="page-row">
      <div class="row-content">
        <div class="page-sec">
          <div class="left">
            <div class="item-container" *ngIf="showAddendumMessage">
              <app-addendum-message
                [extMessage]="addendumExtMessage"
              ></app-addendum-message>
            </div>
            <div class="item-container">
              <div class="sub-header status">Lease Status</div>
              <div class="lease-status-wrapper" *ngIf="tenant">
                <div
                  class="lease-status"
                  [ngClass]="{
                    active: tenant.leaseInfo.statusId === 4,
                    awaiting: tenant.leaseInfo.statusId === 3,
                    expired: tenant.leaseInfo.statusId === 5
                  }"
                  matTooltip="{{ leaseStatusInfo }}"
                  matTooltipPosition="above"
                ></div>
                <div class="lease-status-text">
                  <span class="status">{{ leaseStatusText }}</span>
                </div>
              </div>
            </div>
            <div class="item-container">
              <div class="item-label-wrapper">
                <span class="item-label">Room:</span>
              </div>
              <div class="item-input-wrapper medium left-close">
                <app-flat-input
                  [placeHolder]="'Room'"
                  [(value)]="roomName"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
              <div class="item-label-wrapper">
                <span class="item-label">Start Date:</span>
              </div>
              <div class="item-input-wrapper skinny left-close">
                <app-flat-input
                  [placeHolder]="'Start Date'"
                  [value]="tenant.leaseInfo.startDate | customDate"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
              <div class="item-label-wrapper">
                <span class="item-label">End Date:</span>
              </div>
              <div class="item-input-wrapper skinny left-close">
                <app-flat-input
                  [placeHolder]="'End Date'"
                  [value]="tenant.leaseInfo.endDate | customDate"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="item-container">
              <div class="item-label-wrapper">
                <span class="item-label">Monthly Rent:</span>
              </div>
              <div class="item-input-wrapper medium left-close">
                <app-flat-input
                  [placeHolder]="'Rent'"
                  [(value)]="monthlyRent"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
              <div
                class="item-label-wrapper"
                *ngIf="tenant && tenant.parkingSpaceRent"
              >
                <span class="item-label">Monthly Parking Fee:</span>
              </div>
              <div
                class="item-input-wrapper medium left-close"
                *ngIf="tenant && tenant.parkingSpaceRent"
              >
                <app-flat-input
                  [placeHolder]="'Parking'"
                  [(value)]="parkingFee"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="item-container" *ngIf="showAutoRenewal">
              <div class="item-checkbox-wrapper">
                <div class="item-checkbox">
                  <mat-checkbox
                    [(ngModel)]="autoRenewal"
                    [(checked)]="autoRenewal"
                    (change)="onAutoRenewalChange($event)"
                  >
                  </mat-checkbox>
                </div>
                <div class="checkbox-text question">
                  Auto-renew your lease for the same
                  {{ this.leaseDuration }} months duration as lease.
                </div>
              </div>
              <div class="item-note-wrapper">
                <div class="item-note">
                  <i class="material-icons info-icon">info</i>
                  <span class="note-text">
                    This action cannot be revert back. For duration other than
                    lease click on lease extension to create extension addendum.
                  </span>
                </div>
              </div>
            </div>
            <div
              class="item-container"
              *ngIf="tenant && tenant.leaseInfo.statusId > 3 && showNextPayment"
            >
              <div class="sub-header status">Next Payment Due Date</div>
              <div
                class="lease-status"
                [ngClass]="{
                  active: paymentDueStatus === 'green',
                  awaiting: paymentDueStatus === 'yellow',
                  expired: paymentDueStatus === 'red'
                }"
                matTooltip="{{ paymentDueInfo }}"
                matTooltipPosition="above"
              ></div>
              <div class="lease-status-text">
                <span class="status">{{ nextPaymentDueDate }}</span>
              </div>
              <div class="btn-container">
                <a
                  button
                  mat-raised-button
                  class="page-btn"
                  color="primary"
                  (click)="openStripeDialog()"
                >
                  <fa-icon [icon]="faMosque" class="icon-buttons"></fa-icon> Pay
                  {{ nextPaymentTotal }}
                </a>
              </div>
            </div>
            <div *ngIf="tenant && tenant.leaseInfo?.addendums">
              <div
                *ngFor="let item of tenant.leaseInfo?.addendums; let i = index"
              >
                <div class="item-container" *ngIf="item.type === 'Lease-Ext'">
                  <div class="sub-header">Lease Extension Addendum</div>
                </div>
                <div class="item-container" *ngIf="item.type === 'Parking-Add'">
                  <div class="sub-header">Parking Addition Addendum</div>
                </div>
                <div
                  class="item-container"
                  *ngIf="item.type === 'Parking-Term'"
                >
                  <div class="sub-header">Parking Termination Addendum</div>
                </div>
                <div class="item-container">
                  <div class="item-label-wrapper">
                    <span class="item-label">Addendum Date:</span>
                  </div>
                  <div class="item-input-wrapper medium left-close">
                    <app-flat-input
                      [placeHolder]="'Addendum Date'"
                      [value]="item.addendumDate | customDate"
                      [disabled]="true"
                    >
                    </app-flat-input>
                  </div>
                  <div class="item-label-wrapper">
                    <span class="item-label">Start:</span>
                  </div>
                  <div class="item-input-wrapper medium left-close">
                    <app-flat-input
                      [placeHolder]="'Start Date'"
                      [value]="item.startDate | customDate"
                      [disabled]="true"
                    >
                    </app-flat-input>
                  </div>
                  <div
                    class="item-label-wrapper"
                    *ngIf="item.type !== 'Parking-Term'"
                  >
                    <span class="item-label">End:</span>
                  </div>
                  <div
                    class="item-input-wrapper medium left-close"
                    *ngIf="item.type !== 'Parking-Term'"
                  >
                    <app-flat-input
                      [placeHolder]="'End Date'"
                      [value]="item.endDate | customDate"
                      [disabled]="true"
                    >
                    </app-flat-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="item-container" *ngIf="tenant && vehicle">
              <app-vehicle-manage
                [vehicle]="vehicle"
                [tenantId]="tenant.id"
                [headerTitle]="vehicleTitle"
                (notifyParent)="setVehicle($event)"
              ></app-vehicle-manage>
            </div>
          </div>
          <div class="right">
            <div class="right-header">
              <div class="right-header-title">Documents</div>
            </div>
            <div *ngFor="let doc of docs" class="lease-docs">
              <div class="doc-button" *ngIf="doc.file.url">
                <a
                  [href]="doc.file.url | safe"
                  class="download-link"
                  target="_blank"
                  matTooltip="Download"
                  matTooltipPosition="below"
                >
                  <fa-icon [icon]="faDownload" class="icon-download"> </fa-icon>
                  {{ doc.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spinner-container" *ngIf="!notLoading">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</div>
