<div class="ext-request-container">
  <div class="item-container" *ngIf="showInfo">
    <div class="sub-header">{{ headerTitle }}</div>
  </div>
  <div class="item-container">
    <div class="sub-header info">
      ISGL shall not consider prorate rent amount for parking fee.
    </div>
  </div>
  <div class="item-container left-align">
    <div class="item-label-wrapper">
      <span class="item-label">Monthly Rent:</span>
    </div>
    <div class="item-input-wrapper medium left-close">
      <app-flat-input
        [placeHolder]="'Rent'"
        [(value)]="rentAmount"
        [(ngModel)]="rentAmount"
        [disabled]="true"
      >
      </app-flat-input>
    </div>

    <div class="item-label-wrapper" *ngIf="model.type === 'Parking-Add'">
      <span class="item-label">Duration:</span>
    </div>
    <div
      class="item-select-wrapper"
      *ngIf="!disableControls && model.type === 'Parking-Add'"
    >
      <app-flat-select
        [selectOptions]="extDurationList"
        [(ngModel)]="selectedExtDuration"
        [defaultLabel]="'Select'"
        [(value)]="selectedExtDuration.value"
        [position]="'bottom'"
        (selectOptionChange)="setDuration($event)"
        ngDefaultControl
      ></app-flat-select>
    </div>

    <div
      class="item-input-wrapper medium left-close"
      *ngIf="disableControls && model.type === 'Parking-Add'"
    >
      <app-flat-input
        [placeHolder]="'Duration'"
        [(value)]="selectedExtDuration.label"
        [(ngModel)]="selectedExtDuration.label"
        [disabled]="true"
      >
      </app-flat-input>
    </div>

    <div class="item-label-wrapper">
      <span class="item-label">Start Date:</span>
    </div>
    <div class="input-date date-picker" *ngIf="!disableControls">
      <input
        [matDatepicker]="datePicker"
        class="input-date"
        [(ngModel)]="sDate"
        (dateInput)="datePicker.close()"
        (dateChange)="onStartDateChange();datePicker.close()"
        (focus)="datePicker.open()"
        readonly
      />
      <mat-datepicker-toggle
        class="item-date-picker"
        matSuffix
        [for]="datePicker"
      >
      </mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </div>
    <div class="item-input-wrapper medium left-close" *ngIf="disableControls">
      <app-flat-input
        [placeHolder]="'Start Date'"
        [(value)]="sDate"
        [(ngModel)]="sDate"
        [disabled]="true"
      >
      </app-flat-input>
    </div>

    <div class="item-label-wrapper" *ngIf="model.type === 'Parking-Add'">
      <span class="item-label">End Date:</span>
    </div>
    <div
      class="item-input-wrapper medium left-close"
      *ngIf="model.type === 'Parking-Add'"
    >
      <app-flat-input
        [placeHolder]="'End'"
        [(value)]="eDate"
        [(ngModel)]="eDate"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
  </div>

  <div class="item-container left-align">
    <div class="item-label-wrapper">
      <span class="item-label">Name:</span>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Vehicle Name'"
        [(value)]="veh.name"
        [(ngModel)]="veh.name"
        [hasError]="veh.validation.name.hasError"
        [errorMessage]="veh.validation.name.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleName', veh.name)"
        (blur)="setValue('vehicleName', veh.name)"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Model:</span>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Model'"
        [(value)]="veh.model"
        [(ngModel)]="veh.model"
        [hasError]="veh.validation.model.hasError"
        [errorMessage]="veh.validation.model.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleModel', veh.model)"
        (blur)="setValue('vehicleModel', veh.model)"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">License Plate:</span>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'License Plate'"
        [(value)]="veh.licensePlate"
        [(ngModel)]="veh.licensePlate"
        [hasError]="veh.validation.licensePlate.hasError"
        [errorMessage]="veh.validation.licensePlate.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('licensePlate', veh.licensePlate)"
        (blur)="setValue('licensePlate', veh.licensePlate)"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Year:</span>
    </div>
    <div class="item-select-wrapper skinny">
      <app-flat-select
        [selectOptions]="vehicleYears"
        [(ngModel)]="veh.year"
        [(defaultLabel)]="defaultLabel"
        [(value)]="veh.year"
        [hasError]="veh.validation.year.hasError"
        [errorMessage]="veh.validation.year.errorMessage"
        (selectOptionChange)="setValue('vehicleYear', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
  </div>
  <div class="ext-btn-container">
    <div class="child-container" [ngClass]="{ center: showSigErrorContainer }">
      <span *ngIf="showSigErrorContainer" class="error-icon-container">
        <span class="error-icon"><mat-icon>error_outline</mat-icon></span>
        <span class="error-text">Required!</span>
      </span>
      <button
        mat-raised-button
        class="upload-btn"
        (click)="openSignatureDialog()"
        [disabled]="disableSig"
      >
        <fa-icon [icon]="faSignature" class="icon-buttons"></fa-icon>
        Click to Sign
      </button>
    </div>
    <div class="child-container">
      <button
        mat-raised-button
        class="ext-add-btn"
        [disabled]="hasError"
        (click)="addAddendum()"
      >
        <i class="material-icons icon">save</i>
        {{ btnText }}
      </button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="!notLoading">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</div>
