<div class="flat-input-container" [ngClass]="{ error: hasError }">
  <div class="input-wrapper" [ngClass]="{ error: hasError }">
    <div class="thumbnail-wrapper" *ngIf="thumbnail">
      <div class="thumbnail-image">
        <img [src]="thumbnail" />
      </div>
    </div>
    <input
      class="flat-input"
      placeholder="{{ placeHolder }}"
      value="{{ value }}"
      type="{{ inputType }}"
      (keydown.enter)="$event.target.blur()"
      (keyup)="updateValue($event.target.value)"
      (blur)="blur()"
      [disabled]="disabled"
    />

    <div class="button-wrapper" *ngIf="!hasError && buttonType === 'delete'">
      <div
        *ngIf="buttonType === 'delete'"
        class="hover-wrapper button-warn"
        (click)="buttonClicked('delete')"
      >
        <i class="material-icons input-button">delete</i>
      </div>
    </div>
  </div>
  <div class="validation-error-content" *ngIf="hasError">
    <div class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</div>
