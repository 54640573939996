import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { SecurityService } from "../services/security.service";

@Injectable()
export class RoleGuard  {
  constructor(
    private securityService: SecurityService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const role = this.securityService.getRole();
    const moduelAccessClaims = this.securityService.getModuleAccess();
    const expectedModuleAccess = route.data.moduleAccess;

    if (role === "Admin") {
      return true;
    } else if (role === "TenantAdmin" || role === "ContentAdmin" || role === 'IebAdmin') {
      const intersection = moduelAccessClaims.filter(
        (e) => expectedModuleAccess.indexOf(e) !== -1
      );
      if (intersection) {
        return true;
      }
    } else if (role === "Member") {
      if (moduelAccessClaims.includes(expectedModuleAccess)) {
        return true;
      }
    } else {
      if (state.url === "/profile" || state.url === "/bookRoom") {
        this.router.navigate(["login"], {
          queryParams: { returnUrl: state.url },
        });
      } else {
        this.router.navigate(["401"], {
          queryParams: { returnUrl: state.url },
        });
      }
    }
  }
}
