<div class="event-categories-container">
  <div class="categories-container">
    <div class="categories-header">
      <div class="header">Manage Event Categories</div>
    </div>
    <div class="category-row" *ngFor="let category of categories">
      <div class="category-active-col">
        <div class="checkbox-wrapper">
          <mat-checkbox
            class="category-checkbox"
            [(ngModel)]="category.active"
            [(checked)]="category.active"
            (change)="onCheckChange($event, category)"
          >
          </mat-checkbox>
          <span class="checkbox-text">Active </span>
        </div>
      </div>
      <div class="category-name-col">
        <div class="category-item-wrapper">
          <app-flat-input
            [placeHolder]="'Category'"
            [(value)]="category.name"
            [buttonType]="'delete'"
            [hasError]="category.validation.name.hasError"
            [errorMessage]="category.validation.name.errorMessage"
            (focusChanged)="setUpdateValue(category.id, category.name)"
            (actionTriggered)="deleteCategory(category.id)"
          >
          </app-flat-input>
        </div>
      </div>
    </div>
    <button
      mat-fab
      class="add-category-button-fab"
      matTooltip="Add Category"
      matTooltipPosition="left"
      (click)="addCategory()"
      *ngIf="!showAddCategory"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-category-container"
      [ngClass]="{ visible: showAddCategory, invisible: !showAddCategory }"
    >
      <div class="new-category">
        <div class="new-category-item-wrapper">
          <app-flat-input
            [placeHolder]="'Category Name'"
            [(value)]="newCategory.name"
            [hasError]="newCategory.validation.name.hasError"
            [errorMessage]="newCategory.validation.name.errorMessage"
            (focusChanged)="validateNewCategory(newCategory.name)"
          >
          </app-flat-input>
        </div>
        <div class="form-buttons">
          <button
            type="button"
            class="text-button"
            [disabled]="newCategory.validation.name.hasError"
            (click)="saveCategory()"
          >
            SAVE
          </button>
          <button
            type="button"
            class="text-button"
            (click)="closeAddCategory()"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
