<div class="sig-modal-container" >
  <div class="modal-header">
    <button *ngIf="signaturePad"
      [ngClass]="{ hide: !showClear }"
      type="button"
      class="text-button clear"
      [disabled]="signaturePad.isEmpty()"
      (click)="clearSignature()"
    >
      Clear Signature
    </button>
    <div class="close-button" (click)="onDismiss()">
      <a><fa-icon [icon]="faTimes"></fa-icon></a>
    </div>
  </div>
  <div class="row-content">
    <div class="signature-container">
      <div class="sig-section">
        <div class="sig-col">
          <div class="sig-row grow">
            <div class="existing-sig-container" *ngIf="model.existingSig">
              <div class="existing-sig">
                <img alt="" [src]="model.existingSig.url" />
              </div>
              <div class="checkbox-wrapper">
                <div class="item-checkbox">
                  <mat-checkbox
                    [(ngModel)]="model.useExisting"
                    [(checked)]="model.useExisting"
                    (change)="onUseExistingChange($event)"
                  >
                  </mat-checkbox>
                </div>
                <div class="checkbox-text question">Select</div>
              </div>
            </div>
          </div>
          <div class="sig-row">
            <div class="print-sig-container">
              <label class="print-name" id="printSig">{{
                model.signeeName
              }}</label>
            </div>
            <div class="checkbox-wrapper">
              <div class="item-checkbox">
                <mat-checkbox
                  [(ngModel)]="usePrintSig"
                  [(checked)]="usePrintSig"
                  (change)="onUsePrintSigChange($event)"
                >
                </mat-checkbox>
              </div>
              <div class="checkbox-text question">Select</div>
            </div>
          </div>
        </div>
        <div class="sig-col">
          <div class="pad-row">
            <span class="label">Sign here:</span>
          </div>
          <div class="pad-row center">
            <div
              class="sig-pad-container"
              [ngClass]="{ error: model.hasError }"
            >
              <div class="sig-pad" [ngClass]="{ error: model.hasError }">
                <signature-pad
                  #signature
                  [options]="signaturePadOptions"
                  fxFlex="1 1 50"
                  class="signature"
                  (drawStart)="drawStart($event)"
                  (drawEnd)="saveSignature($event)"
                ></signature-pad>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-content" [ngClass]="{ 'left-space': model.hasError }">
    <div class="error-content" *ngIf="model.hasError">
      <span class="error-icon"> <mat-icon>error_outline</mat-icon></span>
      <span class="error-text"> {{ model.errorMessage }}</span>
    </div>
    <div class="dialog-buttons" [ngClass]="{ error: model.hasError }">
      <button
        mat-raised-button
        color="primary"
        class="btn-ok"
        [disabled]="model.hasError"
        (click)="onConfirm()"
      >
        OK
      </button>
    </div>
  </div>
</div>
