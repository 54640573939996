<div class="members-container">
  <div class="members-filter-container">
    <div class="members-btn-container">
      <div class="total-count-container">
        <div
          class="total-count-text"
          *ngIf="memberListResponse && memberListResponse.totalCount"
        >
          {{ memberListResponse.totalCount }} members
        </div>
      </div>
      <div class="download-members-container">
        <div
          class="download-button"
          (click)="downloadMembers()"
          matTooltip="Download member list"
          matTooltipPosition="right"
          matPrefix
        >
          <fa-icon class="download-properties" [icon]="faDownload"></fa-icon>
        </div>
      </div>
    </div>

    <div class="members-search-container">
      <input
        type="text"
        placeholder="Search by name, email, cell, address or city"
        class="member-search-input"
        [(ngModel)]="query"
        (keyup)="memberSearch($event)"
      />
      <fa-icon
        [icon]="faTimesCircle"
        class="member-clear-search-icon"
        *ngIf="query"
        (click)="clearSearch()"
      ></fa-icon>
      <a class="member-search-btn" (click)="memberSearch($event)">
        <span class="member-search-btn-content">
          <fa-icon [icon]="faSearch" class="member-search-icon"></fa-icon>
        </span>
      </a>
    </div>

    <div class="members-filter-items">
      <div class="filter-header">Filters:</div>
      <div class="filter-item">
        <div class="checkbox-wrapper">
          <mat-checkbox
            id="isStudent"
            [(ngModel)]="filters.isStudent"
            [(checked)]="filters.isStudent"
            (change)="applyFilter()"
          >
          </mat-checkbox>
          <span class="checkbox-text">Student </span>
        </div>
      </div>
      <div class="filter-item">
        <div class="checkbox-wrapper">
          <mat-checkbox
            id="isVoting"
            [(ngModel)]="filters.isVoting"
            [(checked)]="filters.isVoting"
            (change)="applyFilter()"
          >
          </mat-checkbox>
          <span class="checkbox-text">Voting </span>
        </div>
      </div>
      <div class="filter-item">
        <div class="checkbox-wrapper">
          <mat-checkbox
            id="residencyStatus"
            [(ngModel)]="filters.residencyStatus"
            [(checked)]="filters.residencyStatus"
            (change)="applyFilter()"
          >
          </mat-checkbox>
          <span class="checkbox-text">Resident </span>
        </div>
      </div>
    </div>
  </div>

  <div class="members-table-container" *ngIf="dataSource; else loading">
    <div
      class="infinite-scroll"
      infiniteScroll
      [infiniteScrollDistance]="0.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <table mat-table [dataSource]="dataSource" *ngIf="membersLoaded | async">
        <ng-container matColumnDef="name">
          <th class="members-table-header" mat-header-cell *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let member">
            {{ member.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="members-table-header" mat-header-cell *matHeaderCellDef>
            Email
          </th>
          <td mat-cell *matCellDef="let member">
            {{ member.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cellPhone">
          <th
            class="members-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Cell Phone
          </th>
          <td mat-cell *matCellDef="let member" class="hide-column">
            {{ member.cellPhone }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isStudent">
          <th
            class="members-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Student
          </th>
          <td mat-cell *matCellDef="let member" class="hide-column">
            {{ member.isStudent }}
          </td>
        </ng-container>
        <ng-container matColumnDef="residencyStatus">
          <th
            class="members-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Residency Status
          </th>
          <td mat-cell *matCellDef="let member" class="hide-column">
            {{ member.residencyStatus }}
          </td>
        </ng-container>
        <ng-container matColumnDef="membershipExpiration">
          <th
            class="members-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Membership Expiration
          </th>
          <td mat-cell *matCellDef="let member" class="hide-column">
            {{ member.membershipExpiration }}
          </td>
        </ng-container>
        <ng-container matColumnDef="isVoting">
          <th
            class="members-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Voting
          </th>
          <td mat-cell *matCellDef="let member" class="hide-column">
            {{ member.isVoting }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="showMemberSummary(row.id, row.name)"
        ></tr>
      </table>
    </div>
    <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div>
  </div>
  <div
    class="member-summary-container"
    [ngClass]="{
      'summary-panel-slide-in': showSummary,
      'summary-panel-slide-out': hideSummary
    }"
  >
    <div class="member-summary-header">
      <div class="member-summary-header-name">
        {{ summaryMemberName }}
        <div class="address">
          {{ summaryMemberAddress }}
        </div>
      </div>
    </div>
    <div class="close-summary-wrapper">
      <a class="close-summary-button" (click)="hideMemberSummary()">
        <i class="material-icons expand">expand_more</i>
      </a>
      <span class="close-info" id="close-kid-panel">
        click or escape to close
      </span>
    </div>
    <mat-tab-group
      #memberSummaryTabs
      class="member-summary-tabs"
      animationDuration="0ms"
      disableRipple="true"
      (selectedIndexChange)="selectedTabChange($event)"
    >
      <mat-tab label="Member Info">
        <app-member-info
          *ngIf="memberUpdate"
          [memberUpdate]="memberUpdate"
          (notifyUpdates)="updateMember($event)"
        ></app-member-info>
      </mat-tab>
      <mat-tab label="Payments">
        <app-member-payment
          [memberId]="summaryMemberId"
          (paymentCollected)="paymentCollected($event)"
          (paymentDeleted)="paymentDeleted($event)"
          (paymentUpdated)="paymentUpdated($event)"
        ></app-member-payment>
      </mat-tab>
      <mat-tab label="Kids">
        <app-member-kids
          [memberKids]="memberKids"
          [memberId]="summaryMemberId"
          (memberKidsUpdated)="updateMemberKids($event)"
        ></app-member-kids>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-template #loading>
    <app-card-skeleton></app-card-skeleton>
  </ng-template>
</div>
