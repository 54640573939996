<div class="team-manage-container">
  <div class="team-container">
    <div class="team-header">
      <div class="header">Manage Teams</div>
    </div>
    <div class="team-section">
      <div class="team-section-info" *ngIf="teams">
        <div class="team-item-container">
          <div class="team-select-wrapper">
            <app-flat-select
              [selectOptions]="teamList"
              (selectOptionChange)="onTeamSelectChange($event)"
              [(defaultLabel)]="defaultLabel"
              [(value)]="teamListValue"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div class="team-card-wrapper" *ngIf="selectedPerson">
            <app-team-card
              [team]="selectedPerson"
              [showProfileIcon]="true"
            ></app-team-card>
          </div>
        </div>
      </div>
    </div>
    <div class="team-section">
      <div class="team-section-info">
        <div class="team-item-container" *ngIf="selectedPerson">
          <div class="team-input-wrapper skinny">
            <app-flat-input
              [placeHolder]="'Type'"
              [(value)]="selectedPerson.type"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="team-input-wrapper">
            <app-flat-input
              [placeHolder]="'Position'"
              [(value)]="selectedPerson.position"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="team-item-container" *ngIf="selectedPerson">
          <div class="team-input-wrapper">
            <app-flat-input
              [placeHolder]="'Name'"
              [(value)]="selectedPersonName"
              [(ngModel)]="selectedPersonName"
              [hasError]="selectedPerson.validation.name.hasError"
              [errorMessage]="selectedPerson.validation.name.errorMessage"
              [buttonType]="'delete'"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('name', selectedPersonName)"
              (blur)="setUpdateValue('name', selectedPersonName)"
              (actionTriggered)="deleteTeamPerson(selectedPerson.id)"
            >
            </app-flat-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    *ngIf="selectedPerson && !selectedPerson.validation.hasError"
    mat-fab
    class="add-image-button-fab"
    matTooltip="Add Profile Image"
    matTooltipPosition="left"
    (click)="imageInput.click()"
  >
    <input
      hidden
      (change)="onImageSelected()"
      #imageInput
      type="file"
      id="image"
    />
    <i class="material-icons md-24">add</i>
  </button>
</div>
