<div class="rent-info-container">
  <div class="info-section">
    <div class="info-container">
      <div class="info-icon-container">
        <i class="material-icons info-icon">info</i>
      </div>
      <div class="rent-info">
        <ul class="info-list">
          <li class="list-item">
            Potential tenants must be Purdue students and willing to participate
            in masjid activities.
          </li>
          <li class="list-item" *ngIf="showWaitListInfo">
            Wait-list does not guarantee accommodation unless confirmed by ISGL
            admin via email.
          </li>
          <li class="list-item">
            As a faith organization, ISGL does consider religious affiliation in
            consideration of eligibility for residency.
          </li>
          <li class="list-item">
            Approved applicants will be directed to "ISGL Tenants Portal" to
            fill out and sign the lease, and pay security deposit along with
            rent online.
          </li>
        </ul>
      </div>
    </div>
    <div class="info-container rent" *ngIf="showRentBreakup">
      <div class="info-icon-container">
        <i class="material-icons info-icon">info</i>
      </div>
      <div class="rent-info">
        <ul class="info-list">
          Rent Breakup
          <li class="list-item">Single Room: $500 Monthly(Room rent).</li>
          <li class="list-item">Double Room: $350 Monthly(Room rent).</li>
          <li class="list-item">
            Utilities: $50 Monthly for(water, electricity, gas, internet, trash
            etc).
          </li>
          <li class="list-item">
            Cleaning Fee: $20 Monthly (cleaning of common areas).
          </li>
          <li class="list-item">
            Parking: $25 Monthly (for tenants with active parking lease).
          </li>
          <li class="list-item">Short Term Lease rents are 10% additional.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
