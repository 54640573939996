<div
  class="card-container"
  (click)="detail()"
  [matTooltip]="toolTipText"
  matTooltipPosition="above"
>
  <div class="card-row" *ngIf="announcement.eDate">
    <div class="announcement-date">
      {{ announcement.eDate }}
    </div>
  </div>
  <div class="card-row">
    <div class="header-thumbnail" *ngIf="announcement.eventFilesSummaryList">
      <img [src]="announcement.eventFilesSummaryList[0].thumbNail" />
    </div>
    <div class="announcement-header">{{ announcement.name }}</div>
  </div>
  <div class="card-row">
    <div class="announcement-content">
      {{ announcement.description }}
    </div>
  </div>
  <div class="card-row">
    <div class="announcement-content" *ngIf="announcement.anchorUrl">
      <a
        target="_blank"
        href="{{ announcement.anchorUrl }}"
        class="anchor-link"
      >
        <div class="anchor-link-text">{{ announcement.anchorUrlText }}</div>
      </a>
    </div>
  </div>
  <div class="card-row">
    <div class="announcement-content" *ngIf="announcement.videoUrl">
      <a target="_blank" href="{{ announcement.videoUrl }}" class="anchor-link">
        <fa-icon [icon]="faPlayCircle" class="link-icon video"></fa-icon>
        <div class="anchor-link-text icon">Video</div>
      </a>
    </div>
    <div class="announcement-content left" *ngIf="announcement.address">
      <a
        target="_blank"
        href="https://www.google.com/maps/search/{{ announcement.address }}"
        class="anchor-link"
      >
        <mat-icon class="link-icon map">place</mat-icon>
        <div class="anchor-link-text">Map Directions</div>
      </a>
    </div>
  </div>
  <div class="card-row"></div>
</div>
