<div class="ieb-registration-container">
  <div class="page-section">
    <div class="page-header-section">
      <div class="header">{{headerTitle}}</div>
    </div>
    <div *ngIf="schoolRegVM$ | async as vm; else loading">
      <div class ="page-row" *ngIf="vm.reg.id>0">
         <app-ieb-reg-detail [regModel] = "vm.reg"></app-ieb-reg-detail>
      </div>
      <div class="page-row"  *ngIf="vm.reg.id===0">
        <div class="row-content no-top-pad" *ngIf="vm.reg.regOpen">
          <mat-horizontal-stepper linear labelPosition="bottom" [disableRipple]="true" #stepper>
              <mat-step label="Parents" [completed]="vm.parentCompleted">
                <app-ieb-parent [model]=" vm.parent" (saveRequest)="onSaveParent($event)" (updateRequest)="onUpdateParent($event)"></app-ieb-parent>
                  <div class="btn-container btn-single">
                    <button
                      mat-flat-button
                      class="btn btn-secondary-blue-gradient btn-stepper"
                      [disabled]="!vm.parentCompleted"
                      matStepperNext
                    >
                      Next
                    </button>
                  </div>
              </mat-step>
              <mat-step label="Children" [completed]="vm.kidsCompleted">
                <app-ieb-kids [model]="vm.kids" (addKid)="onAddNewKid($event)" (updateKid) ="onUpdateNewKid($event)" (deleteKid)="onDeleteKid($event)"></app-ieb-kids>
                <div class="btn-container">
                  <button
                    mat-flat-button
                    class="btn btn-green-mend-gradient btn-stepper"
                    matStepperPrevious
                  >
                    Back
                  </button>
                  <button
                    mat-flat-button
                    class="btn btn-secondary-blue-gradient btn-stepper"
                    [disabled]="!vm.kidsCompleted"
                    matStepperNext
                  >
                    Next
                  </button>
                </div>
              </mat-step>
              <mat-step label="Register" [completed]="vm.regCompleted" >
                <div *ngIf="vm.kids.schoolKids && vm.kids.schoolKids.length > 0">
                  <app-ieb-reg-payment [model]="vm.regForm" (addNewReg) = "onAddNewReg($event)" ></app-ieb-reg-payment>
                  <div class="btn-container">
                    <button
                    mat-flat-button
                    class="btn btn-green-mend-gradient btn-stepper"
                    matStepperPrevious>
                    Back
                  </button>
                </div>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
      </div>
      <div class="row-content xtra-pad-top" *ngIf="!vm.reg.regOpen">
        <div class="reg-close-message">
          <div class="message-content">
            {{regCloseText}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="confirm$ | async as confirm">

  </div>
  <div class="spinner-container" *ngIf="load$ | async as load">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</div>
<ng-template #loading>
  <div class="table-skeleton">
  </div>
</ng-template>
