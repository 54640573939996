import { Component, OnInit } from '@angular/core';
import { ListRequest } from 'src/app/models/list-request';
import { Team } from 'src/app/models/team';
import { TeamFilters } from 'src/app/models/team-filters';
import { TeamService } from 'src/app/services/team.service';

@Component({
  standalone: false,
  selector: 'app-ieb-contacts',
  templateUrl: './ieb-contacts.component.html',
  styleUrls: ['./ieb-contacts.component.scss']
})
export class IebContactsComponent implements OnInit {

  filters: TeamFilters;
  listRequest: ListRequest;
  team: Team[];
  constructor(private teamService: TeamService) {
    this.getIebTeam();
  }

  ngOnInit() {
  }

  getIebTeam() {
    this.filters = {
      name: null,
      position: 'Islamic Education Board',
      type: 'EC'
    };

    this.listRequest = {
      filter: this.filters,
      itemsPerPage: null,
      pageNumber: null,
    };
    this.teamService.teamList(this.listRequest).subscribe(data => {
      this.team = [];
      this.team = Object.assign([], data);
      this.team.map(t => {
        if (t.position === 'EC-Islamic Education Board(Sunday School)') {
          t.contact = 'ieb@masjidisgl.org';
        }
      });
    });
  }

}
