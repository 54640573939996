import { EventFileSummary } from "./../../models/event-file-summary";
import { AddEventFileRequest } from "./../../models/add-event-file-request";
import { FileService } from "./../../services/file.service";
import { AddFileRequest } from "./../../models/add-file-request";
import { EventService } from "src/app/services/event.service";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnChanges,
} from "@angular/core";
import { SelectListItem } from "./../../models/select-list-item";
import { Event } from "./../../models/event";
import * as _moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EventCategory } from "src/app/models/event-category";
import { EventValidation } from "src/app/models/event-validation";
import { ValidationError } from "src/app/models/validation-error";
import { ConfirmationSnackbarComponent } from "../../components/confirmation-snackbar/confirmation-snackbar.component";
import config from 'src/assets/config.json';
import { FileValidation } from "src/app/models/file-validation";
import { File } from "src/app/models/file";
import { UpdateFileRequest } from "src/app/models/update-file-request";

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM/DD/YYYY",
    monthYearA11yLabel: "MM/DD/YYYY",
  },
};

@Component({
  standalone: false,
  selector: "app-announcement-manage",
  templateUrl: "./announcement-manage.component.html",
  styleUrls: ["./announcement-manage.component.scss"],
})
export class AnnouncementManageComponent implements OnInit, OnChanges {
  announcements: Event[];
  events: SelectListItem[];
  selectedEvent: Event;
  categories: EventCategory[];
  categoryList: SelectListItem[];
  selectedCategory: SelectListItem;
  defaultLabel = "Select";
  selectedEventhour: any;
  selectedEventMinutes: any;
  disableDelete: boolean;
  disableFileDelete: boolean;
  showAddEvent = false;
  newAnnouncement: Event;
  newAnnouncementHour: any;
  newAnnouncementMinutes: any;
  eventListValue = "";
  resetEventsList = false;
  addImageRequest: AddFileRequest;
  seletedEventImages: File[];
  @Input() hideAdd: boolean;
  constructor(
    private eventService: EventService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private fileService: FileService

  ) {
    // this.events.push(new SelectListItem('Select announcement', null, false));
    this.getEvents();
    this.getCategories();
    this.resetAnnouncement(true);
  }

  ngOnInit() {}

  ngOnChanges() {
    if (this.hideAdd) {
      this.showAddEvent = false;
    }
  }

  resetAnnouncement(isNew: boolean) {
    if (!isNew) {
      this.selectedEvent = {
        id: 0,
        categoryId: 0,
        eventDate: null,
        eDate: "",
        name: "",
        description: "",
        address: "",
        videoUrl: "",
        anchorUrl: "",
        anchorUrlText: "",
        category: null,
        hasEventDetails: false,
        createdDate: null,
        eventFilesSummaryList: null,
        validation: new EventValidation(
          new ValidationError(false),
          new ValidationError(false),
          false
        ),
      };
    } else {
      this.newAnnouncement = {
        id: 0,
        categoryId: 1,
        eventDate: null,
        eDate: "",
        name: "",
        description: "",
        address: "",
        videoUrl: "",
        anchorUrl: "",
        anchorUrlText: "",
        category: null,
        hasEventDetails: false,
        createdDate: null,
        eventFilesSummaryList: null,
        validation: new EventValidation(
          new ValidationError(true),
          new ValidationError(false),
          false
        ),
      };
    }
  }

  getEvents() {
    this.eventService.announcementAll().subscribe((data) => {
      if (data) {
        this.announcements = [];
        this.announcements = Object.assign([], data);
        this.events = this.announcements.map((item) => ({
          label: item.name,
          value: item.id,
          disabled: false,
        }));
        this.eventListValue = this.events[0].label;
      }
    });
  }

  getCategories() {
    this.eventService.categoriesList().subscribe((data) => {
      this.categories = data;
      this.categoryList = this.categories.map((item) => ({
        label: item.name,
        value: item.id,
        disabled: false,
      }));
    });
  }

  OnSelectChange(value: any) {
    this.selectedEvent = this.announcements.find((a) => a.id === value);
    if (
      this.selectedEvent.eventFilesSummaryList &&
      this.selectedEvent.eventFilesSummaryList.length > 0
    ) {
      this.selectedEvent.eventFilesSummaryList.map((item) => {
        item.thumbNail = `${config.fileBaseUrl}${item.thumbNail}`;
        item.validation = new FileValidation(new ValidationError(false), false);
      });
    }

    this.selectedCategory = this.categoryList.find(
      (c) => c.value === this.selectedEvent.categoryId
    );
    this.selectedEvent.validation = new EventValidation(
      new ValidationError(false),
      new ValidationError(false),
      false
    );
    this.selectedEvent.eventDate = this.selectedEvent.eventDate
      ? moment(this.selectedEvent.eventDate).local()
      : "";
    this.selectedEventhour = this.selectedEvent.eventDate
      ? moment(this.selectedEvent.eventDate).local().format("HH")
      : "00";
    this.selectedEventMinutes = this.selectedEvent.eventDate
      ? moment(this.selectedEvent.eventDate).local().format("mm")
      : "00";
  }

  OnCategorySelectChange(value: any, isNew: boolean) {
    if (!isNew) {
      this.selectedEvent.categoryId = value;
      this.updateAnnoucement();
    } else {
      this.newAnnouncement.categoryId = value;
    }
  }

  setUpdateValue(field: string, value: string) {
    let toUpdate = false;
    if (field === "name") {
      toUpdate = this.validateUpdate(field, value);
    } else if (
      field === "eventDate" ||
      field === "eventHours" ||
      field === "eventMinutes"
    ) {
      this.setEventDate(false);
      this.updateAnnoucement();
    } else {
      this.updateAnnoucement();
    }
    if (toUpdate) {
      this.updateAnnoucement();
    }
  }

  validateUpdate(field: string, value: string) {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        this.selectedEvent.validation.name.hasError = true;
        this.selectedEvent.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.selectedEvent.validation.name.hasError = false;
      }
    }
    return isValid;
  }

  updateAnnoucement() {
    if (!this.selectedEvent.eventDate) {
      this.selectedEvent.eventDate = null;
    }
    this.eventService
      .annoucementUpdate(this.selectedEvent)
      .subscribe((data) => {
        if (data) {
          this.selectedEvent.eventDate = moment(
            this.selectedEvent.eventDate
          ).local();
          const index = this.announcements.findIndex(
            (c) => c.id === this.selectedEvent.id
          );
          this.announcements.splice(index, 1, this.selectedEvent);
          const indx = this.events.findIndex(
            (e) => e.value === this.selectedEvent.id
          );
          this.events[indx].label = this.selectedEvent.name;
        }
      });
  }

  setEventDate(isNew: boolean) {
    if (!isNew) {
      if (!this.selectedEvent.eventDate) {
        return;
      }
      let hours = this.selectedEventhour
        ? this.selectedEventhour.toString()
        : "00";
      let minutes = this.selectedEventMinutes
        ? this.selectedEventMinutes.toString()
        : "00";
      hours = hours.length === 1 ? `0${hours}` : hours;
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;
      this.selectedEvent.eventDate = moment
        .utc(this.selectedEvent.eventDate)
        .set("hour", hours)
        .set("minute", minutes);
    } else {
      if (!this.newAnnouncement.eventDate) {
        return;
      }
      let hours = this.newAnnouncementHour
        ? this.newAnnouncementHour.toString()
        : "00";
      let minutes = this.newAnnouncementMinutes
        ? this.newAnnouncementMinutes.toString()
        : "00";
      hours = hours.length === 1 ? `0${hours}` : hours;
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;
      this.newAnnouncement.eventDate = moment
        .utc(this.newAnnouncement.eventDate)
        .set("hour", hours)
        .set("minute", minutes);
    }
  }

  deleteAnnouncement() {
    if (!this.disableDelete) {
      let dismissedByAction = false;
      this.disableDelete = true;
      const selectedEvent = this.selectedEvent;
      const announcementToDelete = this.announcements.find(
        (c) => c.id === selectedEvent.id
      );
      const index = this.announcements.indexOf(announcementToDelete);
      this.announcements.splice(index, 1); // remove the contact from the array
      const eventToDelete = this.events.find(
        (e) => e.value === selectedEvent.id
      );
      const indx = this.events.indexOf(eventToDelete);
      this.events.splice(indx, 1);
      this.eventListValue = null;
      this.resetEventsList = true;

      const deleteSnackBar = this.snackBar.openFromComponent(
        ConfirmationSnackbarComponent,
        {
          horizontalPosition: "center",
          duration: 5000,
          panelClass: "announcement-snack-bar",
          data: {
            undoDelete: () => {
              dismissedByAction = true;
              this.announcements.splice(index, 0, announcementToDelete);
              this.events.splice(indx, 0, eventToDelete);
              this.eventListValue = eventToDelete.label;
              this.resetEventsList = false;
              this.cd.detectChanges();
              const undoSnackBar = this.snackBar.openFromComponent(
                ConfirmationSnackbarComponent,
                {
                  horizontalPosition: "center",
                  duration: 5000,
                  panelClass: "announcement-snack-bar",
                  data: {
                    dismissSnackbar: () => {
                      this.snackBar.dismiss();
                      this.disableDelete = false;
                    },
                    displayText: "Undone!",
                    type: "message",
                  },
                }
              );
              this.disableDelete = false;
            },
            dismissSnackbar: () => {
              dismissedByAction = true;
              this.eventService.annoucementRemove(selectedEvent.id).subscribe();
              this.selectedEvent = null;
              this.resetEventsList = false;
              this.snackBar.dismiss();
              this.disableDelete = false;
            },
            displayText: `Announcement ${announcementToDelete.name}, is deleted`,
            type: "delete-kid",
          },
        }
      );

      // delete the contact if the snackbar closes due to duration and not closed by user.
      deleteSnackBar.afterDismissed().subscribe((info) => {
        if (info.dismissedByAction === false && dismissedByAction === false) {
          this.eventService.annoucementRemove(selectedEvent.id).subscribe();
          this.selectedEvent = null;
          this.resetEventsList = false;
          this.disableDelete = false;
        }
      });
    }
  }

  addEvent() {
    this.resetAnnouncement(true);
    this.newAnnouncement.eventDate = moment.utc().local().add(15, "days");
    this.newAnnouncementHour = moment.utc().local().format("HH");
    this.newAnnouncementMinutes = "00"; //moment.utc().local().format('mm');
    this.showAddEvent = true;
  }

  saveAnnouncement() {
    this.setEventDate(true);
    this.eventService.annoucementAdd(this.newAnnouncement).subscribe((data) => {
      this.getEvents();
      this.closeAddAnnouncement();
    });
  }

  closeAddAnnouncement() {
    this.showAddEvent = false;
    this.resetAnnouncement(true);
  }

  validateNew(field: string) {
    let isValid = true;
    if (field === "name") {
      if (this.newAnnouncement.name.length === 0) {
        this.newAnnouncement.validation.name.hasError = true;
        this.newAnnouncement.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.newAnnouncement.validation.name.hasError = false;
      }
    }
    return isValid;
  }

  async onImageSelected() {
    const inputNode: any = document.querySelector("#image");
    if (this.validateFileImage(inputNode.files[0])) {
      const contentBuffer: any = await this.readFileAsync(inputNode.files[0]);
      const fileRequest = new AddFileRequest(
        "G",
        inputNode.files[0].name,
        "",
        contentBuffer,
        null
      );
      const addRequest = new AddEventFileRequest();
      addRequest.eventId = this.selectedEvent.id;
      addRequest.fileRequest = fileRequest;
      addRequest.isImage = true;
      this.eventService.eventFileAdd(addRequest).subscribe((data) => {
        if (data) {
          this.selectedEvent.eventFilesSummaryList = !this.selectedEvent
            .eventFilesSummaryList
            ? (this.selectedEvent.eventFilesSummaryList = [])
            : this.selectedEvent.eventFilesSummaryList;
          const fileSummary = data;
          fileSummary.validation = new FileValidation(
            new ValidationError(false),
            false
          );
          this.selectedEvent.eventFilesSummaryList.push(fileSummary);
        }
      });
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().split(",")[1];
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };

      reader.onerror = reject;
    });
  }

  validateFileImage(file: any) {
    if (!file) {
      return false;
    }
    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      this.showDialog("Invalid Image");
      return false;
    }
    // 1MB
    if (file.size > 5048576) {
      this.showDialog("Image size should not exceed 5MB");
      return false;
    }
    return true;
  }

  updateImage(uploadedFileName: string, file: EventFileSummary) {
    if (this.validateImage(uploadedFileName, file)) {
      const request: UpdateFileRequest = {
        id: file.id,
        access: file.access,
        uploadedFileName: file.uploadedFileName,
        storedFileName: file.storedFileName,
        fileType: file.fileType,
      };

      this.fileService.FileUpdate(request).subscribe((data) => {});
    }
  }

  validateImage(uploadedFileName: string, file: File) {
    let isValid = true;
    if (uploadedFileName.length == 0) {
      file.validation.uploadedFileName.errorMessage = "Required";
      file.validation.uploadedFileName.hasError = true;
      isValid = false;
    } else {
      file.validation.uploadedFileName.errorMessage = "";
      file.validation.uploadedFileName.hasError = false;
    }

    return isValid;
  }

  deleteImage(file: EventFileSummary) {
    if (this.disableFileDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const index = this.selectedEvent.eventFilesSummaryList.indexOf(file);
    this.selectedEvent.eventFilesSummaryList.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(
      ConfirmationSnackbarComponent,
      {
        horizontalPosition: "center",
        duration: 5000,
        panelClass: "announcement-snack-bar",
        data: {
          undoDelete: () => {
            dismissedByAction = true;
            this.selectedEvent.eventFilesSummaryList.splice(index, 0, file);
            this.cd.detectChanges();
            const undoSnackBar = this.snackBar.openFromComponent(
              ConfirmationSnackbarComponent,
              {
                horizontalPosition: "center",
                duration: 5000,
                panelClass: "announcement-snack-bar",
                data: {
                  dismissSnackbar: () => {
                    this.snackBar.dismiss();
                    this.disableDelete = false;
                  },
                  displayText: "Undone!",
                  type: "message",
                },
              }
            );
            this.disableDelete = false;
          },
          dismissSnackbar: () => {
            dismissedByAction = true;
            this.eventService.eventFileRemove(file.id).subscribe();
            this.snackBar.dismiss();
            this.disableDelete = false;
          },
          displayText: `${file.uploadedFileName} deleted`,
          type: "delete-kid",
        },
      }
    );
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe((info) => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.eventService.eventFileRemove(file.id).subscribe();
        this.disableDelete = false;
      }
    });
  }

  showDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "confirmation-message-snack-bar",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "message",
      },
    });
  }
}
