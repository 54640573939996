<div class="tenant-details-container">
  <div class="tenant-details-container-row">
    <div class="details-container">
      <div class="details-section">
        <div class="details-section-wrapper">
          <div class="details-wrapper">
            <div class="subheader-text">Reference Info:</div>
          </div>
          <div class="details-wrapper close-top">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Name:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Reference Name'"
                  [(value)]="model.referenceName"
                  [(ngModel)]="model.referenceName"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Email:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Reference Email'"
                  [(value)]="model.referenceEmail"
                  [(ngModel)]="model.referenceEmail"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Phone:</span>
                </div>
              </div>
              <div class="details-info-item small">
                <app-flat-input
                  [placeHolder]="'Reference Phone'"
                  [(value)]="model.referencePhone"
                  [(ngModel)]="model.referencePhone"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container thin">
              <div class="details-info-item checkbox-wrapper left">
                <mat-checkbox
                  *ngIf="model && !model.isVerified"
                  id="isVerified"
                  [(ngModel)]="model.isVerified"
                  [(checked)]="model.isVerified"
                  (change)="onCheckChange($event)"
                >
                </mat-checkbox>
                <mat-checkbox
                  *ngIf="model && model.isVerified"
                  id="isVerified"
                  [(ngModel)]="model.isVerified"
                  [(checked)]="model.isVerified"
                  [class.mat-checkbox-disabled]="false"
                  disabled
                >
                </mat-checkbox>
                <span class="checkbox-text">Verified</span>
              </div>
            </div>
            <div
              class="details-item-container left-margin"
              *ngIf="!model.leaseInfo || model.leaseInfo.statusId < 2"
            >
              <div class="btn-container">
                <button
                  mat-raised-button
                  class="btn-rej"
                  (click)="deleteAlert()"
                >
                  Reject & Delete
                </button>
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="subheader-text">Rent Info:</div>
          </div>
          <div class="details-wrapper close-top">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Room:</span>
                </div>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item" *ngIf="!showEditRoom">
                <app-flat-input
                  [placeHolder]="'Room'"
                  [(value)]="room"
                  [(ngModel)]="room"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
            <div
              class="details-info-item select"
              *ngIf="showEditRoom && roomList"
            >
              <app-flat-select
                [selectOptions]="roomList"
                [(ngModel)]="model.roomInfo.id"
                [(defaultLabel)]="defaultLabel"
                [(value)]="model.roomInfo.id"
                (selectOptionChange)="setRentList($event)"
                ngDefaultControl
              ></app-flat-select>

              <!--  -->
            </div>

            <div class="details-item-container" *ngIf="showEditRoom">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Rent:</span>
                </div>
              </div>
              <div class="details-info-item select" *ngIf="rentList">
                <app-flat-select
                  [selectOptions]="rentList"
                  [(ngModel)]="model.roomRent.id"
                  [(defaultLabel)]="defaultLabel"
                  [(value)]="model.roomRent.id"
                  (selectOptionChange)="setRent($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Parking:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Parking'"
                  [(value)]="hasParking"
                  [(ngModel)]="hasParking"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container left-margin" *ngIf="showResend">
              <div class="btn-container">
                <button
                  mat-raised-button
                  class="btn-resend"
                  (click)="resendEmailAlert()"
                >
                  Resend Lease Signing Email
                </button>
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="subheader-text">Tenant Info:</div>
          </div>
          <div class="details-wrapper close-top">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Age:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Age'"
                  [(value)]="model.memberInfo.age"
                  [(ngModel)]="model.memberInfo.age"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Student:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Student'"
                  [(value)]="isStudent"
                  [(ngModel)]="isStudent"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Residency:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Residency'"
                  [(value)]="residencyStatus"
                  [(ngModel)]="residencyStatus"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
            <div *ngIf="model.files && model.files.length > 0">
              <div class="details-item-container">
                <div class="details-info-item">
                  <div class="item-label-wrapper">
                    <span class="item-label">Documents:</span>
                  </div>
                </div>
                <div class="details-item-container files">
                  <div
                    class="details-info-item select space"
                    *ngFor="let file of model.files; let i = index"
                  >
                    <a
                      [href]="file.url | safe"
                      class="file-link"
                      target="_blank"
                    >
                      <fa-icon [icon]="faFile" class="icon-file"> </fa-icon>
                      Doc-{{ i + 1 }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="details-wrapper" *ngIf="model.guarantor">
            <div class="subheader-text">Guarantor Info:</div>
          </div>
          <div class="details-wrapper close-top" *ngIf="model.guarantor">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Name:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Guarantor Name'"
                  [(value)]="model.guarantor.name"
                  [(ngModel)]="model.guarantor.name"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Email:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Guarantor Email'"
                  [(value)]="model.guarantor.email"
                  [(ngModel)]="model.guarantor.email"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Phone:</span>
                </div>
              </div>
              <div class="details-info-item small">
                <app-flat-input
                  [placeHolder]="'Guarantor Phone'"
                  [(value)]="model.guarantor.phone"
                  [(ngModel)]="model.guarantor.phone"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Address:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Guarantor Address'"
                  [(value)]="model.guarantor.address"
                  [(ngModel)]="model.guarantor.address"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">City:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Guarantor City'"
                  [(value)]="model.guarantor.city"
                  [(ngModel)]="model.guarantor.city"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label"> State:</span>
                </div>
              </div>
              <div class="details-info-item small">
                <app-flat-input
                  [placeHolder]="'Guarantor state'"
                  [(value)]="model.guarantor.state"
                  [(ngModel)]="model.guarantor.state"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
