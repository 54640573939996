
import { APP_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { MaterialModule } from "./material.module";
import { AuthGuard } from "./security/auth.guard";
import { RoleGuard } from "./security/role.guard";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpInterceptorModule } from "./http-interceptor.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularSignaturePadModule  } from "@almothafar/angular-signature-pad";
//import { NgxCurrencyInputMode, NgxCurrencyDirective } from "ngx-currency";
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { IndexComponent } from "./components/index/index.component";
import { HeaderComponent } from "./components/header/header.component";
import { MemberRegistrationComponent } from "./components/member-registration/member-registration.component";
import { MemberKidsComponent } from "./components/member-kids/member-kids.component";
import { ConfirmationSnackbarComponent } from "./components/confirmation-snackbar/confirmation-snackbar.component";
import { CardSkeletonComponent } from "./components/card-skeleton/card-skeleton.component";
import { MembersComponent } from "./components/members/members.component";
import { MemberPaymentComponent } from "./components/member-payment/member-payment.component";
import { TestComponent } from "./components/test/test.component";
import { MemberInfoComponent } from "./components/member-info/member-info.component";
import { ErrorComponent } from "./components/error/error.component";
import { IqamahTimesComponent } from "./components/iqamah-times/iqamah-times.component";
import { AnnouncementCardComponent } from "./components/announcement-card/announcement-card.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RegisterUserComponent } from "./components/register-user/register-user.component";
import { EventsManageComponent } from "./components/events-manage/events-manage.component";
import { FlatInputComponent } from "./components/flat-input/flat-input.component";
import { EventCategoriesComponent } from "./components/event-categories/event-categories.component";
import { FlatSelectComponent } from "./components/flat-select/flat-select.component";
import { AnnouncementManageComponent } from "./components/announcement-manage/announcement-manage.component";
import { AyahManageComponent } from "./components/ayah-manage/ayah-manage.component";
import { ManageAreaComponent } from "./components/manage-area/manage-area.component";
import { UsersComponent } from "./components/users/users.component";
import { UserManageComponent } from "./components/user-manage/user-manage.component";
import { ServicesComponent } from "./components/services/services.component";
import { YouthGroupsComponent } from "./components/youth-groups/youth-groups.component";
import { WelfareComponent } from "./components/welfare/welfare.component";
import { ReserveFacilitiesComponent } from "./components/reserve-facilities/reserve-facilities.component";
import { NikahComponent } from "./components/nikah/nikah.component";
import { NewMuslimsComponent } from "./components/new-muslims/new-muslims.component";
import { JanazahComponent } from "./components/janazah/janazah.component";
import { CounsellingComponent } from "./components/counselling/counselling.component";
import { AboutIsglComponent } from "./components/about-isgl/about-isgl.component";
import { TeamCardComponent } from "./components/team-card/team-card.component";
import { TeamsComponent } from "./components/teams/teams.component";
import { ConstitutionComponent } from "./components/constitution/constitution.component";
import { IlglComponent } from "./components/ilgl/ilgl.component";
import { InterfaithComponent } from "./components/interfaith/interfaith.component";
import { RestrictionsComponent } from "./components/restrictions/restrictions.component";
import { IebComponent } from "./components/ieb/ieb.component";
import { KeyFobComponent } from "./components/key-fob/key-fob.component";
import { MemberProfileComponent } from "./components/member-profile/member-profile.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { IebRegistrationComponent } from "./components/ieb-registration/ieb-registration.component";
import { IebVolunteersComponent } from "./components/ieb-volunteers/ieb-volunteers.component";
import { IebCurriculumComponent } from "./components/ieb-curriculum/ieb-curriculum.component";
import { IebContactsComponent } from "./components/ieb-contacts/ieb-contacts.component";
import { TeamManageComponent } from "./components/team-manage/team-manage.component";
import { VisionComponent } from "./components/vision/vision.component";
import { DonorboxPaymentModalComponent } from "./components/donorbox-payment-modal/donorbox-payment-modal.component";
import { AnnouncementDetailComponent } from "./components/announcement-detail/announcement-detail.component";
import { RoomCardComponent } from "./components/room-card/room-card.component";
import { RoomsComponent } from "./components/rooms/rooms.component";
import { RoomBookComponent } from "./components/room-book/room-book.component";
import { UploadProgressComponent } from "./components/upload-progress/upload-progress.component";
import { UploadFilesComponent } from "./components/upload-files/upload-files.component";
import { LeaseRequestComponent } from "./components/lease-request/lease-request.component";
import { TenantComponent } from "./components/tenant/tenant.component";
import { WaitListAddComponent } from "./components/wait-list-add/wait-list-add.component";
import { TenantProfileComponent } from "./components/tenant-profile/tenant-profile.component";
import { VehicleComponent } from "./components/vehicle/vehicle.component";
import { GuarantorComponent } from "./components/guarantor/guarantor.component";
import { RentInfoComponent } from "./components/rent-info/rent-info.component";
import { UploadModalComponent } from "./components/upload-modal/upload-modal.component";
import { FileListComponent } from "./components/file-list/file-list.component";
import { TenantsManageComponent } from "./components/tenants-manage/tenants-manage.component";
import { TenantsComponent } from "./components/tenants/tenants.component";
import { TenantDetailsComponent } from "./components/tenant-details/tenant-details.component";
import { SignatureModalComponent } from "./components/signature-modal/signature-modal.component";
import { LeaseDetailsComponent } from "./components/lease-details/lease-details.component";
import { TenantPaymentsComponent } from "./components/tenant-payments/tenant-payments.component";
import { RentsManageComponent } from "./components/rents-manage/rents-manage.component";
import { RoomsManageComponent } from "./components/rooms-manage/rooms-manage.component";
import { ParkingManageComponent } from "./components/parking-manage/parking-manage.component";
import { StripePaymentModalComponent } from "./components/stripe-payment-modal/stripe-payment-modal.component";
import { WaitListComponent } from "./components/wait-list/wait-list.component";
import { WaitDetailsComponent } from "./components/wait-details/wait-details.component";
import { ServiceRequestComponent } from "./components/service-request/service-request.component";
import { LeaseExtRequestComponent } from "./components/lease-ext-request/lease-ext-request.component";
import { LeaseAddendumComponent } from "./components/lease-addendum/lease-addendum.component";
import { AddendumMessageComponent } from "./components/addendum-message/addendum-message.component";
import { AddendumManageComponent } from "./components/addendum-manage/addendum-manage.component";
import { VehicleManageComponent } from "./components/vehicle-manage/vehicle-manage.component";
import { TenantSectionComponent } from "./components/tenant-section/tenant-section.component";
import { ParkingAddendumComponent } from "./components/parking-addendum/parking-addendum.component";
import { LeaseUpdateComponent } from "./components/lease-update/lease-update.component";
import { LeaseUpdateTenantComponent } from "./components/lease-update-tenant/lease-update-tenant.component";
import { LateFeeWaiverComponent } from "./components/late-fee-waiver/late-fee-waiver.component";
import { TenantOverrideComponent } from ".//components/tenant-override/tenant-override.component";
import { TenantPaymentAddComponent } from "./components/tenant-payment-add/tenant-payment-add.component";
import { IebParentComponent } from "./components/ieb-parent/ieb-parent.component";
import { IebKidComponent } from './components/ieb-kid/ieb-kid.component';
import { IebKidsComponent } from "./components/ieb-kids/ieb-kids.component";
import { IebInformationComponent } from './components/ieb-information/ieb-information.component';
import { IebRegPaymentComponent } from "./components/ieb-reg-payment/ieb-reg-payment.component";
import { IebRegDetailComponent } from "./components/ieb-reg-detail/ieb-reg-detail.component";
import { IebManageComponent } from './components/ieb-manage/ieb-manage.component';

// import directives
import { DragNDropDirective } from "./directives/drag-n-drop.directive";
import { CurrencyDirective } from "./directives/currency.directive";

//import pipes
import { ComponentsModule } from "./components.module";

// import services
import { GenericDataService } from "./services/generic-data.service";
import { GlobalErrorHandlerService } from "./services/global-error-handler.service";
import { MemberService } from "./services/member.service";
import { HelperService } from "./services/helper.service";
import { MemberPaymentService } from "./services/member-payment.service";
import { SecurityService } from "./services/security.service";
import { EventService } from "./services/event.service";
import { UserService } from "./services/user.service";
import { RoleService } from "./services/role.service";
import { FileService } from "./services/file.service";
import { TeamService } from "./services/team.service";
import { EmailService } from "./services/email.service";
import { RoomService } from "./services/room.service";
import { ParkingSpaceService } from "./services/parking-space.service";
import { TenantService } from "./services/tenant.service";
import { VehicleService } from "./services/vehicle.service";
import { LeaseService } from "./services/lease.service";
import { RentService } from "./services/rent.service";
import { IebRegisterService } from 'src/app/services/ieb-register.service';
import { IebParentService } from "./services/ieb-parent.service";
import { IebKidService } from './services/ieb-kid.service';
import { SchoolManageService } from './services/school-manage.service';
import { IebTeachersComponent } from "./components/ieb-teachers/ieb-teachers.component";
import { IebTeacherModalComponent } from "./components/ieb-teacher-modal/ieb-teacher-modal.component";
import { IebStudentsComponent } from "./components/ieb-students/ieb-students.component";
import { IebStudentComponent } from "./components/ieb-student/ieb-student.component";
import { IebStudentDetailsComponent } from "./components/ieb-student-details/ieb-student-details.component";
import { IebSemestersComponent } from "./components/ieb-semesters/ieb-semesters.component";
import { IebReducedFeeKidModalComponent } from "./components/ieb-reduced-fee-kid-modal/ieb-reduced-fee-kid-modal.component";
import { IebReducedFeeKidsComponent } from "./components/ieb-reduced-fee-kids/ieb-reduced-fee-kids.component";
import { ConfirmationService } from "./services/confirmation.service";
//import { IConfig, NgxMaskDirective } from "ngx-mask";
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { LoadService } from "./services/load.service";
import { IebNewRegComponent } from "./components/ieb-new-reg/ieb-new-reg.component";
import { IebAdminRegPaymentComponent } from "./components/ieb-admin-reg-payment/ieb-admin-reg-payment.component";
import { CommonModule } from "@angular/common";


//export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


// export const customCurrencyMaskConfig = {
//   align: "left",
//   allowNegative: false,
//   allowZero: true,
//   decimal: ".",
//   precision: 2,
//   prefix: "$ ",
//   suffix: "",
//   thousands: ",",
//   nullable: true,
//   min: null,
//   max: null,
//   inputMode: NgxCurrencyInputMode.Natural,
// };

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    InfiniteScrollDirective,
    NgxSpinnerModule,
    HttpInterceptorModule,
    AngularSignaturePadModule,
    //NgxCurrencyDirective,
    //NgxMaskDirective,
    ComponentsModule
   ],
  declarations: [
        AppComponent,
        LoginComponent,
        IndexComponent,
        HeaderComponent,
        MemberRegistrationComponent,
        MemberKidsComponent,
        ConfirmationSnackbarComponent,
        CardSkeletonComponent,
        MembersComponent,
        MemberPaymentComponent,
        CardSkeletonComponent,
        TestComponent,
        MemberInfoComponent,
        ErrorComponent,
        IqamahTimesComponent,
        AnnouncementCardComponent,
        FooterComponent,
        RegisterUserComponent,
        EventsManageComponent,
        FlatInputComponent,
        EventCategoriesComponent,
        FlatSelectComponent,
        AnnouncementManageComponent,
        AyahManageComponent,
        ManageAreaComponent,
        UsersComponent,
        UserManageComponent,
        ServicesComponent,
        NewMuslimsComponent,
        NikahComponent,
        WelfareComponent,
        YouthGroupsComponent,
        ReserveFacilitiesComponent,
        JanazahComponent,
        CounsellingComponent,
        AboutIsglComponent,
        TeamsComponent,
        TeamCardComponent,
        ConstitutionComponent,
        InterfaithComponent,
        IlglComponent,
        RestrictionsComponent,
        IebComponent,
        KeyFobComponent,
        MemberProfileComponent,
        ConfirmDialogComponent,
        IebRegistrationComponent,
        IebCurriculumComponent,
        IebVolunteersComponent,
        IebContactsComponent,
        TeamManageComponent,
        VisionComponent,
        DonorboxPaymentModalComponent,
        AnnouncementDetailComponent,
        RoomsComponent,
        RoomCardComponent,
        RoomBookComponent,
        UploadProgressComponent,
        UploadFilesComponent,
        LeaseRequestComponent,
        TenantComponent,
        WaitListAddComponent,
        TenantProfileComponent,
        GuarantorComponent,
        VehicleComponent,
        RentInfoComponent,
        UploadModalComponent,
        FileListComponent,
        TenantsManageComponent,
        TenantsComponent,
        TenantDetailsComponent,
        SignatureModalComponent,
        LeaseDetailsComponent,
        TenantPaymentsComponent,
        RentsManageComponent,
        RoomsManageComponent,
        ParkingManageComponent,
        StripePaymentModalComponent,
        WaitListComponent,
        WaitDetailsComponent,
        ServiceRequestComponent,
        LeaseExtRequestComponent,
        LeaseAddendumComponent,
        AddendumMessageComponent,
        AddendumManageComponent,
        VehicleManageComponent,
        TenantSectionComponent,
        ParkingAddendumComponent,
        LeaseUpdateComponent,
        LeaseUpdateTenantComponent,
        LateFeeWaiverComponent,
        TenantOverrideComponent,
        TenantPaymentAddComponent,
        IebParentComponent,
        IebKidComponent,
        IebKidsComponent,
        IebInformationComponent,
        IebRegPaymentComponent,
        IebRegDetailComponent,
        IebManageComponent,
        IebTeachersComponent,
        IebTeacherModalComponent,
        IebStudentsComponent,
        IebStudentComponent,
        IebStudentDetailsComponent,
        IebSemestersComponent,
        IebReducedFeeKidsComponent,
        IebReducedFeeKidModalComponent,
        IebNewRegComponent,
        IebAdminRegPaymentComponent,

    ],

    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_ID,
            useValue: 'serverApp',
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        AuthGuard,
        RoleGuard,
        GenericDataService,
        MemberService,
        HelperService,
        GlobalErrorHandlerService,
        MemberPaymentService,
        GlobalErrorHandlerService,
        SecurityService,
        EventService,
        UserService,
        RoleService,
        FileService,
        TeamService,
        EmailService,
        RoomService,
        TenantService,
        ParkingSpaceService,
        VehicleService,
        LeaseService,
        RentService,
        IebRegisterService,
        IebParentService,
        IebKidService,
        SchoolManageService,
        ConfirmationService,
        LoadService,
        MatSnackBarModule,
        DragNDropDirective,
        CurrencyDirective,
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
