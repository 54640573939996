import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-ieb-curriculum',
  templateUrl: './ieb-curriculum.component.html',
  styleUrls: ['./ieb-curriculum.component.scss']
})
export class IebCurriculumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
