<p>
  <iframe
    width="100%"
    height="300"
    [src]="url | safe"
    frameborder="0"
    allowtransparency="true"
    type="text/html"
  ></iframe>
</p>
