import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-reserve-facilities',
  templateUrl: './reserve-facilities.component.html',
  styleUrls: ['./reserve-facilities.component.scss']
})
export class ReserveFacilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
