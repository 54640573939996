<div class="ieb-curriculum-container">
  <div class="ieb-section">
    <div class="header-section">
      <div class="header">Curriculum</div>
    </div>

    <div class="ieb-info-section">
      <div class="ieb-info">

        <div class="ieb-info-text">
          Curriculum: ISGL Sunday Islamic school offers Quran, Islamic Studies, Arabic and Tajweed:
        </div>
        <div class="ieb-info-text normal">
          <span class="bold-text">Islamic Studies:</span> At an age-appropriate
          level, the curriculum covers the following topics
        </div>
        <ul class="ieb-list left-padding">
          <li class="list-item">Quran (translations and explanations).</li>
          <li class="list-item">
            Hadeeth and Sunnah (sayings and practices of the Prophet Muhammad
            [pbuh]).
          </li>
          <li class="list-item">Seerah (life of Prophet Muhammad [pbuh]).</li>
          <li class="list-item">
            Stories of other Prophets and companions of the Prophet Muhammad
            (pbuh).
          </li>
          <li class="list-item">Aqeeda (belief).</li>
          <li class="list-item">Fiqh (jurisprudence).</li>
          <li class="list-item">Ibadah (worship).</li>
        </ul>
        <div class="ieb-info-text normal">
          <span class="bold-text">Arabic and Tajweed:</span>
        </div>
        <ul class="ieb-list left-padding">
          <li class="list-item">
            The goal of the Arabic curriculum is to enable the students to learn to read the Quran with Tajweed rules.
          </li>
          <li class="list-item">
            Students learn to read and write Arabic alphabets, words, and sentences.
          </li>
          <li class="list-item">
            The Madina Arabic reader books are used for grammar lessons.
          </li>
        </ul>
        <div class="ieb-info-text normal">
          <span class="bold-text">Quran:</span>
        </div>
        <ul class="ieb-list left-padding">
          <li class="list-item">
            Students are assigned surahs to memorize ( about 3-5 ayats per week).
            The memorization will be given as homework to be supervised by parents.
          </li>
          <li class="list-item">
            The meanings and tafseer of the ayats assigned will be discussed in class.
          </li>
          <li class="list-item">
            Goal is to memorize Juzz Amma (all suras in the 30th part of the
            Qur'an) by Level 5.
          </li>
        </ul>
        <div class="ieb-info-text normal">
          Please remind your child to bring their own pencils, erasers and water bottles to school.
        </div>
        <div class="ieb-info-text italics">
          <i class="material-icons info-icon">info</i>
          Provided textbooks are rentals and should be returned to school by the end of the year.
          Students will also receive workbooks to keep.
        </div>
        <div class="ieb-info-text normal">
          <span class="bold-text">Safety Guidelines:</span>
        </div>
        <ul class="ieb-list left-padding">
          <li class="list-item">
            For the safety of your child and the community, we request everyone to wear masks indoors.
          </li>
          <li class="list-item">
            Do not send to school if your child exhibits cold-like symptoms, even a little bit.
            Our staff will ask them to leave if we see any symptoms.
          </li>
          <li class="list-item">
            If your family recently traveled or have been in contact with those with COVID-19,
            please stay at home and wait for 5 days before taking a PCR or antigen test to obtain
            an accurate result before returning to class.
          </li>
          <li class="list-item">
            Please inform the teachers immediately if your child or any family members have tested positive.
          </li>

        </ul>
      </div>
    </div>
  </div>
</div>
