<div class="page-container">
  <div class="page-filter-container">
    <div class="page-info-container">
      <div class="total-count-container">
        <div class="total-count-text" *ngIf="totalRowsText">
          {{ totalRowsText }}
        </div>
      </div>
    </div>
  </div>
  <div class="empty-row" *ngIf="showEmpty">
    <div class="empty-message">No Wait Requests Found!</div>
  </div>
  <div
    class="page-table-container"
    *ngIf="!showEmpty && dataSource; else loading"
  >
    <div>
      <table mat-table [dataSource]="dataSource" *ngIf="loaded | async">
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="verified">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Verified
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.referenceChecked }}
          </td>
        </ng-container>
        <ng-container matColumnDef="room">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Room/Type
          </th>
          <td mat-cell *matCellDef="let item" class="hide-column">
            {{ item.room }}
          </td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Start
          </th>
          <td mat-cell *matCellDef="let item" class="hide-column">
            {{ item.startDate | customDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="requestDate">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Request Date
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.requestDate | customDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="parking">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Parking
          </th>
          <td mat-cell *matCellDef="let item" class="hide-column">
            {{ item.parking }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="showWaitSummary(row.id, row.type)"
          [matTooltip]="
            row.type === 'T' ? 'Tenant Request' : 'Wait List Request'
          "
          [ngClass]="{ custom: row.type === 'W' }"
        ></tr>
      </table>
    </div>
    <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div>
  </div>
  <div class="summary-wrapper">
    <div
      class="page-summary-container"
      [ngClass]="{
        'summary-panel-slide-in': showSummary,
        'summary-panel-slide-out': hideSummary
      }"
    >
      <div class="page-summary-header">
        <div class="page-summary-header-name">
          {{ summaryName }}
          <div class="summary-name">
            {{ summaryEmail }}
          </div>
        </div>
      </div>
      <div class="close-summary-wrapper">
        <a class="close-summary-button" (click)="hideWaitSummary()">
          <i class="material-icons expand">expand_more</i>
        </a>
        <span class="close-info"> click or escape to close </span>
      </div>
      <div *ngIf="waitDetails">
        <app-wait-details
          [model]="waitDetails"
          (notifyParent)="updateList($event)"
        ></app-wait-details>
      </div>
      <div *ngIf="tenantDetails">
        <app-tenant-details
          [model]="tenantDetails"
          (notifyVerification)="setVerification($event)"
          (notifyUpdate)="updateTenant($event)"
          (notifyDelete)="deleteTenant($event)"
        ></app-tenant-details>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <app-card-skeleton></app-card-skeleton>
  </ng-template>
</div>
