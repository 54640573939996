import { TenantOverride } from "./../../models/tenant-override";
import { TenantPaymentSummary } from "src/app/models/tenant-payment-summary";
import { LeaseInfo } from "./../../models/lease-info";
import { HelperService } from "src/app/services/helper.service";
import { TenantDisplayValues } from "./../../models/tenant-display-values";
import { TenantInfo } from "./../../models/tenant-info";
import { TenantService } from "./../../services/tenant.service";
import { Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { TenantFilters } from "src/app/models/tenant-filters";
import { ListRequest } from "src/app/models/list-request";
import {
  faSearch,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { TenantListResponse } from "src/app/models/tenant-list-response";
import * as _moment from "moment";
import { LeaseService } from "src/app/services/lease.service";
import config from 'src/assets/config.json';
import { File } from "src/app/models/file";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
const moment = _moment;
@Component({
  standalone: false,
  selector: "app-tenants",
  templateUrl: "./tenants.component.html",
  styleUrls: ["./tenants.component.scss"],
})
export class TenantsComponent implements OnInit, OnChanges {
  @Input() hideAdd: boolean;
  displayedColumns: string[] = [
    "name",
    "room",
    "start",
    "end",
    "parking",
    "leaseStatus",
    "rentPaid",
    "hasPendingAddendums",
  ];
  tenantList: TenantInfo[];
  selectedTenant: TenantInfo;
  listResponse: TenantListResponse;
  dataSource :  TenantDisplayValues[];
  tableData = new MatTableDataSource<TenantDisplayValues>();
  filters: TenantFilters;
  listRequest: ListRequest;
  itemsPerPage = 15;
  pageNumber: number;
  pageNumberWithFilter: number;
  totalRows = 0;
  tenantsLoaded: Promise<boolean>;
  notLoading = true;
  moreToLoad = true;
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  query: string;
  showSummary = false;
  hideSummary = false;
  summaryId: number;
  summaryName: string;
  summaryTenantName: string;
  summaryTenant: TenantInfo;
  paymentSummary: TenantPaymentSummary;
  selectedMemberId: number;
  tenantDetails: TenantInfo;
  tabs: string[] = ["Lease", "Payments", "Late Fee Waiver", "Lease Update", "Payment Override", "Add Payment"];
  showEmpty: boolean;
  existingCoordSig: File;
  expiredOnly = false;
  countText: string;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.tableData.sort = sort;
  }

  constructor(
    private tenantService: TenantService,
    private helperService: HelperService,
    private leaseService: LeaseService,
    private router: Router
  ) {}

  ngOnInit() {
    this.setListRequest();
    this.getTenants();
    this.getExistingCoordSig();
  }

  ngOnChanges() {
    if (this.hideAdd) {
      this.showSummary = false;
    }
  }

  setListRequest() {
    if (!this.filters) {
      this.filters = {
        expiredOnly: this.expiredOnly,
        query: null,
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber,
    };
  }

  getTenants() {
    this.tenantsLoaded = Promise.resolve(false);
    this.tenantService.tenantList(this.listRequest).subscribe((data) => {
      if (data) {
        this.listResponse = Object.assign(data);
        this.tenantList = Object.assign([], this.listResponse.items);
        if (this.tenantList.length === 0) {
          this.showEmpty = true;
        }
      }
      this.totalRows = this.listResponse.totalCount;
      this.countText = this.expiredOnly ? `${this.listResponse.totalCount} expired tenants` : `${this.listResponse.totalCount} active tenants`;
      //this.pageNumber = this.listResponse.request.pageNumber;
      this.setDataSource(this.tenantList);
      this.tenantsLoaded = Promise.resolve(true);
    });
  }

  setDataSource(tenants: TenantInfo[]) {
    let dataSource: TenantDisplayValues[] = [];
    dataSource = this.tenantList.map((value) => ({
      id: value.id,
      name: `${value.memberInfo.firstName} ${value.memberInfo.lastName}`,
      email: value.memberInfo.email,
      referenceChecked: !value.isVerified ? "No" : "Yes",
      room: value.roomInfo
        ? `${value.roomInfo.name}/${value.roomInfo.occupancy}`
        : "-",
      startDate: value.leaseInfo
        ? moment(value.leaseInfo.startDate).clone().format("MM-DD-YYYY")
        : value.tentativeStartDate
        ? moment(value.tentativeStartDate).clone().format("MM-DD-YYYY")
        : "-",
      endDate: value.leaseInfo
        ? moment(value.leaseInfo.endDate).clone().format("MM-DD-YYYY")
        : "-",
      parkingSpace: value.parkingSpaceRent ? "Yes" : "No",
      leaseStatus: !value.leaseInfo
        ? "N/A"
        : value.leaseInfo && value.leaseInfo.status
        ? this.helperService.mapLeaseStatus(value.leaseInfo.status)
        : "N/A",
      leaseStatusId: !value.leaseInfo ? 0 : value.leaseInfo.statusId,
      rowClass: !value.leaseInfo
        ? ""
        : this.setRowClass(value.leaseInfo.statusId),
      hasPendingAddendums: !value.hasPendingAddendums
        ? false
        : value.hasPendingAddendums,
      monthlyRentCollected: value.monthlyRentCollected,
      rentCellClass: this.setRentCellClass(value.monthlyRentCollected),
    }));
    dataSource =
      !this.filters.query && this.dataSource && this.dataSource.length > 0
        ? this.dataSource.concat(dataSource)
        : dataSource;
    this.tableData.data = dataSource;
  }

  setRowClass(statusId: number) {
    let rowClass = "";
    switch (statusId) {
      case 3:
        rowClass = "buzz-out";
        break;
      case 2:
        rowClass = "custom1";
        break;
      case 5:
        rowClass = "custom2";
        break;
    }
    return rowClass;
  }

  setRentCellClass(text: string) {
    let cellClass = "";
    if (text.includes("Not Paid")) {
      cellClass = "not-paid";
    } else if (text.includes("Partial")) {
      cellClass = "partial";
    } else if (text.includes("Paid")) {
      cellClass = "paid";
    }

    return cellClass;
  }

  tenantSearch(event: any) {
    if (event.keyCode) {
      if (!this.query || event.keyCode === 27) {
        this.clearSearch();
        return;
      }
      if (event.keyCode !== 13) {
        return false;
      }
    }
    this.filters.query = this.query.trim();
    this.listRequest.pageNumber = 1;
    if (!this.filters.query) {
      this.dataSource = [];
    }
    this.getTenants();
  }

  clearSearch() {
    this.filters.query = null;
    this.filters.expiredOnly = this.expiredOnly;
    this.query = null;
    this.listRequest.pageNumber = 1;
    this.dataSource = [];
    this.showEmpty = false;
    this.getTenants();
  }

  applyFilter() {
    this.filters.expiredOnly = this.expiredOnly;
    this.listRequest.pageNumber = 1;
    this.getTenants();
  }

  showTenantSummary(id: number) {
    this.summaryId = id;
    this.selectedTenant = this.tenantList.find((x) => x.id === id);
    this.selectedMemberId = this.selectedTenant.memberInfo.id;
    this.getTenant(false);
    this.getSelectedTenantPayments();
    this.summaryName = `${this.selectedTenant.memberInfo.firstName} ${this.selectedTenant.memberInfo.lastName}`;
    this.summaryTenantName = this.selectedTenant.memberInfo.email;
    var row = this.tableData.data.find((x) => x.id === this.summaryId);
    if (row && row.rowClass === "buzz-out") {
      row.rowClass = "custom1";
    }
    //this.getSummaryUser();
    this.showSummary = true;
    this.hideSummary = false;
  }

  hideUserSummary() {
    this.showSummary = false;
    this.hideSummary = true;
    this.summaryId = null;
    this.summaryTenantName = null;
    this.summaryName = null;
  }

  setVerification(verified: boolean) {
    if (verified) {
      var row = this.dataSource.find((x) => x.id === this.summaryId);
      if (row) {
        row.referenceChecked = "Yes";
      }
    }
  }

  setAutoRenewal(renewal: boolean) {
    this.tenantDetails.leaseInfo.autoRenewal = renewal;
  }

  setLeaseSigned(status: string) {
    this.getLease();
  }

  leaseUpdate(hasUpdated: boolean) {
    if (hasUpdated) {
      this.getLease();
    }
  }

  getLease() {
    if (!this.tenantDetails.leaseInfo.id) {
      return;
    }
    this.leaseService
      .leaseGet(this.tenantDetails.leaseInfo.id)
      .subscribe((data) => {
        if (data) {
          this.tenantDetails.leaseInfo = Object.assign({}, data);
          var row = this.dataSource.find((x) => x.id === this.summaryId);
          row.leaseStatus = this.helperService.mapLeaseStatus(
            this.tenantDetails.leaseInfo.status
          );
          row.rowClass = this.setRowClass(
            this.tenantDetails.leaseInfo.statusId
          );
        }
      });
  }

  selectedTabChange(event: any) {
    const tabName = this.tabs[event];
  }

  getSelectedTenantPayments() {
    this.tenantService
      .tennatPaymentSummary(this.selectedTenant.id)
      .subscribe((data) => {
        this.paymentSummary = Object.assign({}, data);
      });
  }

  getTenant(verifyAddendumsClicked: boolean) {
    this.tenantService
      .tenantGetByMember(this.selectedMemberId)
      .subscribe((data) => {
        if (data) {
          this.tenantDetails = Object.assign(data);
          if (this.tenantDetails.files && this.tenantDetails.files.length > 0) {
            this.tenantDetails.files.map((file) => {
              if (file) {
                file.url = `${config.fileBaseUrl}${file.storedFileName}`;
              }
            });
            if (verifyAddendumsClicked) {
              this.tenantService.verifyAddendumsClicked(this.tenantDetails);
              this.router.navigate(["addendumsManage"]);
            }
          }
        }
      });
  }

  updateTenant(tenantUpdated: TenantInfo) {
    let index = this.tenantList.findIndex((t) => t.id == tenantUpdated.id);
    this.tenantList.splice(index, 1);
    let item = this.dataSource.find((d) => d.id == tenantUpdated.id);
    item.room = tenantUpdated.roomInfo
      ? `${tenantUpdated.roomInfo.name}/${tenantUpdated.roomInfo.occupancy}`
      : "-";
  }

  overrideUpdate(tenantUpdated: TenantInfo) {
    let index = this.tenantList.findIndex((t) => t.id == tenantUpdated.id);
    this.tenantList.splice(index, 1);
    let item = this.dataSource.find((d) => d.id == tenantUpdated.id);
    item.startDate = tenantUpdated.tentativeStartDate;
    item.endDate = tenantUpdated.leaseInfo
      ? moment(tenantUpdated.leaseInfo.endDate).clone().format("MM-DD-YYYY")
      : "-";
    item.parkingSpace = tenantUpdated.parkingSpaceRent ? "Yes" : "No";
    this.tenantDetails = null;
    this.tenantDetails = Object.assign(tenantUpdated);
  }

  getExistingCoordSig() {
    this.leaseService.coordSignatureGet().subscribe((data) => {
      if (data) {
        this.existingCoordSig = Object.assign(data);
      }
    });
  }
  checkAddendums($event, tenantId: number) {
    $event.stopPropagation();
    this.selectedTenant = this.tenantList.find((x) => x.id === tenantId);
    this.selectedMemberId = this.selectedTenant.memberInfo.id;
    this.getTenant(true);
  }

  tenantCustomPaymentAdded(tenant: TenantInfo) {
    this.tenantDetails = tenant;
  }
}
