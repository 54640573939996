<div class="manage-component-container">
  <div class="component-container">
    <div class="component-wrapper">
      <div
        class="select-component-types"
        (click)="showTypes = true"
        [ngClass]="{ hide: showTypes }"
      >
        <div class="icon-wrapper">
          <!-- <a href="#eventtypespanel" class="anchor-link"> -->
          <i class="material-icons select-component-icon">filter_list</i>
          <!-- </a> -->
        </div>
      </div>
      <div
        id="eventtypespanel"
        class="component-types"
        [ngClass]="{
          'show-component-types': showTypes,
          'hide-component-types': !showTypes
        }"
      >
        <div class="type-header-row" [ngClass]="{ show: showTypes }">
          <div class="back-icon-wrapper" (click)="showTypes = false">
            <i class="material-icons back-icon">arrow_back</i>
          </div>
        </div>

        <div
          class="component-type-row"
          (click)="eventTypeClick('category'); showTypes = false"
          [ngClass]="{ 'row-active': showCategories }"
        >
          <div class="component-name" [ngClass]="{ active: showCategories }">
            Category
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="eventTypeClick('announcement'); showTypes = false"
          [ngClass]="{ 'row-active': showAnnouncements }"
        >
          <div class="component-name" [ngClass]="{ active: showAnnouncements }">
            Annoucements
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="eventTypeClick('ayah'); showTypes = false"
          [ngClass]="{ 'row-active': showAyahs }"
        >
          <div class="component-name" [ngClass]="{ active: showAyahs }">
            Quran-Ayah
          </div>
        </div>
      </div>
      <div class="component-content" [ngClass]="{ hide: showTypes }">
        <div *ngIf="showCategories">
          <app-event-categories [hideAdd]="showTypes"></app-event-categories>
        </div>
        <div *ngIf="showAnnouncements">
          <app-announcement-manage
            [hideAdd]="showTypes"
          ></app-announcement-manage>
        </div>
        <div *ngIf="showAyahs">
          <app-ayah-manage [hideAdd]="showTypes"></app-ayah-manage>
        </div>
      </div>
    </div>
  </div>
</div>
