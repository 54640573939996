import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor() { }
  url = 'https://masjidal.com/widget/simple?masjid_id=XEdozpA7';
  ngOnInit() {

  }

}
