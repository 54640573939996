<app-header></app-header>
<div class="services-container">
  <!-- <div class="tab-header"></div> -->
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center" animationDuration="0ms">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="link.active"
      (click)="toggleTab(link.emptyResultType)"
    >
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <!-- <router-outlet></router-outlet> -->
  </mat-tab-nav-panel>
  <div *ngIf="emptyResultType === 'newmuslims'" class="empty-results-container">
    <app-new-muslims></app-new-muslims>
  </div>
  <div *ngIf="emptyResultType === 'nikah'" class="empty-results-container">
    <app-nikah></app-nikah>
  </div>
  <div *ngIf="emptyResultType === 'janazah'" class="empty-results-container">
    <app-janazah></app-janazah>
  </div>
  <div
    *ngIf="emptyResultType === 'counselling'"
    class="empty-results-container"
  >
    <app-counselling></app-counselling>
  </div>
  <div *ngIf="emptyResultType === 'welfare'" class="empty-results-container">
    <app-welfare></app-welfare>
  </div>
  <div
    *ngIf="emptyResultType === 'youthgroups'"
    class="empty-results-container"
  >
    <app-youth-groups></app-youth-groups>
  </div>
  <div *ngIf="emptyResultType === 'reserve'" class="empty-results-container">
    <app-reserve-facilities></app-reserve-facilities>
  </div>
  <div *ngIf="emptyResultType === 'keyfob'" class="empty-results-container">
    <app-key-fob></app-key-fob>
  </div>
</div>
<app-footer></app-footer>
