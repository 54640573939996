<app-header></app-header>
<div class="waiting-list-container">
  <div class="waiting-list-section">
    <div class="waiting-list-header-section">
      <div class="header">Add Me To Tenant Wait List</div>
    </div>
    <div class="page-row">
      <div class="row-content">
        <div>
          <app-rent-info
            [showWaitListInfo]="'true'"
            [showRentBreakup]="showRentBreakup"
          ></app-rent-info>
        </div>
        <div class="item-container left-align" *ngIf="waitInfo">
          <div class="item-label-wrapper">
            <span class="item-label">Tentative Start Date:</span>
          </div>
          <div class="input-date date-picker">
            <input
              [matDatepicker]="datePicker"
              class="input-date"
              [(ngModel)]="waitInfo.tentativeStartDate"
              readonly
              (dateInput)="datePicker.close()"
              (dateChange)="datePicker.close()"
              (focus)="datePicker.open()"
              readonly
            />
            <mat-datepicker-toggle
              class="item-date-picker"
              matSuffix
              [for]="datePicker"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </div>
          <div class="item-label-wrapper thin left-margin">
            <span class="item-label">Room Type:</span>
          </div>
          <div class="item-radio-list-wrapper" *ngIf="selectedRoomType">
            <mat-list role="list">
              <mat-list-item role="listitem" *ngFor="let type of roomTypes">
                <mat-radio-button
                  [checked]="selectedRoomType.value === type.value"
                  [value]="type.value"
                  (change)="onRoomTypeSelection($event)"
                >
                  {{ type.label }}
                </mat-radio-button>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
        <app-tenant-profile
          [model]="profile"
          (notifyParent)="getProfile($event)"
          *ngIf="profile"
        ></app-tenant-profile>
        <div class="item-container">
          <div class="item-checkbox-wrapper">
            <div class="item-checkbox">
              <mat-checkbox
                [(ngModel)]="hasGuarantor"
                [(checked)]="hasGuarantor"
                (change)="onGuarantorChange($event)"
              >
              </mat-checkbox>
            </div>
            <div class="checkbox-text question">
              This information is required if you have a Guarantor?
            </div>
          </div>
        </div>
        <div *ngIf="hasGuarantor">
          <app-guarantor
            [model]="guarantor"
            [referenceInfo]="profile"
            (notifyParent)="getGuarantor($event)"
          ></app-guarantor>
        </div>
        <div
          class="item-container"
          *ngIf="uploadedFiles && uploadedFiles.length > 0"
        >
          <div class="item-label-wrapper">
            <span class="item-label">Uploaded Files:</span>
          </div>
          <div class="item-input-wrapper">
            <app-file-list
              [uploadedFiles]="uploadedFiles"
              [showDelete]="showfileListDelete"
              [showTitle]="showfileListTitle"
            ></app-file-list>
          </div>
        </div>
        <div class="item-container">
          <div class="btn-container pad-bottom">
            <span *ngIf="displayError" class="error-icon-container">
              <span class="error-icon"><mat-icon>error_outline</mat-icon></span>
              <span class="error-text">Required!</span>
            </span>

            <button
              mat-raised-button
              class="upload-btn"
              (click)="openUploadFileDaialog()"
            >
              <mat-icon class="icon"> upload </mat-icon>
              Upload File(s)
            </button>
          </div>
          <div class="item-note-wrapper">
            <div class="item-note">
              <i class="material-icons info-icon">info</i>
              <div class="note-text">
                Valid passport or US driver license or Purdue Id or admission
                letter is required.
              </div>
            </div>
          </div>
        </div>
        <div class="item-container" *ngIf="profile">
          <div class="btn-container">
            <button
              mat-raised-button
              class="save-btn"
              [disabled]="hasError"
              (click)="upsertWait()"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
