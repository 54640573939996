import { NgModule } from "@angular/core";
import { SafePipe } from "./pipes/safe.pipe";
import { PadLeftZeroPipe } from "./pipes/pad-left-zero.pipe";
import { CustomDatePipe } from "./pipes/dates.pipe";

@NgModule({
  imports: [SafePipe, PadLeftZeroPipe, CustomDatePipe],
  exports: [ SafePipe, PadLeftZeroPipe, CustomDatePipe ]
})
export class ComponentsModule { }
