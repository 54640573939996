<div class="dialog-container" *ngIf="model">
  <header class="header-row">
    <div class="logo-container">
      <img class="isgl-logo" src="../../../assets/images/isgl-logo.jpg" />
    </div>
    <div class="close-btn" (click)="closeDialog()">
      <a><fa-icon [icon]="faTimes"></fa-icon></a>
    </div>
  </header>
  <div class="title-row">
    <div class="dialog-title">
      <span class="dialog-icon">
        <fa-icon [icon]="faChalkboardTeacher"></fa-icon>
      </span>
      {{headerTitle}}
    </div>
  </div>
  <div class="search-container" *ngIf="!model.id">
    <span class="input-icon-right element-search">
      <i class="material-icons">search</i>
      <input
        type="text"
        #memberSearch
        matInput
        pInputText
        [matAutocomplete]="auto"
        class="input-left-rounded input-white"
        placeholder="Search members by name or email "
        (keyup)="searchChanged(memberSearch.value)"
        [(ngModel)]="name"

      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selectedMember($event.option.value)">
        <mat-option *ngFor="let item of searchSource$ | async" [value]="item" [disabled]="item.disabled">
          {{ item.firstName }} {{item.lastName}}
      </mat-option>
    </mat-autocomplete>
    </span>
  </div>

  <form [formGroup]="teacherForm" (ngSubmit)="upsertTeacher()">
    <div class="page-form">
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">First Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" [(ngModel)] = "model.firstName" formControlName= "firstName"
            [readonly]="isReadOnly" inputText/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="teacherForm.controls['firstName'].errors && teacherForm.controls['firstName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Last Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" [(ngModel)] = "model.lastName"  formControlName= "lastName"
            [readonly]="isReadOnly" inputText/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="teacherForm.controls['lastName'].errors && teacherForm.controls['lastName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
        </div>
      </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Email</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" [(ngModel)] = "model.email" formControlName= "email"
            [readonly]="isReadOnly" inputText/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="teacherForm.controls['email'].errors && teacherForm.controls['email'].errors['required']">Required</span>
                  <span *ngIf="teacherForm.controls['email'].errors && teacherForm.controls['email'].errors['email']">Invalid email</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Valid Till</div>
          <div class="input-wrapper flex">
            <input
            [matDatepicker]="datePicker"
            class="input-rounded"
            [(ngModel)]="validTill"
            formControlName= "validTill"
            (dateInput)="datePicker.close()"
            (dateChange)="setDisable();datePicker.close()"
            (focus)="datePicker.open()"
            [min]="minDate"
            readonly
          />
          <mat-datepicker-toggle
            class="date-picker"
            matSuffix
            [for]="datePicker"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker [startAt]="minDate"></mat-datepicker>
          </div>
          <div class="validation-container">
            <div class="validation-icon">
              <i class="material-icons error-icon">error</i>
            </div>
            <div class="validation-content">
              <span class="validation-message validation-left">
                <span *ngIf="teacherForm.controls['validTill'].errors && teacherForm.controls['validTill'].errors['required']">Required</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <div class="form-btn">
          <button
          mat-flat-button
          class="btn btn-lacoste btn-save"
          type="submit"
          [disabled]="!teacherForm.valid || disableBtn"
        >
       {{btnText}}
        </button>
        </div>


      </div>


    </div>
  </form>

</div>

