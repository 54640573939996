<div class="member-kids-container">
  <div *ngIf="memberKids">
    <div class="kids-container-row">
      <div class="kids-container">
        <div class="info-help">
          <i class="material-icons info-help-icon">info</i>
          Enter kids information to update.
        </div>
        <div *ngFor="let item of memberKids" class="kid-section">
          <div class="kid-info-container">
            <div class="kid-info-item">
              <app-flat-input
                [placeHolder]="'First Last Name'"
                [(value)]="item.kid.name"
                [(ngModel)]="item.kid.name"
                [hasError]="item.kid.validation.name.hasError"
                [errorMessage]="item.kid.validation.firstName.errorMessage"
                [buttonType]="'delete'"
                (actionTriggered)="deleteKid(item)"
                (keydown.enter)="$event.target.blur()"
                (keyup)="setUpdateValue(item.id, 'name', item.kid.name)"
                (blur)="setUpdateValue(item.id, 'name', item.kid.name)"
              >
              </app-flat-input>
            </div>
            <div class="kid-select-wrapper">
              <div class="kid-type">
                <div class="kid-select-item">
                  <div class="select-wrapper">
                    <app-flat-select
                      [selectOptions]="genderList"
                      [(ngModel)]="item.kid.gender"
                      [(defaultLabel)]="defaultLabel"
                      [(value)]="item.kid.gender"
                      [hasError]="item.kid.validation.gender.hasError"
                      [errorMessage]="item.kid.validation.gender.errorMessage"
                      (selectOptionChange)="
                        setUpdateValue(item.id, 'gender', $event)
                      "
                      ngDefaultControl
                    ></app-flat-select>
                  </div>
                </div>
              </div>
              <div class="kid-type">
                <div class="kid-select-item">
                  <div class="select-wrapper">
                    <app-flat-select
                      [selectOptions]="relationshipList"
                      [(ngModel)]="item.relationship"
                      [(defaultLabel)]="defaultLabel"
                      [(value)]="item.relationship"
                      [hasError]="item.validation.relationship.hasError"
                      [errorMessage]="item.validation.relationship.errorMessage"
                      (selectOptionChange)="
                        setUpdateValue(item.id, 'relationship', $event)
                      "
                      ngDefaultControl
                    ></app-flat-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="kid-select-wrapper">
              <div class="kid-type">
                <div class="kid-select-item">
                  <div class="select-wrapper">
                    <app-flat-select
                      [selectOptions]="birthMonths"
                      [(ngModel)]="item.kid.birthMonth"
                      [(defaultLabel)]="defaultLabel"
                      [(value)]="item.kid.birthMonth"
                      (selectOptionChange)="
                        setUpdateValue(item.id, 'birthMonth', $event)
                      "
                      ngDefaultControl
                    ></app-flat-select>
                  </div>
                </div>
              </div>
              <div class="kid-type">
                <div class="kid-select-item">
                  <div class="select-wrapper">
                    <app-flat-select
                      [selectOptions]="birthYears"
                      [(ngModel)]="item.kid.birthYear"
                      [(defaultLabel)]="defaultLabel"
                      [(value)]="item.kid.birthYear"
                      (selectOptionChange)="
                        setUpdateValue(item.id, 'birthYear', $event)
                      "
                      ngDefaultControl
                    ></app-flat-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    mat-fab
    class="add-kid-button-fab"
    matTooltip="Add Kid"
    matTooltipPosition="left"
  >
    <i class="material-icons md-16" (click)="addKid()">add</i>
  </button>
  <div
    class="add-kid-container"
    [ngClass]="{ visible: showAddKid, invisible: !showAddKid }"
  >
    <form [formGroup]="newKidForm" (ngSubmit)="saveKid()">
      <div class="form-inputs">
        <div class="kid-info-item">
          <input
            #nameInput
            id="name"
            formControlName="name"
            type="text"
            placeHolder="First last name"
            class="kid-input"
            [(ngModel)]="newMemberKid.kid.name"
            [ngClass]="{
              invalid: (name.dirty || name.touched) && name.errors
            }"
          />
          <div
            *ngIf="(name.dirty || name.touched) && name.errors"
            class="kid-validation-error-container"
          >
            <div class="kid-validation-error-icon">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="kid-validation-error-content">
              <span *ngIf="name.errors?.required" class="kid-validation-error"
                >Required
              </span>
              <span class="kid-validation-error" *ngIf="name.errors?.pattern"
                >Invalid</span
              >
            </div>
          </div>
        </div>
        <div class="new-kid-select-wrapper">
          <mat-form-field
            class="kid-form-field-light-grey new-kid"
            floatLabel="never"
          >
            <mat-select
              id="gender"
              formControlName="gender"
              class="kid-select-light-grey new-kid"
              placeholder="Gender"
              [(ngModel)]="newMemberKid.kid.gender"
              [ngClass]="{
                invalid: (gender.dirty || gender.touched) && gender.errors
              }"
            >
              <mat-option
                *ngFor="let gender of genderList"
                [value]="gender.value"
              >
                {{ gender.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="(gender.dirty || gender.touched) && gender.errors"
            class="kid-validation-error-container"
          >
            <div class="kid-validation-error-icon select new-kid">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="kid-validation-error-content">
              <span class="kid-validation-error">Required.</span>
            </div>
          </div>
        </div>

        <div class="new-kid-select-wrapper">
          <mat-form-field
            class="kid-form-field-light-grey new-kid new-kid"
            floatLabel="never"
          >
            <mat-select
              id="birthMonth"
              formControlName="birthMonth"
              class="kid-select-light-grey new-kid"
              placeholder="Birth month"
              [(ngModel)]="newMemberKid.kid.birthMonth"
            >
              <mat-option
                *ngFor="let month of birthMonths"
                [value]="month.value"
              >
                {{ month.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="new-kid-select-wrapper">
          <mat-form-field
            class="kid-form-field-light-grey new-kid"
            floatLabel="never"
          >
            <mat-select
              id="birthYear"
              formControlName="birthYear"
              class="kid-select-light-grey new-kid"
              placeholder="Birth year"
              [(ngModel)]="newMemberKid.kid.birthYear"
            >
              <mat-option *ngFor="let year of birthYears" [value]="year.value">
                {{ year.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="new-kid-select-wrapper">
          <mat-form-field
            class="kid-form-field-light-grey new-kid"
            floatLabel="never"
          >
            <mat-select
              id="relationship"
              formControlName="relationship"
              id="relationship"
              class="kid-select-light-grey new-kid"
              placeholder="Relationship"
              [(ngModel)]="newMemberKid.relationship"
              [ngClass]="{
                invalid:
                  (relationship.dirty || relationship.touched) &&
                  relationship.errors
              }"
            >
              <mat-option
                *ngFor="let relation of relationshipList"
                [value]="relation.value"
              >
                {{ relation.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="
              (relationship.dirty || relationship.touched) &&
              relationship.errors
            "
            class="new-kid-validation-error-container"
          >
            <div class="kid-validation-error-icon select new-kid">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="kid-validation-error-content">
              <span class="kid-validation-error">Required.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button
          type="submit"
          class="text-button"
          [disabled]="!newKidForm.valid"
        >
          SAVE
        </button>
        <button type="button" class="text-button" (click)="closeAddKid()">
          CANCEL
        </button>
      </div>
    </form>
  </div>
</div>
