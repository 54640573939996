import { TenantPaymentSummary } from "src/app/models/tenant-payment-summary";
import { Component, Input, OnInit, OnChanges } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-tenant-payments",
  templateUrl: "./tenant-payments.component.html",
  styleUrls: ["./tenant-payments.component.scss"],
})
export class TenantPaymentsComponent implements OnInit {
  @Input() model: TenantPaymentSummary;
  constructor() {}

  ngOnInit() {}
}
