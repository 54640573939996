import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { TenantProfile } from "src/app/models/tenant-profile";
import { HelperService } from "src/app/services/helper.service";

@Component({
  standalone: false,
  selector: "app-tenant-profile",
  templateUrl: "./tenant-profile.component.html",
  styleUrls: ["./tenant-profile.component.scss"],
})
export class TenantProfileComponent implements OnInit {
  @Input() model: TenantProfile;
  @Output() notifyParent = new EventEmitter<TenantProfile>();
  genderList: SelectListItem[];
  studentStatusList: SelectListItem[];
  residencyStatusList: SelectListItem[];
  defaultLabel = "Select";
  phoneFormat = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.getLists();
  }

  getLists() {
    this.genderList = this.helperService.genderListGet();
    this.studentStatusList = this.helperService.booleanListGet();
    this.residencyStatusList = this.helperService.booleanListGet();
  }

  setValue(field: string, value: any) {
    if (field === "isStudent") {
      this.model.isStudent = value;
    } else if (field === "residencyStatus") {
      this.model.residencyStatus = value;
    } else if (field === "gender") {
      this.model.gender = value;
    }
    this.model.validation.hasError = !this.validateFields(field, value);
    this.notifyParent.emit(this.model);
  }

  validateFields(field: string, value: any): boolean {
    let isValid = true;

    if (field === "age") {
      const age = parseInt(value);
      if (value.length === 0) {
        this.model.validation.age.hasError = true;
        this.model.validation.age.errorMessage = "Required";
        isValid = false;
      } else {
        if (age < 18) {
          this.model.validation.age.hasError = true;
          this.model.validation.age.errorMessage = "Invalid age";
        } else {
          this.model.age = age;
          this.model.validation.age.hasError = false;
        }
      }
    }
    if (field === "refName") {
      if (value.length === 0) {
        this.model.validation.refName.hasError = true;
        this.model.validation.refName.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.refName.hasError = false;
      }
    }
    if (field === "refEmail") {
      if (value.length === 0) {
        this.model.validation.refEmail.hasError = true;
        this.model.validation.refEmail.errorMessage = "Required";
        isValid = false;
      } else if (!value.match(this.emailFormat)) {
        this.model.validation.refEmail.hasError = true;
        this.model.validation.refEmail.errorMessage = "Invalid email";
      } else {
        this.model.validation.refEmail.hasError = false;
      }
    }
    if (field === "refPhone") {
      if (value.length === 0) {
        this.model.validation.refPhone.hasError = true;
        this.model.validation.refPhone.errorMessage = "Required";
        isValid = false;
      } else if (!value.match(this.phoneFormat)) {
        this.model.validation.refPhone.hasError = true;
        this.model.validation.refPhone.errorMessage = "Invalid number";
      } else {
        this.model.validation.refPhone.hasError = false;
      }
    }
    if (!this.model.age || this.model.age === 0 || this.model.age < 18) {
      this.model.validation.age.hasError = true;
    }
    if (this.model.refName.length == 0) {
      this.model.validation.refName.hasError = true;
    }
    if (this.model.refEmail.length == 0) {
      this.model.validation.refEmail.hasError = true;
    }
    if (this.model.refPhone.length == 0) {
      this.model.validation.refPhone.hasError = true;
    }
    isValid =
      this.model.validation.age.hasError ||
      this.model.validation.refName.hasError ||
      this.model.validation.refEmail.hasError ||
      this.model.validation.refPhone.hasError
        ? false
        : true;

    return isValid;
  }
}
