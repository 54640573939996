<div class="member-info-container">
  <div class="member-info-container-row">
    <div class="member-container">
      <div class="member-section">
        <div class="member-section-wrapper">
          <div class="member-wrapper">
            <div class="member-item-container thin">
              <div class="member-info-item">
                <app-flat-input
                  [placeHolder]="'First Name'"
                  [(value)]="memberUpdate.firstName"
                  [(ngModel)]="memberUpdate.firstName"
                  [hasError]="memberUpdate.validation.firstName.hasError"
                  [errorMessage]="
                    memberUpdate.validation.firstName.errorMessage
                  "
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('firstName', memberUpdate.firstName)"
                  (blur)="setUpdateValue('firstName', memberUpdate.firstName)"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="member-item-container thin">
              <div class="member-info-item">
                <app-flat-input
                  [placeHolder]="'Last Name'"
                  [(value)]="memberUpdate.lastName"
                  [(ngModel)]="memberUpdate.lastName"
                  [hasError]="memberUpdate.validation.lastName.hasError"
                  [errorMessage]="memberUpdate.validation.lastName.errorMessage"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('lastName', memberUpdate.lastName)"
                  (blur)="setUpdateValue('lastName', memberUpdate.lastName)"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="member-wrapper">
            <div class="member-item-container">
              <div class="member-info-item input-large">
                <app-flat-input
                  [placeHolder]="'Address'"
                  [(value)]="memberUpdate.address"
                  [(ngModel)]="memberUpdate.address"
                  [hasError]="memberUpdate.validation.address.hasError"
                  [errorMessage]="memberUpdate.validation.address.errorMessage"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('address', memberUpdate.address)"
                  (blur)="setUpdateValue('address', memberUpdate.address)"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="member-wrapper">
            <div class="member-item-container">
              <div class="member-info-item input-large">
                <app-flat-input
                  [placeHolder]="'City'"
                  [(value)]="memberUpdate.city"
                  [(ngModel)]="memberUpdate.city"
                  [hasError]="memberUpdate.validation.city.hasError"
                  [errorMessage]="memberUpdate.validation.city.errorMessage"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('city', memberUpdate.city)"
                  (blur)="setUpdateValue('city', memberUpdate.city)"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="member-wrapper">
            <div class="member-item-container thin">
              <div class="member-info-item">
                <div class="select-wrapper">
                  <app-flat-select
                    [selectOptions]="stateList"
                    [(ngModel)]="memberUpdate.state"
                    [(defaultLabel)]="defaultLabel"
                    [(value)]="memberUpdate.state"
                    [(position)]="selectListPosition"
                    (selectOptionChange)="setUpdateValue('state', $event)"
                    ngDefaultControl
                  ></app-flat-select>
                </div>
              </div>
            </div>
            <div class="member-item-container thin">
              <div class="member-info-item">
                <app-flat-input
                  [placeHolder]="'Zip'"
                  [(value)]="memberUpdate.zip"
                  [(ngModel)]="memberUpdate.zip"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('zip', memberUpdate.zip)"
                  (blur)="setUpdateValue('zip', memberUpdate.zip)"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="member-section">
        <div class="member-section-wrapper">
          <div class="member-wrapper">
            <div class="member-item-container thin">
              <div class="member-info-item">
                <app-flat-input
                  [placeHolder]="'Cell'"
                  [(value)]="memberUpdate.cellPhone"
                  [(ngModel)]="memberUpdate.cellPhone"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="setUpdateValue('cellPhone', memberUpdate.cellPhone)"
                  (blur)="setUpdateValue('cellPhone', memberUpdate.cellPhone)"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="member-item-container thin">
              <div class="member-info-item">
                <div class="select-wrapper top">
                  <app-flat-select
                    [selectOptions]="genderList"
                    [(ngModel)]="memberUpdate.gender"
                    [(defaultLabel)]="defaultLabel"
                    [(value)]="memberUpdate.gender"
                    (selectOptionChange)="setUpdateValue('gender', $event)"
                    ngDefaultControl
                  ></app-flat-select>
                </div>
              </div>
            </div>
          </div>
          <div class="member-wrapper">
            <div class="member-item-container thin">
              <div class="member-info-item checkbox-wrapper">
                <mat-checkbox
                  id="isStudent"
                  [(ngModel)]="memberUpdate.isStudent"
                  [(checked)]="memberUpdate.isStudent"
                  (change)="
                    onCheckChange($event);
                    setUpdateValueBool('isStudent', memberUpdate.isStudent)
                  "
                >
                </mat-checkbox>
                <span class="checkbox-text">Student </span>
              </div>
            </div>
            <div class="member-item-container thin">
              <div class="member-info-item checkbox-wrapper">
                <mat-checkbox
                  id="isAlumni"
                  [(ngModel)]="memberUpdate.isAlumni"
                  [(checked)]="memberUpdate.isAlumni"
                  (change)="
                    onCheckChange($event);
                    setUpdateValueBool('isAlumni', memberUpdate.isAlumni)
                  "
                >
                </mat-checkbox>
                <span class="checkbox-text">Alumni </span>
              </div>
            </div>
          </div>
          <div class="member-wrapper">
            <div class="member-item-container">
              <div class="member-info-item checkbox-wrapper">
                <mat-checkbox
                  id="residencyStatus"
                  [(ngModel)]="memberUpdate.residencyStatus"
                  [(checked)]="memberUpdate.residencyStatus"
                  (change)="
                    setUpdateValueBool(
                      'residencyStatus',
                      memberUpdate.residencyStatus
                    )
                  "
                >
                </mat-checkbox>
                <span class="checkbox-text">US Citizen/GreenCard Holder </span>
              </div>
            </div>
          </div>
          <div class="member-wrapper" *ngIf="keyFob">
            <div class="member-item-container">
              <div class="member-info-item">
                <div class="keyfob-header">KeyFob:</div>
                <app-flat-input
                  [placeHolder]="'KeyFob'"
                  [(value)]="keyFob.keyFob"
                  [(ngModel)]="keyFob.keyFob"
                  [hasError]="keyFob.validation.keyFob.hasError"
                  [errorMessage]="keyFob.validation.keyFob.errorMessage"
                  (keydown.enter)="$event.target.blur()"
                  (keyup)="UpdateKeyFob()"
                  (blur)="UpdateKeyFob()"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    mat-fab
    class="add-Keyfob-button-fab"
    *ngIf="!keyFob"
    matTooltip="Add KeyFob"
    matTooltipPosition="left"
  >
    <i class="material-icons md-16" (click)="addKeyFob()">add</i>
  </button>
  <div
    class="add-keyfob-container"
    [ngClass]="{ visible: showAddKeyFob, invisible: !showAddKeyFob }"
  >
    <div class="add-keyfob-container-row">
      <div class="form-inputs">
        <div class="member-info-item">
          <input
            #keyFobInput
            id="keyFob"
            type="text"
            placeHolder="KeyFob"
            value="newKeyFob.keyFob"
            [(ngModel)]="newKeyFob.keyFob"
            class="member-input new-keyfob"
            [ngClass]="{
              invalid: newKeyFob.validation.hasError
            }"
            (blur)="validateKeyFob()"
          />
          <div
            *ngIf="newKeyFob.validation.hasError"
            class="member-validation-error-container"
          >
            <div class="member-validation-error-icon">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="member-validation-error-content">
              <span class="member-validation-error"
                >{{ newKeyFob.validation.keyFob.errorMessage }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button
          class="text-button"
          [disabled]="newKeyFob.validation.hasError"
          (click)="saveKeyFob()"
        >
          SAVE
        </button>
        <button type="button" class="text-button" (click)="closeAddKeyFob()">
          CANCEL
        </button>
      </div>
    </div>
  </div>
</div>
