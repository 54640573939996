<div class="page-container" >
  <div class="page-header-section">
    <div class="header">
      Manage Semesters
    </div>
  </div>
  <div class="page-section">
    <div class="page-form" *ngIf="VM$  | async as vm;">
      <div class="page-table-container" *ngIf="vm.nonEditableSchoolFees">
        <div class="sub-header-container" >
          <div class="info">Current Semester</div>
        </div>
        <table mat-table [dataSource]="vm.nonEditableSchoolFees">
          <ng-container matColumnDef="name">
            <th class="page-table-header" mat-header-cell *matHeaderCellDef>
              Name
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.displayName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th class="page-table-header hide-column" mat-header-cell *matHeaderCellDef>
             Amount
            </th>
            <td mat-cell *matCellDef="let item" class="hide-column">
              {{ item.amount | currency}}
            </td>
          </ng-container>
          <ng-container matColumnDef="validity">
            <th class="page-table-header" mat-header-cell *matHeaderCellDef>
             Validity
            </th>
            <td mat-cell *matCellDef="let item">
              {{ item.valid}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="row.rowClass"
          ></tr>
        </table>
      </div>
      <div *ngIf="vm.existingRegistrationDate">
        <div class="sub-header-container" >
          <div class="info">Registration Dates</div>
        </div>
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Semester</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded">{{vm.existingRegistrationDate.semester}} {{vm.existingRegistrationDate.registrationYear}}</label>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Open Date</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded">{{vm.existingRegistrationDate.openDate | customDate | date: 'MM-dd-yy'}}</label>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Close Date</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded">{{vm.existingRegistrationDate.closeDate | customDate | date: 'MM-dd-yy'}}</label>
            </div>
          </div>
      </div>
      </div>
      <div class="fab-container">
        <button
          mat-mini-fab
          class="btn-secondary-blue-gradient"
          [disabled]="disableAdd"
          (click) ="clickAddKid()"
        >
          <div class="label">New Semester</div>
        </button>
      </div>
      <div *ngIf="showNewSemester && vm.editableSchoolFees">
        <div class="sub-header-container pad-top" >
          <div class="info">New Semester</div>
        </div>
          <div class="form-group value-group" *ngFor="let item of vm.editableSchoolFees">
            <div class="form-field">
              <div class="form-field-title">{{item.type}}</div>
              <div class="input-wrapper small">
                <label class="label-rounded">{{item.description}}</label>
              </div>
            </div>
            <div class="form-field">
              <div class="form-field-title">Fee</div>
              <div class="input-wrapper xx-small input-icon">
                <input type="number" class="input-rounded" inputText [value] ="item.amount" [(ngModel)] = "item.amount"
                [ngModelOptions]="{ standalone: true }" appCurrency (ngModelChange)="setDisableBtn(item.amount)" />
                <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
              </div>
            </div>
            <div class="form-field">
              <div class="form-field-title">Valid From</div>
              <div class="input-wrapper flex flex-small">
                <input
                [matDatepicker]="datePicker"
                class="input-rounded"
                [(ngModel)]="item.validFrom"
                (dateInput)="datePicker.close()"
                (dateChange)="datePicker.close()"
                (focus)="datePicker.open()"
                [min]="item.validFrom"
                readonly
              />
              <mat-datepicker-toggle
                class="date-picker"
                matSuffix
                [for]="datePicker"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker [startAt]="item.validFrom"></mat-datepicker>
              </div>

            </div>
            <div class="form-field">
              <div class="form-field-title">Valid Till</div>
              <div class="input-wrapper flex flex-small">
                <input
                [matDatepicker]="datePickerTill"
                class="input-rounded"
                [(ngModel)]="item.validTill"
                (dateInput)="datePickerTill.close()"
                (dateChange)="datePickerTill.close()"
                (focus)="datePickerTill.open()"
                [min]="item.validTill"
                readonly
              />
              <mat-datepicker-toggle
                class="date-picker"
                matSuffix
                [for]="datePickerTill"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePickerTill [startAt]="item.validTill"></mat-datepicker>
              </div>
            </div>
          </div>
          <div *ngIf="vm.newRegistrationDate">
            <div class="sub-header-container" >
              <div class="info">New Registration Dates</div>
            </div>
            <div class="form-group value-group">
              <div class="form-field">
                <div class="form-field-title">Semester</div>
                <div class="input-wrapper x-small">
                  <label class="label-rounded">{{vm.newRegistrationDate.semester}} {{vm.newRegistrationDate.registrationYear}}</label>
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">Open Date</div>
                <div class="input-wrapper flex flex-small">
                  <input
                  [matDatepicker]="datePickerOpenDate"
                  class="input-rounded"
                  [(ngModel)]="vm.newRegistrationDate.openDate"
                  (dateInput)="datePickerOpenDate.close()"
                  (dateChange)="datePickerOpenDate.close()"
                  (focus)="datePickerOpenDate.open()"
                  [min]="vm.newRegistrationDate.openDate"
                  readonly
                />
                <mat-datepicker-toggle
                  class="date-picker"
                  matSuffix
                  [for]="datePickerOpenDate"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #datePickerOpenDate [startAt]="vm.newRegistrationDate.openDate"></mat-datepicker>
                </div>

              </div>
              <div class="form-field">
                <div class="form-field-title">Close Date</div>
                <div class="input-wrapper flex flex-small">
                  <input
                  [matDatepicker]="datePickerCloseDate"
                  class="input-rounded"
                  [(ngModel)]="vm.newRegistrationDate.closeDate"
                  (dateInput)="datePickerCloseDate.close()"
                  (dateChange)="datePickerCloseDate.close()"
                  (focus)="datePickerCloseDate.open()"
                  [min]="vm.newRegistrationDate.closeDate"
                  readonly
                />
                <mat-datepicker-toggle
                  class="date-picker"
                  matSuffix
                  [for]="datePickerCloseDate"
                >
                </mat-datepicker-toggle>
                <mat-datepicker #datePickerCloseDate [startAt]="vm.newRegistrationDate.closeDate"></mat-datepicker>
                </div>
              </div>
          </div>
          </div>

          <div class="btn-container">
            <div class="btn-wrapper ">
              <button
              mat-flat-button
              class="btn btn-yellow btn-save"
              (click)="cancel()"

            >
           Cancel
            </button>
          </div>
            <div class="btn-wrapper ">
              <button
              mat-flat-button
              class="btn btn-secondary-blue btn-save"
              (click)="addSemester(vm)"
              [disabled]="disableBtn"
            >
           Add
            </button>
            </div>
      </div>
    </div>
  </div>

  <div *ngIf="message$ | async as message">

  </div>
</div>
