<div class="ieb-volunteers-container">
  <div class="ieb-section">
    <div class="header-section">
      <div class="header">Volunteer</div>
    </div>
    <div class="ieb-info-section">
      <div class="ieb-info">
        <ul class="ieb-list">
          <li class="list-item">
            ISGL Sunday school is completely run by teachers and assistants, who
            are dedicated volunteers from our community.
          </li>
          <li class="list-item">
            If you are interested in volunteering, please contact the Islamic
            Education Board (IEB) director, sister Salmina Sadeq at
            <span class="bold-text">(ieb&#64;masjidisgl.org)</span> and fill out the
            <a
              class="anchor-link"
              href=" https://forms.gle/MmNzPBQJr2d9u3ZRA"
              target="_blank"
            >
              volunteer application form.
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
