<div *ngIf="model">
  <div class="item-container">
    <div class="sub-header">{{ headerTitle }}</div>
  </div>

  <div class="item-container left-align">
    <div class="item-label-wrapper">
      <span class="item-label">Name:</span>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Vehicle Name'"
        [(value)]="model.name"
        [(ngModel)]="model.name"
        [hasError]="model.validation.name.hasError"
        [errorMessage]="model.validation.name.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleName', model.name)"
        (blur)="setValue('vehicleName', model.name)"
        [disabled]="!vehicle.isActive"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Model:</span>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Model'"
        [(value)]="model.model"
        [(ngModel)]="model.model"
        [hasError]="model.validation.model.hasError"
        [errorMessage]="model.validation.model.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleModel', model.model)"
        (blur)="setValue('vehicleModel', model.model)"
        [disabled]="!vehicle.isActive"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">License:</span>
    </div>
    <div class="item-input-wrapper small">
      <app-flat-input
        [placeHolder]="'License Plate'"
        [(value)]="model.licensePlate"
        [(ngModel)]="model.licensePlate"
        [hasError]="model.validation.licensePlate.hasError"
        [errorMessage]="model.validation.licensePlate.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('licensePlate', model.licensePlate)"
        (blur)="setValue('licensePlate', model.licensePlate)"
        [disabled]="!vehicle.isActive"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label skinny">Year:</span>
    </div>
    <div class="item-select-wrapper skinny" *ngIf="vehicle.isActive">
      <app-flat-select
        [selectOptions]="vehicleYears"
        [(ngModel)]="model.year"
        [defaultLabel]="'Select'"
        [(value)]="model.year"
        [hasError]="model.validation.year.hasError"
        [errorMessage]="model.validation.year.errorMessage"
        (selectOptionChange)="setValue('vehicleYear', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-input-wrapper skinny" *ngIf="!vehicle.isActive">
      <app-flat-input
        [placeHolder]="'vehicleYears'"
        [(value)]="model.year"
        [(ngModel)]="model.year"
        [disabled]="!vehicle.isActive"
      >
      </app-flat-input>
    </div>
    <div class="btn-container" *ngIf="vehicle.isActive">
      <button
        mat-raised-button
        class="vehicle-btns update"
        [disabled]="enableBtn"
        (click)="updateVehicle()"
      >
        <i class="material-icons icon">save</i>
        Update Vehicle
      </button>
    </div>
  </div>
</div>
