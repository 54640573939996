import { TenantService } from "src/app/services/tenant.service";
import { SecurityService } from "src/app/services/security.service";
import { RoomService } from "./../../services/room.service";
import { Component, OnInit } from "@angular/core";
import { RoomInfo } from "src/app/models/room-info";
import { RoomFilters } from "src/app/models/room-filters";
import { ListRequest } from "src/app/models/list-request";
import { Observable } from "rxjs";
import { TenantInfo } from "src/app/models/tenant-info";
import { Router } from "@angular/router";

@Component({
  standalone: false,
  selector: "app-rooms",
  templateUrl: "./rooms.component.html",
  styleUrls: ["./rooms.component.scss"],
})
export class RoomsComponent implements OnInit {
  rooms: RoomInfo[];
  filters: RoomFilters;
  listRequest: ListRequest;
  itemsPerPage = 10;
  pageNumber: number;
  signedIn$: Observable<boolean>;
  memberId: number;
  tenant: TenantInfo;
  showRentBreakup: boolean;

  constructor(
    private roomService: RoomService,
    private securityService: SecurityService,
    private tenantService: TenantService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getRooms();
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        this.showRentBreakup = this.memberId ? true : false;
      }
    });
  }

  setListRequest() {
    if (!this.filters) {
      this.filters = {
        name: null,
        occupancy: null,
        rentId: null,
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber,
    };
  }

  getRooms() {
    this.setListRequest();
    this.roomService.roomList(this.listRequest).subscribe((data) => {
      this.rooms = Object.assign([], data);
    });
  }

  signedInUser() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        if (this.memberId) {
          this.showRentBreakup = true;
          this.tenantService
            .determineRedirect(this.memberId, "waitList")
            .subscribe((redirect) => {
              if (redirect) {
                if (redirect === "/waitList") {
                  redirect = "/waitList/add";
                }
                this.router.navigate([redirect]);
              } else {
                this.router.navigate(["/waitList/add"]);
              }
            });
        }
      } else {
        this.showRentBreakup = false;
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: "rooms" },
        });
      }
    });
  }

  waitList() {
    this.signedInUser();
  }
}
