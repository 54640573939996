<div class="addendum-container">
  <div class="addendum-section">
    <div class="addendum-header-section">
      <div class="header">
        <div class="header-title">
          <div class="title-text">{{ title }}</div>
        </div>
      </div>
    </div>
    <div class="page-row">
      <div class="row-content" *ngIf="showAddendumRequest">
        <div class="item-container" *ngIf="showMessage">
          <app-addendum-message
            [extMessage]="addendumMessage"
          ></app-addendum-message>
        </div>
        <div *ngIf="model && model.type === 'Lease-Ext'">
          <app-lease-ext-request
            [tenantInfo]="model.tenant"
            [(disableControls)]="disableExtControls"
            [(disableSig)]="disableSig"
            [(sigModel)]="sigModel"
            (notifyParent)="getAdddendumData($event)"
          ></app-lease-ext-request>
        </div>
        <div *ngIf="model && model.type !== 'Lease-Ext'">
          <app-parking-addendum
            [model]="model"
            [showInfo]="false"
            [(disableControls)]="disableExtControls"
            [(disableSig)]="disableSig"
            [(sigModel)]="sigModel"
            (notifyParent)="getAdddendumData($event)"
            (newVehicleNotification)="newVehicleAdded($event)"
          ></app-parking-addendum>
        </div>
      </div>
    </div>
  </div>
  <div class="pdf-container" *ngIf="addendumSource && !showAddendumRequest">
    <div class="item-container">
      <div class="pdf-btn-container">
        <button
          type="button"
          class="pdf-buttons save"
          (click)="confirmAddendum()"
        >
          Confirm to save (required)
        </button>
        <button type="button" class="pdf-buttons cancel" (click)="cancel()">
          Go back & fix
        </button>
      </div>
    </div>
    <div class="pdf-wrapper">
      <div class="pdf-doc">
        <iframe
          width="100%"
          height="600px"
          [src]="addendumSource | safe"
          frameborder="0"
          class="file-viewer"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>
