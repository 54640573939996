import { RegRequest } from './../../models/school/reg-request';
import { SelectListItem } from 'src/app/models/select-list-item';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegModel } from 'src/app/models/school/reg-model';
import * as _moment from "moment";
import { StripePayment } from 'src/app/models/stripe-payment';
import { StripePaymentModalComponent } from '../stripe-payment-modal/stripe-payment-modal.component';
import { StripeSchoolPayment } from 'src/app/models/school/stripe-school-payment';
import { Fee } from 'src/app/models/school/fee';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
const moment = _moment;
@Component({
  standalone: false,
  selector: 'app-ieb-reg-payment',
  templateUrl: './ieb-reg-payment.component.html',
  styleUrls: ['./ieb-reg-payment.component.scss']
})
export class IebRegPaymentComponent implements OnInit, OnChanges {
  @Input() model!: RegModel;
  @Output() addNewReg = new EventEmitter<RegRequest>();
  regPayForm!: FormGroup;
  payment: StripeSchoolPayment;
  selectedRegFee: SelectListItem;
  selectedPreschoolRegFee: SelectListItem;
  regFee: Fee;
  preschoolRegFee: Fee;
  totalRegFee: number;
  totalPreschoolRegFee: number;
  totalRegFeeDue: number;
  materialFee: Fee;
  totalMaterialFee: number;
  lateFee: Fee;
  totalLateFee: number;
  kidsNumber: number;

  hasMaterialFee: boolean;
  hasLateFee: boolean;
  workCheck: boolean;
  parentsInitials: string;
  donationCheck: boolean;
  submitBtnText: string;
  paymentAmount: number;
  disableBtn = false;
  subheader = '';
  reducedFeeChildCount = 0;
  reducedFeePreschoolChildCount = 0;
  reducedFeeAmount = 0;
  reducedFeeAmountPreschool = 0;
  totalReducedFeeAmount = 0;
  reducedFeeText = '';
  preschoolReducedFeeText = '';
  schoolKidsCount: number;
  totalSchoolKidsCount: number;
  totalPreschoolKidsCount: number;
  preschoolKidsCount: number;



  constructor(public dialog: MatDialog, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.regPayForm = this.formBuilder.group({
      workCb: [false, Validators.requiredTrue],
      initials: ['', [Validators.required]],
     });

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.model) {
      this.selectedRegFee = this.model.schoolFeeList.find((f)=> f.label.includes('Semester'));
      this.selectedPreschoolRegFee = this.model.hasPreschool ?
              this.model.preschoolFeeList.find((f)=> f.label.includes('Semester')) : null;
      if(this.model.kidsFees && this.model.kidsFees.length > 0) {
        let rKids = this.model.kidsFees.filter(k=> k.hasSemesterFee && !k.hasReducedFee && !k.isPreschool);
        this.schoolKidsCount = rKids && rKids.length > 0 ? rKids.length : 0;
        let preschoolKids = this.model.kidsFees.filter(k=> k.hasSemesterFee && !k.hasReducedFee && k.isPreschool);
        this.preschoolKidsCount = preschoolKids && preschoolKids.length > 0 ? preschoolKids.length : 0;
        let redFeeKids = this.model.kidsFees.filter(k =>  k.hasReducedFee && !k.isPreschool);
        this.reducedFeeChildCount = redFeeKids && redFeeKids.length > 0 ? redFeeKids.length : 0;

        let preschoolRedFeeKids = this.model.kidsFees.filter(k =>  k.hasReducedFee && k.isPreschool);
        this.reducedFeePreschoolChildCount = preschoolRedFeeKids && preschoolRedFeeKids.length > 0 ? preschoolRedFeeKids.length : 0;
        this.totalSchoolKidsCount = this.schoolKidsCount +  this.reducedFeeChildCount;
        this.totalPreschoolKidsCount = this.preschoolKidsCount + this.reducedFeePreschoolChildCount;

        this.kidsNumber = this.model.schoolKids && this.model.schoolKids.length > 0 ? this.model.schoolKids.length : 0;
        this.subheader = this.kidsNumber > 1 ? `${this.kidsNumber} Children` : `${this.kidsNumber} Child`;
        this.regFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedRegFee.value);
        this.preschoolRegFee = this.selectedPreschoolRegFee ?
                this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedPreschoolRegFee.value) : null;
        this.reducedFeeAmount = this.reducedFeeChildCount ? this.regFee.amount * 50/100 : 0;
        this.reducedFeeAmountPreschool = this.reducedFeePreschoolChildCount ? this.preschoolRegFee.amount * 50/100 : 0;
        let reduceFeeTotal = 0;
        let reduceFeePreschoolTotal = 0;
        if(!this.model.isTeacher) {
          if(this.reducedFeeAmount) {
             reduceFeeTotal = this.reducedFeeAmount * this.reducedFeeChildCount;
              var verb = this.reducedFeeChildCount > 1 ? 'children are' : 'child is';
              let redFee = this.model.schoolFees.find((f)=> f.type == 'Reduced 50%');
              this.reducedFeeText = `${this.reducedFeeChildCount} ${verb} accepted for ${redFee.name}`;
              this.subheader = this.subheader + ' ('+this.reducedFeeText+')';
            }
            if(this.reducedFeeAmountPreschool) {
              reduceFeePreschoolTotal = this.reducedFeeAmountPreschool * this.reducedFeePreschoolChildCount;
              var verbPreschool = this.reducedFeePreschoolChildCount > 1 ? 'children are' : 'child is';
              let redFeePreschool = this.model.schoolFees.find((f)=> f.type == 'Reduced 50%');
              this.preschoolReducedFeeText = `${this.reducedFeePreschoolChildCount} ${verbPreschool} accepted for ${redFeePreschool.name}`;
            }
          let regFeeDue =  rKids && rKids.length > 0 ? this.regFee.amount * rKids.length : 0;
          let regFeeReducedDue = redFeeKids && redFeeKids.length > 0 ? this.reducedFeeAmount * this.reducedFeeChildCount : 0;

          this.totalRegFee = regFeeDue + regFeeReducedDue;

          let preschoolRegFeeDue = preschoolKids && preschoolKids.length > 0 ? this.preschoolRegFee.amount * preschoolKids.length : 0;
          let preschoolRegFeeReducedDue = this.reducedFeePreschoolChildCount ? this.reducedFeeAmountPreschool * this.reducedFeePreschoolChildCount : 0;

          this.totalPreschoolRegFee = preschoolRegFeeDue + preschoolRegFeeReducedDue;
          this.totalRegFeeDue = this.totalPreschoolRegFee + this.totalRegFee;
        } else {
          this.totalRegFee = 0;
          this.totalPreschoolRegFee = 0;
          this.totalRegFeeDue = 0;
        }

        this.materialFee = this.model.schoolFees.find((f)=> f.type === 'Material');
        let mKids = this.model.kidsFees.filter(k=> k.hasMaterialFee);
        this.totalMaterialFee = mKids && mKids.length > 0 ? this.materialFee.amount * mKids.length : 0;
        this.hasMaterialFee = mKids && mKids.length > 0;
        this.lateFee = this.model.schoolFees.find((f)=> f.type === 'Late');
        let lKids = this.model.kidsFees.filter(k=> k.hasLateFee);
        this.totalLateFee = lKids && lKids.length > 0 ? this.lateFee.amount * lKids.length : 0;
        this.hasLateFee = lKids && lKids.length > 0;
      }

      this.submitBtnText = this.model.amountDue > 0 ? `Pay $${this.model.amountDue} & Register` : 'Register';
    }

  }

  setRegFee(feeTypeId: number) {
    this.selectedRegFee = this.model.schoolFeeList.find((f)=> f.value === feeTypeId);
    this.regFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedRegFee.value);
    let reducedFee = 0;

    if(this.reducedFeeChildCount) {
      reducedFee = this.regFee .amount * 50/100 * this.reducedFeeChildCount;
    }

    if(!this.model.isTeacher) {
      this.totalRegFee = this.regFee.amount * this.schoolKidsCount + reducedFee *this.reducedFeeChildCount;
      this.model.amountDue = parseFloat((this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
      this.submitBtnText = this.model.amountDue > 0 ? `Pay $${this.model.amountDue} & Register` : 'Register';
    }
  }

  setPreschoolRegFee(feeTypeId: number) {
    this.selectedPreschoolRegFee = this.model.preschoolFeeList.find((f)=> f.value === feeTypeId);
    this.preschoolRegFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedPreschoolRegFee.value);
    let reducedFee = 0;

    if(this.reducedFeePreschoolChildCount) {
      reducedFee = this.preschoolRegFee .amount * 50/100 * this.reducedFeePreschoolChildCount;
    }

    if(!this.model.isTeacher) {
      this.totalPreschoolRegFee = this.preschoolRegFee.amount * this.preschoolKidsCount + reducedFee;
      this.model.amountDue = parseFloat(( this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
      this.submitBtnText = this.model.amountDue > 0 ? `Pay $${this.model.amountDue} & Register` : 'Register';
    }
  }


  setDonationTotal(event: any) {
    this.model.donationAmountTotal = !this.model.donationAmount || this.model.donationAmount === 0 ? 0 :
      this.kidsNumber * this.model.donationAmount;
    this.model.donationAmountTotal = Number.isNaN(this.model.donationAmountTotal) ? 0 : this.model.donationAmountTotal;
    this.model.amountDue = parseFloat((this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
    this.model.amountDue = Number.isNaN(this.model.amountDue) ? 0 : this.model.amountDue;

    this.submitBtnText = this.model.amountDue > 0 ? `Pay $${this.model.amountDue} & Register` : 'Register';
  }

  openStripeDialog() {
    let sPayment = {
      amount: this.model.amountDue,
      paymentDate: moment().local().clone(),
      tokenId: "",
      recipientEmail: this.model.parentEmail,
      title: "ISGL Sunday School Registration",
      description: this.model.semester,
      showCancel: false,
    } as StripePayment;

    const dialogRef = this.dialog.open(StripePaymentModalComponent, {
      width: "550px",
      height: "380px",
      data: sPayment,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult && dialogResult.tokenId) {
        this.payment.tokenId = dialogResult.tokenId;
        this.saveRegistration();
      }

    });
  }

  submit(){
    this.disableBtn = true;
    let schoolFeeIds = [];
    if(this.selectedRegFee) {
      schoolFeeIds.push(this.selectedRegFee.value);
    }
    if(this.selectedPreschoolRegFee) {
      schoolFeeIds.push(this.selectedPreschoolRegFee.value);
    }
    this.payment = {
      schoolFeeTypeIds : schoolFeeIds,
      amount: this.model.amountDue,
      paymentDate: moment().local().clone(),
      tokenId: "",
      recipientEmail: this.model.parentEmail,
      title: "ISGL Sunday School Registration",
      description: this.model.semester,
      showCancel: false,
    };
    if(this.model.amountDue > 0) {
      this.openStripeDialog();
    } else {
      this.saveRegistration();
    }

  }

  saveRegistration(){
    let request = this.setRequest();
    this.addNewReg.emit(request);
  }

  setRequest(): RegRequest {
    return {
      id: null,
      parentId: this.model.schoolParentId,
      parentsInitials: this.regPayForm.controls['initials'].value || null,
      semester: this.model.semester,
      startYear: moment(this.regFee.validFrom).clone().local().year(),
      endYear: moment(this.regFee.validTill).clone().local().year(),
      isTeacher: this.model.isTeacher,
      donationAmount: this.model.donationAmountTotal === 0 ? null : this.model.donationAmountTotal,
      materialFeeAmount: this.totalMaterialFee === 0 ? null : this.totalMaterialFee,
      lateFeeAmount: this.totalLateFee === 0 ? null : this.totalLateFee,
      stripeSchoolPayment: this.payment,
      schoolKidsIds: this.model.schoolKids.map((k) => k.id)
    };
  }

}
