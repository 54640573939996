<div>
  <app-header></app-header>
  <div class="isgl-cta-container isgl-cta-background">
    <div class="isgl-cta-layer">
      <div class="isgl-text">
        <div class="isgl-name">
          Welcome to the Islamic Society of Greater Lafayette
        </div>
        <div class="isgl-sub-text arabic" *ngIf="quote">
          {{ quote.ayah }}
        </div>
        <div class="isgl-sub-text" *ngIf="quote">
          {{ quote.translation }}
        </div>
      </div>

      <div class="btn-container">
        <div class="btn-item-container">
          <a
            href="https://donorbox.org/isgl"
            target="_blank"
            class="btn-donate"
          >
            Donate
          </a>
        </div>
        <div class="btn-item-container">
          <div class="scroll-icon-down" (click)="goToBottom()">
            <fa-icon [icon]="faArrowAltCircleDown"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row-container space">
    <div class="notice-container">
      <div *ngFor="let item of announcements">
        <app-announcement-card [announcement]="item"></app-announcement-card>
      </div>
    </div>
    <div class="prayer-times-container">
      <app-iqamah-times></app-iqamah-times>
    </div>
  </div>
  <div class="row-container bottom-footer">
    <app-footer></app-footer>
  </div>
</div>
