import { HelperService } from "src/app/services/helper.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { VehicleRequest } from "src/app/models/vehicle-request";

@Component({
  standalone: false,
  selector: "app-vehicle",
  templateUrl: "./vehicle.component.html",
  styleUrls: ["./vehicle.component.scss"],
})
export class VehicleComponent implements OnInit {
  @Input() model: VehicleRequest;
  @Input() showInfo = true;
  @Output() notifyParent = new EventEmitter<VehicleRequest>();
  vehicleYears: SelectListItem[];
  defaultLabel = "Select";
  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.getYears();
  }
  getYears() {
    this.vehicleYears = this.helperService.vehicleYearsGet();
  }
  setValue(field: string, value: any) {
    this.model.validation.hasError = !this.validateFields(field, value);
    this.notifyParent.emit(this.model);
  }

  validateFields(field: string, value: any): boolean {
    let isValid = true;
    if (field === "vehicleName") {
      if (value.length === 0) {
        this.model.validation.name.hasError = true;
        this.model.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.name.hasError = false;
      }
    }
    if (field === "vehicleModel") {
      if (value.length === 0) {
        this.model.validation.model.hasError = true;
        this.model.validation.model.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.model.hasError = false;
      }
    }
    if (field === "vehicleYear") {
      if (value.length === 0) {
        this.model.validation.year.hasError = true;
        this.model.validation.year.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.year = value;
        this.model.validation.year.hasError = false;
      }
    }
    if (field === "licensePlate") {
      if (value.length === 0) {
        this.model.validation.licensePlate.hasError = true;
        this.model.validation.licensePlate.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.licensePlate.hasError = false;
      }
    }

    isValid =
      this.model.name.length > 0 &&
      this.model.licensePlate.length > 0 &&
      this.model.model.length > 0 &&
      this.model.year.length > 0
        ? true
        : false;

    // isValid =
    //   this.model.validation.name.hasError ||
    //   this.model.validation.model.hasError ||
    //   this.model.validation.year.hasError ||
    //   this.model.validation.licensePlate.hasError
    //     ? false
    //     : true;

    return isValid;
  }
}
