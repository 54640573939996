<div class="late-fee-container">
  <div class="late-fee-container-row">
    <div class="details-container">
      <div class="details-section">
        <div
          class="details-section-wrapper"
          *ngIf="tenantInfo && tenantInfo.leaseInfo"
        >
          <div class="details-wrapper tall">
            <div class="subheader-text">Late Fee Waiver:</div>
            <div
              class="delete-item-container"
              *ngIf="
                tenantInfo.paymentSummary &&
                tenantInfo.paymentSummary.lateFeeWaiver
              "
            >
              <div
                class="delete-item"
                [ngClass]="{ 'disable-delete': disableDelete }"
                (click)="deleteFeeWaiver()"
              >
                <i
                  class="material-icons delete-item-button"
                  (click)="deleteFeeWaiver()"
                  >delete</i
                >
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Month:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Month'"
                  [(value)]="nextPaymentMonth"
                  [(ngModel)]="nextPaymentMonth"
                  [inputType]="'text'"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Year:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Year'"
                  [(value)]="nextPaymentYear"
                  [(ngModel)]="nextPaymentYear"
                  [inputType]="'text'"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="details-wrapper">
            <div class="btn-container">
              <button
                mat-raised-button
                class="summary-btns save"
                [disabled]="disableSaveWaiver"
                (click)="saveWaiver()"
              >
                Save Waiver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
