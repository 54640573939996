<div class="container">
  <div class="btn-content">
    <div class="icon-wrapper">
      <a [routerLink]="'/index'" class="anchor-link">
        <i class="material-icons btn-icon">arrow_back</i>
      </a>
    </div>
    <div class="btn-title">
      <a [routerLink]="'/index'" class="anchor-link"> Home </a>
    </div>
  </div>
  <div class="message-content">
    {{ errorMessage }}
  </div>
</div>
