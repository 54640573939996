<div class="lease-details-container">
  <div class="lease-details-container-row">
    <div class="details-container">
      <div class="details-section">
        <div class="details-section-wrapper" *ngIf="model && model.id">
          <div class="details-wrapper">
            <div class="subheader-text">Lease Info:</div>
          </div>
          <div class="details-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Start:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Start'"
                  [(value)]="startDate"
                  [(ngModel)]="startDate"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">End:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'End'"
                  [(value)]="endDate"
                  [(ngModel)]="endDate"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Status:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Status'"
                  [(value)]="status"
                  [(ngModel)]="status"
                  [disabled]="true"
                >
                </app-flat-input>
              </div>
            </div>

            <div class="details-item-container thin" *ngIf="model.autoRenewal">
              <div class="details-info-item checkbox-wrapper left">
                <mat-checkbox
                  id="autoRenewal"
                  [(ngModel)]="model.autoRenewal"
                  [(checked)]="model.autoRenewal"
                  disabled
                >
                </mat-checkbox>

                <span class="checkbox-text">AutoRenewal</span>
              </div>
            </div>
          </div>

          <div class="details-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Rent:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Rent'"
                  [(value)]="rent"
                  [(ngModel)]="rent"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Prorate:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Prorate'"
                  [(value)]="prorate"
                  [(ngModel)]="prorate"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Deposit:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Deposit'"
                  [(value)]="deposit"
                  [(ngModel)]="deposit"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Deposit Paid:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Deposit Paid'"
                  [(value)]="depositPaid"
                  [(ngModel)]="depositPaid"
                  [disabled]="true"
                  class="input thin"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div *ngIf="model.statusId === 3 || model.statusId === 10" class="details-wrapper">
            <div class="btn-container" [ngClass]="{ end: !showSave }">
              <button
                mat-raised-button
                class="summary-btns"
                [ngClass]="{ clear: showSave }"
                (click)="openSignatureDialog()"
              >
                <fa-icon [icon]="faSignature" class="icon-buttons"></fa-icon>
                {{ sigBtnText }}
              </button>

              <button
                mat-raised-button
                class="summary-btns save"
                [ngClass]="{ hide: !showSave }"
                (click)="saveSig()"
              >
                Save Lease
              </button>
            </div>
          </div>
        </div>
        <div class="details-section-wrapper" *ngIf="!model || !model.id">
          <div class="details-wrapper">
            <div class="subheader-text">Lease is not created yet!:</div>
          </div>
        </div>
      </div>
      <div class="details-section docs">
        <div class="details-section-wrapper" *ngIf="model && model.id">
          <div class="details-wrapper docs subheader">
            <div class="subheader-text-docs">Documents</div>
          </div>
          <div class="details-wrapper docs" *ngFor="let doc of docs">
            <div class="download-container" *ngIf="doc.file.url">
              <a
                [href]="doc.file.url | safe"
                class="doc-download-link"
                target="_blank"
              >
                <fa-icon [icon]="faDownload" class="icon-download"> </fa-icon>
                {{ doc.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div>
  </div>
</div>
