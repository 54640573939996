<div *ngIf="model as vm" class="school-kid-container">
  <form [formGroup]="schoolKidForm" (ngSubmit)="submit()">
    <div class="page-form">
      <div class="sub-header-container">
        <div class="title">{{title}}</div>
      </div>
      <div class="form-group value-group">
        <div class="form-field left-field">
          <div class="form-field-title">Child First Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" formControlName="kidFirstName" />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolKidForm.controls['kidFirstName'].errors && schoolKidForm.controls['kidFirstName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field left-field">
          <div class="form-field-title">Child Last Name</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="kidLastName" />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolKidForm.controls['kidLastName'].errors && schoolKidForm.controls['kidLastName'].errors['required']">Required</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Gmail Address</div>
          <div class="input-wrapper small">
            <input type="text" class="input-rounded" inputText formControlName="kidEmail" />
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
              <div class="validation-content">
                <span class="validation-message validation-left">
                  <span *ngIf="schoolKidForm.controls['kidEmail'].errors && schoolKidForm.controls['kidEmail'].errors['required']">Required</span>
                  <span *ngIf="schoolKidForm.controls['kidEmail'].errors && schoolKidForm.controls['kidEmail'].errors['email']">Invalid email</span>
                  <span *ngIf="schoolKidForm.controls['kidEmail'].errors && schoolKidForm.controls['kidEmail'].errors['pattern']">Invalid domain</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Date of Birth(between 4 and 17)</div>
          <div class="input-wrapper flex flex-small">
            <input
            [matDatepicker]="datePicker"
            class="input-rounded"
            formControlName="dob"
            [(ngModel)]="kidDob"
            (dateInput)="datePicker.close()"
            (dateChange)="datePicker.close()"
            (focus)="datePicker.open()"
            [min]="minDate"
            [max]="maxDate"
            readonly
          />
          <mat-datepicker-toggle
            class="date-picker"
            matSuffix
            [for]="datePicker"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker [startAt]="startDate"></mat-datepicker>
          </div>
          <div class="validation-container">
            <div class="validation-icon">
              <i class="material-icons error-icon">error</i>
            </div>
            <div class="validation-content">
              <span class="validation-message validation-left">
                <span *ngIf="schoolKidForm.controls['dob'].errors && schoolKidForm.controls['dob'].errors['required']">Required</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field" *ngIf = "genderList">
          <div class="form-field-title">Child Gender</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="genderList"
                [(ngModel)]="gender"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="gender"
                (selectOptionChange)="setGender($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field" *ngIf = "levelList">
          <div class="form-field-title">Level</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="levelList"
                [(ngModel)]="selectedLevel.value"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="selectedLevel.value"
                (selectOptionChange)="setLevelValue($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <!-- <div class="form-field">
          <div class="form-field-title">Level</div>
          <div class="input-wrapper x-small">
            <input type="text" class="input-rounded" inputText  formControlName="level" readonly/>
            <div class="validation-container">
              <div class="validation-icon">
                <i class="material-icons error-icon">error</i>
              </div>
            <div class="validation-content">
              <span class="validation-message validation-left">
                <span *ngIf="schoolKidForm.controls['level'].errors && schoolKidForm.controls['level'].errors['required']">Required</span>
              </span>
            </div>
          </div>
          </div>
        </div> -->
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Arabic Skill</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="arabicSkills"
                [(ngModel)]="arabicSkill.value"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="arabicSkill.value"
                (selectOptionChange)="setArabicSkill($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Can child read Quran with Tajweed rules?</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="quranSkills"
                [(ngModel)]="quranSkill.value"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="quranSkill.value"
                (selectOptionChange)="setQuranSkill($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <div class="btn-wrapper">
          <button
          mat-flat-button
          class="btn btn-lacoste btn-save"
          type="submit"
          [disabled]="!schoolKidForm.valid"
        >
        {{btnText}}
        </button>
        </div>
        <div class="btn-wrapper">
          <button
          mat-flat-button
          class="btn btn-yellow btn-save"
          (click) ="onCancelNewKid()"
        >
        Cancel
        </button>
        </div>

      </div>
    </div>
  </form>
