 <div class="page-form" *ngIf="model && model.studentDetails">
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Name</div>
            <div class="input-wrapper small">
              <label class="label-rounded" id="name" >{{model.studentDetails.name}}</label>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Email</div>
            <div class="input-wrapper small">
              <label class="label-rounded" id="email" >{{model.studentDetails.email}}</label>
            </div>
          </div>
        </div>
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Date of birth</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded"id ="dob" >{{model.studentDetails.dob}}</label>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Gender</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded" id="gender" >{{model.studentDetails.gender}}</label>
            </div>
          </div>
          <div class="form-field" *ngIf="selectedLevel">
            <div class="form-field-title">Levels</div>
            <div class="select-wrapper">
              <app-flat-select
                  [selectOptions]="model.studentForm.levelList"
                  [(ngModel)]="selectedLevel.value"
                  [ngModelOptions]="{ standalone: true }"
                  [defaultLabel]="'Select'"
                  [(value)]="selectedLevel.value"
                  (selectOptionChange)="setLevel($event)"
                  ngDefaultControl
                ></app-flat-select>
            </div>
          </div>
        </div>
        <div class="form-group value-group">
          <div class="form-field" *ngIf="selectedArabicSkill">
            <div class="form-field-title">Arabic Skills</div>
            <div class="select-wrapper">
              <app-flat-select
                  [selectOptions]="model.studentForm.arabicSkillList"
                  [(ngModel)]="selectedArabicSkill.value"
                  [ngModelOptions]="{ standalone: true }"
                  [defaultLabel]="'Select'"
                  [(value)]="selectedArabicSkill.value"
                  (selectOptionChange)="setArabicSkill($event)"
                  ngDefaultControl
                ></app-flat-select>
            </div>
          </div>
          <div class="form-field" *ngIf="selectedQuranSkill">
            <div class="form-field-title">Quran Skills</div>
            <div class="select-wrapper">
              <app-flat-select
                  [selectOptions]="model.studentForm.quranSkillList"
                  [(ngModel)]="selectedQuranSkill.value"
                  [ngModelOptions]="{ standalone: true }"
                  [defaultLabel]="'Select'"
                  [(value)]="selectedQuranSkill.value"
                  (selectOptionChange)="setQuranSkill($event)"
                  ngDefaultControl
                ></app-flat-select>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <div class="form-btn">
            <button
            mat-flat-button
            class="btn btn-secondary-blue btn-save"
            (click)="updateStudent()"
            [disabled]="disableUpdateBtn"
          >
         Update
          </button>
          </div>
        </div>

      <div class="sub-header-container pad-top">
        <div class="title">Parent Information</div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Parent Name</div>
          <div class="input-wrapper small">
            <label class="label-rounded" id="parentName">{{model.studentDetails.parentName}}</label>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Parent Email</div>
          <div class="input-wrapper small">
            <label class="label-rounded" id="parentEmail">{{model.studentDetails.parentEmail}}</label>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Parent Phone</div>
          <div class="input-wrapper small">
            <label class="label-rounded" id="parentPhone">{{model.studentDetails.parentPhone}}</label>
          </div>
        </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Spouse Name</div>
          <div class="input-wrapper small">
            <label class="label-rounded"id="spouseName">{{model.studentDetails.spouseName}}</label>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Spouse Email</div>
          <div class="input-wrapper small">
            <label class="label-rounded"id="spouseEmail">{{model.studentDetails.spouseEmail}}</label>

          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Spouse Phone</div>
          <div class="input-wrapper small">
            <label class="label-rounded" id="spousePhone">{{model.studentDetails.spousePhone}}</label>
          </div>
        </div>
      </div>
      <div class="form-group value-group">
        <div class="form-field">
          <div class="form-field-title">Primary Contact</div>
          <div class="input-wrapper x-small">
            <label class="label-rounded" id="primaryContact">{{model.studentDetails.primaryContact}}</label>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Is Teacher</div>
          <div class="input-wrapper x-small">
            <label class="label-rounded" id="isTeacher">{{model.studentDetails.isTeacher}}</label>
          </div>

        </div>
      </div>
      <div *ngIf="model.studentDetails.activeSchoolKidReg">
        <div class="sub-header-container pad-top" >
          <div class="title">Registration Information</div>
        </div>
        <div class="form-group value-group">
          <div class="form-field">
            <div class="form-field-title">Semester</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded" id="Semester">{{model.studentDetails.activeSchoolKidReg.semester}}</label>
            </div>
          </div>
          <div class="form-field">
            <div class="form-field-title">Registration Date</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded" id="registrationDate">{{model.studentDetails.activeSchoolKidReg.registrationDate}}</label>
            </div>

          </div>
          <div class="form-field">
            <div class="form-field-title">Status</div>
            <div class="input-wrapper x-small">
              <label class="label-rounded" id="activeRegStatus" [ngClass] ="{'label-navejo': model.studentDetails.activeSchoolKidReg.status === 'Expired'}" >{{model.studentDetails.activeSchoolKidReg.status}}</label>
            </div>

          </div>
        </div>
        <div class="page-table-container" *ngIf="dataSourceActive">
          <div class="sub-header-container" >
            <div class="info">Payments for Semester {{model.studentDetails.activeSchoolKidReg.semester}}</div>
          </div>
          <table mat-table [dataSource]="dataSourceActive">
            <ng-container matColumnDef="schoolFee">
              <th class="page-table-header" mat-header-cell *matHeaderCellDef>
                School Fee
              </th>
              <td mat-cell *matCellDef="let item">
                {{ item.schoolFee }}
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th class="page-table-header" mat-header-cell *matHeaderCellDef>
               Amount
              </th>
              <td mat-cell *matCellDef="let item">
                {{ item.amount | currency}}
              </td>
            </ng-container>
            <ng-container matColumnDef="paymentDate">
              <th class="page-table-header" mat-header-cell *matHeaderCellDef>
               Date
              </th>
              <td mat-cell *matCellDef="let item">
                {{ item.paymentDate }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="row.rowClass"
            ></tr>
          </table>
        </div>
        <div *ngIf="model.studentDetails.pastSchoolKidRegs && model.studentDetails.pastSchoolKidRegs.length > 0">
          <div class="sub-header-container pad-top" >
            <div class="title">Past Registrations</div>
          </div>
          <div *ngFor="let item of model.studentDetails.pastSchoolKidRegs; let i = index">
            <div class="form-group value-group">
              <div class="form-field">
                <div class="form-field-title">Semester</div>
                <div class="input-wrapper x-small">
                  <label class="label-rounded" id="pastSemester_"{i}>{{item.semester}}</label>
                </div>
              </div>
              <div class="form-field">
                <div class="form-field-title">Registration Date</div>
                <div class="input-wrapper x-small">
                  <label class="label-rounded" id="registrationDate_"{i}>{{item.registrationDate}}</label>
                </div>

              </div>
              <div class="form-field">
                <div class="form-field-title">Status</div>
                <div class="input-wrapper x-small">
                  <label class="label-rounded" id="status_"{i}>{{item.status}}</label>
                </div>
              </div>
            </div>
            <div class="page-table-container" *ngIf="item.payments">
              <div class="sub-header-container" >
                <div class="info">Payments for Semester {{item.semester}}</div>
              </div>
              <table mat-table [dataSource]="item.payments">
                <ng-container matColumnDef="schoolFee">
                  <th class="page-table-header" mat-header-cell *matHeaderCellDef>
                    School Fee
                  </th>
                  <td mat-cell *matCellDef="let p">
                    {{ p.schoolFee }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                  <th class="page-table-header" mat-header-cell *matHeaderCellDef>
                   Amount
                  </th>
                  <td mat-cell *matCellDef="let p">
                    {{ p.amount | currency}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="paymentDate">
                  <th class="page-table-header" mat-header-cell *matHeaderCellDef>
                   Date
                  </th>
                  <td mat-cell *matCellDef="let p">
                    {{ p.paymentDate }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="row.rowClass"
                ></tr>
              </table>

          </div>
        </div>
        </div>

      </div>
    </div>
    <ng-template #loading>
      <div class="table-skeleton">
      </div>
    </ng-template>

