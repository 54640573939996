import { UserService } from './../../services/user.service';
import { ApplicationUser } from './../../models/application-user';
import { Component, HostListener, OnInit } from '@angular/core';
import { UserDisplayValues } from 'src/app/models/user-display-values';
import { UserListResponse } from 'src/app/models/user-list-response';
import { UserFilters } from 'src/app/models/user-filters';
import { ListRequest } from 'src/app/models/list-request';
import { NgxSpinnerService } from 'ngx-spinner';
import { faSearch, faTimes, faTimesCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { HelperService } from 'src/app/services/helper.service';
import { SelectListItem } from 'src/app/models/select-list-item';
import { UserValidation } from 'src/app/models/user-validation';
import { ValidationError } from 'src/app/models/validation-error';

@Component({
  standalone: false,
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['name', 'userName', 'role'];
  dataSource: UserDisplayValues[];
  userListResponse: UserListResponse;
  userList: ApplicationUser[];
  filters: UserFilters;
  listRequest: ListRequest;
  itemsPerPage = 15;
  pageNumber: number;
  pageNumberWithFilter: number;
  totalRows = 0;
  usersLoaded: Promise<boolean>;
  notLoading = true;
  moreToLoad = true;
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faDownload = faDownload;
  query: string;
  showSummary = false;
  hideSummary = false;
  summaryUserId: string;
  summaryName: string;
  summaryUserName: string;
  summaryUser: ApplicationUser;
  roles: SelectListItem[];

  constructor(private userService: UserService, private spinner: NgxSpinnerService ) { }

  ngOnInit() {
    this.setListRequest();
    this.getUsers();
    // this.getLists();
  }

  setListRequest() {
    if (!this.filters) {
      this.filters = {
        query: null
      };
    }
    this.listRequest = {
      filter: this.filters,
      itemsPerPage: !this.itemsPerPage ? null : this.itemsPerPage,
      pageNumber: !this.pageNumber ? 1 : this.pageNumber
    };
  }

  getUsers() {
    this.usersLoaded = Promise.resolve(false);
    this.notLoading = false;
    this.userService.userList(this.listRequest).subscribe(data => {
      this.userListResponse = Object.assign(data);
      this.userList = Object.assign([], this.userListResponse.items);
      this.totalRows = this.userListResponse.totalCount;
      this.pageNumber = this.userListResponse.request.pageNumber;
      this.setDataSource(this.userList);
      this.notLoading = true;
      this.usersLoaded = Promise.resolve(true);
    });

  }

  setDataSource(users: ApplicationUser[]) {
    let dataSource: UserDisplayValues[] = [];
    dataSource = users.map(value => ({
      id: value.id,
      memberId: value.memberId,
      name: `${value.firstName} ${value.lastName}`,
      userName: value.userName,
      email: value.email,
      role: value.role
    }));
    this.dataSource = !this.filters.query && this.dataSource && this.dataSource.length > 0 ?
      this.dataSource.concat(dataSource) : dataSource;
  }

  onScroll() {
    if (this.notLoading) {
      this.incrementStartIndex();
      if (this.moreToLoad) {
        this.notLoading = false;
        this.loadMoreUsers();
      }
    }
  }

  incrementStartIndex() {
    const recordsFetched = this.listRequest.pageNumber * this.listRequest.itemsPerPage;
    this.pageNumber += 1;
    this.listRequest.pageNumber = this.pageNumber;
    if (recordsFetched >= this.totalRows) {
      this.moreToLoad = false;
    }
  }

  resetScroll() {
    this.moreToLoad = true;
    this.pageNumber = 1;
  }


  loadMoreUsers() {
    this.spinner.show();
    let moreUsers: ApplicationUser[] = [];
    this.userService.userList(this.listRequest).subscribe(data => {
      const response = Object.assign(data);
      moreUsers = Object.assign([], response.items);
      this.userList = this.userList.concat(moreUsers);
      this.pageNumber = response.request.pageNumber;
      this.setDataSource(moreUsers);
      this.usersLoaded = Promise.resolve(true);
      this.spinner.hide();
      this.notLoading = true;
    });
  }

  userSearch(event: any) {
    if (event.keyCode) {
      if (!this.query || event.keyCode === 27) {
        this.clearSearch();
        return;
      }
      if (event.keyCode !== 13) {
        return false;
      }
    }
    this.filters.query = this.query.trim();
    this.listRequest.pageNumber = 1;
    if (!this.filters.query) {
      this.dataSource = [];
    }
    this.getUsers();
  }

  clearSearch() {
    this.filters.query = null;
    this.query = null;
    this.listRequest.pageNumber = 1;
    this.dataSource = [];
    this.getUsers();
  }

  showUserSummary(id: string, name: string) {
    this.summaryUserId = id;
    const user = this.userList.find(x => x.id === id);
    this.summaryName = name;
    this.summaryUserName = user.userName;
    this.getSummaryUser();
    this.showSummary = true;
    this.hideSummary = false;
  }

  hideUserSummary() {
    this.showSummary = false;
    this.hideSummary = true;
    this.summaryUserId = null;
    this.summaryUserName = null;
    this.summaryName = null;
  }

  getSummaryUser() {
    this.userService.userGet(this.summaryUserId).subscribe(data => {
      this.summaryUser = Object.assign({}, data);
      this.summaryUser.validation = new UserValidation(new ValidationError(false),
        new ValidationError(false), false);

    });
  }

  userUpdated(userToUpdate: ApplicationUser) {
    const name = `${userToUpdate.firstName} ${userToUpdate.lastName}`;
    this.summaryName = name;
    this.summaryUserName = userToUpdate.userName;
    let user = this.userList.find(x => x.id === userToUpdate.id);
    if (user) {
      user = userToUpdate;
    }
    const displayValue = this.dataSource.find(x => x.id === userToUpdate.id);
    if (displayValue) {
      displayValue.name = name;
      displayValue.role = userToUpdate.role;
    }
  }

}
