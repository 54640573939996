<div class="footer-container">
  <div class="row footer-row">
    <div class="row footer-header row-divider">
      Islamic Society of Greater Lafayette (ISGL)
    </div>
    <div class="row spacer">
      1022 1st St. (Corner of Russell St. and 1st St.)
    </div>
    <div class="row">West Lafayette, IN 47906</div>
    <div class="row footer-info">
      Phone: 765-233-2878
      <span class="email-text"> Email: admin&#64;masjidisgl.org</span>
      <span class="social-media">
        <a
          target="_blank"
          href="https://www.facebook.com/groups/isglmasjid"
          class="facebook-link"
        >
          <mat-icon class="icon-facebook">facebook</mat-icon>
        </a>
      </span>
    </div>
  </div>
</div>
