<div class ="school-kids-container">
  <div class="sub-header-container">
    <div class="title">{{titleText}}</div>
  </div>
  <div class = "school-kids-table">
    <div class="table-container">
      <table mat-table [dataSource]="model.schoolKids" *ngIf="model.schoolKids && model.schoolKids.length > 0">
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.kid.firstName }} {{item.kid.lastName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="level">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Level
          </th>
          <td mat-cell *matCellDef="let item">
            <span *ngIf="item.kidLevel && item.kidLevel.name">{{ item.kidLevel.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let item">
            <div class="action-btn-container">
              <div class="btn-container" matTooltip="Edit Child">
                <div class="btn-icon" (click)="onEditKid($event, item)" [ngClass]="{ 'disable-icon': disableEdit }">
                  <i class="material-icons action-icon edit-icon" (click)="onEditKid($event, item)" >edit</i>
                </div>
              </div>
              <div class="btn-container" matTooltip="Delete Child">
                <div class="btn-icon" (click)="openDeleteAlert($event, item)">
                  <i class="material-icons action-icon delete-icon" (click)="openDeleteAlert($event, item)">delete</i>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="row.rowClass"
        ></tr>
      </table>
    </div>
  </div>
  <div class="fab-container" *ngIf = "!viewOnly" [ngClass]="{'extra-top': adminView}">
    <button
      mat-mini-fab
      class="btn-secondary-blue-gradient"
      [disabled]="disableAdd"
      (click) ="clickAddKid()"
    >
      <div class="label">ADD CHILD</div>
    </button>
  </div>
  <div *ngIf="model.schoolParentId && !viewOnly">
      <app-ieb-kid [model]="newKid" [genderList]="model.genderList"
      [arabicSkills]="model.arabicSkillList" [quranSkills]="model.quranSkillList" [levels]="model.levels"
      [levelList]="model.levelList"
      [memberId] ="model.memberId"
      (addKid) = "addNewKid($event)"
      (editKid) = "editKid($event)"
      (cancelKid) = "onCancelNewKid($event)"
      ></app-ieb-kid>
  </div>


</div>

