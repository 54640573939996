import { RegRequest } from './../../models/school/reg-request';
import { SelectListItem } from 'src/app/models/select-list-item';
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { RegModel } from 'src/app/models/school/reg-model';
import * as _moment from "moment";
import { Fee } from 'src/app/models/school/fee';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolPaymentCustom } from 'src/app/models/school/school-payment-custom';
const moment = _moment;

@Component({
  standalone: false,
  selector: 'app-ieb-admin-reg-payment',
  templateUrl: './ieb-admin-reg-payment.component.html',
  styleUrls: ['./ieb-admin-reg-payment.component.scss']
})
export class IebAdminRegPaymentComponent implements OnInit, OnChanges {
  @Input() model!: RegModel;
  @Output() showLoading = new EventEmitter<boolean>();
  @Output() addNewReg = new EventEmitter<RegRequest>();
  regPayForm!: FormGroup;
  selectedRegFee: SelectListItem;
  selectedPreschoolRegFee: SelectListItem;
  regFee: Fee;
  preschoolRegFee: Fee;
  totalRegFee: number;
  totalPreschoolRegFee: number;
  totalRegFeeDue: number;
  materialFee: Fee;
  totalMaterialFee: number;
  lateFee: Fee;
  totalLateFee: number;
  kidsNumber: number;

  hasMaterialFee: boolean;
  hasLateFee: boolean;
  workCheck: boolean;
  parentsInitials: string;
  donationCheck: boolean;
  submitBtnText: string;
  paymentAmount: number;
  disableBtn = false;
  subheader = '';
  reducedFeeChildCount = 0;
  reducedFeePreschoolChildCount = 0;
  reducedFeeAmount = 0;
  reducedFeeAmountPreschool = 0;
  totalReducedFeeAmount = 0;
  reducedFeeText = '';
  preschoolReducedFeeText = '';
  schoolKidsCount: number;
  totalSchoolKidsCount: number;
  totalPreschoolKidsCount: number;
  preschoolKidsCount: number;
  selectedPaymentMode: SelectListItem;
  paymentId: string;
  paymentIdReadOnly : boolean;
  paymentType: string;
  amountPaid: number;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.regPayForm = this.formBuilder.group({
      paymentId: ['', [Validators.required]],
      amountPaid: ['', [Validators.required]]
     });
  }
  ngOnChanges(): void {
    if(this.model) {
      this.selectedRegFee = this.model.schoolFeeList.find((f)=> f.label.includes('Semester'));
      this.selectedPreschoolRegFee = this.model.hasPreschool ?
              this.model.preschoolFeeList.find((f)=> f.label.includes('Semester')) : null;
      this.selectedPaymentMode = this.model.paymentModes[0];
      this.paymentType = this.selectedPaymentMode.value;
      if(this.model.kidsFees && this.model.kidsFees.length > 0) {
        let rKids = this.model.kidsFees.filter(k=> k.hasSemesterFee && !k.hasReducedFee && !k.isPreschool);
        this.schoolKidsCount = rKids && rKids.length > 0 ? rKids.length : 0;
        let preschoolKids = this.model.kidsFees.filter(k=> k.hasSemesterFee && !k.hasReducedFee && k.isPreschool);
        this.preschoolKidsCount = preschoolKids && preschoolKids.length > 0 ? preschoolKids.length : 0;
        let redFeeKids = this.model.kidsFees.filter(k =>  k.hasReducedFee && !k.isPreschool);
        this.reducedFeeChildCount = redFeeKids && redFeeKids.length > 0 ? redFeeKids.length : 0;

        let preschoolRedFeeKids = this.model.kidsFees.filter(k =>  k.hasReducedFee && k.isPreschool);
        this.reducedFeePreschoolChildCount = preschoolRedFeeKids && preschoolRedFeeKids.length > 0 ? preschoolRedFeeKids.length : 0;
        this.totalSchoolKidsCount = this.schoolKidsCount +  this.reducedFeeChildCount;
        this.totalPreschoolKidsCount = this.preschoolKidsCount + this.reducedFeePreschoolChildCount;

        this.kidsNumber = this.model.schoolKids && this.model.schoolKids.length > 0 ? this.model.schoolKids.length : 0;
        this.subheader = this.kidsNumber > 1 ? `${this.kidsNumber} Children` : `${this.kidsNumber} Child`;
        this.regFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedRegFee.value);
        this.preschoolRegFee = this.selectedPreschoolRegFee ?
                this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedPreschoolRegFee.value) : null;
        this.reducedFeeAmount = this.reducedFeeChildCount ? this.regFee.amount * 50/100 : 0;
        this.reducedFeeAmountPreschool = this.reducedFeePreschoolChildCount ? this.preschoolRegFee.amount * 50/100 : 0;
        let reduceFeeTotal = 0;
        let reduceFeePreschoolTotal = 0;
        if(!this.model.isTeacher) {
          if(this.reducedFeeAmount) {
             reduceFeeTotal = this.reducedFeeAmount * this.reducedFeeChildCount;
              var verb = this.reducedFeeChildCount > 1 ? 'children are' : 'child is';
              let redFee = this.model.schoolFees.find((f)=> f.type == 'Reduced 50%');
              this.reducedFeeText = `${this.reducedFeeChildCount} ${verb} accepted for ${redFee.name}`;
              this.subheader = this.subheader + ' ('+this.reducedFeeText+')';
            }
            if(this.reducedFeeAmountPreschool) {
              reduceFeePreschoolTotal = this.reducedFeeAmountPreschool * this.reducedFeePreschoolChildCount;
              var verbPreschool = this.reducedFeePreschoolChildCount > 1 ? 'children are' : 'child is';
              let redFeePreschool = this.model.schoolFees.find((f)=> f.type == 'Reduced 50%');
              this.preschoolReducedFeeText = `${this.reducedFeePreschoolChildCount} ${verbPreschool} accepted for ${redFeePreschool.name}`;
            }
          let regFeeDue =  rKids && rKids.length > 0 ? this.regFee.amount * rKids.length : 0;
          let regFeeReducedDue = redFeeKids && redFeeKids.length > 0 ? this.reducedFeeAmount * this.reducedFeeChildCount : 0;

          this.totalRegFee = regFeeDue + regFeeReducedDue;

          let preschoolRegFeeDue = preschoolKids && preschoolKids.length > 0 ? this.preschoolRegFee.amount * preschoolKids.length : 0;
          let preschoolRegFeeReducedDue = this.reducedFeePreschoolChildCount ? this.reducedFeeAmountPreschool * this.reducedFeePreschoolChildCount : 0;

          this.totalPreschoolRegFee = preschoolRegFeeDue + preschoolRegFeeReducedDue;
          this.totalRegFeeDue = this.totalPreschoolRegFee + this.totalRegFee;
          this.amountPaid = this.model.amountDue;
        } else {
          this.totalRegFee = 0;
          this.totalPreschoolRegFee = 0;
          this.totalRegFeeDue = 0;
        }

        this.materialFee = this.model.schoolFees.find((f)=> f.type === 'Material');
        let mKids = this.model.kidsFees.filter(k=> k.hasMaterialFee);
        this.totalMaterialFee = mKids && mKids.length > 0 ? this.materialFee.amount * mKids.length : 0;
        this.hasMaterialFee = mKids && mKids.length > 0;
        this.lateFee = this.model.schoolFees.find((f)=> f.type === 'Late');
        let lKids = this.model.kidsFees.filter(k=> k.hasLateFee);
        this.totalLateFee = lKids && lKids.length > 0 ? this.lateFee.amount * lKids.length : 0;
        this.hasLateFee = lKids && lKids.length > 0;

      }

      this.setSubmitBtnText();
    }

  }

  setRegFee(feeTypeId: number) {
    this.selectedRegFee = this.model.schoolFeeList.find((f)=> f.value === feeTypeId);
    this.regFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedRegFee.value);
    let reducedFee = 0;

    if(this.reducedFeeChildCount) {
      reducedFee = this.regFee .amount * 50/100 * this.reducedFeeChildCount;
    }

    if(!this.model.isTeacher) {
      this.totalRegFee = this.regFee.amount * this.schoolKidsCount + reducedFee *this.reducedFeeChildCount;
      this.model.amountDue = parseFloat((this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
      this.amountPaid = this.model.amountDue;
      this.setSubmitBtnText();
    }
  }

  setPreschoolRegFee(feeTypeId: number) {
    this.selectedPreschoolRegFee = this.model.preschoolFeeList.find((f)=> f.value === feeTypeId);
    this.preschoolRegFee = this.model.schoolFees.find((f)=> f.schoolFeeTypeId === this.selectedPreschoolRegFee.value);
    let reducedFee = 0;

    if(this.reducedFeePreschoolChildCount) {
      reducedFee = this.preschoolRegFee .amount * 50/100 * this.reducedFeePreschoolChildCount;
    }

    if(!this.model.isTeacher) {
      this.totalPreschoolRegFee = this.preschoolRegFee.amount * this.preschoolKidsCount + reducedFee;
      this.model.amountDue = parseFloat(( this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
      this.amountPaid = this.model.amountDue;
      this.setSubmitBtnText();
    }
  }


  setDonationTotal(event: any) {
    this.model.donationAmountTotal = !this.model.donationAmount || this.model.donationAmount === 0 ? 0 :
      this.kidsNumber * this.model.donationAmount;
    this.model.donationAmountTotal = Number.isNaN(this.model.donationAmountTotal) ? 0 : this.model.donationAmountTotal;
    this.model.amountDue = parseFloat((this.totalRegFee + this.totalPreschoolRegFee + this.totalMaterialFee + this.totalLateFee + this.model.donationAmountTotal).toFixed(2));
    this.model.amountDue = Number.isNaN(this.model.amountDue) ? 0 : this.model.amountDue;
    this.amountPaid = this.model.amountDue;
    this.setSubmitBtnText();

  }

  setPaymentId(mode: string) {
    this.paymentType = mode;
    if(mode === 'Cash') {
      this.paymentId = 'Cash Payment'
      this.paymentIdReadOnly = true;
    } else {
      this.paymentIdReadOnly = false;
    }
  }
  onFocusOutEvent(event: any) {
    this.setSubmitBtnText();
  }

  setSubmitBtnText() {
    this.submitBtnText = this.amountPaid > 0 ? `Pay $${this.amountPaid} & Register` : 'Register';
  }

  submit(){
    this.disableBtn = true;
    this.saveRegistration();
    }



    saveRegistration(){
      let request = this.setRequest();
      this.addNewReg.emit(request);
    }

    setRequest(): RegRequest {
      let schoolFeeIds = [];
        if(this.selectedRegFee) {
          schoolFeeIds.push(this.selectedRegFee.value);
        }
        if(this.selectedPreschoolRegFee) {
          schoolFeeIds.push(this.selectedPreschoolRegFee.value);
        }
      return {
        id: null,
        parentId: this.model.schoolParentId,
        parentsInitials: '',
        semester: this.model.semester,
        startYear: moment(this.regFee.validFrom).clone().local().year(),
        endYear: moment(this.regFee.validTill).clone().local().year(),
        isTeacher: this.model.isTeacher,
        donationAmount: this.model.donationAmountTotal === 0 ? null : this.model.donationAmountTotal,
        materialFeeAmount: this.totalMaterialFee === 0 ? null : this.totalMaterialFee,
        lateFeeAmount: this.totalLateFee === 0 ? null : this.totalLateFee,
        customPayment: {
          paymentId : this.paymentId,
          type: this.paymentType,
          amountDue: this.model.amountDue,
          amountPaid: this.amountPaid,
          schoolFeeTypeIds : schoolFeeIds
        },
        schoolKidsIds: this.model.schoolKids.map((k) => k.id)
      };
    }


}
