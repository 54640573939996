import { RegRequest } from './../../models/school/reg-request';
import { KidRequest } from './../../models/school/kid-request';
import { SecurityService } from 'src/app/services/security.service';
import { IebParentService } from './../../services/ieb-parent.service';
import { ParentRequest } from './../../models/school/parent-request';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IebRegisterService } from 'src/app/services/ieb-register.service';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { IebKidService } from 'src/app/services/ieb-kid.service';
import * as _moment from "moment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { KidInfo } from 'src/app/models/school/kid-info';
import { LoadService } from 'src/app/services/load.service';
import { NgxSpinnerService } from 'ngx-spinner';

const moment = _moment;
@Component({
  standalone: false,
  selector: 'app-ieb-registration',
  templateUrl: './ieb-registration.component.html',
  styleUrls: ['./ieb-registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebRegistrationComponent implements OnInit {
@ViewChild('stepper') private stepper: MatStepper;
activeParent: boolean = true;
activeKids: boolean;
activeSave: boolean;
headerTitle: string;
regCloseText: string;
//load = false;
//schoolRegVM$ = this.registerService.regModel$;

  schoolRegVM$ = combineLatest([
    this.registerService.regModel$,
     this.parentService.parentModel$,
     this.kidService.kidsModel$,
     this.registerService.regFormSource$
  ]).pipe(
    map(([reg, parent, kids, regForm]) => {
      return {
        reg,
        parent,
        kids,
        regForm,
        parentCompleted: parent.schoolParentInfo.id ? true : false,
        kidsCompleted: this.isKidsStepValid(kids.schoolKids),//kids.schoolKids && kids.schoolKids.length > 0 && kids.schoolKids[0].id > 0  ? true : false,
        regCompleted: reg.id ? true : false,
        goToKids: this.goForward(parent.schoolParentInfo.id)

      }
    })
  );
  confirm$ = this.confirmService.confirmation$.pipe(
    map((confirm) => {
      if(confirm.toggle) {
        //this.load = false;
        this.showConfirmationDialog(confirm.message, confirm.type);
      }

    })
  );
  load$ = this.loadService.load$.pipe(
    map((load) => {
      if(load) {
        //this.load = false;
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
      return load;
    })
  );

  constructor(private securityService: SecurityService, private router: Router,
    private registerService: IebRegisterService, private parentService: IebParentService,
    private kidService: IebKidService, private snackBar: MatSnackBar,
    private confirmService: ConfirmationService, private loadService : LoadService,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    this.securityService.isSignedIn$.subscribe((res) => {
      if(!res) {
        this.router.navigate(["/login"]);
      }
    });
    let dt = moment().clone().local();
    let dtMonth = dt.month() +1;
    let dtYear = dt.year();
    let semester = dtMonth > 5 ? 'Fall' : 'Spring'
    this.headerTitle = `Sunday School Registration ${semester} ${dtYear}-${dtYear+1}`;
    this.regCloseText = `Sunday School Registration for ${semester} ${dtYear} is closed. Please register next semester.`

  }


  goForward(schoolParentId: number) {
    if(this.stepper && this.stepper.selectedIndex === 0 && schoolParentId) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }

  }

  onSaveParent(saveRequest: ParentRequest){
    this.parentService.newParentAdd(saveRequest);
  }

  onUpdateParent(updateRequest: ParentRequest){
    this.parentService.parentUpdate(updateRequest);
  }



  onAddNewKid(newKid: KidRequest) {
    this.kidService.newKidAdd(newKid);
  }

  onUpdateNewKid(editKid: KidRequest) {
    this.kidService.kidUpdate(editKid);
  }

  onDeleteKid(deleteKid: KidRequest) {
    this.kidService.kidDelete(deleteKid);
  }



  onAddNewReg(newReg: RegRequest) {
    this.registerService.newRegAdd(newReg);
  }

  showConfirmationDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }

  isKidsStepValid(schoolKids: KidInfo[]): boolean {
    var isValid = false;
    if(schoolKids == null ) {
      return isValid;
    }
    if(schoolKids.length == 0) {
      return isValid;
    }
    if(schoolKids.some(s=> s.id == 0)) {
      return isValid;
    }

    if(schoolKids.some(s=> s.kidLevel == null)) {
      return isValid;
    }
    return true;

  }


}
