import { Lease } from "src/app/models/lease";
import { TenantInfo } from "src/app/models/tenant-info";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { RoomInfo } from "src/app/models/room-info";
import { RoomService } from "src/app/services/room.service";
import { SecurityService } from "src/app/services/security.service";
import { TenantService } from "src/app/services/tenant.service";
import * as _moment from "moment";
const moment = _moment;
@Component({
  standalone: false,
  selector: "app-room-card",
  templateUrl: "./room-card.component.html",
  styleUrls: ["./room-card.component.scss"],
})
export class RoomCardComponent implements OnInit, OnChanges {
  @Input() room: RoomInfo;
  occupancy: string;
  roomStatusText: string;
  roomStatusToolTip: string;
  roomBtnText: string;
  signedIn$: Observable<boolean>;
  memberId: number;
  tenant: TenantInfo;
  genderText: string;
  constructor(
    private roomService: RoomService,
    private router: Router,
    private securityService: SecurityService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
      }
    });
  }

  ngOnChanges() {
    if (this.room) {
      this.occupancy =
        this.room.occupancy === 1 ? "Single Occupancy" : "Double Occupancy";
      this.roomStatusText = this.room.isAvailable ? "Available" : "Leased";
      this.roomStatusToolTip = `Available from ${moment(this.room.availableDate)
        .clone()
        .local()
        .format("MM-DD-YYYY")}`;
      this.roomBtnText = "Book Request";
      this.genderText = this.room.name.toLowerCase().startsWith("f")
        ? "(females only)"
        : "(males only)";
    }
  }

  bookRoom() {
    this.roomService.selectedRoom = this.room;
    this.signedInUser();
  }

  signedInUser() {
    this.signedIn$ = this.securityService.isSignedIn();
    this.signedIn$.subscribe((result) => {
      if (result) {
        this.memberId = this.securityService.getMemberId();
        if (this.memberId) {
          // this.tenantService
          //   .tenantGetByMember(this.memberId)
          //   .subscribe((data) => {
          //     this.tenant = Object.assign({}, data);
          //     if (this.tenant) {
          //       if (this.tenant.leaseInfo) {
          //         if (this.tenant.leaseInfo.statusId > 2) {
          //           this.router.navigate([`/tenant`]);
          //         } else if (this.tenant.leaseInfo.statusId <= 2) {
          //           this.router.navigate(["/leaseRequest"]);
          //         }
          //       } else {
          //         this.router.navigate(["/bookRoom"]);
          //       }
          //     } else {
          //       this.router.navigate(["/bookRoom"]);
          //     }
          //   });
          this.tenantService
            .determineRedirect(this.memberId, "bookRoom")
            .subscribe((redirect) => {
              if (redirect) {
                if (redirect === "waitList") {
                  redirect = "/waitList/add";
                }
                this.router.navigate([redirect]);
              } else {
                this.router.navigate(["/bookRoom"]);
              }
            });
        }
      } else {
        this.router.navigate(["/login"], {
          queryParams: { returnUrl: "rooms" },
        });
      }
    });
  }
}
