<div class="tenant-payments-container">
  <div class="tenant-payments-container-row">
    <div class="payments-container">
      <div class="payments-section">
        <div class="payments-section-wrapper">
          <div class="payments-wrapper head">
            <div class="payments-item-container">
              <div class="payments-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label head">Details</span>
                </div>
              </div>
            </div>
            <div class="payments-item-container">
              <div class="payments-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label head">Rent</span>
                </div>
              </div>
            </div>
            <div class="payments-item-container">
              <div class="payments-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label head">Paid</span>
                </div>
              </div>
            </div>
            <div class="payments-item-container">
              <div class="payments-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label head">Date</span>
                </div>
              </div>
            </div>
            <div class="payments-item-container">
              <div class="payments-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label head">Balance</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let group of model.groupedPayments">
            <div class="payments-wrapper less-pad">
              <div class="subheader-text">{{ group.monthYear }}</div>
              <div class="group-message" *ngIf="group.monthlyAmountDue > 0">
                Due: ${{ group.monthlyAmountDue }} ({{ group.message }})
              </div>
            </div>
            <div *ngFor="let p of group.payments; let i = index">
              <div
                class="payments-wrapper"
                [ngClass]="
                  (p.amountDue > 0 || p.paymentType === 'LRNT') && i % 2 === 0
                    ? 'balance'
                    : (p.amountDue > 0 || p.paymentType === 'LRNT') &&
                      i % 2 === 1
                    ? 'balance odd'
                    : (p.amountDue > 0 || p.paymentType !== 'LRNT') &&
                      i % 2 === 0
                    ? 'no-balance'
                    : 'no-balance odd'
                "
              >
                <div class="payments-item-container">
                  <div class="payments-info-item">
                    <div class="item-label-wrapper">
                      <span class="item-label show-mob"> Description: </span>
                      <span
                        class="item-label"
                        [ngClass]="{
                          late: p.paymentType === 'LRNT',
                          balance: p.amountDue > 0
                        }"
                        >{{ p.paymentTypeDescription }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="payments-item-container">
                  <div class="payments-info-item">
                    <div class="item-label-wrapper">
                      <span class="item-label show-mob"> Rent: </span>
                      <span
                        class="item-label"
                        [ngClass]="{
                          late: p.paymentType === 'LRNT',
                          balance: p.amountDue > 0
                        }"
                        >${{ p.rentAmount }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="payments-item-container">
                  <div class="payments-info-item">
                    <div class="item-label-wrapper">
                      <span class="item-label show-mob"> Paid: </span>
                      <span
                        class="item-label"
                        [ngClass]="{
                          late: p.paymentType === 'LRNT',
                          balance: p.amountDue > 0
                        }"
                        >${{ p.amountPaid }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="payments-item-container">
                  <div class="payments-info-item">
                    <div class="item-label-wrapper">
                      <span class="item-label show-mob"> Date: </span>
                      <span
                        class="item-label"
                        [ngClass]="{
                          late: p.paymentType === 'LRNT',
                          balance: p.amountDue > 0
                        }"
                        >{{ p.paymentDateDisplay }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="payments-item-container">
                  <div class="payments-info-item">
                    <div class="item-label-wrapper">
                      <span class="item-label show-mob"> Balance: </span>
                      <span
                        class="item-label"
                        [ngClass]="{
                          late: p.paymentType === 'LRNT',
                          balance: p.amountDue > 0
                        }"
                        >${{ p.amountDue
                        }}<span *ngIf="p.amountDue < 0"> (credit) </span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="payments-wrapper"
            *ngIf="!model.groupedPayments || model.groupedPayments.length === 0"
          >
            <div class="subheader-text">No payments found!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
