<app-header></app-header>
<div class="room-book-container">
  <div class="room-book-section">
    <div class="room-book-header-section">
      <div class="header">
        <div class="header-title">Tenant Request</div>
        <div class="amount-total" *ngIf="model">Total: {{ amountTotal }}</div>
      </div>
    </div>
    <!-- <div class="room-images-container"></div> -->
    <div class="page-row" *ngIf="model">
      <div class="row-content">
        <div>
          <app-rent-info [showRentBreakup]="showRentBreakup"></app-rent-info>
        </div>
        <div class="item-container">
          <div class="sub-header">Room-Info</div>
        </div>
        <div class="item-container left-align">
          <div class="item-select-wrapper">
            <app-flat-select
              [selectOptions]="roomList"
              [(ngModel)]="model.roomId"
              [(defaultLabel)]="defaultLabel"
              [(value)]="model.roomId"
              (selectOptionChange)="setSelectedRoom($event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div class="item-input-wrapper">
            <app-flat-input
              [placeHolder]="'Available Date'"
              [(value)]="model.roomAvailableDate"
              [(ngModel)]="model.roomAvailableDate"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
          <div class="item-input-wrapper">
            <app-flat-input
              [placeHolder]="'Room Rent'"
              [(value)]="model.rentAmount"
              [(ngModel)]="model.rentAmount"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="item-container">
          <div class="item-note-wrapper">
            <div class="item-note">
              <i class="material-icons info-icon">info</i>
              <div class="note-text">
                One month rent will be collected as refundable security deposit
                before signing the lease.
              </div>
            </div>
          </div>
        </div>
        <div class="item-container left-align">
          <div class="item-label-wrapper">
            <span class="item-label">Tentative Start Date:</span>
          </div>
          <div class="input-date date-picker">
            <input
              [matDatepicker]="datePicker"
              class="input-date"
              [(ngModel)]="model.tentativeStartDate"
              (dateInput)="datePicker.close()"
              (dateChange)="datePicker.close()"
              (focus)="datePicker.open()"
              readonly
            />
            <mat-datepicker-toggle
              class="item-date-picker"
              matSuffix
              [for]="datePicker"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </div>
        </div>
        <app-tenant-profile
          [model]="tenantProfile"
          (notifyParent)="getTenantProfile($event)"
          *ngIf="tenantProfile"
        ></app-tenant-profile>
        <div class="item-container">
          <div class="item-checkbox-wrapper">
            <div class="item-checkbox">
              <mat-checkbox
                [(ngModel)]="model.isGuarantor"
                [(checked)]="model.isGuarantor"
                (change)="onIsGuarantorChange($event)"
              >
              </mat-checkbox>
            </div>
            <div class="checkbox-text question">
              This information is required if you have a Guarantor?
            </div>
          </div>
        </div>
        <div *ngIf="model.isGuarantor">
          <app-guarantor
            [model]="guarantor"
            [referenceInfo]="model.tenantProfile"
            (notifyParent)="getGuarantor($event)"
          ></app-guarantor>
        </div>
        <div *ngIf="showParking">
          <div class="item-container">
            <div class="item-checkbox-wrapper">
              <div class="item-checkbox">
                <mat-checkbox
                  [(ngModel)]="model.isParkingSpace"
                  [(checked)]="model.isParkingSpace"
                  (change)="onCheckChange($event)"
                >
                </mat-checkbox>
              </div>
              <div class="checkbox-text question">
                Do you need parking space for ${{ availableParking?.amount }}
                monthly?
              </div>
            </div>
          </div>
          <div *ngIf="model.isParkingSpace">
            <app-vehicle
              [model]="vehicle"
              (notifyParent)="getVehicle($event)"
            ></app-vehicle>
          </div>
        </div>
        <div
          class="item-container"
          *ngIf="uploadedFiles && uploadedFiles.length > 0"
        >
          <div class="item-label-wrapper">
            <span class="item-label">Uploaded Files:</span>
          </div>
          <div class="item-input-wrapper">
            <app-file-list
              [uploadedFiles]="uploadedFiles"
              [showDelete]="showfileListDelete"
              [showTitle]="showfileListTitle"
            ></app-file-list>
          </div>
        </div>
        <div class="item-container">
          <div class="btn-container pad-bottom">
            <span *ngIf="uploadError" class="error-icon-container">
              <span class="error-icon"><mat-icon>error_outline</mat-icon></span>
              <span class="error-text">Required!</span>
            </span>

            <button
              mat-raised-button
              class="upload-btn"
              (click)="openUploadFileDialog()"
            >
              <mat-icon class="icon"> upload</mat-icon>
              Upload File(s)
            </button>
          </div>
          <div class="item-note-wrapper">
            <div class="item-note">
              <i class="material-icons info-icon">info</i>
              Valid passport or US driver license or Purdue Id is required.
            </div>
          </div>
        </div>

        <div class="item-container">
          <div class="btn-container">
            <button
              mat-raised-button
              class="save-btn"
              [disabled]="model.validation.hasError"
              (click)="addTenant()"
            >
              {{ btnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
