<div class="manage-component-container">
  <div class="component-container">
    <div class="component-wrapper">
      <div
        class="select-component-types"
        (click)="showTypes = true"
        [ngClass]="{ hide: showTypes }"
      >
        <div class="icon-wrapper">
          <i class="material-icons select-component-icon">filter_list</i>
        </div>
      </div>
      <div
        id="eventtypespanel"
        class="component-types"
        [ngClass]="{
          'show-component-types': showTypes,
          'hide-component-types': !showTypes
        }"
      >
        <div class="type-header-row" [ngClass]="{ show: showTypes }">
          <div class="back-icon-wrapper" (click)="showTypes = false">
            <i class="material-icons back-icon">arrow_back</i>
          </div>
        </div>

        <div
          class="component-type-row"
          (click)="typeClick('tenants'); showTypes = false"
          [ngClass]="{ 'row-active': showTenants }"
        >
          <div class="component-name" [ngClass]="{ active: showTenants }">
            Tenants
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('waitList'); showTypes = false"
          [ngClass]="{ 'row-active': showWaitList }"
        >
          <div class="component-name" [ngClass]="{ active: showWaitList }">
            Wait List
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('rents'); showTypes = false"
          [ngClass]="{ 'row-active': showRents }"
        >
          <div class="component-name" [ngClass]="{ active: showRents }">
            Rents
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('rooms'); showTypes = false"
          [ngClass]="{ 'row-active': showRooms }"
        >
          <div class="component-name" [ngClass]="{ active: showRooms }">
            Rooms
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('parkings'); showTypes = false"
          [ngClass]="{ 'row-active': showParkings }"
        >
          <div class="component-name" [ngClass]="{ active: showParkings }">
            Parking Spaces
          </div>
        </div>
      </div>
      <div class="component-content" [ngClass]="{ hide: showTypes }">
        <div *ngIf="showTenants">
          <app-tenants [hideAdd]="showTypes"></app-tenants>
        </div>
        <div *ngIf="showWaitList">
          <app-wait-list [hideAdd]="showTypes"></app-wait-list>
        </div>
        <div *ngIf="showRents">
          <app-rents-manage></app-rents-manage>
        </div>
        <div *ngIf="showRooms">
          <app-rooms-manage></app-rooms-manage>
        </div>
        <div *ngIf="showParkings">
          <app-parking-manage></app-parking-manage>
        </div>
      </div>
    </div>
  </div>
</div>
