<app-header></app-header>
<div class="manage-component-container">
  <div class="component-container">
    <div class="component-wrapper">
      <div
        class="select-component-types"
        (click)="showTypes = true"
        [ngClass]="{ hide: showTypes }"
      >
        <div class="icon-wrapper">
          <i class="material-icons select-component-icon">filter_list</i>
        </div>
      </div>
      <div
        id="eventtypespanel"
        class="component-types"
        [ngClass]="{
          'show-component-types': showTypes,
          'hide-component-types': !showTypes
        }"
      >
        <div class="type-header-row" [ngClass]="{ show: showTypes }">
          <div class="back-icon-wrapper" (click)="showTypes = false">
            <i class="material-icons back-icon">arrow_back</i>
          </div>
        </div>

        <div
          class="component-type-row"
          (click)="typeClick('Tenant'); showTypes = false"
          [ngClass]="{ 'row-active': showTenant }"
        >
          <div class="component-name" [ngClass]="{ active: showTenant }">
            <fa-icon [icon]="faHome" class="icon-buttons"></fa-icon>
            Tenant
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('Lease-Ext'); showTypes = false"
          [ngClass]="{ 'row-active': showExt }"
          *ngIf="eligible && eligible.extEligible"
        >
          <div class="component-name" [ngClass]="{ active: showExt }">
            <fa-icon [icon]="faPlusSquare" class="icon-buttons"></fa-icon>
            Lease Extension
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('Parking-Add'); showTypes = false"
          [ngClass]="{ 'row-active': showParkingAdd }"
          *ngIf="eligible && eligible.parkingEligible"
        >
          <div class="component-name" [ngClass]="{ active: showParkingAdd }">
            <fa-icon [icon]="faParking" class="icon-buttons"></fa-icon>
            Add Parking
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('Parking-Term'); showTypes = false"
          [ngClass]="{ 'row-active': showParkingTerm }"
          *ngIf="showParkingTermLink"
        >
          <div class="component-name" [ngClass]="{ active: showParkingTerm }">
            <fa-icon [icon]="faMinusSquare" class="icon-buttons"></fa-icon>
            Terminate Parking
          </div>
        </div>
        <div
          class="component-type-row"
          (click)="typeClick('Svc-Request'); showTypes = false"
          [ngClass]="{ 'row-active': showSvcRequest }"
        >
          <div class="component-name" [ngClass]="{ active: showSvcRequest }">
            <fa-icon [icon]="faWrench" class="icon-buttons"></fa-icon>
            Service Request
          </div>
        </div>
      </div>
      <div class="component-content" [ngClass]="{ hide: showTypes }">
        <div *ngIf="showTenant">
          <app-tenant
            [model]="tenant"
            (notifyParent)="rentCollected($event)"
          ></app-tenant>
        </div>
        <div *ngIf="showExt">
          <app-lease-addendum
            [model]="tenantAddendum"
            (notifyParent)="addendumNotification($event)"
          ></app-lease-addendum>
        </div>
        <div *ngIf="showParkingAdd">
          <app-lease-addendum
            [model]="tenantAddendum"
            (notifyParent)="addendumNotification($event)"
          ></app-lease-addendum>
        </div>
        <div *ngIf="showParkingTerm">
          <app-lease-addendum
            [model]="tenantAddendum"
            (notifyParent)="addendumNotification($event)"
          ></app-lease-addendum>
        </div>
        <div *ngIf="showSvcRequest">
          <app-service-request
            [member]="tenant.memberInfo"
            (notifyParent)="svcRequestSent($event)"
          ></app-service-request>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
