<div *ngIf="model as vm" class="reg-payment-container">
  <form [formGroup]="regPayForm" (ngSubmit)="submit()">
    <div class="page-form">
      <div class="sub-header-container">
        <div class="title">New Registration & Payment for {{subheader}} </div>
      </div>
      <div class="form-group value-group"  *ngIf="vm.schoolFeeList && !model.isTeacher && totalSchoolKidsCount">
        <div class="form-field">
          <div class="form-field-title">Select FeeType per Child</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="vm.schoolFeeList"
                [(ngModel)]="selectedRegFee"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="selectedRegFee.value"
                (selectOptionChange)="setRegFee($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Total Registration Fee ({{totalSchoolKidsCount}} children)</div>
          <div class="form-field-title reduced-fee" *ngIf="reducedFeeChildCount">({{reducedFeeText}})</div>
          <div class="input-wrapper x-small input-icon">
            <input type="text" class="input-rounded" inputText [(ngModel)]="totalRegFee" [value] ="totalRegFee"
            [ngModelOptions]="{ standalone: true }"
            readonly/>
            <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
          </div>
        </div>
      </div>
      <div class="form-group value-group"  *ngIf="vm.preschoolFeeList && !model.isTeacher && model.hasPreschool">
        <div class="form-field">
          <div class="form-field-title">Select FeeType per Preschool Child</div>
          <div class="select-wrapper">
            <app-flat-select
                [selectOptions]="vm.preschoolFeeList"
                [(ngModel)]="selectedPreschoolRegFee"
                [ngModelOptions]="{ standalone: true }"
                [defaultLabel]="'Select'"
                [(value)]="selectedPreschoolRegFee.value"
                (selectOptionChange)="setPreschoolRegFee($event)"
                ngDefaultControl
              ></app-flat-select>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Total Preschool Registration Fee ({{totalPreschoolKidsCount}} children)</div>
          <div class="form-field-title reduced-fee" *ngIf="reducedFeePreschoolChildCount">({{preschoolReducedFeeText}})</div>
          <div class="input-wrapper x-small input-icon">
            <input type="text" class="input-rounded" inputText [(ngModel)]="totalPreschoolRegFee" [value] ="totalPreschoolRegFee"
            [ngModelOptions]="{ standalone: true }"
            readonly/>
            <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
          </div>
        </div>
      </div>
      <div class="form-group value-group"  *ngIf="hasMaterialFee">
        <div class="form-field">
          <div class="form-field-title">Material Fee per Child</div>
          <div class="input-wrapper x-small input-icon">
            <input type="text" class="input-rounded" inputText [value] ="materialFee.amount" readonly/>
            <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-title">Total Material Fee </div>
          <div class="input-wrapper x-small input-icon">
            <input type="text" class="input-rounded" inputText [value] ="totalMaterialFee" readonly/>
            <span class="input-icon-left"><i class="material-icons">attach_money</i></span>

          </div>
        </div>
      </div>

    <div class="form-group value-group" >
      <div class="form-field">
        <div class="form-field-title">Suggested Donation per Child</div>
        <div class="input-wrapper x-small input-icon">
            <input type="text" class="input-rounded" inputText [(ngModel)] = "model.donationAmount"
            [value] ="model.donationAmount" [ngModelOptions]="{ standalone: true }"
            (ngModelChange) = "setDonationTotal($event)" appCurrency/>
            <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
          </div>
       </div>

      <div class="form-field">
        <div class="form-field-title">Total Suggested Donation </div>
        <div class="input-wrapper x-small input-icon">
          <input type="text" class="input-rounded" inputText [(ngModel)] = "model.donationAmountTotal"
          [value] ="model.donationAmountTotal" [ngModelOptions]="{ standalone: true }" readonly/>
          <span class="input-icon-left"><i class="material-icons">attach_money</i></span>
        </div>
      </div>
    </div>
    <div class="form-group" >
      <div class="form-field">
        <div class="input-wrapper x-small">
          <!-- <input type="text" class="input-rounded" value ="Total Amount Due"  readonly/> -->
        </div>
       </div>
      <div class="form-field">
        <div class="form-field-title" >Total Amount Due</div>
        <div class="input-wrapper x-small input-icon">
          <input type="text" class="input-rounded bold-text" inputText [(ngModel)] = "model.amountDue"
          [value] ="model.amountDue" [ngModelOptions]="{ standalone: true }" readonly/>
          <span class="input-icon-left icon-gold"><i class="material-icons">attach_money</i></span>
        </div>
      </div>
    </div>

    <div class="form-group value-group">
      <div class="form-field checkbox-field">
        <div class="form-field-title">Guidelines (Check box after reading, then initial)</div>
        <div class="check-box-group">
          <span>
            <mat-checkbox
              formControlName="workCb"
              [disableRipple]="true"
              class="example-margin"
              [(ngModel)]="workCheck"
            >
            It is the parent's responsibility to go through their child's school work each week,
            make sure they complete their assignments and communicate with the teacher via email as needed.
            </mat-checkbox>
          </span>
        </div>
      </div>
    </div>
    <div class="form-group value-group">
      <div class="form-field">
        <div class="form-field-title">Parents Initials</div>
        <div class="input-wrapper small">
          <input type="text" class="input-rounded" inputText formControlName="initials"/>
          <div class="validation-container">
            <div class="validation-icon">
              <i class="material-icons error-icon">error</i>
            </div>
            <div class="validation-content">
              <span class="validation-message validation-left">
                <span *ngIf="regPayForm.controls['initials'].errors && regPayForm.controls['initials'].errors['required']">Required</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="form-field">
        <div class="btn-container">
          <button
          mat-flat-button
          class="btn btn-lacoste btn-save"
          type="submit"
          [disabled]="!regPayForm.valid || disableBtn"
        >
        {{submitBtnText}}
        </button>
        </div>


      </div>

    </div>
    <div class="form-group">

    </div>
  </div>
  </form>

</div>


