<div class="payment-modal-container">
  <div class="modal-header">
    <div class="close-button" (click)="closeModal()">
      <a><fa-icon [icon]="faTimes"></fa-icon></a>
    </div>
  </div>
  <div class="row-content" *ngIf="paymentUrl">
    <iframe
      width="100%"
      height="900px"
      name="donorbox"
      scrolling="no"
      seamlename="donorbox"
      ss="seamless"
      [src]="paymentUrl | safe"
      frameborder="0"
      allowtransparency="true"
      style="max-width: 500px; min-width: 250px; max-height: none !important"
      allowpaymentrequest=""
      id="donorboxframe"
    ></iframe>
  </div>
</div>
