
<div class="tenant-payment-add-container">
  <div class="dd-payment-container-row">
    <div class="details-container">
      <div class="details-section">
        <div class="details-section-wrapper" *ngIf="tenantInfo">
          <div class="details-wrapper tall">
            <div class="subheader-text">Add Payment:</div>
          </div>
          <div class="details-wrapper">
            <div class="details-item-container" *ngIf="paymentModes">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Mode:</span>
                </div>
              </div>
              <div class="details-info-item select-wrapper top">
                <app-flat-select
                  [selectOptions]="paymentModes"
                  [(ngModel)]="selectedPaymentMode.value"
                  [defaultLabel]="'Please Select'"
                  [(value)]="selectedPaymentMode.value"
                  (selectOptionChange)="setMode($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container" *ngIf="paymentTypes">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Type:</span>
                </div>
              </div>
              <div class="details-info-item select-wrapper top">
                <app-flat-select
                  [selectOptions]="paymentTypes"
                  [(ngModel)]="selectedPaymentType.value"
                  [defaultLabel]="'Please Select'"
                  [(value)]="selectedPaymentType.value"
                  (selectOptionChange)="setType($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container" [ngClass]="{hide: !showMonthYear}" *ngIf="months">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Month:</span>
                </div>
              </div>
              <div class="details-info-item select-wrapper top">
                <app-flat-select
                  [selectOptions]="months"
                  [(ngModel)]="selectedMonth.value"
                  [defaultLabel]="'Please Select'"
                  [(value)]="selectedMonth.value"
                  (selectOptionChange)="onMonthChange($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container" [ngClass]="{hide: !showMonthYear}" *ngIf="years">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Year:</span>
                </div>
              </div>
              <div class="details-info-item thin select-wrapper top">
                <app-flat-select
                [selectOptions]="years"
                [(ngModel)]="selectedYear.value"
                [defaultLabel]="'Please Select'"
                [(value)]="selectedYear.value"
                (selectOptionChange)="onYearChange($event)"
                ngDefaultControl
              ></app-flat-select>
              </div>
            </div>

          </div>
          <div class="details-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">PaymentId:</span>
                </div>
              </div>
              <div class="details-info-item ">
                <app-flat-input
                  [placeHolder]="'Check or DBT No#'"
                  [(value)]="paymentId"
                  [(ngModel)]="paymentId"
                  [inputType]="'text'"
                  class="input-large"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Amount:</span>
                </div>
              </div>
              <div class="details-info-item">
                <div class="currency-container">
                  <span class="currency"> $ </span>
                </div>

                <app-flat-input
                  [placeHolder]="'Amount'"
                  [(value)]="amount"
                  [(ngModel)]="amount"
                  [inputType]="'number'"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
          </div>

          <div class="details-wrapper">
            <div class="btn-container">
              <button
                mat-raised-button
                class="summary-btns save"
                (click)="onSave()"
                [disabled]="disableSave()"
              >
                Save Payment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
