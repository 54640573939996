<div class="iqamah-times-container"> 
  <div class="row-content top-pad">
    <iframe
      width="100%"
      height="360"
      [src]="url | safe"
      frameborder="0"
      allowtransparency="true"
      type="text/html"
    ></iframe>
  </div>
</div>
