import { Component, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-janazah',
  templateUrl: './janazah.component.html',
  styleUrls: ['./janazah.component.scss']
})
export class JanazahComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
