<div class="ayah-manage-container">
  <div class="ayah-container">
    <div class="ayah-header">
      <div class="header">Manage Ayahs</div>
    </div>
    <div class="row top-padding row-no-wrap" *ngIf="this.ayahs">
      <div class="ayah-col col-thin half">
        <app-flat-select
          [selectOptions]="ayahList"
          (selectOptionChange)="OnAyahListSelectChange($event)"
          [(defaultLabel)]="defaultLabel"
          [(value)]="ayahListValue"
          [(resetSelect)]="resetAyahList"
          ngDefaultControl
        ></app-flat-select>
      </div>
      <div class="ayah-col col-thin half" *ngIf="selectedAyah">
        <div class="row">
          <div
            *ngIf="!selectedAyah.validation.hasError"
            class="delete-ayah"
            [ngClass]="{ 'disable-delete': disableDelete }"
            (click)="deleteAyah()"
          >
            <i class="material-icons delete-ayah-button" (click)="deleteAyah()"
              >delete</i
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedAyah">
      <div class="row ayah-row">
        <div class="ayah-col col-thin">
          <div class="ayah-item">
            <app-flat-select
              [selectOptions]="categoryList"
              (selectOptionChange)="OnCategorySelectChange($event, false)"
              [(defaultLabel)]="defaultLabel"
              [(value)]="selectedCategory.value"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="ayah-col col-thin">
          <div class="ayah-item">
            <app-flat-input
              [placeHolder]="placeHolderName"
              [(value)]="selectedAyah.name"
              [hasError]="selectedAyah.validation.name.hasError"
              [errorMessage]="selectedAyah.validation.name.errorMessage"
              (focusChanged)="setUpdateValue('name', selectedAyah.name)"
            >
            </app-flat-input>
          </div>
        </div>
      </div>
      <div class="row ayah-row">
        <div class="ayah-col col-wide">
          <div class="row">
            <div
              class="ayah-item-description"
              [ngClass]="{ error: selectedAyah.validation.ayah.hasError }"
            >
              <textarea
                [(ngModel)]="selectedAyah.ayah"
                value="selectedAyah.ayah"
                class="description-text arabic"
                placeholder="Arabic Ayah"
                [(ngModel)]="selectedAyah.ayah"
                (blur)="setUpdateValue('ayah', selectedAyah.ayah)"
              ></textarea>
            </div>
            <div
              class="validation-error-wrapper"
              *ngIf="selectedAyah.validation.ayah.hasError"
            >
              <div class="error-message">
                {{ selectedAyah.validation.ayah.errorMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ayah-row">
        <div class="ayah-col col-wide">
          <div class="row">
            <div
              class="ayah-item-description"
              [ngClass]="{
                error: selectedAyah.validation.translation.hasError
              }"
            >
              <textarea
                [(ngModel)]="selectedAyah.translation"
                value="selectedAyah.translation"
                class="description-text"
                placeholder="English Transalation"
                [(ngModel)]="selectedAyah.translation"
                (blur)="setUpdateValue('translation', selectedAyah.translation)"
              ></textarea>
            </div>
            <div
              class="validation-error-wrapper"
              *ngIf="selectedAyah.validation.translation.hasError"
            >
              <div class="error-message">
                {{ selectedAyah.validation.translation.errorMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      mat-fab
      class="add-ayah-button-fab"
      matTooltip="Add Ayah"
      matTooltipPosition="left"
      (click)="addAyah()"
      *ngIf="!showAddAyah"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-ayah-container"
      *ngIf="showAddAyah"
      [ngClass]="{ visible: showAddAyah, invisible: !showAddAyah }"
    >
      <div class="new-ayah">
        <div class="row ayah-row">
          <div class="ayah-col">
            <div class="ayah-item">
              <app-flat-select
                [selectOptions]="categoryList"
                (selectOptionChange)="OnCategorySelectChange($event, true)"
                [(defaultLabel)]="defaultLabel"
                [(value)]="newAyah.categoryId"
                ngDefaultControl
              ></app-flat-select>
            </div>
          </div>
          <div class="ayah-col">
            <div class="ayah-item">
              <app-flat-input
                [placeHolder]="placeHolderName"
                [(value)]="newAyah.name"
                [hasError]="newAyah.validation.name.hasError"
                [errorMessage]="newAyah.validation.name.errorMessage"
                (focusChanged)="validateNew('name', newAyah.name)"
              >
              </app-flat-input>
            </div>
          </div>
        </div>
        <div class="row ayah-row">
          <div class="ayah-col">
            <div class="row-no-wrap">
              <div
                class="ayah-item-description"
                [ngClass]="{ error: newAyah.validation.ayah.hasError }"
              >
                <textarea
                  [(ngModel)]="newAyah.ayah"
                  value="newAyah.ayah"
                  class="description-text arabic new"
                  placeholder="Arabic Ayah"
                  [(ngModel)]="newAyah.ayah"
                  (blur)="validateNew('ayah', newAyah.ayah)"
                ></textarea>
              </div>
              <div
                class="validation-error-wrapper"
                *ngIf="newAyah.validation.ayah.hasError"
              >
                <div class="error-message">
                  {{ newAyah.validation.ayah.errorMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="ayah-col">
            <div class="row-no-wrap">
              <div
                class="ayah-item-description"
                [ngClass]="{
                  error: newAyah.validation.translation.hasError
                }"
              >
                <textarea
                  [(ngModel)]="newAyah.translation"
                  value="newAyah.translation"
                  class="description-text new"
                  placeholder="English Transalation"
                  [(ngModel)]="newAyah.translation"
                  (blur)="validateNew('translation', newAyah.translation)"
                ></textarea>
              </div>
              <div
                class="validation-error-wrapper"
                *ngIf="newAyah.validation.translation.hasError"
              >
                <div class="error-message">
                  {{ newAyah.validation.translation.errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row ayah-row">
          <div class="ayah-col">
            <div class="form-buttons">
              <button
                type="button"
                class="text-button"
                [disabled]="newAyah.validation.name.hasError"
                (click)="saveAyah()"
              >
                SAVE
              </button>
              <button
                type="button"
                class="text-button"
                (click)="closeAddAyah()"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
