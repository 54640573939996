<div class="user-manage-container">
  <div class="user-manage-row">
    <div class="user-container">
      <div class="user-section">
        <div class="user-section-wrapper">
          <div class="user-wrapper">
            <div class="user-item-container">
              <app-flat-input
                [placeHolder]="placeHolderFirstName"
                [(value)]="userUpdate.firstName"
                [hasError]="userUpdate.validation.firstName.hasError"
                [errorMessage]="userUpdate.validation.firstName.errorMessage"
                (focusChanged)="setUpdateValue('name', userUpdate.firstName)"
              >
              </app-flat-input>
            </div>
            <div class="user-item-container">
              <app-flat-input
                [placeHolder]="placeHolderLasttName"
                [(value)]="userUpdate.lastName"
                [hasError]="userUpdate.validation.lastName.hasError"
                [errorMessage]="userUpdate.validation.lastName.errorMessage"
                (focusChanged)="setUpdateValue('name', userUpdate.lastName)"
              >
              </app-flat-input>
            </div>
          </div>
          <div class="user-wrapper">
            <div class="user-item-container select" *ngIf="roles">
              <app-flat-select
                [selectOptions]="roles"
                (selectOptionChange)="OnRoleSelectChange($event)"
                [(defaultLabel)]="defaultLabel"
                [(value)]="userUpdate.role"
                ngDefaultControl
              ></app-flat-select>
            </div>

            <div class="user-item-container">
              <app-flat-input
                [placeHolder]="'Username'"
                [(value)]="userUpdate.userName"
                [disabled]="true"
              >
              </app-flat-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
