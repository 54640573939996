import { KidValidation } from './../../models/kid-validation';
import { Subject } from 'rxjs';
import { MemberService } from './../../services/member.service';
import {
  Component, OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef, Renderer2,
  ViewChild, ElementRef
} from '@angular/core';
import { MemberKid } from 'src/app/models/member-kid';
import { SelectListItem } from 'src/app/models/select-list-item';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { Kid } from 'src/app/models/kid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { ValidationError } from 'src/app/models/validation-error';
import { MemberKidValidation } from 'src/app/models/member-kid-validation';


@Component({
  standalone: false,
  selector: 'app-member-kids',
  templateUrl: './member-kids.component.html',
  styleUrls: ['./member-kids.component.scss']
})
export class MemberKidsComponent implements OnInit, OnDestroy {

  @Input() memberKids: MemberKid[];
  @Input() memberId: number;
  @Output() memberKidsChanged = new EventEmitter<MemberKid[]>();
  birthYears: SelectListItem[];
  birthMonths: SelectListItem[];
  genderList: SelectListItem[];
  relationshipList: SelectListItem[];
  newKidForm: FormGroup;
  showAddKid = false;
  // newKid = new Kid();
  newMemberKid = new MemberKid();
  disableDelete: boolean;
  defaultLabel = 'Select';


  @ViewChild('nameInput') nameInput: ElementRef;
  constructor(private helperService: HelperService, private memberService: MemberService,
    private cd: ChangeDetectorRef, private formBuilder: FormBuilder,
    private snackBar: MatSnackBar, private renderer: Renderer2) {
    this.getLists();
    this.newMemberKid.kid = new Kid();
  }

  get name() { return this.newKidForm.get('name'); }
  get gender() { return this.newKidForm.get('gender'); }
  get birthMonth() { return this.newKidForm.get('birthMonth'); }
  get birthYear() { return this.newKidForm.get('birthYear'); }
  get relationship() { return this.newKidForm.get('relationship'); }
  ngOnInit() {
    this.newKidForm = this.formBuilder.group({
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      name: ['', Validators.required],
      gender: ['', Validators.required],
      birthMonth: [''],
      birthYear: [''],
      relationship: ['', Validators.required]
    }, { updateOn: 'blur' });
  }

  ngOnDestroy() {
  }

  emitUpdatedMemberKid() {
    this.memberKidsChanged.emit(this.memberKids);
  }

  updateMemberKid(memberKidUpdated: MemberKid) {
    memberKidUpdated.kid = this.setKidName(memberKidUpdated.kid);
    this.memberService.kidUpdate(memberKidUpdated).subscribe(() => {
      const index = this.memberKids.findIndex(m => m.id === memberKidUpdated.id);
      this.memberKids.splice(index, 1, memberKidUpdated);
      this.cd.detectChanges();
      this.emitUpdatedMemberKid();
    });
  }

  setUpdateValue(id: number, field: string, value: any) {
    let toUpdate = false;
    const updatedMemberKid = this.memberKids.find(c => c.id === id);
    toUpdate = this.validateUpdate(id, field, value, updatedMemberKid);
    if (toUpdate) {
      this.updateMemberKid(updatedMemberKid);
    }
  }
  validateUpdate(id: number, field: string, value: any, memberKid: MemberKid): boolean {
    let isValid = true;
    if (field === 'name') {
      if (value.length === 0) {
        memberKid.kid.validation.name.hasError = true;
        memberKid.kid.validation.name.errorMessage = 'Required';
        isValid = false;
      } else {
        memberKid.kid.validation.name.hasError = false;
        memberKid.kid.name = value;
      }
    }
    if (field === 'gender') {
      if (!value) {
        memberKid.kid.validation.gender.hasError = true;
        memberKid.kid.validation.gender.errorMessage = 'Required';
        isValid = false;
      } else {
        memberKid.kid.validation.gender.hasError = false;
        memberKid.kid.gender = value;
      }
    }
    if (field === 'relationship') {
      if (value.length === 0) {
        memberKid.validation.relationship.hasError = true;
        memberKid.validation.relationship.errorMessage = 'Required';
        isValid = false;
      } else {
        memberKid.validation.relationship.hasError = false;
        memberKid.relationship = value;
      }
    }
    if (field === 'birthMonth') {
      memberKid.kid.birthMonth = value;
    }
    if (field === 'birthYear') {
      memberKid.kid.birthYear = value;
    }
    isValid =
      memberKid.kid.validation.firstName.hasError || memberKid.kid.validation.lastName.hasError
        || memberKid.kid.validation.gender.hasError || memberKid.validation.relationship.hasError
        ? false
        : true;

    return isValid;

  }

  deleteKid(memberKid: MemberKid) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const index = this.memberKids.indexOf(memberKid);
    this.memberKids.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'kid-snack-bar',
      data: {
        undoDelete: () => {
          dismissedByAction = true;
          this.memberKids.splice(index, 0, memberKid);
          this.cd.detectChanges();
          const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: 'kid-snack-bar',
            data: {
              dismissSnackbar: () => {
                this.snackBar.dismiss();
                this.disableDelete = false;
              },
              displayText: 'Undone!',
              type: 'message'
            }
          });
          this.disableDelete = false;
        },
        dismissSnackbar: () => {
          dismissedByAction = true;
          this.memberService.KidDelete(memberKid).subscribe();
          this.snackBar.dismiss();
          this.disableDelete = false;
        },
        displayText: `${memberKid.kid.name} deleted`,
        type: 'delete-kid'
      }
    });
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe(info => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.memberService.KidDelete(memberKid).subscribe();
        this.disableDelete = false;
      }
    });

  }

  addKid() {
    this.showAddKid = true;
    const input = this.renderer.selectRootElement(this.nameInput.nativeElement);
    setTimeout(() => input.focus(), 0);
  }

  saveKid() {
    if (this.newKidForm.valid) {
      this.newMemberKid.memberId = this.memberId;
      const nameArray = this.newMemberKid.kid.name.split(' ');
      this.newMemberKid.kid.firstName = nameArray[0];
      this.newMemberKid.kid.lastName = nameArray[1] ? nameArray[1] : '';
      this.memberService.kidAdd(this.newMemberKid).subscribe(data => {
        const mKid = Object.assign(data);
        mKid.kid.name = `${this.newMemberKid.kid.firstName} ${this.newMemberKid.kid.lastName}`;
        mKid.validation = new MemberKidValidation(new ValidationError(false));
        mKid.kid.validation = new KidValidation(new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          false);
        this.memberKids = !this.memberKids ? [] : this.memberKids;
        this.memberKids.push(mKid);
        // this.cd.detectChanges();
        this.emitUpdatedMemberKid();
        this.showConfirmationDialog('Information is saved');
        this.closeAddKid();
      });
    }
  }

  closeAddKid() {
    this.showAddKid = false;
    if (this.newKidForm) {
      this.newKidForm.reset();
    }
  }

  setKidName(kid: Kid): Kid {
    if (!kid.name) {
      return kid;
    }
    const nameArray = kid.name.split(' ');
    if (nameArray.length === 1) {
      kid.firstName = nameArray[0];
    } else if (nameArray.length === 2) {
      kid.firstName = nameArray[0];
      kid.lastName = nameArray[1];
    } else {
      kid.lastName = nameArray[nameArray.length - 1];
      const obj = { key: nameArray };
      const output = this.getAllButLastElement(obj, 'key');
      kid.firstName = output.join(' ');
    }

    return kid;
  }
  getLists() {
    this.birthYears = this.helperService.birthYearsGet(true);
    this.birthMonths = this.helperService.birthMonthsGet();
    this.genderList = this.helperService.genderListGet();
    this.relationshipList = this.helperService.relationshipListGet();
  }

  getAllButLastElement(obj, k) {
    if (!obj.hasOwnProperty(k) || !Array.isArray(obj[k]) || obj[k].length === 0) {
      return [];
    }
    return obj[k].slice(0, -1);  // getting all but the last element of the array
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'message',
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: 'success'
      }
    });
  }

}
