<div class="member-payments-container">
  <div *ngIf="payments; else loading">
    <div class="payments-container-row">
      <div class="payments-container">
        <div *ngFor="let item of payments" class="payment-section">
          <div class="payment-section-wrapper">
            <div class="payment-wrapper">
              <div class="payment-info-container">
                <div class="membership-year">
                  <div class="label-text">
                    Membership Year: {{ item.membershipYear }}
                  </div>
                  <div
                    *ngIf="!item.validation.paymentId.hasError"
                    class="delete-payment"
                    [ngClass]="{ 'disable-delete': disableDelete }"
                    (click)="deletePayment(item)"
                  >
                    <i class="material-icons delete-payment-button">delete</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment-wrapper">
              <div class="payment-info-container">
                <div class="payment-info-item">
                  <div class="input-wrapper">
                    <app-flat-input
                      [placeHolder]="'Payment Id'"
                      [(value)]="item.paymentId"
                      [(ngModel)]="item.paymentId"
                      [hasError]="item.validation.paymentId.hasError"
                      [errorMessage]="item.validation.paymentId.errorMessage"
                      (keydown.enter)="$event.target.blur()"
                      (keyup)="
                        setUpdateValue(item.id, 'paymentId', item.paymentId)
                      "
                      (blur)="
                        setUpdateValue(item.id, 'paymentId', item.paymentId)
                      "
                    >
                    </app-flat-input>
                  </div>

                  <!--
                  <input
                    id="paymentId"
                    type="text"
                    placeHolder="Payment Id"
                    value="item.paymentId"
                    [(ngModel)]="item.paymentId"
                    class="payment-input"
                    [ngClass]="{ invalid: item.validation.paymentId.hasError }"
                    (blur)="
                      setUpdateValue(item.id, 'paymentId', item.paymentId)
                    "
                  />
                  <div
                    *ngIf="item.validation.paymentId.hasError"
                    class="payment-validation-error-container"
                  >
                    <div class="payment-validation-error-icon">
                      <i class="material-icons md-18">error</i>
                    </div>
                    <div class="payment-validation-error-content">
                      <span class="payment-validation-error"
                        >{{ item.validation.paymentId.errorMessage }}
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="payment-info-container">
                <div class="payment-info-item currency">
                  <fa-icon
                    [icon]="faDollarSign"
                    class="icon-currency"
                  ></fa-icon>
                  <input
                    type="number"
                    placeHolder="0.00"
                    value="$item.amount"
                    min="0"
                    step="1"
                    [(ngModel)]="item.amount"
                    class="payment-input currency"
                    [ngClass]="{ invalid: item.validation.amount.hasError }"
                    (blur)="setUpdateValue(item.id, 'amount', item.amount)"
                  />
                  <div
                    *ngIf="item.validation.amount.hasError"
                    class="payment-validation-error-container"
                  >
                    <div class="payment-validation-error-icon">
                      <i class="material-icons md-18">error</i>
                    </div>
                    <div class="payment-validation-error-content">
                      <span class="payment-validation-error"
                        >{{ item.validation.amount.errorMessage }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment-wrapper">
              <div class="payment-info-container">
                <div class="payment-info-item date-picker">
                  <input
                    [matDatepicker]="paymentDatePicker"
                    class="payment-input"
                    [(ngModel)]="item.paymentDate"
                    readonly
                    (dateInput)="paymentDatePicker.close()"
                    (dateChange)="paymentDatePicker.close()"
                    (focus)="paymentDatePicker.open()"
                    [ngClass]="{
                      invalid: item.validation.paymentDate.hasError
                    }"
                    (blur)="
                      setUpdateValue(item.id, 'paymentDate', item.paymentDate)
                    "
                  />
                  <mat-datepicker-toggle
                    class="date-picker-payment"
                    matSuffix
                    [for]="paymentDatePicker"
                  >
                  </mat-datepicker-toggle>
                  <mat-datepicker #paymentDatePicker></mat-datepicker>
                  <div
                    *ngIf="item.validation.paymentDate.hasError"
                    class="payment-validation-error-container"
                  >
                    <div class="payment-validation-error-icon">
                      <i class="material-icons md-18">error</i>
                    </div>
                    <div class="payment-validation-error-content">
                      <span class="payment-validation-error"
                        >{{ item.validation.paymentDate.errorMessage }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="payment-info-container">
                <div class="payment-info-item">
                  <div class="select-wrapper">
                    <app-flat-select
                      [selectOptions]="validityInMonthsList"
                      [(ngModel)]="item.validityInMonths"
                      [(defaultLabel)]="defaultLabel"
                      [(value)]="item.validityInMonths"
                      [hasError]="item.validation.validityInMonths.hasError"
                      [errorMessage]="
                        item.validation.validityInMonths.errorMessage
                      "
                      (selectOptionChange)="
                        setUpdateValue(item.id, 'validityInMonths', $event)
                      "
                      ngDefaultControl
                    ></app-flat-select>
                  </div>

                  <!-- <mat-form-field
                    class="payment-form-field-light-grey"
                    floatLabel="never"
                  >
                    <mat-select
                      class="payment-select-light-green"
                      placeholder="Validity"
                      [ngClass]="{
                        invalid: item.validation.validityInMonths.hasError
                      }"
                      [(ngModel)]="item.validityInMonths"
                      (selectionChange)="
                        setUpdateValue(
                          item.id,
                          'validityInMonths',
                          item.validityInMonths
                        )
                      "
                    >
                      <mat-option
                        *ngFor="let months of validityInMonthsList"
                        [value]="months.value"
                      >
                        {{ months.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="item.validation.validityInMonths.hasError"
                    class="payment-validation-error-container"
                  >
                    <div class="payment-validation-error-icon select">
                      <i class="material-icons md-18">error</i>
                    </div>
                    <div class="payment-validation-error-content">
                      <span class="payment-validation-error">
                        {{ item.validation.validityInMonths.errorMessage }}
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="payment-wrapper">
              <textarea
                [(ngModel)]="item.note"
                value="item.note"
                class="note-text"
                placeholder="Notes"
                (blur)="setUpdateValue(item.id, 'note', item.note)"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button mat-fab class="add-payment-button-fab" *ngIf="!showAddPayment">
    <i class="material-icons md-16" (click)="addPayment()">add</i>
  </button>
  <div
    class="add-payment-container"
    [ngClass]="{ visible: showAddPayment, invisible: !showAddPayment }"
  >
    <form
      [formGroup]="newPaymentForm"
      (ngSubmit)="savePayment()"
      class="new-payment-form"
    >
      <div class="payment-wrapper">
        <div class="payment-info-item">
          <input
            #newPaymentIdInput
            formControlName="paymentId"
            id="paymentId"
            type="text"
            placeHolder="Payment Id"
            [(ngModel)]="newPayment.paymentId"
            class="payment-input"
            [ngClass]="{
              invalid:
                (paymentId.dirty || paymentId.touched) && paymentId.errors
            }"
          />
          <div
            *ngIf="(paymentId.dirty || paymentId.touched) && paymentId.errors"
            class="payment-validation-error-container"
          >
            <div class="payment-validation-error-icon">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="payment-validation-error-content">
              <span class="payment-validation-error">Required </span>
            </div>
          </div>
        </div>
        <div class="payment-info-item">
          <fa-icon [icon]="faDollarSign" class="icon-currency"></fa-icon>
          <input
            formControlName="amount"
            id="amount"
            type="number"
            min="0"
            step="1"
            placeHolder="0.00"
            [(ngModel)]="newPayment.amount"
            class="payment-input currency"
            [ngClass]="{
              invalid: (amount.dirty || amount.touched) && amount.errors
            }"
          />
          <div
            *ngIf="(amount.dirty || amount.touched) && amount.errors"
            class="payment-validation-error-container"
          >
            <div class="payment-validation-error-icon">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="payment-validation-error-content">
              <span class="payment-validation-error">Required </span>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-wrapper">
        <div class="payment-info-item">
          <input
            formControlName="newPaymentDate"
            [matDatepicker]="newPaymentDatePicker"
            class="payment-input payment-datepicker"
            [(ngModel)]="newPayment.paymentDate"
            readonly
            (dateInput)="newPaymentDatePicker.close()"
            (dateChange)="newPaymentDatePicker.close()"
            (focus)="newPaymentDatePicker.open()"
            [ngClass]="{
              invalid:
                (newPaymentDate.dirty || newPaymentDate.touched) &&
                newPaymentDate.errors
            }"
          />
          <mat-datepicker-toggle
            class="date-picker-payment"
            matSuffix
            [for]="newPaymentDatePicker"
          >
          </mat-datepicker-toggle>
          <mat-datepicker #newPaymentDatePicker></mat-datepicker>
          <!-- <div
            *ngIf="
              (newPaymentDate.dirty || newPaymentDate.touched) &&
              newPaymentDate.errors
            "
            class="payment-validation-error-container"
          >
            <div class="payment-validation-error-icon">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="payment-validation-error-content">
              <span class="payment-validation-error">Required </span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="payment-wrapper">
        <div class="payment-info-item">
          <mat-form-field
            class="payment-form-field-light-grey"
            floatLabel="never"
          >
            <mat-select
              formControlName="validityInMonths"
              class="payment-select-light-green"
              placeholder="Validity"
              [ngClass]="{
                invalid:
                  (validityInMonths.dirty || validityInMonths.touched) &&
                  validityInMonths.errors
              }"
              [(ngModel)]="newPayment.validityInMonths"
            >
              <mat-option
                *ngFor="let months of validityInMonthsList"
                [value]="months.value"
              >
                {{ months.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <div
            *ngIf="
              (validityInMonths.dirty || validityInMonths.touched) &&
              validityInMonths.errors
            "
            class="payment-validation-error-container"
          >
            <div class="payment-validation-error-icon select">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="payment-validation-error-content">
              <span class="payment-validation-error"> Required </span>
            </div>
          </div> -->
        </div>
        <div class="payment-info-item">
          <mat-form-field
            class="payment-form-field-light-grey"
            floatLabel="never"
          >
            <mat-select
              formControlName="membershipYear"
              class="payment-select-light-green"
              placeholder="Membership Year"
              [ngClass]="{
                invalid:
                  (membershipYear.dirty || membershipYear.touched) &&
                  membershipYear.errors
              }"
              [(ngModel)]="newPayment.membershipYear"
            >
              <mat-option
                *ngFor="let item of membershipYears"
                [value]="item.value"
                [disabled]="item.disabled"
                [ngClass]="{ disabled: item.disabled }"
              >
                {{ item.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <div
            *ngIf="
              (membershipYear.dirty || membershipYear.touched) &&
              membershipYear.errors
            "
            class="payment-validation-error-container"
          >
            <div class="payment-validation-error-icon select">
              <i class="material-icons md-18">error</i>
            </div>
            <div class="payment-validation-error-content">
              <span class="payment-validation-error"> Required </span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="payment-wrapper">
        <div class="payment-info-item">
          <textarea
            formControlName="note"
            [(ngModel)]="newPayment.note"
            class="note-text skinny"
            placeholder="Notes"
          ></textarea>
        </div>
      </div>

      <div class="form-buttons">
        <button
          type="submit"
          class="text-button"
          [disabled]="!newPaymentForm.valid"
        >
          SAVE
        </button>
        <button type="button" class="text-button" (click)="closeAddPayment()">
          CANCEL
        </button>
      </div>
    </form>
  </div>
  <ng-template #loading>
    <div class="skeleton">
      <app-card-skeleton></app-card-skeleton>
    </div>
  </ng-template>
</div>
