<div class="keyfob-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Key Fob</div>
    </div>

    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            All community members above age 16 can obtain a key fob to access
            masjid.
          </li>
          <li class="list-item">
            Once
            <a class="anchor-link" href="login" target="_blank"> registered </a>
            you shall be able to request a key fob.
          </li>
          <li class="list-item">
            Please go to profile section and click on
            <span class="bold-text">'request a key fob'.</span>
          </li>
          <li class="list-item">
            An email is sent to the ISGL admin with your request.
          </li>
          <li class="list-item">
            Key fob can be collected from Masjid's office.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
