
import { AddendumRequest } from "./../../models/addendum-request";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { VehicleRequest } from "src/app/models/vehicle-request";
import { HelperService } from "src/app/services/helper.service";
import * as _moment from "moment";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { SignatureModalComponent } from "../signature-modal/signature-modal.component";
import { LeaseSignature } from "src/app/models/lease-signature";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { SignatureDialogModel } from "src/app/models/signature-dialog-model";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { VehicleValidation } from "src/app/models/vehicle-validation";
import { ValidationError } from "src/app/models/validation-error";
import { TenantAddendum } from "src/app/models/tenant-addendum";
import { LeaseService } from "src/app/services/lease.service";
import { LeaseInfo } from "src/app/models/lease-info";

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};

@Component({
  standalone: false,
  selector: "app-parking-addendum",
  templateUrl: "./parking-addendum.component.html",
  styleUrls: ["./parking-addendum.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ParkingAddendumComponent implements OnInit {
  @Input() model: TenantAddendum;
  @Input() showInfo = true;
  @Input() disableControls = false;
  @Input() disableSig = false;
  @Input() sigModel: SignatureDialogModel;
  @Input() type: string;
  @Output() notifyParent = new EventEmitter();
  @Output() newVehicleNotification = new EventEmitter();
  vehicleYears: SelectListItem[];
  defaultLabel = "Select";
  veh: VehicleRequest;
  sDate: any;
  eDate: any;
  parkingFee: string;
  hasSigError: boolean = true;
  faSignature = faSignature;
  notLoading = true;
  hasError = true;
  addendum: AddendumRequest;
  coordinatorSig: LeaseSignature;
  extDurationList: SelectListItem[];
  selectedExtDuration: SelectListItem;
  rentAmount: string;
  showSigErrorContainer: boolean;
  headerTitle: string;
  btnText: string;
  constructor(
    private helperService: HelperService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private leaseService: LeaseService
  ) {}

  ngOnInit() {
    this.getLists();
    this.setModel();
  }

  setModel() {
    if (this.model.type === "Parking-Add") {
      this.headerTitle = this.showInfo
        ? "Vehicle Info (All fields are required)"
        : "Parking Addition Addendum";
      this.btnText = "Save Parking Addition Addendum";
    } else {
      this.headerTitle = this.showInfo ? "Parking Temination Addendum" : "";
      this.btnText = "Save Parking Temination Addendum";
    }
    const addendumToSign =
      this.model.tenant.leaseInfo.addendums &&
      this.model.tenant.leaseInfo.addendums.length > 0
        ? this.model.tenant.leaseInfo.addendums.find(
            (a) => a.type === this.model.type && a.statusId === 3
          )
        : null;
    let tVehicle = addendumToSign
      ? this.model.tenant.vehicles.find(
          (v) => v.id === addendumToSign.vehicleId
        )
      : null;
    if (
      this.model.type === "Parking-Term" &&
      this.model.tenant.leaseInfo.addendums
    ) {
      const pAddedndum = this.model.tenant.leaseInfo.addendums.find(
        (a) =>
          a.type === "Parking-Add" && (a.statusId === 4 || a.statusId === 3)
      );
      tVehicle = pAddedndum
        ? this.model.tenant.vehicles.find((v) => v.id === pAddedndum.vehicleId)
        : null;
    }
    if (!addendumToSign) {
      const extAddendum =
        this.model.tenant.leaseInfo.addendums &&
        this.model.tenant.leaseInfo.addendums.length > 0
          ? this.model.tenant.leaseInfo.addendums.find(
              (a) =>
                a.type === "Lease-Ext" && (a.statusId === 3 || a.statusId === 4)
            )
          : null;
      if (extAddendum) {
        this.sDate = moment(extAddendum.startDate).clone().local();
        this.eDate = moment(extAddendum.endDate)
          .clone()
          .local()
          .format("MM-DD-YYYY");
      } else {
        this.sDate = moment().local();
        this.eDate = moment(this.model.tenant.leaseInfo.endDate)
          .clone()
          .local()
          .format("MM-DD-YYYY");
      }
      let duration = Math.round(
        moment(new Date(this.eDate))
          .clone()
          .diff(new Date(this.sDate), "months", true)
      );
      duration =
        duration <= 12 && duration > 9
          ? 12
          : duration <= 9 && duration > 6
          ? 9
          : duration <= 6 && duration > 3
          ? 6
          : 3;
      this.selectedExtDuration = this.extDurationList.find(
        (l) => l.value === duration
      );

      this.rentAmount = this.model.availableParkingSpace
        ? `$${this.model.availableParkingSpace.amount}`
        : this.model.tenant.parkingSpaceRent
        ? `$${this.model.tenant.parkingSpaceRent.amount}`
        : tVehicle
        ? `$${tVehicle.parkingSpace.amount}`
        : "";

      this.addendum = {
        id: 0,
        addendumDate: moment().clone().local(),
        startDate: this.sDate,
        endDate: this.eDate,
        leaseId: this.model.tenant.leaseInfo.id,
        tenantId: this.model.tenant.id,
        duration: this.selectedExtDuration.value,
        tenantSig: null,
        vehicleId:
          this.model.type === "Parking-Term" && tVehicle ? tVehicle.id : null,
        type: this.model.type,
        rentId: this.model.availableParkingSpace
          ? this.model.availableParkingSpace.rentId
          : this.model.tenant.vehicles[0].parkingSpace.rentId,
        roomId: this.model.tenant.roomInfo.id,
      };
    } else {
      let duration = Math.round(
        moment(new Date(addendumToSign.endDate))
          .clone()
          .diff(new Date(addendumToSign.startDate), "months", true)
      );
      duration =
        duration <= 12 && duration > 9
          ? 12
          : duration <= 9 && duration > 6
          ? 9
          : duration <= 6 && duration > 3
          ? 6
          : 3;
      this.selectedExtDuration = this.extDurationList.find(
        (l) => l.value === duration
      );
      this.addendum = {
        id: addendumToSign.id,
        addendumDate: moment(addendumToSign.addendumDate).clone().local(),
        startDate: moment(addendumToSign.startDate).clone().local(),
        endDate: moment(addendumToSign.endDate)
          .clone()
          .local()
          .format("MM-DD-YYYY"),
        leaseId: this.model.tenant.leaseInfo.id,
        tenantId: this.model.tenant.id,
        duration: this.selectedExtDuration
          ? this.selectedExtDuration.value
          : null,
        tenantSig: null,
        type: addendumToSign.type,
        rentId: addendumToSign.rentId,
        roomId: addendumToSign.roomId,
      };
      this.sDate = moment(this.addendum.startDate)
        .clone()
        .local()
        .format("MM-DD-YYYY");
      this.eDate = moment(this.addendum.endDate)
        .clone()
        .local()
        .format("MM-DD-YYYY");
      this.rentAmount = `$${addendumToSign.rentAmount}`;
    }
    this.veh = {
      id: tVehicle ? tVehicle.id : null,
      licensePlate: tVehicle ? tVehicle.licensePlate : "",
      model: tVehicle ? tVehicle.model : "",
      year: tVehicle ? tVehicle.year : "",
      name: tVehicle ? tVehicle.name : "",
      parkingSpaceId: tVehicle
        ? tVehicle.parkingSpace.id
        : this.model.availableParkingSpace.id,
      tenantId: this.model.tenant.id,
      validation: tVehicle
        ? new VehicleValidation(
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            false
          )
        : new VehicleValidation(
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            new ValidationError(false),
            true
          ),
    };

    this.addendum.vehicle = this.veh;
  }
  getLists() {
    this.extDurationList = this.helperService.tenantLeaseDurationsGet();
    this.vehicleYears = this.helperService.vehicleYearsGet();
  }

  setDuration(value: any) {
    this.selectedExtDuration = this.extDurationList.find(
      (l) => l.value === value
    );

    this.addendum.endDate = this.addendum.startDate
      .clone()
      .add(this.selectedExtDuration.value - 1, "M")
      .endOf("month")
      .local();
    this.eDate = moment(this.addendum.endDate).clone().format("MM-DD-YYYY");
  }

  onStartDateChange() {
    this.addendum.startDate = moment(this.sDate).clone().local();
    this.addendum.endDate = this.addendum.startDate
    .clone()
    .add(this.selectedExtDuration.value - 1, "M")
    .endOf("month")
    .local();
    this.eDate = moment(this.addendum.endDate).clone().format("MM-DD-YYYY");
  }

  setValue(field: string, value: any) {
    this.veh.validation.hasError = !this.validateFields(field, value);
    this.hasError =
      !this.hasSigError && !this.veh.validation.hasError ? false : true;
  }

  validateFields(field: string, value: any): boolean {
    let isValid = true;
    if (field === "vehicleName") {
      if (value.length === 0) {
        this.veh.validation.name.hasError = true;
        this.veh.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.veh.validation.name.hasError = false;
      }
    }
    if (field === "vehicleModel") {
      if (value.length === 0) {
        this.veh.validation.model.hasError = true;
        this.veh.validation.model.errorMessage = "Required";
        isValid = false;
      } else {
        this.veh.validation.model.hasError = false;
      }
    }
    if (field === "vehicleYear") {
      if (value.length === 0) {
        this.veh.validation.year.hasError = true;
        this.veh.validation.year.errorMessage = "Required";
        isValid = false;
      } else {
        this.veh.year = value;
        this.veh.validation.year.hasError = false;
      }
    }
    if (field === "licensePlate") {
      if (value.length === 0) {
        this.veh.validation.licensePlate.hasError = true;
        this.veh.validation.licensePlate.errorMessage = "Required";
        isValid = false;
      } else {
        this.veh.validation.licensePlate.hasError = false;
      }
    }

    isValid =
      this.veh.name.length > 0 &&
      this.veh.licensePlate.length > 0 &&
      this.veh.model.length > 0 &&
      this.veh.year.length > 0
        ? true
        : false;

    return isValid;
  }

  addAddendum() {
    this.notLoading = false;
    this.spinner.show();
    this.addendum.startDate = moment(this.sDate).clone();
    this.addendum.duration = this.selectedExtDuration.value;

    if (this.addendum.id === 0) {
      this.addendum.vehicle = this.veh;
      this.addendum.endDate =
        this.addendum.type === "Parking-Add"
          ? moment(this.eDate).clone()
          : null;
      this.leaseService.addAddendum(this.addendum).subscribe((data) => {
        if (data) {
          let leaseInfo: LeaseInfo = Object.assign({}, data);
          let newAddendum = leaseInfo.addendums.find(
            (a) => a.type === this.addendum.type
          );
          if (newAddendum) {
            this.addendum.id = newAddendum.id;
            if (this.addendum.type === "Parking-Add") {
              this.addendum.vehicleId = this.addendum.vehicle.id =
                newAddendum.vehicleId;
              this.newVehicleNotification.emit(this.addendum.vehicle);
            }
          }
          this.notifyParent.emit(data);
        }
        this.notLoading = true;
        this.spinner.hide();
      });
    } else {
      if (this.coordinatorSig && this.coordinatorSig.addendumId > 0) {
        this.leaseService
          .addIcbSigAddendum(this.coordinatorSig)
          .subscribe((data) => {
            if (data) {
              this.coordinatorSig = null;
              this.hasError = true;
              this.notifyParent.emit(data);
            }
            this.notLoading = true;
            this.spinner.hide();
          });
      }
    }
  }

  openSignatureDialog(): void {
    const dialogRef = this.dialog.open(SignatureModalComponent, {
      height: "400px",
      width: "600px",
      data: this.sigModel,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((sigModel) => {
      this.captureSig(sigModel);
    });
  }

  captureSig(sigRes: any) {
    if (!sigRes) {
      this.showSigErrorContainer = this.hasSigError = true;
      this.toggleError();
      this.hasError = true;
      return;
    }
    if (
      !sigRes.useExisting &&
      (!sigRes.signatureFile ||
        !sigRes.signatureFile.fileByteString ||
        sigRes.signatureFile.length === 0)
    ) {
      this.showSigErrorContainer = this.hasSigError = true;
      this.toggleError();
      this.hasError = true;
      return;
    }
    if (
      !sigRes.useExisting &&
      sigRes.signatureFile &&
      sigRes.signatureFile.fileByteString &&
      sigRes.signatureFile.fileByteString.length > 0
    ) {
      if (this.sigModel.signee === "Tenant") {
        this.addendum.tenantSig = new LeaseSignature();
        this.addendum.tenantSig.newSignature = Object.assign(
          {},
          sigRes.signatureFile
        );
        this.addendum.tenantSig.hasPrintSignature = sigRes.usePrint;
      } else {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.tenant.leaseInfo.id;
        this.coordinatorSig.addendumId = this.addendum.id;
        this.coordinatorSig.newSignature = Object.assign(
          {},
          sigRes.signatureFile
        );
        this.coordinatorSig.hasPrintSignature = sigRes.usePrint;
      }

      this.showSigErrorContainer = this.hasSigError = false;
    }
    if (sigRes.useExisting) {
      if (this.sigModel.signee === "Coordinator") {
        this.coordinatorSig = new LeaseSignature();
        this.coordinatorSig.leaseId = this.model.tenant.leaseInfo.id;
        this.coordinatorSig.addendumId = this.addendum.id;
        this.coordinatorSig.existingSignature = this.sigModel.existingSig;
        this.coordinatorSig.hasPrintSignature = false;
        this.showSigErrorContainer = this.hasSigError = false;
      } else {
        this.addendum.tenantSig = new LeaseSignature();
        this.addendum.tenantSig.existingSignature = Object.assign(
          this.sigModel.existingSig
        );
        this.addendum.tenantSig.hasPrintSignature = false;
        this.hasError = this.hasSigError = false;
      }
    }
    this.hasError =
      !this.hasSigError && !this.veh.validation.hasError ? false : true;
  }

  toggleError(): void {
    setTimeout(
      function () {
        this.showSigErrorContainer = false;
      }.bind(this),
      3000
    );
  }
}
