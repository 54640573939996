<app-header></app-header>
<div class="member-profile-container">
  <div class="page-header">
    <div class="header-content">
      <div class="header-title">
        <div class="header-icon" *ngIf="!profile || (profile && !profile.file)">
          <fa-icon [icon]="faUser" class="user-icon"></fa-icon>
        </div>
        <div
          class="profile-image-container"
          *ngIf="profile && profile.file && profile.file.url"
        >
          <img [src]="profile.file.url" class="profile-image" />
        </div>
        <div
          class="header-title"
          [ngClass]="{ image: profile && profile.file }"
        >
          <span *ngIf="profile"> {{ profile.firstName }}'s Profile</span>
        </div>
      </div>
    </div>
  </div>

  <div class="member-profile-section">
    <div class="sub-header-section">
      <div class="sub-header">
        <div class="sub-header-title">Manage</div>
        <div class="sub-header-btn" *ngIf="tenantUrl">
          <button
            mat-raised-button
            class="tenant-btn"
            (click)="redirectTenant()"
          >
            {{ tennatBtnText }}
          </button>
        </div>
      </div>
    </div>
    <div class="member-profile-info-section" *ngIf="dataLoaded; else loading">
      <div class="member-profile-info" *ngIf="profile">
        <div class="profile-item-container">
          <div class="profile-input-wrapper">
            <app-flat-input
              [placeHolder]="'First Name'"
              [(value)]="profile.firstName"
              [(ngModel)]="profile.firstName"
              [hasError]="profile.validation.firstName.hasError"
              [errorMessage]="profile.validation.firstName.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('firstName', profile.firstName)"
              (blur)="setUpdateValue('firstName', profile.firstName)"
            >
            </app-flat-input>
          </div>
          <div class="profile-input-wrapper medium">
            <app-flat-input
              [placeHolder]="'Last Name'"
              [(value)]="profile.lastName"
              [(ngModel)]="profile.lastName"
              [hasError]="profile.validation.lastName.hasError"
              [errorMessage]="profile.validation.lastName.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('lastName', profile.lastName)"
              (blur)="setUpdateValue('lastName', profile.lastName)"
            >
            </app-flat-input>
          </div>
          <div class="profile-select-wrapper">
            <app-flat-select
              [selectOptions]="genderList"
              [(ngModel)]="profile.gender"
              [(defaultLabel)]="defaultLabel"
              [(value)]="profile.gender"
              [hasError]="profile.validation.gender.hasError"
              [errorMessage]="profile.validation.gender.errorMessage"
              (selectOptionChange)="setUpdateValue('gender', $event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="profile-item-container">
          <div class="profile-input-wrapper">
            <app-flat-input
              [placeHolder]="'Address'"
              [(value)]="profile.address"
              [(ngModel)]="profile.address"
              [hasError]="profile.validation.address.hasError"
              [errorMessage]="profile.validation.address.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('address', profile.address)"
              (blur)="setUpdateValue('address', profile.address)"
            >
            </app-flat-input>
          </div>
          <div class="profile-input-wrapper medium">
            <app-flat-input
              [placeHolder]="'City'"
              [(value)]="profile.city"
              [(ngModel)]="profile.city"
              [hasError]="profile.validation.city.hasError"
              [errorMessage]="profile.validation.city.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('city', profile.city)"
              (blur)="setUpdateValue('city', profile.city)"
            >
            </app-flat-input>
          </div>
          <div class="profile-input-wrapper skinny">
            <app-flat-input
              [placeHolder]="'Zipcode'"
              [(value)]="profile.zip"
              [(ngModel)]="profile.zip"
              [hasError]="profile.validation.zip.hasError"
              [errorMessage]="profile.validation.zip.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('zip', profile.zip)"
              (blur)="setUpdateValue('zip', profile.zip)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="profile-item-container">
          <div class="profile-select-wrapper">
            <app-flat-select
              [selectOptions]="states"
              [(ngModel)]="profile.state"
              [(defaultLabel)]="defaultLabel"
              [(value)]="profile.state"
              [hasError]="profile.validation.state.hasError"
              [errorMessage]="profile.validation.state.errorMessage"
              (selectOptionChange)="setUpdateValue('state', $event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div class="profile-input-wrapper">
            <app-flat-input
              [placeHolder]="'Spouse Name'"
              [(value)]="profile.spouseName"
              [(ngModel)]="profile.spouseName"
              (keydown.enter)="$event.target.blur()"
            >
            </app-flat-input>
          </div>
          <div class="profile-input-wrapper skinny">
            <app-flat-input
              [placeHolder]="'Cellphone'"
              [(value)]="profile.cellPhone"
              [(ngModel)]="profile.cellPhone"
              [hasError]="profile.validation.cellPhone.hasError"
              [errorMessage]="profile.validation.cellPhone.errorMessage"
              (keydown.enter)="$event.target.blur()"
              (keyup)="setUpdateValue('cellPhone', profile.cellPhone)"
              (blur)="setUpdateValue('cellPhone', profile.cellPhone)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="profile-item-container thin">
          <div class="profile-membership-wrapper">
            <div class="membership-header">Membership:</div>
            <div class="membership-status-item">
              <div
                *ngIf="votingStatus"
                class="membership-status"
                [ngClass]="{
                  valid: votingStatus === 'Valid',
                  'due-to-expire': votingStatus === 'Due to expire',
                  invalid: votingStatus === 'Invalid'
                }"
                matTooltip="{{ votingStatus }}"
                matTooltipPosition="above"
              ></div>
              <div class="membership-type-text">
                Voting <span class="status">{{ votingStatusText }}</span>
              </div>
            </div>
            <div class="membership-status-item">
              <div
                class="membership-status valid"
                matTooltip="{{ votingStatus }}"
                matTooltipPosition="above"
              ></div>
              <div class="membership-type-text">
                General <span class="status"> (never expires)</span>
              </div>
            </div>
          </div>
          <div class="profile-button-wrapper top-padding">
            <div class="show-info">
              <a
                button
                mat-raised-button
                class="page-btn"
                color="primary"
                (click)="openPaymentDialog()"
                [disabled]="disableVotingBtn"
              >
                <fa-icon [icon]="faVote" class="icon-buttons"></fa-icon> Voting
                Member
              </a>
              <i class="material-icons info-icon">info</i>
              <div class="info-text">
                <span *ngIf="profile.validation.hasError">
                  Please complete your profile to become voting member</span
                >
                <span *ngIf="!profile.validation.hasError">
                  If paying for your family members, please provide their full
                  names separated by comma.</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="profile-item-container">
          <div class="profile-checkbox-wrapper skinny">
            <mat-checkbox
              id="isStudent"
              [(ngModel)]="profile.isStudent"
              [(checked)]="profile.isStudent"
              (change)="onCheckChange($event)"
            >
            </mat-checkbox>
            <span class="checkbox-text">Student </span>
          </div>
          <div class="profile-checkbox-wrapper skinny">
            <mat-checkbox
              id="isAlumni"
              [(ngModel)]="profile.isAlumni"
              [(checked)]="profile.isAlumni"
              (change)="onCheckChange($event)"
            >
            </mat-checkbox>
            <span class="checkbox-text">Alumni </span>
          </div>
          <div class="profile-checkbox-wrapper">
            <mat-checkbox
              id="residencyStatus"
              [(ngModel)]="profile.residencyStatus"
              [(checked)]="profile.residencyStatus"
            >
            </mat-checkbox>
            <span class="checkbox-text">US Citizen/GreenCard Holder </span>
          </div>
        </div>
        <div class="profile-item-container">
          <div class="profile-button-wrapper">
            <div class="show-info">
              <a
                button
                mat-raised-button
                class="page-btn"
                color="primary"
                [disabled]="profile.validation.hasError"
                (click)="showKidsSummary()"
              >
                <fa-icon [icon]="faChild" class="icon-buttons"></fa-icon>
                {{ KidBtnText }}
              </a>
              <i class="material-icons info-icon">info</i>
              <div class="info-text">
                <span>
                  Kids age 16 & above should be registered as an member.</span
                >
              </div>
            </div>
          </div>
          <div class="profile-button-wrapper">
            <div class="show-info">
              <a
                button
                mat-raised-button
                class="page-btn"
                color="primary"
                [disabled]="profile.validation.hasError"
                (click)="requestFob()"
              >
                <fa-icon [icon]="faKey" class="icon-buttons"></fa-icon>
                Request Fob
              </a>
              <i class="material-icons info-icon">info</i>
              <div class="info-text">
                <span style="overflow-wrap: break-word">
                  when you click, an email will be sent to Masjid Admin with
                  your request. You can collect keyfob from Masjid's office
                  within few days.
                </span>
              </div>
            </div>
          </div>
          <div class="profile-item-container">
            <div class="update-btn-container">
              <button
                mat-raised-button
                class="profile-update-btn"
                [disabled]="profile.validation.hasError"
                (click)="updateProfile()"
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="skeleton">
        <app-card-skeleton></app-card-skeleton>
      </div>
    </ng-template>
  </div>
  <div
    class="kids-summary-container"
    [ngClass]="{
      'summary-panel-slide-in': showSummary,
      'summary-panel-slide-out': hideSummary
    }"
  >
    <div class="kids-summary-header">
      <div class="kids-summary-header-name">
        <fa-icon [icon]="faChild" class="kid-icon"></fa-icon>
        Kids Information
        <i class="material-icons info-kid-icon">info</i>

        <span class="info-content"> Enter kid's only age below 16! </span>
      </div>
      <div class="close-summary-wrapper">
        <a class="close-summary-button" (click)="hideKidsSummary()">
          <i class="material-icons expand">expand_more</i>
        </a>
        <span class="close-info" id="close-kid-panel">
          click or escape to close
        </span>
      </div>
    </div>

    <div *ngIf="profile">
      <app-member-kids
        [memberKids]="kids"
        [memberId]="profile.id"
        (memberKidsUpdated)="updateKids($event)"
      ></app-member-kids>
    </div>
  </div>
  <button
    *ngIf="profile && !profile.validation.hasError"
    mat-fab
    class="add-image-button-fab"
    matTooltip="Add Profile Image"
    matTooltipPosition="left"
    (click)="imageInput.click()"
  >
    <input
      hidden
      (change)="onImageSelected()"
      #imageInput
      type="file"
      id="image"
    />
    <i class="material-icons md-24">add</i>
  </button>
</div>
<app-footer></app-footer>
