import { TenantService } from "src/app/services/tenant.service";
import { Member } from "src/app/models/member";
import { HelperService } from "src/app/services/helper.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { ServiceRequest } from "src/app/models/service-request";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UploadModalComponent } from "../upload-modal/upload-modal.component";
import { UploadDialogModel } from "src/app/models/upload-dialog-model";
import { UploadFile } from "src/app/models/upload-file";
import { ConfirmationSnackbarComponent } from "../confirmation-snackbar/confirmation-snackbar.component";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

@Component({
  standalone: false,
  selector: "app-service-request",
  templateUrl: "./service-request.component.html",
  styleUrls: ["./service-request.component.scss"],
})
export class ServiceRequestComponent implements OnInit {
  constructor(
    private helperService: HelperService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tenantService: TenantService
  ) {}
  serviceCategories: SelectListItem[];
  serviceLocations: SelectListItem[];
  model: ServiceRequest;
  disableSave = true;
  @Input() member: Member;
  @Output() notifyParent = new EventEmitter();
  defaultLabel = "Select";
  uploadFileModel: UploadDialogModel;
  uploadedFiles: UploadFile[];
  showfileListDelete = false;
  showfileListTitle = false;
  faEnvelopeSquare = faEnvelopeSquare;
  ngOnInit() {
    this.getLists();
    this.model = {
      category: "Appliances",
      location: "Kitchen",
      name: `${this.member.firstName} service request`,
      description: "",
      images: null,
      tenantName: `${this.member.firstName} ${this.member.lastName}`,
      tenantEmail: this.member.email,
      gender: this.member.gender,
      memberId: this.member.id,
      tenantPhone: this.member.phone,
    };
  }

  getLists() {
    this.serviceCategories = this.helperService.serviceCategoriesGet();
    this.serviceLocations = this.helperService.serviceLocationsGet();
  }

  setValue(fieldName: string, val: string) {
    if (fieldName === "category") {
      if (val.length > 0) {
        this.model.category = val;
      }
    }
    if (fieldName === "location") {
      if (val.length > 0) {
        this.model.location = val;
      }
    }
    if (fieldName === "description") {
      if (val.length > 0) {
        this.model.description = val;
      }
    }
    this.disableSave =
      this.model.category.length > 0 &&
      this.model.description.length > 0 &&
      this.model.location.length > 0
        ? false
        : true;
  }

  openUploadFileDialog(): void {
    this.uploadFileModel = {
      title: "Drag and drop images here",
      subTitle: "",
      btnText: "Browse",
      imageOnly: true,
      allowMultiple: true,
      hasError: false,
      errorMessage: null,
      showCancel: false,
      uploadedFiles: null,
    };
    const dialogRef = this.dialog.open(UploadModalComponent, {
      height: "580px",
      width: "740px",
      data: this.uploadFileModel,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data.uploadedFiles) {
        this.uploadedFiles = Object.assign([], data.uploadedFiles);
        this.model.images = [];
        this.model.images = this.uploadedFiles.map((item) => ({
          access: "G",
          fileName: item.name,
          fileType: "",
          fileByteString: item.fileByteString,
          fileBytes: null,
        }));
      }
    });
  }

  sendRequest() {
    this.tenantService.serviceRequestSend(this.model).subscribe(() => {
      this.uploadedFiles = [];
      this.showConfirmationDialog("An email is sent to Ieb Coordinator.");
      this.notifyParent.emit(true);
    });
  }

  showConfirmationDialog(message: string) {
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 20000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: "success",
      },
    });
  }
}
