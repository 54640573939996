<app-header></app-header>
<div class="manage-area-container">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center" animationDuration="0ms">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="link.active"
      (click)="toggleTab(link.emptyResultType)"
    >
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <!-- <router-outlet></router-outlet> -->
  </mat-tab-nav-panel>
  <div *ngIf="emptyResultType === 'members'" class="empty-results-container">
    <app-members></app-members>
  </div>
  <div *ngIf="emptyResultType === 'users'" class="empty-results-container">
    <app-users></app-users>
  </div>
  <div *ngIf="emptyResultType === 'events'" class="empty-results-container">
    <app-events-manage></app-events-manage>
  </div>
  <div *ngIf="emptyResultType === 'teams'" class="empty-results-container">
    <app-team-manage></app-team-manage>
  </div>
  <div
    *ngIf="emptyResultType === 'tenantsManage'"
    class="empty-results-container"
  >
    <app-tenants-manage></app-tenants-manage>
  </div>
  <div
    *ngIf="emptyResultType === 'finance'"
    class="empty-results-container"
  ></div>
</div>
<app-footer></app-footer>
