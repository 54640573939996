<div class="ilgl-container">
  <div class="interfaith-section">
    <div class="header-section">
      <div class="ilgl-logo-container">
        <a
          class="anchor-link"
          href="https://interfaithleadersgl.home.blog/"
          target="_blank"
          ><img class="ilgl-logo" src="../../../assets/images/ilgl.jpg"
        /></a>
      </div>
      <div class="header">Interfaith Leaders of Greater Lafayette – (ILGL)</div>
    </div>

    <div class="interfaith-info-section">
      <div class="interfaith-info">
        <ul class="interfaith-list">
          <li class="list-item">
            <a
              class="anchor-link"
              href="https://interfaithleadersgl.home.blog/"
              target="_blank"
              >Interfaith Leaders Greater Lafayette (ILGL)</a
            >
            is a coalition of leaders of religious institutions and
            organizations in greater Lafayette dedicated to dialogue, service,
            hope, and a shared vision for our local community. We are united to
            create and support a community of respect, dignity, and
            responsibility for all. Through our work together, we hope to create
            an atmosphere of hospitality, belonging, compassion, and safety,
            where the city can stand firmly together in our diverse beliefs.
          </li>
          <li class="list-item">
            ILGL’s vision is to foster and sustain the multi-faith fabric of the
            greater Lafayette community through:
            <ul class="interfaith-list">
              <li class="list-item child-item">
                <fa-icon
                  class="list-item-arrow"
                  [icon]="faArrowAltCircleRight"
                ></fa-icon>
                Relationship building.
              </li>
              <li class="list-item child-item">
                <fa-icon
                  class="list-item-arrow"
                  [icon]="faArrowAltCircleRight"
                ></fa-icon>
                Community education.
              </li>
              <li class="list-item child-item">
                <fa-icon
                  class="list-item-arrow"
                  [icon]="faArrowAltCircleRight"
                ></fa-icon>
                Celebrating diversity.
              </li>
            </ul>
          </li>
          <li class="list-item">
            Islamic Society of GL is an active participant of all ILGL
            interfaith activities. These include hosting interfaith iftars,
            meeting city officials against hate crimes, sending public awareness
            and goodwill messages on key social issues, etc
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
