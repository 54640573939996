<div class="rooms-container">
  <div class="rooms-wrapper">
    <div class="rooms-header">
      <div class="header">Manage Rooms</div>
    </div>
    <div class="rooms-content">
      <div class="rooms-row" *ngFor="let room of roomList">
        <div class="rooms-item-container">
          <div class="item-wrapper">
            <app-flat-input
              [placeHolder]="'Room'"
              [(value)]="room.name"
              [hasError]="room.validation.name.hasError"
              [errorMessage]="room.validation.name.errorMessage"
              (focusChanged)="setUpdate('name', room.name, room.id)"
              matTooltip="Room name"
              [matTooltip]="!room.validation.name.hasError ? 'Room name' : null"
              matTooltipPosition="right"
            >
            </app-flat-input>
            <div class="delete-wrapper-mob">
              <div
                class="delete-item"
                [ngClass]="{ 'disable-delete': !room.isRoomAvailable }"
                [matTooltip]="
                  !room.isRoomAvailable
                    ? 'Non-editable, this room is associated with existing lease'
                    : null
                "
                matTooltipPosition="right"
                *ngIf="!hasError"
              >
                <i
                  class="material-icons delete-item-button"
                  (click)="delete(room)"
                  >delete</i
                >
              </div>
            </div>
          </div>
        </div>
        <div class="rooms-item-container">
          <div class="item-select-wrapper" *ngIf="room.isRoomAvailable">
            <app-flat-select
              [selectOptions]="occupanyList"
              [(ngModel)]="room.occupancy"
              [(value)]="room.occupancy"
              [hasError]="room.validation.occupancy.hasError"
              [errorMessage]="room.validation.occupancy.errorMessage"
              (selectOptionChange)="setUpdate('occupancy', $event, room.id)"
              [matTooltip]="
                !room.validation.occupancy.hasError ? 'Occupancy' : null
              "
              matTooltipPosition="right"
              ngDefaultControl
            ></app-flat-select>
          </div>
          <div class="item-wrapper cap-width" *ngIf="!room.isRoomAvailable">
            <app-flat-input
              [placeHolder]="'Occupancy'"
              [(value)]="room.description"
              matTooltip="Occupancy"
              matTooltipPosition="right"
              [disabled]="true"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="rooms-item-container">
          <div class="item-select-wrapper">
            <mat-form-field class="form-field">
              <!-- <mat-label>Rents</mat-label> -->
              <mat-select-trigger>
                {{
                  room.rentList && room.rentList.length > 0
                    ? room.rentList[0].label
                    : ""
                }}
                <span
                  *ngIf="room.selectedRents?.length > 1"
                  class="additional-rent"
                >
                  (+{{ room.selectedRents.length - 1 }}
                  {{ room.selectedRents?.length === 2 ? "other" : "others" }})
                </span>
              </mat-select-trigger>
              <mat-select
                [(ngModel)]="room.selectedRents"
                multiple
                name="rentList"
                (selectionChange)="
                  setUpdate('rentList', room.selectedRents, room.id)
                "
              >
                <mat-option
                  *ngFor="let rent of room.rentList"
                  [value]="rent.value"
                  [disabled]="rent.disabled"
                  class="option-rent"
                >
                  {{ rent.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="rooms-item-container">
          <div class="item-wrapper date">
            <div class="input-date date-picker">
              <input
                [matDatepicker]="datePicker"
                class="input-date"
                [(ngModel)]="room.availableDate"
                (dateInput)="datePicker.close()"
                (dateChange)="
                  setUpdate('availableDate', room.availableDate, room.id);
                  datePicker.close()
                "
                (focus)="datePicker.open()"
                readonly
              />
              <mat-datepicker-toggle
                class="item-date-picker"
                matSuffix
                [for]="datePicker"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="rooms-item-container">
          <div class="item-wrapper">
            <div
              class="delete-wrapper"
              [matTooltip]="
                !room.isRoomAvailable
                  ? 'Non-editable, this room is associated with existing lease'
                  : null
              "
              matTooltipPosition="right"
            >
              <div
                class="delete-item"
                [ngClass]="{ 'disable-delete': !room.isRoomAvailable }"
                *ngIf="!hasError"
              >
                <i
                  class="material-icons delete-item-button"
                  (click)="delete(room)"
                  >delete</i
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      mat-fab
      class="add-room-button-fab"
      matTooltip="Add Room"
      matTooltipPosition="left"
      (click)="addRoom()"
      *ngIf="!showAddRoom"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-room-container"
      *ngIf="showAddRoom"
      [ngClass]="{ visible: showAddRoom, invisible: !showAddRoom }"
    >
      <div class="new-room">
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <app-flat-select
              [selectOptions]="occupanyList"
              [(ngModel)]="newRoom.occupancy"
              [defaultLabel]="'Select occupancy'"
              [(value)]="newRoom.occupancy"
              [position]="'top'"
              (selectOptionChange)="setNewRent('occupancy', $event)"
              ngDefaultControl
            ></app-flat-select>
          </div>
        </div>
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <mat-form-field class="new-form-field">
              <!-- <mat-label>Rents</mat-label> -->
              <mat-select-trigger>
                {{
                  newRoom.rentList && newRoom.rentList.length > 0
                    ? newRoom.rentList[0].label
                    : ""
                }}
                <span
                  *ngIf="newRoom.selectedRents?.length > 1"
                  class="additional-rent"
                >
                  (+{{ newRoom.selectedRents.length - 1 }}
                  {{
                    newRoom.selectedRents?.length === 2 ? "other" : "others"
                  }})
                </span>
              </mat-select-trigger>
              <mat-select
                [(ngModel)]="newRoom.selectedRents"
                multiple
                name="newRoomRentList"
                (selectionChange)="onSelectionChange($event)"
              >
                <mat-option
                  *ngFor="let rent of newRoom.rentList"
                  [value]="rent.value"
                  [disabled]="rent.disabled"
                  class="option-rent"
                >
                  {{ rent.label }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <app-flat-input
              [placeHolder]="'Room Name'"
              [(value)]="newRoom.name"
              [hasError]="newRoom.validation.name.hasError"
              [errorMessage]="newRoom.validation.name.errorMessage"
              (focusChanged)="setNewRent('name', newRoom.name)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <div class="input-date date-picker">
              <input
                [matDatepicker]="datePicker1"
                class="input-date"
                [(ngModel)]="newRoom.availableDate"
                (dateInput)="datePicker1.close()"
                (dateChange)="
                  setNewRent('availableDate', newRoom.availableDate);
                  datePicker1.close()
                "
                (focus)="datePicker1.open()"
                readonly
              />
              <mat-datepicker-toggle
                class="item-date-picker"
                matSuffix
                [for]="datePicker1"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker1></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <div class="form-buttons">
              <button
                type="button"
                class="text-button"
                [disabled]="newRoom.validation.hasError"
                (click)="saveRoom()"
              >
                SAVE
              </button>
              <button
                type="button"
                class="text-button cancel"
                (click)="closeAddRoom()"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
