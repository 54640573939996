<div class="page-container">
  <app-header></app-header>
  <div class="annoucement-detail-container">
    <div class="annoucement-detail-section" *ngIf="event">
      <div class="header-section">
        <div class="header">{{ event.name }}</div>
        <div class="annoucement-date" *ngIf="event.eDate">
          {{ event.eDate }}
        </div>
        <div class="annoucement-date-mobile" *ngIf="eDateMobil">
          {{ eDateMobil }}
        </div>
      </div>
      <div class="images-container">
        <!-- <mat-carousel
          timings="200ms ease-in"
          [autoplay]="true"
          interval="6000"
          color="accent"
          orientation="ltr"
          [hideArrows]="false"
          [hideIndicators]="false"
          [useKeyboard]="true"
          [useMouseWheel]="false"
          [proportion]="40"
        >
          <mat-carousel-slide
            *ngFor="let slide of slides; let i = index"
            [image]="slide.image"
            overlayColor="#00000040"
            [hideOverlay]="false"
          ></mat-carousel-slide>
        </mat-carousel> -->
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
