<div class="page-container">
  <div class="page-filter-container">
    <div class="page-info-container wide">
      <div class="total-count-container">
        <div
          class="total-count-text"
          *ngIf="listResponse && listResponse.totalCount"
        >
          {{ countText }}
        </div>
      </div>
    </div>

    <div class="page-search-container" >
      <input
        type="text"
        placeholder="Search by First, Last Name, and Email"
        class="page-search-input"
        [(ngModel)]="query"
        (keyup)="tenantSearch($event)"
      />
      <fa-icon
        [icon]="faTimesCircle"
        class="page-clear-search-icon"
        *ngIf="query"
        (click)="clearSearch()"
      ></fa-icon>
      <a class="page-search-btn" (click)="tenantSearch($event)">
        <span class="page-search-btn-content">
          <fa-icon [icon]="faSearch" class="page-search-icon"></fa-icon>
        </span>
      </a>
    </div>

    <div class="filter-items">
      <div class="filter-item">
        <div class="filter-checkbox-wrapper">
          <mat-checkbox
            id="expired"
            [(ngModel)]="expiredOnly"
            [(checked)]="expiredOnly"
            (change)="applyFilter()"
          >
          </mat-checkbox>
          <span class="filter-checkbox-text">Expired Only </span>
        </div>
      </div>
    </div>
  </div>
  <div class="empty-row" *ngIf="showEmpty">
    <div class="empty-message">No Tenants Found!</div>
  </div>
  <div
    class="page-table-container"
    *ngIf="!showEmpty && tableData; else loading"
  >
    <div>
      <table mat-table [dataSource]="tableData" *ngIf="tenantsLoaded | async" matSort>
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </th>
          <td mat-cell *matCellDef="let tenant">
            {{ tenant.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="room">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            Room/Occupancy
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column" >
            {{ tenant.room }}
          </td>
        </ng-container>
        <ng-container matColumnDef="start">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Start
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column">
            {{ tenant.startDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="end">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            End
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column">
            {{ tenant.endDate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="parking">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Parking
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column">
            {{ tenant.parkingSpace }}
          </td>
        </ng-container>
        <ng-container matColumnDef="leaseStatus">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Lease Status
          </th>
          <td mat-cell *matCellDef="let tenant">
            {{ tenant.leaseStatus }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rentPaid">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Rent Paid
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column">
            <span class="rent" [ngClass]="tenant.rentCellClass">
              {{ tenant.monthlyRentCollected }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="hasPendingAddendums">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Addendums
          </th>
          <td mat-cell *matCellDef="let tenant" class="hide-column">
            <div *ngIf="tenant.hasPendingAddendums" class="btn-container">
              <button
                mat-raised-button
                class="addendums-btn"
                (click)="checkAddendums($event, tenant.id)"
              >
                Verify
              </button>
            </div>
            <div *ngIf="!tenant.hasPendingAddendums" class="btn-container">
              -
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="showTenantSummary(row.id)"
          [ngClass]="row.rowClass"
        ></tr>
      </table>
    </div>
    <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div>
  </div>
  <div class="summary-wrapper">
    <div
      class="page-summary-container"
      [ngClass]="{
        'summary-panel-slide-in': showSummary,
        'summary-panel-slide-out': hideSummary
      }"
    >
      <div class="page-summary-header">
        <div class="page-summary-header-name">
          {{ summaryName }}
          <div class="summary-name">
            {{ summaryTenantName }}
          </div>
        </div>
      </div>
      <div class="close-summary-wrapper">
        <a class="close-summary-button" (click)="hideUserSummary()">
          <i class="material-icons expand">expand_more</i>
        </a>
        <span class="close-info"> click or escape to close </span>
      </div>
      <mat-tab-group
        #memberSummaryTabs
        class="summary-tabs"
        animationDuration="0ms"
        disableRipple="true"
        (selectedIndexChange)="selectedTabChange($event)"
      >
        <mat-tab label="Details" *ngIf="tenantDetails">
          <app-tenant-details
            [model]="tenantDetails"
            (notifyVerification)="setVerification($event)"
            (notifyUpdate)="updateTenant($event)"
            *ngIf="selectedTenant"
          ></app-tenant-details>
        </mat-tab>
        <mat-tab label="Lease">
          <app-lease-details
            [tenantInfo]="tenantDetails"
            [existingCoordSig]="existingCoordSig"
            (notifyParent)="setAutoRenewal($event)"
            (notifyLeaseSigned)="setLeaseSigned($event)"
          ></app-lease-details>
        </mat-tab>
        <mat-tab label="Payments">
          <app-tenant-payments
            [model]="paymentSummary"
            *ngIf="paymentSummary"
          ></app-tenant-payments>
        </mat-tab>
        <mat-tab label="Late Fee Waiver" *ngIf="tenantDetails">
          <app-late-fee-waiver
            [tenantInfo]="tenantDetails"
          ></app-late-fee-waiver>
        </mat-tab>
        <mat-tab label="Lease Update" *ngIf="tenantDetails">
          <app-lease-update
            [tenantInfo]="tenantDetails"
            (notifyParent)="leaseUpdate($event)"
          ></app-lease-update>
        </mat-tab>
        <mat-tab label="Payment Override" *ngIf="tenantDetails && tenantDetails.leaseInfo && tenantDetails.leaseInfo.statusId > 0 && tenantDetails.leaseInfo.statusId < 5">
          <app-tenant-override
            [tenantInfo]="tenantDetails"
            (notifyParent)="updateTenant($event)"
          ></app-tenant-override>
        </mat-tab>
        <mat-tab label="Add Payment" *ngIf="tenantDetails && tenantDetails.leaseInfo && tenantDetails.leaseInfo.statusId > 0 && tenantDetails.leaseInfo.statusId < 5">
          <app-tenant-payment-add
            [tenant]="tenantDetails"
            (notifyParent)="tenantCustomPaymentAdded($event)"
          ></app-tenant-payment-add>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <ng-template #loading>
    <app-card-skeleton></app-card-skeleton>
  </ng-template>
</div>
