import { Component, OnInit } from '@angular/core';
import config from 'src/assets/config.json';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
@Component({
  standalone: false,
  selector: 'app-constitution',
  templateUrl: './constitution.component.html',
  styleUrls: ['./constitution.component.scss']
})
export class ConstitutionComponent implements OnInit {

  constructor() { }
  storedFileName = '6ea6292c-a0c8-4dfa-92c8-97e5df1d0c19';
  fileUrl: string;
  fileName = 'ISGL Constitution 202003.pdf';
  faFileDownload = faFileDownload;
  ngOnInit() {
    this.fileUrl = `${config.fileBaseUrl}${this.storedFileName}`;
  }

  // downloadFile() {
  //   window.open(this.fileUrl);
  // }

}
