<div class="files-list">
  <div
    class="title"
    *ngIf="uploadedFiles && uploadedFiles.length > 0 && showTitle"
  >
    Uploaded Files
  </div>
  <div class="single-file" *ngFor="let file of uploadedFiles; let i = index">
    <div class="file-icon" style="width: 50px">
      <i class="material-icons file-upload">file_copy</i>
    </div>
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-upload-progress [progress]="file?.progress"></app-upload-progress>
    </div>
    <div class="delete-wrapper" *ngIf="showDelete && file.progress === 100">
      <div class="hover-wrapper button-warn" (click)="deleteFile(file.id)">
        <i class="material-icons input-button">delete</i>
      </div>
    </div>
  </div>
</div>
