<div class="ext-request-container">
  <div class="item-container" *ngIf="showSubHeader">
    <div class="sub-header">{{ headerTitle }}</div>
  </div>
  <div class="item-container">
    <div class="item-label-wrapper" *ngIf="showAddendumDate">
      <span class="item-label">Addendum Date:</span>
    </div>
    <div class="item-input-wrapper medium left-close" *ngIf="showAddendumDate">
      <app-flat-input
        [placeHolder]="'Addendum Date'"
        [(value)]="addendumDate"
        [(ngModel)]="addendumDate"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Room:</span>
    </div>
    <div class="item-input-wrapper medium left-close">
      <app-flat-input
        [placeHolder]="'Room'"
        [(value)]="roomName"
        [(ngModel)]="roomName"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Monthly Rent:</span>
    </div>
    <div class="item-input-wrapper medium left-close">
      <app-flat-input
        [placeHolder]="'Rent'"
        [(value)]="rentAmount"
        [(ngModel)]="rentAmount"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
  </div>
  <div class="item-container">
    <div class="item-label-wrapper">
      <span class="item-label">Duration:</span>
    </div>
    <div class="item-select-wrapper" *ngIf="!disableControls">
      <app-flat-select
        [selectOptions]="extDurationList"
        [(ngModel)]="selectedExtDuration"
        [defaultLabel]="'Select'"
        [(value)]="selectedExtDuration.value"
        [position]="'bottom'"
        (selectOptionChange)="setDuration($event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-input-wrapper medium left-close" *ngIf="disableControls">
      <app-flat-input
        [placeHolder]="'Start'"
        [(value)]="selectedExtDuration.label"
        [(ngModel)]="selectedExtDuration.label"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">Start:</span>
    </div>
    <div class="item-input-wrapper medium left-close">
      <app-flat-input
        [placeHolder]="'Start'"
        [(value)]="startDate"
        [(ngModel)]="startDate"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper">
      <span class="item-label">End:</span>
    </div>
    <div class="item-input-wrapper medium left-close">
      <app-flat-input
        [placeHolder]="'End'"
        [(value)]="endDate"
        [(ngModel)]="endDate"
        [disabled]="true"
      >
      </app-flat-input>
    </div>
  </div>
  <div class="ext-btn-container">
    <div class="child-container" [ngClass]="{ center: hasSigError }">
      <span *ngIf="hasSigError" class="error-icon-container">
        <span class="error-icon"><mat-icon>error_outline</mat-icon></span>
        <span class="error-text">Required!</span>
      </span>
      <button
        mat-raised-button
        class="upload-btn"
        (click)="openSignatureDialog()"
        [disabled]="disableSig"
      >
        <fa-icon [icon]="faSignature" class="icon-buttons"></fa-icon>
        Click to Sign
      </button>
    </div>
    <div class="child-container">
      <button
        mat-raised-button
        class="ext-add-btn"
        [disabled]="hasError"
        (click)="addAddendum()"
      >
        <i class="material-icons icon">save</i>
        Save Extension
      </button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="!notLoading">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</div>
