<div class="ieb-contacts-container">
  <div class="ieb-section">
    <div class="header-section">
      <div class="header">IEB-Contacts</div>
    </div>
    <div class="ieb-info-section">
      <div class="ieb-info">
        <div class="ieb-contacts-card-row" *ngIf="team">
          <div *ngFor="let card of team">
            <app-team-card [team]="card"></app-team-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
