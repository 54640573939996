<app-header></app-header>
<div class="interfaith-container">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center" animationDuration="0ms">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="link.active"
      (click)="toggleTab(link.emptyResultType)"
    >
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <!-- <router-outlet></router-outlet> -->
  </mat-tab-nav-panel>
  <div *ngIf="emptyResultType === 'ilgl'" class="empty-results-container">
    <app-ilgl></app-ilgl>
  </div>
  <div *ngIf="emptyResultType === 'activities'" class="empty-results-container">
    <!-- <app-nikah></app-nikah> -->
  </div>
  <div *ngIf="emptyResultType === 'resources'" class="empty-results-container">
    <!-- <app-janazah></app-janazah> -->
  </div>
</div>
<app-footer></app-footer>
