<div class="pspaces-container">
  <div class="pspaces-wrapper">
    <div class="pspaces-header">
      <div class="header">Manage Parking Spaces</div>
    </div>
    <div class="pspaces-row" *ngFor="let parking of parkings">
      <div class="pspaces-item-container">
        <div class="item-wrapper skinny">
          <app-flat-input
            [placeHolder]="'Parking Name'"
            [(value)]="parking.name"
            [hasError]="parking.validation.name.hasError"
            [errorMessage]="parking.validation.name.errorMessage"
            (focusChanged)="setUpdate('name', parking.name, parking.id)"
            matTooltip="Parking name"
            [matTooltip]="
              !parking.validation.name.hasError ? 'Parking name' : null
            "
            matTooltipPosition="right"
          >
          </app-flat-input>
          <div class="delete-wrapper-mob">
            <div
              class="delete-item"
              [ngClass]="{ 'disable-delete': !parking.isAvailable }"
              [matTooltip]="
                !parking.isAvailable
                  ? 'Non-editable, this parking is allocated to a tenant'
                  : null
              "
              matTooltipPosition="right"
              *ngIf="!parking.validation.hasError"
            >
              <i
                class="material-icons delete-item-button"
                (click)="delete(parking)"
                >delete</i
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pspaces-item-container">
        <div class="item-wrapper date">
          <div class="input-date date-picker">
            <input
              [matDatepicker]="datePicker"
              class="input-date"
              [(ngModel)]="parking.availableDate"
              (dateInput)="datePicker.close()"
              (dateChange)="
                setUpdate('availableDate', parking.availableDate, parking.id);
                datePicker.close()
              "
              (focus)="datePicker.open()"
              readonly
            />
            <mat-datepicker-toggle
              class="item-date-picker"
              matSuffix
              [for]="datePicker"
            >
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="pspaces-item-container">
        <div class="item-wrapper">
          <div
            class="delete-wrapper"
            [matTooltip]="
              !parking.isAvailable
                ? 'Non-editable, this parking is allocated to a tenant'
                : null
            "
            matTooltipPosition="right"
          >
            <div
              class="delete-item"
              [ngClass]="{ 'disable-delete': !parking.isAvailable }"
              *ngIf="!parking.validation.hasError"
            >
              <i
                class="material-icons delete-item-button"
                (click)="delete(parking)"
                >delete</i
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      mat-fab
      class="add-pspace-button-fab"
      matTooltip="Add Room"
      matTooltipPosition="left"
      (click)="addParking()"
      *ngIf="!showAddParking"
    >
      <i class="material-icons md-24">add</i>
    </button>
    <div
      class="add-pspace-container"
      *ngIf="showAddParking"
      [ngClass]="{ visible: showAddParking, invisible: !showAddParking }"
    >
      <div class="new-pspace">
        <div class="new-pspace-wrapper">
          <div class="new-pspace-item-wrapper">
            <app-flat-input
              [placeHolder]="'Parking Name'"
              [(value)]="newParking.name"
              [hasError]="newParking.validation.name.hasError"
              [errorMessage]="newParking.validation.name.errorMessage"
              (focusChanged)="setNewParking('name', newParking.name)"
            >
            </app-flat-input>
          </div>
        </div>
        <div class="new-pspace-wrapper">
          <div class="new-pspace-item-wrapper">
            <div class="input-date date-picker">
              <input
                [matDatepicker]="datePicker1"
                class="input-date"
                [(ngModel)]="newParking.availableDate"
                (dateInput)="datePicker1.close()"
                (dateChange)="
                  setNewParking('availableDate', newParking.availableDate);
                  datePicker1.close()
                "
                (focus)="datePicker1.open()"
                readonly
              />
              <mat-datepicker-toggle
                class="item-date-picker"
                matSuffix
                [for]="datePicker1"
              >
              </mat-datepicker-toggle>
              <mat-datepicker #datePicker1></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="new-room-wrapper">
          <div class="new-room-item-wrapper">
            <div class="form-buttons">
              <button
                type="button"
                class="text-button"
                [disabled]="newParking.validation.hasError"
                (click)="saveParking()"
              >
                SAVE
              </button>
              <button
                type="button"
                class="text-button cancel"
                (click)="closeAddParking()"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
