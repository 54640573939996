import { ReducedFeeKidSearchResult } from './../../models/school/reduced-fee-kid-search-result';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faChild, faTimes} from '@fortawesome/free-solid-svg-icons';
import * as _moment from "moment";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ReducedFeeKid } from 'src/app/models/school/reduced-fee-kid';
import { SchoolManageService } from 'src/app/services/school-manage.service';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM-DD-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "MM-DD-YYYY",
    monthYearA11yLabel: "MM-DD-YYYY",
  },
};

@Component({
  standalone: false,
  selector: 'app-ieb-reduced-fee-kid-modal',
  templateUrl: './ieb-reduced-fee-kid-modal.component.html',
  styleUrls: ['./ieb-reduced-fee-kid-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class IebReducedFeeKidModalComponent implements OnInit, OnDestroy {
  model : ReducedFeeKid
  showSearch: boolean;
  faChild = faChild;
  faTimes= faTimes;
  headerTitle = 'Update Reduced Fee Kid';
  minDate: Date;
  btnText = 'Update';
  feeForm!: FormGroup;
  searchSubscription$!: Subscription;
  private kidSearchSub = new Subject<string>();
  searchSource$ = this.schoolService.reducedFeeKidSearch$;
  disableBtn = true;
  validTill: Date;
  validFrom: Date;
  name: string;
  constructor(public dialogRef: MatDialogRef<IebReducedFeeKidModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReducedFeeKid, private formBuilder: FormBuilder,
    private schoolService: SchoolManageService) {
      this.model = Object.assign({}, data); }

  ngOnInit() {
    this.feeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      parentName: ['', [Validators.required]],
      validFrom: ['', [Validators.required]],
      validTill: ['', [Validators.required]]
    });
      let dt = moment().clone().local();
      this.minDate =  new Date (dt.year(), dt.month(), dt.date())//new Date(dt.year(), 7, 1)

      if(!this.model.validTill) {
        this.validTill = new Date(dt.year()+1, 4, 31);
      }
      if(!this.model.validFrom) {
        this.validFrom = new Date(dt.year(), 7, 1);
      }
    if(this.model){
      this.showSearch = !this.model.kidId;
      this.headerTitle = !this.model.id ? 'Add Reduced Fee Kid' : this.headerTitle;
      this.btnText = !this.model.id ? 'Save' : this.btnText;
      this.validTill = this.model.validTill ? this.model.validTill : this.validTill;
      this.validFrom = this.model.validFrom ? this.model.validFrom : this.validFrom;
    }
    this.searchSubscription$ = this.kidSearchSub.pipe(debounceTime(500)).subscribe((query: string) => {
      this.searchChanged(query);
    });
  }

  ngOnDestroy(): void {
    this.searchSubscription$.unsubscribe();
  }

  setDisable(): void {
    if(!this.model.id) {
      return;
    }
    let validTill = moment(this.model.validTill).clone().format('MM-DD-YYYY');
    if(moment(moment(this.validTill).clone().format('MM-DD-YYYY')).isSame(validTill)) {
      this.disableBtn = true;
    } else {
      this.disableBtn = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  upsertReducedFeeKid(): void {
    this.model.validFrom = this.validFrom;
    this.model.validTill = this.validTill;
    this.dialogRef.close(this.model);
  }

  searchChanged(query: string): void {
    this.schoolService.kidSearch(query);
  }

  selectedKid(selected : ReducedFeeKidSearchResult) : void {
    this.model = {
      id : 0,
      kidId: selected.kidId,
      name: selected.name,
      parentName: selected.parentName,
    };
    this.name = selected.name;
    this.disableBtn = false;
  }

}
