<div class="janazah-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Funeral/Janazah</div>
    </div>

    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            Please contact ISGL and inform about the death immediately.
          </li>
          <li class="list-item">
            We have secured burial space in
            <a
              class="anchor-link"
              href="https://www.google.com/maps/place/Tippecanoe+Memory+Gardens,+Funeral+%26+Cremation+Services/@40.4685932,-86.9380667,205m/data=!3m1!1e3!4m5!3m4!1s0x8812fd06de960545:0x3029da57dc6998c8!8m2!3d40.4682842!4d-86.9374886"
              target="_blank"
            >
              Tippecanoe Memory Gardens(TMG)
            </a>
            at &nbsp;
            <span class="bold-text">1718 W 350 N, West Lafayette, IN 47906</span
            >.
          </li>
          <li class="list-item">
            Once cleared by the authorities, body is moved to the TMG for
            washing. ISGL provides appropriate shroud (kafan) for the deceased.
            Body is then placed in the cold storage at TMG.
          </li>
          <li class="list-item">
            At the funeral time, body is brought to ISGL masjid. After the
            janazah prayers, it is taken to the burial site.
          </li>
          <li class="list-item">
            ISGL does not charge any fee or the funeral servies. Current TMG
            costs are around &nbsp;
            <span class="bold-text">~$3,900/-,</span> &nbsp; all inclusive.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
