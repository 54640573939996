<span *ngIf="data.type === 'delete-kid'" class="delete-sack-bar-container">
  <span> {{ data.displayText }} </span>
  <span>
    <button mat-button (click)="undo()" class="mat-button undo-button">
      Undo
    </button>
  </span>
  <i class="material-icons delete-button" (click)="dismiss()">close</i>
</span>

<span *ngIf="data.type === 'message'" class="delete-sack-bar-container">
  <span class="icon-container">
    <mat-icon class="icon-success">check_circle_outline</mat-icon>
  </span>
  <span class="message-container">
    {{ data.displayText }}
  </span >
  <i class="material-icons delete-button" (click)="dismiss()">close</i>
</span>

<div *ngIf="data.type === 'success' || data.type === 'error' || data.type === 'warning'" class="snackbar">
  <div class="icon-container" >
    <mat-icon *ngIf="data.type === 'success'" class="icon icon-success">check_circle_outline</mat-icon>
    <mat-icon *ngIf="data.type === 'error'" class="icon icon-error">error_outline</mat-icon>
    <mat-icon *ngIf="data.type === 'warning'" class="icon icon-warning">warning</mat-icon>
  </div>
    <div *ngIf="data.type === 'success' || data.type === 'warning'" class="message-container">
      {{ data.displayText }}
    </div>
    <div *ngIf="data.type === 'error'" class="message-container error-message">
      {{ data.displayText }}
    </div>
    <div class="mat-button-wrapper">
      <i class="material-icons delete-button" (click)="dismiss()">OK</i>
    </div>

</div>
