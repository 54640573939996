<div mat-dialog-content>
  <div class="registration-header">
    <div class="close-button" (click)="closeModal()">
      <a><fa-icon [icon]="faTimes"></fa-icon></a>
    </div>
    <img class="pi-logo" src=".../../../assets/images/isgl-logo.jpg" />
    <div class="header-message">
      <span class="user-icon">
        <fa-icon [icon]="faUserPlus"></fa-icon>
      </span>
      <span class="header-message-bold">Register</span>
    </div>
  </div>

  <div class="divider"></div>
  <div class="modal-content">
    <div class="register-user-container" *ngIf="showForm">
      <form [formGroup]="registerUserForm" (ngSubmit)="registerUser()">
        <div class="row spacer">
          <input
            required
            formControlName="firstName"
            id="firstName"
            type="text"
            class="register-user-input"
            placeholder="First name"
            value="{{ newUser.firstName }}"
            [ngClass]="{
              invalid:
                (firstName.dirty || firstName.touched) && firstName.errors
            }"
            [(ngModel)]="newUser.firstName"
          />
          <div
            *ngIf="firstName.errors && firstName.errors.required"
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
        <div class="row spacer">
          <input
            required
            formControlName="lastName"
            id="lastName"
            type="text"
            class="register-user-input"
            placeholder="Last name"
            value="{{ newUser.lastName }}"
            [ngClass]="{
              invalid: (lastName.dirty || lastName.touched) && lastName.errors
            }"
            [(ngModel)]="newUser.lastName"
          />
          <div
            *ngIf="lastName.errors && lastName.errors.required"
            class="validation-error-container"
          >
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span class="validation-error">Required.</span>
            </div>
          </div>
        </div>
        <div class="row spacer">
          <input
            required
            formControlName="email"
            id="email"
            type="text"
            class="register-user-input"
            placeholder="Email/Username"
            value="{{ newUser.email }}"
            [ngClass]="{
              invalid: (email.dirty || email.touched) && email.errors
            }"
            [(ngModel)]="newUser.email"
          />
          <div *ngIf="email.errors" class="validation-error-container">
            <div class="validation-error-icon">
              <i class="material-icons">error</i>
            </div>
            <div class="validation-error-content">
              <span
                class="validation-error"
                *ngIf="email.errors && email.errors.required"
                >Required.</span
              >
              <span
                class="validation-error"
                *ngIf="email.errors && email.errors.email"
                >Invalid.</span
              >
              <span
                class="validation-error"
                *ngIf="email.errors && email.errors.emailExists"
                >Email already exists.</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="btn-container">
            <button
              mat-raised-button
              class="register-button"
              [disabled]="!registerUserForm.valid"
              type="submit"
            >
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="register-message-container" *ngIf="!showForm">
      <div class="row center">
        <span class="register-message-icon">
          <fa-icon [icon]="faCheckCircle"></fa-icon>
        </span>
      </div>
      <div class="row spacer">
        <span class="register-message">
          <p><b>Alhamdullilah</b></p>
          {{ newUser.firstName }} you are successfully registered, Please check
          your email for further instructions.
          <b>
            <p class="register-notice">
              Make sure to check your spam folder. Email will be expired in 48
              hours.
            </p>
          </b>
        </span>
      </div>
      <div class="row">
        <div class="btn-container">
          <button
            mat-raised-button
            class="register-button"
            (click)="closeModal()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
