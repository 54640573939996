import { RoomUpsert } from "./../models/room-upsert";
import { RoomImageInfo } from "./../models/room-image-info";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ListRequest } from "../models/list-request";
import { RoomFileRequest } from "../models/room-file-request";
import { RoomInfo } from "../models/room-info";
import config from 'src/assets/config.json';
import { GenericDataService } from "./generic-data.service";

@Injectable({
  providedIn: "root",
})
export class RoomService {
  public roomSubject$ = new Subject<boolean>();
  rooms: RoomInfo[];
  room: RoomInfo;
  roomImageInfo: RoomImageInfo;
  public selectedRoom: RoomInfo;
  constructor(
    private genericDataService: GenericDataService
  ) {}

  public roomList(listRequest: ListRequest): any {
    this.genericDataService.endPoint = "Room/List";

    const listObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(listRequest).subscribe((data) => {
        this.rooms = Object.assign([], data.items);
        subscriber.next(this.rooms);
      });
    });
    return listObservable$;
  }

  public roomAdd(request: RoomUpsert): any {
    this.genericDataService.endPoint = "Room/Add";
    const roomObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.room = Object.assign({}, data);
        subscriber.next(this.room);
      });
    });
    return roomObservable$;
  }

  public roomImageAdd(request: RoomFileRequest): any {
    this.genericDataService.endPoint = "Room/File/Add";
    const fileObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.roomImageInfo = Object.assign({}, data);
        this.roomImageInfo.url = `${config.fileBaseUrl}${this.roomImageInfo.storedFileName}`;
        subscriber.next(this.roomImageInfo);
      });
    });

    return fileObservable$;
  }

  public roomUpdate(request: RoomUpsert): any {
    this.genericDataService.endPoint = "Room/Update";
    const roomObservable$ = new Observable((subscriber) => {
      this.genericDataService.post<any>(request).subscribe((data) => {
        this.room = Object.assign({}, data);
        subscriber.next(this.room);
      });
    });
    return roomObservable$;
  }

  public roomRemove(id: number): any {
    this.genericDataService.endPoint = "Room/Remove";
    const roomObservable$ = new Observable((observer) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.roomSubject$.next(true);
      });
    });
    return roomObservable$;
  }

  public roomImageRemove(id: number): any {
    this.genericDataService.endPoint = "Room/File/Remove";
    const fileObservable$ = new Observable((observer) => {
      this.genericDataService.delete<any>(null, id).subscribe((data) => {
        this.roomSubject$.next(true);
      });
    });
    return fileObservable$;
  }

  public roomGet(id: number): any {
    this.genericDataService.endPoint = "Room";
    const roomObservable$ = new Observable((subscriber) => {
      this.genericDataService.readById<RoomInfo>(null, id).subscribe((data) => {
        this.room = Object.assign({}, data);
        if (this.room.roomImages && this.room.roomImages.length > 0) {
          this.room.roomImages.map((item) => {
            item.url = `${config.fileBaseUrl}${item.storedFileName}`;
          });
        }
        subscriber.next(this.room);
      });
    });
    return roomObservable$;
  }
}
