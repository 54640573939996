<div class="room-card-container" *ngIf="!room.name.includes('Special')">
  <div class="card-row">
    <div class="room-container">
      <div class="row room-content spacer-bottom">
        {{ room.name }} {{ occupancy }}
        <span class="gender-text"> {{ genderText }}</span>
      </div>
      <div class="column">
        <div class="row room-row room-info" *ngIf="memberId">
          Rent: <span class="text">${{ room.rents[0].amount }} monthly</span>
        </div>
        <div class="row room-row room-info" *ngIf="memberId">
          Parking:
          <span class="text">$25 monthly</span>
        </div>
        <div class="row room-row room-info">
          <div
            class="room-availability-status"
            [ngClass]="{
              available: room.isAvailable,
              leased: !room.isAvailable
            }"
            matTooltip="{{ roomStatusToolTip }}"
            matTooltipPosition="above"
          ></div>
          <div class="room-availability-text">
            <span class="status">{{ roomStatusText }}</span>
          </div>
        </div>
        <div class="row book-room">
          <div class="btn-container">
            <button
              mat-raised-button
              class="book-room-btn"
              [ngClass]="{ disabled: !room.isAvailable }"
              (click)="bookRoom()"
            >
              {{ roomBtnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
