<div class="page-container" >
  <div class="page-header">
    <div class="header">
      Reduced Fee
    </div>
  </div>

  <div class="page-table-container">
    <div class="top-btn-container">
      <div class="panel-btn-wrapper">
        <button mat-mini-fab class="btn btn-thin btn-secondary-blue" (click)="addKid()" matTooltip="Add Reduced Fee Kid">
          <span class="icon"><fa-icon [icon]="faPlus"></fa-icon></span>
          Add New
        </button>
      </div>
    </div>
    <div *ngIf="dataSource$ | async as vm; else loading">
      <table mat-table [dataSource]="vm" matSort >
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="parentName">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Parent Name
          </th>
          <td mat-cell *matCellDef="let item" class="hide-column">
            {{ item.parentName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="validFrom">
          <th
            class="page-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Valid From
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.validFrom | customDate | date: 'MM-dd-yyyy'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="validTill">
          <th
            class="page-table-header"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            Valid Till
          </th>
          <td mat-cell *matCellDef="let item">
            {{ item.validTill | customDate | date: 'MM-dd-yyyy'}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="editKid(row)"
          [ngClass]="row.rowClass"
        ></tr>
      </table>
      <div *ngIf="vm.length ===0" class="sub-header-container">
        <div class="info">No Records Found./div>
      </div>
    </div>

  </div>

</div>
<ng-template #loading>
  <div class="spinner-container">
    <ngx-spinner
      bdOpacity="0.9"
      bdColor=""
      size="large"
      color="#FF673D"
      type="square-jelly-box"
      [fullScreen]="true"
    >
      <p class="spinner-text">Loading ...</p>
    </ngx-spinner>
  </div>
</ng-template>
