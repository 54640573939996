import { KidLevel } from './../../models/school/kid-level';
import { KidInfo } from '../../models/school/kid-info';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { KidModel } from 'src/app/models/school/kid-model';
import { KidRequest } from 'src/app/models/school/kid-request';
import { Kid } from 'src/app/models/kid';
import { ConfirmDialogModel } from 'src/app/models/confirm-dialog-model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SkillAnswerRequest } from 'src/app/models/school/skill-answer-request';
import { KidLevelRequest } from 'src/app/models/school/kid-level-request';

@Component({
  standalone: false,
  selector: 'app-ieb-kids',
  templateUrl: './ieb-kids.component.html',
  styleUrls: ['./ieb-kids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IebKidsComponent implements OnInit, OnChanges {

  constructor( public dialog: MatDialog) { }
  @Input() model: KidModel;
  @Input() viewOnly = false;
  @Input() adminView = false;
  @Output() addKid = new EventEmitter<KidRequest>();
  @Output() updateKid = new EventEmitter<KidRequest>();
  @Output() deleteKid = new EventEmitter<KidRequest>();
  newKid: KidInfo;
  schoolKids: KidInfo[];
  disableAdd: boolean;
  disableEdit: boolean;
  titleText = 'Children to Register';
  displayedColumns: string[] = [
    "name",
    "level",
    "action"
  ];

  ngOnInit() {
    if(this.viewOnly) {
      const indx = this.displayedColumns.indexOf('action');
      if(indx !== -1) {
        this.displayedColumns.splice(indx, 1);
      }
    }

    if(this.model) {
      this.setObjects();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.model) {
        this.setObjects();
      }
  }

  setObjects() {
    this.titleText = this.viewOnly ?'Registered Children': this.titleText;
    if(!this.model.schoolKids || this.model.schoolKids.length === 0){
      this.titleText = '';
      if(!this.model.kids || this.model.kids.length === 0) {
        this.newKid = this.getSchoolKid(this.model.schoolParentId, null);
      } else {
        this.newKid = this.getSchoolKid(this.model.schoolParentId, this.model.kids[0].kid);
      }
    }
    this.disableAdd = this.newKid ? true : false;


  }

  clickAddKid() {
    if(!this.model.kids || this.model.kids.length === 0) {
      this.newKid = this.getSchoolKid(this.model.schoolParentId, null);
    }
    else {
      if(this.model.schoolKids && this.model.schoolKids.length > 0) {
        const pkids = this.model.kids.filter(({ kidId: id1 }) => !this.model.schoolKids.some(({ kidId: id2 }) => id2 === id1));

        // let pkids =  this.model.kids.filter((p) => {
        //   return this.model.schoolKids.find((sp) => {
        //     return sp.kidId !== p.kidId;
        //   })
        // })
        if(pkids && pkids.length > 0) {
          this.newKid = this.getSchoolKid(this.model.schoolParentId, pkids[0].kid);
          this.disableAdd = true;
          return;
        }
        else {
          this.newKid = this.getSchoolKid(this.model.schoolParentId, null);
          this.disableAdd = true;
          return;
        }
      } else {
        this.newKid = this.getSchoolKid(this.model.schoolParentId, null);
        this.disableAdd = true;
        return;
      }
    }
    this.disableAdd = this.newKid ? true : false;
  }

  addNewKid(newKid: KidRequest) {
    this.addKid.emit(newKid);
    this.newKid = null;
  }

  editKid(editKid: KidRequest){
    this.updateKid.emit(editKid);
    this.newKid = null;
    this.disableAdd = this.newKid ? true : false;
    this.disableEdit = false;
  }

  onCancelNewKid(cancel: boolean) {
    if(cancel) {
      this.newKid = null;
      this.disableAdd = this.newKid ? true : false;
      this.disableEdit = false;
    }

  }

  onEditKid($event: any, schoolKid: KidInfo) {
    $event.stopPropagation();
    this.newKid = schoolKid;
    this.disableAdd = this.newKid ? true : false;
    this.disableEdit = true;
  }

  openDeleteAlert($event: any, schoolKid: KidInfo) {
    $event.stopPropagation();
    const dialogData = new ConfirmDialogModel(
      "Alert",
      "Are you sure? You want to delete this child!.",
      "alert",
      true
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      height: "300px",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let level = {
          id: schoolKid.kidLevel.id
        };
        let skills = schoolKid.skills.map((skill) =>
        ({
            id: skill.id,
            answerId: skill.skillAnswerId,
            schoolKidId: schoolKid.id,
            skillAnswerId: skill.skillAnswerId,
            skillDefId: skill.skillDefId,
            skillDefType: skill.skillDefType,
            skillAnswer: skill.skillAnswer
        } as SkillAnswerRequest));
        const request = {
          id: schoolKid.id,
          kidLevel: level as KidLevelRequest,
          kidSkills: {

            skills: skills
          }
        } as KidRequest

        this.deleteKid.emit(request);
      }
    });
  }



  getSchoolKid(parentId: number, kid: Kid): KidInfo {
    return {
      id: 0,
      schoolParentId: parentId,
      dob: null,
      kidId: kid == null ? 0 : kid.id,
      email: '',
      remove: false,
      kid: {
        id: kid == null ? 0 : kid.id,
        firstName: kid == null ? '' : kid.firstName,
        lastName: kid == null ? '' : kid.lastName,
        name: kid == null ? '' : kid.name,
        gender: kid == null ? '' : kid.gender,
        birthYear: kid == null ? 0 : kid.birthYear,
        birthMonth: kid == null ? 0 : kid.birthMonth,
        validation: null
      },
      kidLevel: null,
      skills: null
    };
  }
}
function Injectable(arg0: { providedIn: string; }): (target: typeof IebKidsComponent) => void | typeof IebKidsComponent {
  throw new Error('Function not implemented.');
}

