<div class="card-skeleton-container">
  <div class="card-skeleton-header">
    <div class="circle"></div>
    <div class="content content-short"></div>
  </div>
  <div class="card-skeleton-body">
    <div class="card-skeleton-section">
      <div class="card-skeleton-row">
        <div class="circle"></div>
        <div class="content content-long"></div>
      </div>
      <div class="card-skeleton-row">
        <div class="content content-long single-line-top"></div>
      </div>
      <div class="card-skeleton-row">
        <div class="content content-long single-line"></div>
      </div>
    </div>

    <div class="card-skeleton-section">
      <div class="card-skeleton-row">
        <div class="circle"></div>
        <div class="content content-long"></div>
      </div>
      <div class="card-skeleton-row">
        <div class="content content-long single-line-top"></div>
      </div>
      <div class="card-skeleton-row">
        <div class="content content-long single-line"></div>
      </div>
    </div>
  </div>
</div>
