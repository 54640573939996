<div class="teams-container">
  <div class="teams-section">
    <div class="teams-header-section">
      <div class="header">ISGL Team</div>
    </div>
    <div class="teams-info-section">
      <div class="teams-info-header header">BOARD OF TRUSTEES (BoT)</div>
      <div class="teams-card-row" *ngIf="botTeam">
        <div *ngFor="let bot of botTeam">
          <app-team-card [team]="bot"></app-team-card>
        </div>
      </div>
    </div>
    <div class="teams-info-section">
      <div class="teams-info-header header">Executive Committee (EC)</div>
      <div class="teams-card-row" *ngIf="ecTeam">
        <div *ngFor="let ec of ecTeam">
          <app-team-card [team]="ec"></app-team-card>
        </div>
      </div>
    </div>
  </div>
</div>
