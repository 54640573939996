import { HelperService } from 'src/app/services/helper.service';
import { SelectListItem } from './../../models/select-list-item';
import {
  ChangeDetectorRef, Component, Input, OnInit, OnChanges, Renderer2,
  ViewChild, ElementRef, Output, EventEmitter
} from '@angular/core';
import { MemberPayment } from 'src/app/models/member-payment';
import { PaymentValidation } from 'src/app/models/payment-validation';
import { ValidationError } from 'src/app/models/validation-error';
import { MemberPaymentService } from 'src/app/services/member-payment.service';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MM/DD/YYYY',
  },
};

@Component({
  standalone: false,
  selector: 'app-member-payment',
  templateUrl: './member-payment.component.html',
  styleUrls: ['./member-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MemberPaymentComponent implements OnInit, OnChanges {
  @Input() memberId: number;
  @Output() paymentCollected = new EventEmitter<MemberPayment>();
  @Output() paymentDeleted = new EventEmitter<boolean>();
  @Output() paymentUpdated = new EventEmitter<MemberPayment>();
  payment: MemberPayment;
  payments: MemberPayment[];
  disableDelete: boolean;
  validityInMonthsList: SelectListItem[];
  membershipYears: SelectListItem[];
  paymentDate: any;
  faDollarSign = faDollarSign;
  showAddPayment = false;
  newPaymentForm: FormGroup;
  newPayment: MemberPayment = new MemberPayment();
  defaultLabel = 'Select';
  @ViewChild('newPaymentIdInput') newPaymentIdInput: ElementRef;
  constructor(private paymentService: MemberPaymentService,
    private cd: ChangeDetectorRef, private helperService: HelperService,
    private formBuilder: FormBuilder, private renderer: Renderer2, private snackBar: MatSnackBar) { }

  get paymentId() { return this.newPaymentForm.get('paymentId'); }
  get amount() { return this.newPaymentForm.get('amount'); }
  get newPaymentDate() { return this.newPaymentForm.get('newPaymentDate'); }
  get validityInMonths() { return this.newPaymentForm.get('validityInMonths'); }
  get note() { return this.newPaymentForm.get('note'); }
  get membershipYear() { return this.newPaymentForm.get('membershipYear'); }
  ngOnInit() {
    // this.newPayment = new MemberPayment();
    this.getLists();
    this.newPaymentForm = this.formBuilder.group({
      paymentId: ['', Validators.required],
      amount: ['', Validators.required],
      newPaymentDate: ['', Validators.required],
      validityInMonths: ['', Validators.required],
      note: [''],
      membershipYear: ['', Validators.required]
    }, { updateOn: 'blur' });
  }
  ngOnChanges() {
    if (this.memberId) {
      this.getPayments();
    }
  }

  getPayments() {
    this.paymentService.paymentlist(this.memberId).subscribe(data => {
      this.payments = Object.assign([], data);

      this.payments.forEach(m => {
        if (moment(m.paymentDateDisplay).isValid()) {
          m.paymentDate = moment(m.paymentDateDisplay);
        }
        m.validation = new PaymentValidation(new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false),
          new ValidationError(false), false);

        if (this.membershipYears) {
          const item = this.membershipYears.find(x => x.value === m.membershipYear);
          if (item != null) {
            item.disabled = true;
          }
        }
      });
    });
  }

  getLists() {
    this.validityInMonthsList = this.helperService.validityInMonthsListGet();
    this.membershipYears = this.helperService.membershipYearsGet();
  }

  updatePayment(paymentUpdated: MemberPayment) {
    this.paymentService.paymentUpdate(paymentUpdated).subscribe(data => {
      paymentUpdated.expirationDate = data.expirationDate;
      const index = this.payments.findIndex(m => m.id === paymentUpdated.id);
      this.payments.splice(index, 1, paymentUpdated);
      this.paymentUpdated.emit(paymentUpdated);
      this.cd.detectChanges();
    });
  }

  setUpdateValue(id: number, field: string, value: any) {
    let toUpdate = false;
    const updatedPayment = this.payments.find(c => c.id === id);
    toUpdate = this.validateUpdate(id, field, value, updatedPayment);
    if (toUpdate) {
      this.updatePayment(updatedPayment);
    }
  }

  validateUpdate(id: number, field: string, value: any, payment: MemberPayment): boolean {
    let isValid = true;
    if (field === 'paymentId') {
      if (value.length === 0) {
        payment.validation.paymentId.hasError = true;
        payment.validation.paymentId.errorMessage = 'Required';
        isValid = false;
      } else {
        payment.validation.paymentId.hasError = false;
      }
    }
    if (field === 'amount') {
      if (!value) {
        payment.validation.amount.hasError = true;
        payment.validation.amount.errorMessage = 'Required';
        isValid = false;
      } else {
        payment.validation.amount.hasError = false;
      }
    }
    if (field === 'paymentDate') {
      if (!value) {
        payment.validation.paymentDate.hasError = true;
        payment.validation.paymentDate.errorMessage = 'Required';
        isValid = false;
      } else {
        payment.validation.paymentDate.hasError = false;
      }
    }
    if (field === 'validityInMonths') {
      if (!value) {
        payment.validation.validityInMonths.hasError = true;
        payment.validation.validityInMonths.errorMessage = 'Required';
        isValid = false;
      } else {
        payment.validation.validityInMonths.hasError = false;
        payment.validityInMonths = value;
      }
    }
    isValid =
      payment.validation.paymentId.hasError || payment.validation.amount.hasError
        || payment.validation.validityInMonths.hasError || payment.validation.paymentDate.hasError
        ? false
        : true;

    return isValid;

  }

  deletePayment(payment: MemberPayment) {
    if (this.disableDelete) {
      return;
    }
    let dismissedByAction = false;
    this.disableDelete = true;
    const index = this.payments.indexOf(payment);
    this.payments.splice(index, 1);
    const deleteSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'payment-snack-bar',
      data: {
        undoDelete: () => {
          dismissedByAction = true;
          this.payments.splice(index, 0, payment);
          this.cd.detectChanges();
          const undoSnackBar = this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: 'payment-snack-bar',
            data: {
              dismissSnackbar: () => {
                this.snackBar.dismiss();
                this.disableDelete = false;
              },
              displayText: 'Undone!',
              type: 'message'
            }
          });
          this.disableDelete = false;
        },
        dismissSnackbar: () => {
          dismissedByAction = true;
          this.paymentService.paymentRemove(payment.id).subscribe();
          this.setMembershipYears();
          setTimeout(() => {
            this.emitPaymentDeleted();
          }, 0);
          this.snackBar.dismiss();
          this.disableDelete = false;
        },
        displayText: `${payment.paymentId} for $${payment.amount} deleted`,
        type: 'delete-kid'
      }
    });
    // delete the kid if the snackbar closes due to duration and not closed by user.
    deleteSnackBar.afterDismissed().subscribe(info => {
      if (info.dismissedByAction === false && dismissedByAction === false) {
        this.paymentService.paymentRemove(payment.id).subscribe();
        this.setMembershipYears();
        setTimeout(() => {
          this.emitPaymentDeleted();
        }, 0);
        this.disableDelete = false;
      }
    });
  }

  addPayment() {
    this.newPayment.validityInMonths = 12;
    this.newPayment.amount = 10;
    this.newPayment.note = 'Membership Fee';
    const membershipYear = this.membershipYears.find(x => x.disabled === false && x.value);
    this.showAddPayment = true;
    this.newPayment.membershipYear = membershipYear.value;
    const input = this.renderer.selectRootElement(this.newPaymentIdInput.nativeElement);
    setTimeout(() => input.focus(), 0);
  }

  emitPaymentCollected(collectedPayment: MemberPayment) {
    this.paymentCollected.emit(collectedPayment);
  }

  emitPaymentDeleted() {
    this.paymentDeleted.emit(true);
  }

  closeAddPayment() {
    this.showAddPayment = false;
    if (this.newPaymentForm) {
      this.newPaymentForm.reset();
    }
  }

  savePayment() {
    if (!this.newPaymentForm.valid) {
      return;
    }
    this.newPayment.memberId = this.memberId;
    this.payments = !this.payments || this.payments.length === 0 ? [] : this.payments;
    this.paymentService.paymentAdd(this.newPayment).subscribe(data => {
      if (data) {
        this.getPayments();
        this.emitPaymentCollected(data);
        this.closeAddPayment();

      }

    });
  }

  setMembershipYears() {
    this.membershipYears.forEach(m => {
      const payment = this.payments.find(p => p.membershipYear === m.value);
      if (payment != null) {
        m.disabled = true;
      } else {
        m.disabled = false;
      }
    });
  }

}
