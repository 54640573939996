import { Guarantor } from "./../../models/guarantor";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { SelectListItem } from "src/app/models/select-list-item";
import { HelperService } from "src/app/services/helper.service";
import { TenantProfile } from "src/app/models/tenant-profile";

@Component({
  standalone: false,
  selector: "app-guarantor",
  templateUrl: "./guarantor.component.html",
  styleUrls: ["./guarantor.component.scss"],
})
export class GuarantorComponent implements OnInit, OnChanges {
  @Input() model: Guarantor;
  @Input() referenceInfo: TenantProfile;
  @Input() readonly = false;
  @Output() notifyParent = new EventEmitter<Guarantor>();
  stateList: SelectListItem[];
  defaultLabel = "Select";
  phoneFormat = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  showChk: boolean = false;
  constructor(private helperService: HelperService) {}

  ngOnInit() {
    this.getStateList();
  }

  ngOnChanges() {
    if (this.referenceInfo) {
      if (
        !this.referenceInfo.validation.hasError &&
        this.referenceInfo.refName &&
        this.referenceInfo.refEmail &&
        this.referenceInfo.refPhone &&
        !this.model.name &&
        !this.model.email &&
        !this.model.phone
      ) {
        this.showChk = true;
      }
    }
  }

  getStateList() {
    this.stateList = this.helperService.statesGet();
    if (this.readonly) {
      this.stateList.forEach((i) => (i.disabled = true));
    }
  }

  setValue(field: string, value: any) {
    this.model.validation.hasError = !this.validateFields(field, value);
    this.notifyParent.emit(this.model);
  }

  validateFields(field: string, value: any): boolean {
    let isValid = true;
    if (field === "name") {
      if (value.length === 0) {
        this.model.validation.name.hasError = true;
        this.model.validation.name.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.name.hasError = false;
      }
    }
    if (field === "guarantorAddress") {
      if (value.length === 0) {
        this.model.validation.address.hasError = true;
        this.model.validation.address.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.address.hasError = false;
      }
    }
    if (field === "guarantorCity") {
      if (value.length === 0) {
        this.model.validation.city.hasError = true;
        this.model.validation.city.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.city.hasError = false;
      }
    }
    if (field === "guarantorZip") {
      if (value.length === 0) {
        this.model.validation.zip.hasError = true;
        this.model.validation.zip.errorMessage = "Required";
        isValid = false;
      } else {
        this.model.validation.zip.hasError = false;
      }
    }
    if (field === "guarantorEmail") {
      if (value.length === 0) {
        this.model.validation.email.hasError = true;
        this.model.validation.email.errorMessage = "Required";
        isValid = false;
      } else if (!value.match(this.emailFormat)) {
        this.model.validation.email.hasError = true;
        this.model.validation.email.errorMessage = "Invalid email";
      } else {
        this.model.validation.email.hasError = false;
      }
    }
    if (field === "guarantorPhone") {
      if (value.length === 0) {
        this.model.validation.phone.hasError = true;
        this.model.validation.phone.errorMessage = "Required";
        isValid = false;
      } else if (!value.match(this.phoneFormat)) {
        this.model.validation.phone.hasError = true;
        this.model.validation.phone.errorMessage = "Invalid number";
      } else {
        this.model.validation.phone.hasError = false;
      }
    }

    // isValid =
    //   this.model.validation.name.hasError ||
    //   this.model.validation.address.hasError ||
    //   this.model.validation.city.hasError ||
    //   this.model.validation.zip.hasError ||
    //   this.model.validation.email.hasError ||
    //   this.model.validation.phone.hasError
    //     ? false
    //     : true;
    this.setModelValidation();
    isValid = !this.model.validation.hasError;

    return isValid;
  }

  setModelValidation() {
    this.model.validation.name.hasError = this.model.name ? false : true;
    this.model.validation.email.hasError =
      this.model.email && this.model.email.match(this.emailFormat)
        ? false
        : true;
    this.model.validation.phone.hasError =
      this.model.phone && this.model.phone.match(this.phoneFormat)
        ? false
        : true;
    this.model.validation.city.hasError = this.model.city ? false : true;
    this.model.validation.address.hasError = this.model.address ? false : true;
    this.model.validation.zip.hasError = this.model.zip ? false : true;
    this.model.validation.hasError =
      this.model.validation.name.hasError ||
      this.model.validation.address.hasError ||
      this.model.validation.city.hasError ||
      this.model.validation.zip.hasError ||
      this.model.validation.email.hasError ||
      this.model.validation.phone.hasError
        ? true
        : false;
  }

  chkChange(event: any) {
    if (event.checked) {
      this.model.name = this.referenceInfo.refName;
      this.model.email = this.referenceInfo.refEmail;
      this.model.phone = this.referenceInfo.refPhone;
    }
  }
}
