<div class="container">
  <div class="login-container">
    <div class="login-header">
      <div class="icon-wrapper">
        <a [routerLink]="'/index'" class="anchor-link">
          <i class="material-icons header-icon">arrow_back</i>
        </a>
      </div>
      <div class="header-title">
        <a [routerLink]="'/index'" class="anchor-link"> Home </a>
      </div>
    </div>
    <div
      class="dot"
      [ngClass]="{ 'grow-dot': isValid }"
      (animationend)="animationFinished($event)"
    ></div>
    <div class="login-controls">
      <div>
        <a [routerLink]="'/index'" class="anchor-link">
          <img
            class="isgl-logo"
            src="../../../assets/images/isgl-logo-gold-gradient.jpg"
            alt="Back to home page"
          />
        </a>
      </div>
      <div [ngClass]="showLogin ? 'show' : 'hide'">
        <div class="error-container">
          <div>
            <span
              class="error-message"
              [ngClass]="incorrectCredentials ? 'show' : 'hide'"
              >The username or password is invalid</span
            >
          </div>
        </div>

        <div>
          <input
            class="login-textbox"
            placeholder="Username"
            [(ngModel)]="user.userName"
          />
        </div>
        <div>
          <input
            class="login-textbox"
            placeholder="Password"
            type="password"
            [(ngModel)]="user.password"
            (keyup.enter)="login(); resetIncorrectCredentials()"
          />
        </div>

        <div class="login-button-container">
          <div
            class="login-button"
            [ngClass]="{
              'checking-credentials': isCheckingCreds,
              validated: isValid
            }"
            (click)="login(); resetIncorrectCredentials()"
          >
            <!-- <div class="dot" [ngClass]="{ 'grow-dot': isValid }" (transitionend)="test($event)"></div> -->
          </div>
          <!-- <div class="dot" [ngClass]="{ 'grow-dot': isValid }" (transitionend)="test($event)"></div> -->
        </div>
        <div class="links-container">
          <div>
            <a class="forgot-password-link" (click)="toggleForgotPassword()"
              >Forgot Password?</a
            >
          </div>
          <div>
            <a class="register-new-user-link" (click)="register()">Register</a>
          </div>
        </div>
      </div>
      <div
        class="password-reset-container"
        [ngClass]="showForgotPassword ? 'show' : 'hide'"
      >
        <div class="title">Forgot your password?</div>
        <div class="title-text">
          Enter your address and we'll send you a link.
        </div>
        <form [formGroup]="emailForm">
          <div class="textbox-container">
            <input
              #emailAddress
              class="login-textbox reset-email-textbox"
              placeholder="Email"
              formControlName="email"
            />
            <div class="validation-error-container email-validation">
              <div class="validation-error-icon">
                <i class="material-icons">error</i>
              </div>
              <div class="validation-error-content">
                <span class="validation-error">
                  <span *ngIf="f.email.errors?.required">Required</span>
                  <span *ngIf="f.email.errors?.email">Invalid email</span>
                </span>
              </div>
            </div>
          </div>
        </form>
        <div class="reset-buttons-container">
          <button
            class="password-reset-button"
            (click)="toggleForgotPassword()"
          >
            CANCEL
          </button>
          <button
            class="password-reset-button"
            [disabled]="!emailForm.valid"
            (click)="sendEmail(emailAddress.value)"
          >
            SEND EMAIL
          </button>
        </div>
      </div>

      <div
        class="password-reset-container"
        [ngClass]="showPasswordReset ? 'show' : 'hide'"
      >
        <div class="title">Create new password</div>
        <div class="sub-title">
          Password must be 8 characters long and must have one capital letter,
          one number and one special character.
        </div>
        <form [formGroup]="resetPasswordForm">
          <div class="textbox-container">
            <input
              class="login-textbox"
              placeholder="Password"
              type="password"
              formControlName="password"
            />
            <div class="validation-error-container password-validation">
              <div class="validation-error-icon">
                <i class="material-icons">error</i>
              </div>
              <div class="validation-error-content">
                <span
                  class="validation-error"
                  [ngClass]="p.password.errors?.pattern ? 'long-message' : ''"
                >
                  <span *ngIf="p.password.errors?.required">Required</span>
                  <span *ngIf="p.password.errors?.pattern"
                    >Invalid Password</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="textbox-container">
            <input
              #confirmPassword
              class="login-textbox"
              placeholder="Confirm Password"
              type="password"
              formControlName="confirm"
            />
            <div class="validation-error-container password-validation">
              <div class="validation-error-icon">
                <i class="material-icons">error</i>
              </div>
              <div class="validation-error-content">
                <span
                  class="validation-error"
                  [ngClass]="p.confirm.errors?.required ? '' : 'long-message'"
                >
                  <span *ngIf="p.confirm.errors?.required">Required</span>
                  <span *ngIf="p.confirm.errors?.mustMatch"
                    >Passwords don't match</span
                  >
                  <span *ngIf="p.confirm.errors?.pattern"
                    >Invalid Password</span
                  >
                </span>
              </div>
            </div>
          </div>
        </form>
        <div class="reset-buttons-container">
          <button
            class="password-reset-button"
            [disabled]="!resetPasswordForm.valid"
            (click)="changePassword(confirmPassword.value)"
          >
            SAVE PASSWORD
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="image-container">
    <div class="image-logo-container">
      <div class="image-layer"></div>
    </div>
  </div>
</div>
