<div class="welfare-container">
  <div class="service-section">
    <div class="header-section">
      <div class="header">Welfare</div>
    </div>
    <div class="service-info-section">
      <div class="service-info">
        <ul class="service-list">
          <li class="list-item">
            Zakat is one of the five Pillars of Islam, at ISGL, we strive to
            provide those in our community who are in need a monthly stipend
            from the Zakat funds, contributed by the community.
          </li>
          <li class="list-item">
            Currently, with the generous donations from our community members,
            we are able to provide&nbsp;
            <span class="bold-text">four families</span>&nbsp; with monthly
            assistance.
          </li>
        </ul>
        <div class="info-italics">
          <i class="material-icons info-icon">info</i>
          During the start of the COVID-19 Pandemic, ISGL formed a Welfare
          Committee to follow up with all of the members of our community to
          ensure they are staying safe, and to find out if any assistance was
          needed in terms of finances, food, transportation, etc.
        </div>
      </div>
    </div>
  </div>
</div>
