<app-header></app-header>
<div class="about-isgl-container">
  <!-- <div class="tab-header"></div> -->
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-align-tabs="center" animationDuration="0ms">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="link.active"
      (click)="toggleTab(link.emptyResultType)"
    >
      {{ link.label }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <!-- <router-outlet></router-outlet> -->
  </mat-tab-nav-panel>
  <div *ngIf="emptyResultType === 'vision'" class="empty-results-container">
    <app-vision></app-vision>
  </div>
  <!-- <div *ngIf="emptyResultType === 'locations'" class="empty-results-container">
    <app-nikah></app-nikah>
  </div> -->
  <div *ngIf="emptyResultType === 'team'" class="empty-results-container">
    <app-teams></app-teams>
  </div>
  <div
    *ngIf="emptyResultType === 'constitution'"
    class="empty-results-container"
  >
    <app-constitution></app-constitution>
  </div>
</div>
<app-footer></app-footer>
