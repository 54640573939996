import { UserUpdate } from './../../models/user-update';
import { SelectListItem } from './../../models/select-list-item';
import { RoleService } from './../../services/role.service';
import { UserService } from './../../services/user.service';
import { ApplicationUser } from './../../models/application-user';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, Renderer2, ViewChild, ElementRef } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.scss']
})
export class UserManageComponent implements OnInit {
  @Input() userUpdate: ApplicationUser;
  @Output() notifyUpdates = new EventEmitter<ApplicationUser>();
  roles: SelectListItem[];
  defaultLabel = 'Select';
  placeHolderFirstName = 'First name';
  placeHolderLasttName = 'Last name';
  constructor(private userService: UserService, private roleService: RoleService) {
    this.getRoles();
  }

  ngOnInit() {
  }

  getRoles() {
    this.roleService.roleList().subscribe(data => {
      if (data) {
        this.roles = data.map(item => ({
          label: item,
          value: item,
          disabled: false
        }));
      }

    });
  }

  OnRoleSelectChange(role: string) {
    this.userUpdate.role = role;
    this.updateUser();
  }

  updateUser() {
    const userToUpdate: UserUpdate = {
      id: this.userUpdate.id,
      userName: this.userUpdate.userName,
      firstName: this.userUpdate.firstName,
      lastName: this.userUpdate.lastName,
      email: this.userUpdate.email,
      role: this.userUpdate.role,
      phone: null
    };
    this.userService.userUpdate(userToUpdate).subscribe(data => {
      if (data) {
        this.updateParent();
      }
    });

  }

  setUpdateValue(field: string, value: string) {
    let toUpdate = false;

    toUpdate = this.validateUpdate(field, value, this.userUpdate);
    if (toUpdate) {
      this.updateUser();
    }
  }

  validateUpdate(field: string, value: string, user: ApplicationUser): boolean {
    let isValid = true;
    if (field === 'firstName') {
      if (value.length === 0) {
        user.validation.firstName.hasError = true;
        user.validation.firstName.errorMessage = 'Required';
        isValid = false;
      } else {
        user.validation.firstName.hasError = false;
      }
    }
    if (field === 'lastName') {
      if (value.length === 0) {
        user.validation.lastName.hasError = true;
        user.validation.lastName.errorMessage = 'Required';
        isValid = false;
      } else {
        user.validation.lastName.hasError = false;
      }
    }


    isValid = user.validation.firstName.hasError || user.validation.lastName.hasError
      ? false : true;

    return isValid;

  }

  updateParent() {
    this.notifyUpdates.emit(this.userUpdate);
  }

}
