<div class="tenant-override-container">
  <div class="override-container-row">
    <div class="details-container">
      <div class="details-section">
        <div class="details-section-wrapper" *ngIf="model && model.tenantId">
          <div class="details-wrapper tall">
            <div class="subheader-text">Payment Override:</div>
          </div>
          <div class="details-wrapper">
            <div class="details-item-container" *ngIf="overrideTypes">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Type:</span>
                </div>
              </div>
              <div class="details-info-item select-wrapper top">
                <app-flat-select
                  [selectOptions]="overrideTypes"
                  [(ngModel)]="selectedType.value"
                  [defaultLabel]="'Please Select'"
                  [(value)]="selectedType.value"
                  (selectOptionChange)="setType($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container" [ngClass]="{hide: !showMonthYear}">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Month:</span>
                </div>
              </div>
              <div class="details-info-item">
                <app-flat-input
                  [placeHolder]="'Month'"
                  [(value)]="paymentMonth"
                  [(ngModel)]="paymentMonth"
                  [inputType]="'text'"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container" [ngClass]="{hide: !showMonthYear}">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Year:</span>
                </div>
              </div>
              <div class="details-info-item thin">
                <app-flat-input
                  [placeHolder]="'Year'"
                  [(value)]="paymentYear"
                  [(ngModel)]="paymentYear"
                  [inputType]="'text'"
                  [disabled]="true"
                  class="input"
                >
                </app-flat-input>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper">
                  <span class="item-label">Amount:</span>
                </div>
              </div>
              <div class="details-info-item">
                <div class="currency-container">
                  <span class="currency"> $ </span>
                </div>

                <app-flat-input
                  [placeHolder]="'Amount'"
                  [(value)]="paymentAmount"
                  [(ngModel)]="paymentAmount"
                  [inputType]="'number'"
                  class="input left-margin"
                  (input)="setDisable($event.target.value)"

                >
                </app-flat-input>
              </div>
            </div>
          </div>

          <div class="details-wrapper">
            <div class="btn-container">
              <button
                mat-raised-button
                class="summary-btns save"
                [disabled]="disableSave"
                (click)="save()"
              >
                Save Payment Override
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
