<div>
  <div class="item-container" *ngIf="model">
    <div class="sub-header">
      Tenant Name: {{ model.firstName }} {{ model.lastName }} - Required
      Info(All fields are required)
    </div>
  </div>
  <div class="item-container left-align">
    <div class="item-label-wrapper skinny">
      <span class="item-label">Age:</span>
    </div>

    <div class="item-input-wrapper xskinny">
      <app-flat-input
        [placeHolder]="'Age'"
        [(value)]="model.age"
        [(ngModel)]="model.age"
        [inputType]="'number'"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('age', model.age)"
        (blur)="setValue('age', model.age)"
        (focusChanged)="setValue('age', model.age)"
      >
      </app-flat-input>
    </div>
    <div class="item-label-wrapper skinny">
      <span class="item-label">Gender:</span>
    </div>
    <div class="item-select-wrapper skinny left-close right-margin">
      <app-flat-select
        [selectOptions]="genderList"
        [(ngModel)]="model.gender"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.gender"
        (selectOptionChange)="setValue('gender', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-label-wrapper skinny">
      <span class="item-label">Student:</span>
    </div>
    <div class="item-select-wrapper skinny left-close right-margin">
      <app-flat-select
        [selectOptions]="studentStatusList"
        [(ngModel)]="model.isStudent"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.isStudent"
        (selectOptionChange)="setValue('isStudent', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
    <div class="item-label-wrapper thin">
      <span class="item-label">USA Residency:</span>
    </div>
    <div class="item-select-wrapper skinny left-close right-margin">
      <app-flat-select
        [selectOptions]="residencyStatusList"
        [(ngModel)]="model.residencyStatus"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.residencyStatus"
        (selectOptionChange)="setValue('residencyStatus', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
  </div>
  <div class="item-container left-align">
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Reference Name'"
        [(value)]="model.refName"
        [(ngModel)]="model.refName"
        [hasError]="model.validation.refName.hasError"
        [errorMessage]="model.validation.refName.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('refName', model.refName)"
        (blur)="setValue('refName', model.refName)"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper">
      <app-flat-input
        [placeHolder]="'Reference Email'"
        [(value)]="model.refEmail"
        [(ngModel)]="model.refEmail"
        [hasError]="model.validation.refEmail.hasError"
        [errorMessage]="model.validation.refEmail.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('refEmail', model.refEmail)"
        (blur)="setValue('refEmail', model.refEmail)"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Reference Phone'"
        [(value)]="model.refPhone"
        [(ngModel)]="model.refPhone"
        [hasError]="model.validation.refPhone.hasError"
        [errorMessage]="model.validation.refPhone.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('refPhone', model.refPhone)"
        (blur)="setValue('refPhone', model.refPhone)"
      >
      </app-flat-input>
    </div>
  </div>
</div>
