<div class="team-card-container" [ngClass]="team.type === 'BOT' ? 'bot' : 'ec'">
  <div class="card-row">
    <div class="column image-column">
      <div class="image-container" *ngIf="team.file">
        <img [src]="team.file.url" class="person-image" />
      </div>
      <div
        class="image-container profile-icon"
        *ngIf="!team.file && showProfileIcon"
      >
        <fa-icon
          [icon]="faPortrait"
          class="person-image profile-icon"
        ></fa-icon>
      </div>
    </div>
    <div class="column name-column">
      <div class="person-container">
        <div class="row team-person spacer-bottom">
          {{ team.name }}
        </div>
        <div
          class="row team-person team-position"
          *ngIf="!team.position.startsWith('Bot')"
        >
          {{ team.position }}
        </div>
        <div class="row team-person team-contact" *ngIf="team.contact">
          {{ team.contact }}
        </div>
      </div>
    </div>
  </div>
</div>
