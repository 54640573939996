<div class="lease-update-container">
  <div class="lease-update-container-row">
    <div class="update-container">
      <div class="update-section">
        <div class="update-section-wrapper" *ngIf="model && model.leaseId">
          <div class="update-wrapper">
            <div class="subheader-text">Lease Update:</div>
          </div>
          <div class="update-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">Start:</span>
                </div>
              </div>
              <div class="date-wrapper">
                <div class="input-date date-picker">
                  <input
                    [matDatepicker]="datePicker"
                    class="input-date"
                    [(ngModel)]="model.startDate"
                    [min]="minStartDate"
                    [max]="maxStartDate"
                    (dateInput)="datePicker.close()"
                    (dateChange)="onDateChange(); datePicker.close()"
                    (focus)="datePicker.open()"
                    readonly
                    [disabled]="disableView"
                  />
                  <mat-datepicker-toggle
                    class="item-date-picker"
                    matSuffix
                    [for]="datePicker"
                  >
                  </mat-datepicker-toggle>
                  <mat-datepicker #datePicker></mat-datepicker>
                </div>
              </div>

              <div class="details-info-item">
                <div class="item-label-wrapper thin">
                  <span class="item-label">End:</span>
                </div>
                <div class="date-wrapper">
                  <div class="input-date date-picker">
                    <input
                      [matDatepicker]="datePicker1"
                      class="input-date"
                      [(ngModel)]="model.endDate"
                      (dateInput)="datePicker.close()"
                      (dateChange)="onDateChange(); datePicker.close()"
                      (focus)="datePicker.open()"
                      readonly
                      [disabled]="disableView"
                    />
                    <mat-datepicker-toggle
                      class="item-date-picker"
                      matSuffix
                      [for]="datePicker1"
                    >
                    </mat-datepicker-toggle>
                    <mat-datepicker #datePicker1></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="update-wrapper">
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin top-margin">
                  <span class="item-label">Room:</span>
                </div>
              </div>
              <div
                class="details-info-item select"
                *ngIf="roomList && selectedRoom"
              >
                <app-flat-select
                  [selectOptions]="roomList"
                  [(ngModel)]="selectedRoom.id"
                  [(defaultLabel)]="defaultLabel"
                  [(value)]="selectedRoom.id"
                  [disabledToolTipText]="'Not Available'"
                  (selectOptionChange)="setRentList($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>

            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin top-margin">
                  <span class="item-label">Rent:</span>
                </div>
              </div>
              <div
                class="details-info-item select"
                *ngIf="rentList && selectedRent"
              >
                <app-flat-select
                  [selectOptions]="rentList"
                  [(ngModel)]="selectedRent.id"
                  [(defaultLabel)]="defaultLabel"
                  [(value)]="selectedRent.id"
                  (selectOptionChange)="setRent($event)"
                  ngDefaultControl
                ></app-flat-select>
              </div>
            </div>
            <div class="details-item-container">
              <div class="details-info-item">
                <div class="item-label-wrapper thin top-margin">
                  <span class="item-label">Deposit:</span>
                </div>
              </div>
              <div class="details-info-item">
                <div class="currency-container">
                  <span class="currency"> $ </span>
                </div>

                <app-flat-input
                  [placeHolder]="'Deposit'"
                  [(value)]="model.securityDeposit"
                  [(ngModel)]="model.securityDeposit"
                  [inputType]="'number'"
                  class="input left-margin"
                  (input)="setSecurityDeposit($event)"
                  [disabled]="disableView"
                >
                </app-flat-input>
              </div>
            </div>
          </div>
          <div class="update-wrapper">
            <div class="details-wrapper">
              <div class="btn-container">
                <button
                  mat-raised-button
                  class="summary-btns save"
                  (click)="update()"
                  [disabled]="updateDisable"
                >
                  Update Lease
                </button>
              </div>
            </div>
          </div>

          <div class="details-section-wrapper" *ngIf="!model || !model.leaseId">
            <div class="details-wrapper">
              <div class="subheader-text">Lease is not created yet!:</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div>
  </div>
</div>
