<div class="page-container">
  <div class="page-filter-container">
    <div class="page-info-container">
      <div class="total-count-container">
        <div
          class="total-count-text"
          *ngIf="userListResponse && userListResponse.totalCount"
        >
          {{ userListResponse.totalCount }} users
        </div>
      </div>
    </div>
    <div class="page-search-container">
      <input
        type="text"
        placeholder="Search by firstName, lastName, userName and role"
        class="page-search-input"
        [(ngModel)]="query"
        (keyup)="userSearch($event)"
      />
      <fa-icon
        [icon]="faTimesCircle"
        class="page-clear-search-icon"
        *ngIf="query"
        (click)="clearSearch()"
      ></fa-icon>
      <a class="page-search-btn" (click)="userSearch($event)">
        <span class="page-search-btn-content">
          <fa-icon [icon]="faSearch" class="page-search-icon"></fa-icon>
        </span>
      </a>
    </div>
  </div>
  <div class="page-table-container" *ngIf="dataSource; else loading">
    <div class="infinite-scroll">
    <div
      infiniteScroll
      [infiniteScrollDistance]="0.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <table mat-table [dataSource]="dataSource" *ngIf="usersLoaded | async">
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Name
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Username
          </th>
          <td mat-cell *matCellDef="let user" class="hide-column">
            {{ user.userName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th
            class="page-table-header hide-column"
            mat-header-cell
            *matHeaderCellDef
          >
            Role
          </th>
          <td mat-cell *matCellDef="let user" class="hide-column">
            {{ user.role }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="showUserSummary(row.id, row.name)"
        ></tr>
      </table>
    </div>
    </div>
    <!-- <div class="spinner-container" *ngIf="!notLoading">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor=""
        size="large"
        color="#FF673D"
        type="square-jelly-box"
        [fullScreen]="true"
      >
        <p class="spinner-text">Loading ...</p>
      </ngx-spinner>
    </div> -->
  </div>
  <div class="summary-wrapper">
    <div
      class="page-summary-container"
      [ngClass]="{
        'summary-panel-slide-in': showSummary,
        'summary-panel-slide-out': hideSummary
      }"
    >
      <div class="page-summary-header">
        <div class="page-summary-header-name">
          {{ summaryName }}
          <div class="summary-name">
            {{ summaryUserName }}
          </div>
        </div>
      </div>
      <div class="close-summary-wrapper">
        <a class="close-summary-button" (click)="hideUserSummary()">
          <i class="material-icons expand">expand_more</i>
        </a>
        <span class="close-info"> click or escape to close </span>
      </div>
      <div *ngIf="summaryUser">
        <app-user-manage
          [userUpdate]="summaryUser"
          (notifyUpdates)="userUpdated($event)"
        ></app-user-manage>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <app-card-skeleton></app-card-skeleton>
  </ng-template>
</div>
