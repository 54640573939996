import { UploadFile } from "src/app/models/upload-file";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.scss"],
})
export class FileListComponent implements OnInit {
  @Input() uploadedFiles: UploadFile[];
  @Input() showDelete: boolean = false;
  @Input() showTitle: boolean = false;
  @Output() notifyDelete = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (!bytes || bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  deleteFile(id: number) {
    this.notifyDelete.emit(id);
  }
}
