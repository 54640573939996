<div>
  <div class="item-container" *ngIf="showInfo">
    <div class="sub-header">Vehicle Info (All fields are required)</div>
  </div>
  <div class="item-container left-align">
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Vehicle Name'"
        [(value)]="model.name"
        [(ngModel)]="model.name"
        [hasError]="model.validation.name.hasError"
        [errorMessage]="model.validation.name.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleName', model.name)"
        (blur)="setValue('vehicleName', model.name)"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'Model'"
        [(value)]="model.model"
        [(ngModel)]="model.model"
        [hasError]="model.validation.model.hasError"
        [errorMessage]="model.validation.model.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('vehicleModel', model.model)"
        (blur)="setValue('vehicleModel', model.model)"
      >
      </app-flat-input>
    </div>
    <div class="item-input-wrapper medium">
      <app-flat-input
        [placeHolder]="'License Plate'"
        [(value)]="model.licensePlate"
        [(ngModel)]="model.licensePlate"
        [hasError]="model.validation.licensePlate.hasError"
        [errorMessage]="model.validation.licensePlate.errorMessage"
        (keydown.enter)="$event.target.blur()"
        (keyup)="setValue('licensePlate', model.licensePlate)"
        (blur)="setValue('licensePlate', model.licensePlate)"
      >
      </app-flat-input>
    </div>
    <div class="item-select-wrapper skinny">
      <app-flat-select
        [selectOptions]="vehicleYears"
        [(ngModel)]="model.year"
        [(defaultLabel)]="defaultLabel"
        [(value)]="model.year"
        [hasError]="model.validation.year.hasError"
        [errorMessage]="model.validation.year.errorMessage"
        (selectOptionChange)="setValue('vehicleYear', $event)"
        ngDefaultControl
      ></app-flat-select>
    </div>
  </div>
</div>
