<div *ngIf="regModel" class="reg-detail-container">
  <div class="reg-detail">
    <div class="sub-header-container">
      <div class="info">{{infoText}} </div>
    </div>
    <div class="page-row" *ngIf ="regModel.kidRegistrations">
      <table mat-table [dataSource]="regModel.kidRegistrations">
        <ng-container matColumnDef="name">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
            Child Name
          </th>
          <td mat-cell *matCellDef="let k">
            {{ k.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="level">
          <th class="page-table-header" mat-header-cell *matHeaderCellDef>
           Level
          </th>
          <td mat-cell *matCellDef="let k">
            {{ k.level}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="row.rowClass"
        ></tr>
      </table>


      <!-- <app-ieb-kids [model]="kidModel" [viewOnly]="true"></app-ieb-kids> -->
    </div>
  </div>
</div>
